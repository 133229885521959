import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { scrollToTop } from "utils/helpers";
import "./style.scss";

export default function Pagination({
  totalRecords,
  uiProps,
  caller,
  activeTab,
  history,
}) {
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    setPageCount(Math.ceil(totalRecords / uiProps.queryParams.per_page));
  }, [totalRecords]);

  const handlePageClick = ({ selected }) => {
    uiProps.setQueryParams({
      ...uiProps.queryParams,
      page: selected + 1,
    });

    if (caller === "myStands") {
      history.push({
        pathname: "/my-profile/my-stands",
        search:
          "?" +
          new URLSearchParams({ activeTab: activeTab }).toString() +
          "&" +
          new URLSearchParams({ page: selected + 1 }).toString(),
      });
    }

    if (caller === "followedStands") {
      history.push({
        pathname: "/my-profile/followed-stands",
        search:
          "?" +
          new URLSearchParams({ page: selected + 1 }).toString(),
      });
    }

    if (caller === "myReservations") {
      history.push({
        pathname: "/my-profile/my-reservations",
        search:
          "?" +
          new URLSearchParams({ activeTab: activeTab }).toString() +
          "&" +
          new URLSearchParams({ page: selected + 1 }).toString(),
      });
    }
  };

  const handlePageSelect = (e) => {
    uiProps.setQueryParams({
      ...uiProps.queryParams,
      page: e,
    });

    if (caller === "myStands") {
      history.push({
        pathname: "/my-profile/my-stands",
        search:
          "?" +
          new URLSearchParams({ activeTab: activeTab }).toString() +
          "&" +
          new URLSearchParams({ page: e }).toString(),
      });
    }

    if (caller === "followedStands") {
      history.push({
        pathname: "/my-profile/followed-stands",
        search:
          "?" +
          new URLSearchParams({ page: e }).toString(),
      });
    }

    if (caller === "myReservations") {
      history.push({
        pathname: "/my-profile/my-reservations",
        search:
          "?" +
          new URLSearchParams({ activeTab: activeTab }).toString() +
          "&" +
          new URLSearchParams({ page: e }).toString(),
      });
    }

    scrollToTop();
  };

  return totalRecords > uiProps?.queryParams?.per_page ? (
    <div className="pagination-container">
      <div className="custom-pg-dropdown">
        <Dropdown>
          <Dropdown.Toggle
            className="btn btn-default dropdown-toggle"
            value={parseInt(uiProps?.queryParams?.page)}
          >
            {parseInt(uiProps?.queryParams?.page)}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {pageCount &&
              [...Array(pageCount).keys()].map((page, index) => (
                <Dropdown.Item
                  onClick={() => handlePageSelect(page + 1)}
                  value={page + 1}
                  key={index}
                >
                  {" "}
                  {page + 1}
                </Dropdown.Item>
              ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <div className="mx-3">
        Showing {parseInt(uiProps?.queryParams?.page)} of {pageCount}{" "}
        <span>pages</span>
      </div>
      <ReactPaginate
        previousLabel={<i className="bi bi-chevron-left"></i>}
        nextLabel={<i className="bi bi-chevron-right"></i>}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={pageCount}
        forcePage={parseInt(uiProps?.queryParams?.page - 1)}
        onPageChange={(e) => {
          handlePageClick(e);
          scrollToTop();
        }}
        containerClassName={"pagination"}
      />
    </div>
  ) : null;
}
