import React, { useRef, useState } from "react";
import ReactPlayer from "react-player";
import SwiperCore, { Navigation, Thumbs } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "./style.scss";
import playIcon from "../../../../assets/icons/play-icon.svg";
import defaultImage from "assets/images/default.png";
import { useHistory } from "react-router";

SwiperCore.use([Navigation, Thumbs]);

export default function ThumbnailCarusal({ pictures, standId, from, media }) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [playingStates, setPlayingStates] = useState([]);
  const history = useHistory();
  const videoRefs = useRef([]);

  const handleSlideChange = (swiper) => {
    const newPlayingStates = [...playingStates];

    newPlayingStates?.forEach((_, index) => {
      if (index !== swiper.activeIndex) {
        newPlayingStates[index] = false;
      }
    });

    setPlayingStates(newPlayingStates);
  };

  const togglePlay = (index) => {
    const newPlayingStates = [...playingStates];
    newPlayingStates[index] = !newPlayingStates[index];
    setPlayingStates(newPlayingStates);
  };

  if (from === "standBlog") {
    return (
      (media && media?.length > 0) && (
        <div className="thumbslider">
          <Swiper
            style={{
              "--swiper-navigation-color": "#fff",
              "--swiper-pagination-color": "#fff",
            }}
            spaceBetween={10}
            navigation={media?.length > 1}
            thumbs={{ swiper: thumbsSwiper }}
            className="mySwiper2 main-img"
            onSlideChange={handleSlideChange}
          >
            {media &&
              media?.map((mediaItem, index) => (
                <SwiperSlide key={index}>
                  {mediaItem?.video === null ? (
                    <img
                        src={mediaItem?.image || defaultImage}
                        alt=""
                        onError={(e) => (e.target.src = defaultImage)}
                    />
                  ) : (
                    <ReactPlayer
                      ref={(player) => (videoRefs.current[index] = player)}
                      url={mediaItem?.video}
                      controls={true}
                      width={"100%"}
                      height={"100%"}
                      playIcon={<img src={playIcon} alt="play" style={{ width: 'auto', height: 'auto', background: 'transparent' }} />}
                      light={encodeURI(mediaItem?.image)}
                      playsinline={true}
                      playing={playingStates[index]}
                      onClick={() => togglePlay(index)}
                    />  
                  )}
                </SwiperSlide>
              ))}
          </Swiper>
        </div>)
    )

  } else if (from != 'stand') {
    return (
      <div className="thumbslider">
        <Swiper
          style={{
            "--swiper-navigation-color": "#fff",
            "--swiper-pagination-color": "#fff",
          }}
          spaceBetween={10}
          navigation={true}
          thumbs={{ swiper: thumbsSwiper }}
          className="mySwiper2 main-img"
        >
          {pictures &&
            pictures.map((picture, index) => (
              <SwiperSlide key={index}>
                <img
                  src={picture.picture || defaultImage}
                  alt=""
                  onError={(e) => (e.target.src = defaultImage)}
                />
              </SwiperSlide>
            ))}
        </Swiper>
        <Swiper
          observer={true}
          observerParents={true}
          onSwiper={setThumbsSwiper}
          spaceBetween={14}
          slidesPerView={4}
          freeMode={true}
          watchSlidesProgress={true}
          className="mySwiper swiper-thumnail"
        >
          {pictures &&
            pictures.map((picture, index) => (
              <SwiperSlide key={index}>
                <img src={picture.picture} alt=""
                  onError={(e) => (e.target.src = defaultImage)}
                />
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    );
  } else {
    return (
      <div className="stand-swiper">
        <Swiper
          spaceBetween={10}
          navigation={pictures?.length > 1}
          thumbs={{ swiper: thumbsSwiper }}
          key={standId}
          className="stand-slider"
          pagination={{
            clickable: true,
          }}
        >
          {pictures &&
            pictures.map((picture, index) => (
              <SwiperSlide key={index}>
                <img
                  src={picture.picture || defaultImage}
                  alt=""
                  onError={(e) => (e.target.src = defaultImage)}
                  className="stand-img"
                  onClick={() => {
                    history.push(`/stand/${standId}`);
                  }}
                />
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    );
  }
}
