import { createSlice } from "@reduxjs/toolkit";

const initialReservationState = {
  error: null,
  actionsLoading: null,
  verifyingVoucher: false,
  verifyingReferral: false,
  listLoading: null,
  reservationsList: [],
  invitationList: [],
  reservationHistoryList: [],
  cancelledReservationList: [],
  totalReservations: null,
  reservationForEdit: null,
  clientSecret: null,
  publishableKey: null,
  reservationDetail: null,
  bookingDetails: null,
  pricingDetails: {},
  sentInvitesToHunters: [],
  joinedHunters: [],
  ihuntSettings: null,
  fundNotification: null,
  invitationDetailsLoading: false,
  invitationDetails: null,
  clickedOnNotification: false,
  additionalHunterTab: "Invitations",
  gettingReservationDetail: false,
  wavierAccepted: false,
  invitationLoading: false,
  activeReservationLoading: false,
  reservationHistoryLoading: false,
  cancelledReservationLoading: false,
  showPreConditionModal: false,
  showPreConditionError: false,
  processCompletionTitle: null,
  processCompletionDescription: null,
  huntApprovalSetting: null,
  rejectionReasonModal: false,
  rejectionReason: false
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const reservationSlice = createSlice({
  name: "reservations",
  initialState: initialReservationState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
      state.verifyingVoucher = false;
      state.verifyingReferral = false;
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    startVerifyVoucherCall: (state, action) => {
      state.error = null;
      state.verifyingVoucher = true;
    },
    startVerifyReferralCall: (state, action) => {
      state.error = null;
      state.verifyingReferral = true;
    },
    clearEntities: (state, action) => {
      state.reservationsList = null;
    },
    // reservations list fetched
    invitationRequest: (state) => {
      state.invitationLoading = true;
      state.invitationList = [];
      state.error = null;
      state.totalReservations = null;
    },
    invitationRequestFailed: (state) => {
      state.invitationLoading = false;
      state.invitationList = [];
      state.error = null;
      state.totalReservations = null;
    },
    invitationFetched: (state, action) => {
      state.invitationList = action.payload.entities;
      state.error = null;
      state.invitationLoading = false;
      state.totalReservations = action.payload.totalRecords;
    },
    activeReservationsRequest: (state, action) => {
      state.activeReservationLoading = true
      state.reservationsList = [];
      state.totalReservations = null;
      state.error = null;
    },
    activeReservationsFetched: (state, action) => {
      state.reservationsList = action.payload.entities;
      state.totalReservations = action.payload.totalRecords;
      state.error = null;
      state.activeReservationLoading = false;
    },
    activeReservationsRequestFailed: (state, action) => {
      state.reservationsList = [];
      state.activeReservationLoading = false
      state.totalReservations = null;
      state.error = null;
    },
    reservationHistoryRequest: (state, action) => {
      state.reservationHistoryLoading = true
      state.reservationHistoryList = [];
      state.totalReservations = null;
      state.error = null;
    },
    reservationHistoryFetched: (state, action) => {
      state.reservationHistoryList = action.payload.entities;
      state.totalReservations = action.payload.totalRecords;
      state.error = null;
      state.reservationHistoryLoading = false;
    },
    reservationHistoryRequestFailed: (state, action) => {
      state.reservationHistoryList = [];
      state.reservationHistoryLoading = false
      state.totalReservations = null;
      state.error = null;
    },

    cancelledReservationRequest: (state, action) => {
      state.cancelledReservationLoading = true
      state.cancelledReservationList = [];
      state.totalReservations = null;
      state.error = null;
    },
    cancelledReservationFetched: (state, action) => {
      state.cancelledReservationList = action.payload.entities;
      state.totalReservations = action.payload.totalRecords;
      state.error = null;
      state.cancelledReservationLoading = false;
    },
    cancelledReservationRequestFailed: (state, action) => {
      state.cancelledReservationList = [];
      state.cancelledReservationLoading = false
      state.totalReservations = null;
      state.error = null;
    },

    reservationCreated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.clientSecret = action.payload.clientSecret;
      state.publishableKey = action.payload.publishableKey;
      state.processCompletionTitle = action.payload.processCompletionTitle;
      state.processCompletionDescription = action.payload.processCompletionDescription;
      state.huntApprovalSetting = action.payload.huntApprovalSetting;
    },
    reservationPricing: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.pricingDetails = action.payload;
    },
    clearPricing: (state) => {
      state.error = null;
      state.actionsLoading = false;
      state.pricingDetails = {};
    },
    resetPaymentIntent: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.clientSecret = null;
      state.publishableKey = null;
    },
    voucherVerified: (state, action) => {
      state.error = null;
      state.verifyingVoucher = false;
    },
    referralVerified: (state, action) => {
      state.error = null;
      state.verifyingReferral = false;
    },
    requestReservationDetail: (state) => {
      state.gettingReservationDetail = true;
      state.error = null
    },
    reservationDetailFetched: (state, action) => {
      state.reservationDetail = action?.payload?.reservationDetail;
      state.error = null;
      state.gettingReservationDetail = false;
    },
    reservationDetailFetchedFailed: (state) => {
      state.reservationDetail = null;
      state.error = null;
      state.gettingReservationDetail = false;
    },
    trophyPosted: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.reservationDetail = {
        ...state.reservationDetail,
        trophy: { trophy: action.payload.fileUrl },
      };
    },
    hunterReviewPosted: (state, action) => {
      const { rating, review } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.reservationDetail = {
        ...state.reservationDetail,
        reviews: {
          hunter: { rating: rating, review: review },
        },
      };
    },
    reservationCancelled: (state, action) => {
      const { id, callerReservationDetail } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      if (callerReservationDetail) {
        state.reservationDetail = {
          ...state.reservationDetail,
          status: "cancel",
        };
      } else {
        state.reservationsList = state.reservationsList.filter(
          (reservation) => reservation?.id !== id
        );
      }
    },
    reviewPublishRequested: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    clearReservationsList: (state, action) => {
      state.reservationsList = [];
      state.invitationList = [];
    },
    blockoutDayPosted: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    blockoutDayDeleted: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    requestSent: (state, action) => {
      state.actionsLoading = false;
      state.error = null
    },
    settingFetched: (state, action) => {
      state.actionsLoading = false;
      state.ihuntSettings = action.payload.settings
      state.error = null
    },
    FetchedSentInvitesToHunters: (state, action) => {
      state.listLoading = false;
      state.sentInvitesToHunters = action.payload.data
      state.error = null
    },
    FetchedJoinedHuntersList: (state, action) => {
      state.listLoading = false;
      state.joinedHunters = action.payload.data
      state.error = null
    },
    fundNotificationfetched: (state, action) => {
      state.fundNotification = action.payload
      state.error = null
      state.listLoading = false;
    },
    resetFundNotifications: (state) => {
      state.listLoading = false;
      state.fundNotification = null
      state.error = null
    },
    handleNotificationClicked: (state, action) => {
      state.clickedOnNotification = action.payload.flag
    },
    requestInvitationDetails: (state) => {
      state.invitationDetails = null
      state.error = null
      state.invitationDetailsLoading = true;
    },
    invitationDetailsfetched: (state, action) => {
      state.invitationDetails = action.payload
      state.error = null
      state.invitationDetailsLoading = false;
    },
    resetInvitationDetails: (state) => {
      state.invitationDetails = null
      state.error = null
      state.invitationDetailsLoading = false;
    },
    setBookingDetails: (state, action) => {
      state.bookingDetails = action.payload
    },
    removeBookingDetails: (state) => {
      state.bookingDetails = null
    },
    changeAdditionalHunterTab: (state, action) => {
      state.additionalHunterTab = action.payload
    },
    resetAdditionalHunterTab: (state) => {
      state.additionalHunterTab = "Invitations"
    },
    Setwavierstatus: (state, action) => {
      state.wavierAccepted = action.payload
    },
    additionalHunterInvitationVerified: (state) => {
      state.error = null;
      state.actionsLoading = false;
    },
    setShowPreConditionModal: (state, action) => {
      state.showPreConditionModal = action.payload.status;
      state.showPreConditionError = action.payload.error
      state.actionsLoading = false;
    },
    showRejectionReasonModal: (state, action) => {
      state.rejectionReasonModal = action.payload;
    },
    showRejectionReason: (state, action) => {
      state.rejectionReason = action.payload;
    }
  },
});
