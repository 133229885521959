import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./../style.scss";
import { useHistory } from "react-router-dom";
import {
    getAllAdditionalHuntersInvitations,
    cancelAdditionalHunterInvitation,
    revokeAdditionalHunterInvitation,
    resetAdditionalHunterTab,
    changeAdditionalHunterTab
} from "modules/dashboard/_redux/reservations/reservationActions";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import Spinner from "components/UI/Spinner.js";
import User from "assets/images/default-avatar.png";
import NoRecordFound from "utils/noRecordFound";
import CustomSpinner from "components/layout/Loader/Spinner";
import ContactButton from "components/UI/contactButton";
import { HunterNotificationModal } from "./notifications/hunterNotificationModal";
import AdditionalHunterInvitationModal from "components/UI/modals/additionalHunterInvitation-modal/AdditionalHunterInvitationModal";


export default function AdditionalHunter() {
    const [currentId, setCurrentId] = useState("")
    const [openHunterInvitationNotificationModal, setOpenHunterInvitationNotificationModal] = useState(false);
    const [additionalHunterNotificationModal, setAdditionalHunterNotificationModal] = useState(false);
    const history = useHistory()
    const dispatch = useDispatch()
    const reservationId = history?.location?.pathname.match(/\d+/)[0] || undefined
    const [key, setKey] = useState("");
    const [value, setValue] = useState(0);

    const handleShow = () => setAdditionalHunterNotificationModal(true);
    const handleClose = () => {
        setAdditionalHunterNotificationModal(false)
    };

    const reload = () => {
        setValue(value + 1)
    }


    const handleCancelRequest = (id) => {
        setCurrentId(id)
        dispatch(cancelAdditionalHunterInvitation(id, onSuccess))
    }

    const onSuccess = (type) => {
        if (type == "revoked") {
            dispatch(getAllAdditionalHuntersInvitations({ id: reservationId, type: "accepted" }))
            reload();
        } else {
            dispatch(getAllAdditionalHuntersInvitations({ id: reservationId, type: "" }))
            reload()
        }
    }

    const handleRevokeAccess = (id) => {
        setCurrentId(id)
        dispatch(revokeAdditionalHunterInvitation(id, onSuccess))
    }

    const { actionsLoading, sentInvitesToHunters,
        joinedHunters, listLoading,
        invitationDetails, invitationDetailsLoading, additionalHunterTab } = useSelector(
            (state) => ({
                sentInvitesToHunters: state.reservation.sentInvitesToHunters,
                joinedHunters: state.reservation.joinedHunters,
                actionsLoading: state.reservation.actionsLoading,
                listLoading: state.reservation.listLoading,
                invitationDetails: state.reservation.invitationDetails,
                invitationDetailsLoading: state.reservation.invitationDetailsLoading,
                additionalHunterTab: state.reservation.additionalHunterTab
            }),
            shallowEqual
        );

    useEffect(() => {
        dispatch(getAllAdditionalHuntersInvitations({ id: reservationId, type: "" }))
        dispatch(getAllAdditionalHuntersInvitations({ id: reservationId, type: "accepted" }))
        return () => {
            dispatch(resetAdditionalHunterTab("Invitations"))
        }
    }, [])

    useEffect(() => {
        setKey(additionalHunterTab)
    }, [additionalHunterTab])


    useEffect(() => {
        if (invitationDetails) {
            setOpenHunterInvitationNotificationModal(true);
        }
    }, [invitationDetails, invitationDetailsLoading])


    const handleBack = () => {
        history.goBack();
    };

    const handleKeyChange = (key) => {
        dispatch(changeAdditionalHunterTab(key))
        dispatch(getAllAdditionalHuntersInvitations({ id: reservationId, type: key === "Invitations" ? "" : key === "Joined" ? "accepted" : "" }))
    }

    const canSendInvites = () => {
        if (sentInvitesToHunters?.main_hunter) {
            if (!history?.location?.state) {
                return true
            } else if (history?.location?.state?.state?.activeTab == "current") {
                return true
            } else return false
        } else return false
    }

    return (
        <>
            <div className="pb-4">
                <h6 className="d-content-inner-title font-roboto mb-0">
                    Additional Hunters
                </h6>
            </div>
            <div className="top-bar d-flex justify-content-between align-items-center flex-wrap">
                <div className="top" onClick={handleBack}>
                    <i className="bi bi-arrow-left-short me-1"></i>
                    <span>Go back</span>
                </div>
            </div>
            <div className="my-land res-pay-line position-relative px-0">
                {canSendInvites() ?
                    <button className="btn btn-white text-primary mx-auto d-flex w-auto fs-6 align-items-center px-0 right" onClick={handleShow}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15.001" height="15.736" viewBox="0 0 17.001 17.736" style={{ marginRight: "5px" }}>
                            <path id="Path_1275" data-name="Path 1275" d="M102.66,16.985a.331.331,0,0,0,0-.041.384.384,0,0,0-.015-.041v-.02a.058.058,0,0,0-.014-.015.161.161,0,0,0-.027-.032.257.257,0,0,0-.032-.029l-.015-.012-.02-.009-.041-.015-.06-.01h-.041a.2.2,0,0,0-.044,0h-.022l-15.484,4.8h0a.259.259,0,0,0-.044.476l6.864,3.721,3.721,6.864a.259.259,0,0,0,.221.134h.026a.255.255,0,0,0,.221-.18L102.66,17.1a.1.1,0,0,0,0-.022.2.2,0,0,0,0-.044.312.312,0,0,0,0-.049Zm-1.174.585L95,24.051l-1.191,1.191-6.226-3.364ZM97.542,31.835l-3.369-6.226.111-.111,7.567-7.562Z" transform="translate(-86.16 -15.519)" fill="#ea9b45" stroke="#ea9b45" stroke-width="1" />
                        </svg>
                        Send Invite
                    </button> : null
                }
                <div className="tabs mt-0 additinal-hunters-tab-main">
                    <Tabs
                        defaultActiveKey="active"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                        activeKey={key}
                        onSelect={(k) => handleKeyChange(k)}
                    >
                        <Tab eventKey="Invitations" title="Invitations" className="invitations-main">
                            {
                                listLoading ? <CustomSpinner /> : sentInvitesToHunters?.data?.length > 0 ?
                                    <div className="row justify-content-between">
                                        {
                                            sentInvitesToHunters?.data?.map((item, index) => {
                                                return (
                                                    <div className="col-xxl-6 px-2" key={`sent-invites-key${index}`}>
                                                        <div className="d-flex justify-content-between items mb-3">
                                                            <strong className="fs-6">{item?.sent_at}</strong>
                                                            {
                                                                sentInvitesToHunters?.main_hunter ?
                                                                    item?.status == "pending" ?
                                                                        <button
                                                                            type="button"
                                                                            className="btn px-3 d-flex w-auto px-4 text-capitalize ms-3"
                                                                            style={{ border: "1px solid red", color: "red" }}
                                                                            onClick={() => handleCancelRequest(item?.id)}
                                                                        >
                                                                            {actionsLoading && item?.id == currentId ? <Spinner /> : "CANCEL"}
                                                                        </button> :
                                                                        <span
                                                                            className="text-capitalize px-4"
                                                                            style={{ color: "red" }}
                                                                        >{item?.status}</span> : <span
                                                                        className="text-capitalize px-4"
                                                                        style={{ color: "red" }}
                                                                    >{item?.status}</span>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    : <NoRecordFound from="additional-hunter" />
                            }

                        </Tab>
                        <Tab eventKey="Joined" title="Joined" className="invitations-main">
                            {
                                listLoading ? <CustomSpinner /> : joinedHunters?.data?.length > 0 ?
                                    <div className="row">
                                        {
                                            joinedHunters?.data?.map((item, index) => {
                                                return <div className="col-xxl-6" key={`-joined-key${index}`}>
                                                    <div className="user-card w-100 mt-0 items border-0 p-0 mb-3">
                                                        <div className="user-detail">
                                                            <div className="user-info-content">
                                                                <img src={item?.user?.image || User}
                                                                    onError={(e) => (e.target.src = User)}
                                                                    alt="" />
                                                                <div className="ms-3">
                                                                    <p className="mb-0 fw-bold fs-6">{item?.user?.first_name} {item?.user?.last_name}</p>
                                                                    <p className="mb-0 text-tertiary fs-6">{item?.user?.email}</p>
                                                                    <p className="fw-medium m-0 fs-6"><i className="bi bi-star-fill me-2 text-yellow fs-7"></i>{item?.user?.rating || 0}</p>
                                                                </div>
                                                            </div>
                                                            <div role="group" className="dropdown">
                                                                <ContactButton
                                                                    user={item?.user}
                                                                    subject="Main Hunter"
                                                                    reservationId={reservationId}
                                                                    showChat={joinedHunters?.main_hunter}
                                                                    showInput={joinedHunters?.main_hunter}
                                                                />
                                                                {
                                                                    joinedHunters?.main_hunter ?
                                                                        <button
                                                                            type="button"
                                                                            className="btn px-3 px-1 text-uppercase"
                                                                            style={{ border: "1px solid red", color: "red" }}
                                                                            onClick={() => handleRevokeAccess(item?.id)}
                                                                        >
                                                                            {
                                                                                actionsLoading && item?.id == currentId ? <Spinner /> : "Revoke access"
                                                                            }
                                                                        </button> : null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div> : <NoRecordFound from="additional-hunter" />
                            }
                        </Tab>
                    </Tabs>
                </div>
            </div>

            <HunterNotificationModal show={openHunterInvitationNotificationModal} setShow={setOpenHunterInvitationNotificationModal} />

            <AdditionalHunterInvitationModal show={additionalHunterNotificationModal} setShow={setAdditionalHunterNotificationModal} onHide={handleClose} reservationId={reservationId} reload={reload} />
        </>
    );
}
