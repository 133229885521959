import React from "react";
import CheckInHuntingLocationImg from "./../../../../assets/images/check-in-location.png";
import PlaceHolderImage from "./../../../../assets/images/check-in-location-1.jpg";
import "./style.scss";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


export default function checkInHuntingLocation() {
    return (
        <section>
            <div className="inner-container">
                <div className="row justify-content-between">
                    
                    <div className="col-lg-4 col-md-5 order-lg-1 order-md-1 order-sm-2 order-2">
                        <div className="right px-3">
                            <div className="right-inner">
                                <LazyLoadImage
                                    src={CheckInHuntingLocationImg}
                                    alt=""
                                    className="lazy-load-image"
                                    placeholderSrc={PlaceHolderImage}
                                    visibleByDefault={CheckInHuntingLocationImg}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-6 order-lg-2 order-md-2 order-sm-1 order-1">
                        <div className="left">
                            <div className="left-inner">
                                <h5>Check-In at the Hunting Location</h5>
                                <p>
                                Upon arrival at the property, use the app to check-in. This step ensures that you have arrived safely and provides you with any additional directions or instructions specific to the hunting location.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
