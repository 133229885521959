import { groupSlice, callTypes } from "./groupSlice";
import * as requestFromServer from "./groupsCrud";
import toastHandler from "components/UI/toastHandler/toastHandler";
const { actions } = groupSlice;

export const getAllGroups = () => async (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    try {
        const response = await requestFromServer.getGroups();
        if (response.status) {
            dispatch(
                actions.groupsFetched({
                    entities: response.data
                })
            );
        }
    } catch (error) {
        if (error?.response?.data?.message) {
            dispatch(actions.clearEntities());
        }
        return 0;
    }
};

export const createGroups = (body, onSuccess, onError) => async (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    try {
        const response = await requestFromServer.createGroups(body);
        if (response.status) {
            dispatch(actions.groupCreated());
            toastHandler(response?.message, "success", "create-group")
            onSuccess()
            dispatch(getAllGroups())
        }
    } catch (error) {
        if (error?.response?.data?.message) {
            dispatch(actions.clearEntities());
            toastHandler(error?.response?.data?.errors, "error")
        }
        onError()
        return 0;
    }
};

export const getGroupsById = (id) => async (dispatch) => {
    if (!id) {
      return dispatch(actions.groupDetailsFetched({ selectedGroup: null }));
    }
    dispatch(actions.startCall({ callType: callTypes.listLoading }));
    try {
      const response = await requestFromServer.getGroupsById(id);
      if (response.status) {
        const group = response.data;
        dispatch(actions.groupDetailsFetched({ selectedGroup: group }));
      }
    } catch (error) {
      dispatch(actions.catchError({ error, callType: callTypes.listLoading }));
      return 0;
    }
};

export const assignGroups = (id, body, onSuccess, onError) => async (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    try {
        const response = await requestFromServer.assignGroups(id, body);
        if (response.status) {
            dispatch(actions.groupAssigned());
            toastHandler(response?.message, "success", "create-group")
            onSuccess();
        }
    } catch (error) {
        if (error?.response?.data?.message) {
            dispatch(actions.clearEntities());
            toastHandler(error?.response?.data?.errors, "error")
        }
        onError()
        return 0;
    }
};

export const deleteGroup = (id) => async (dispatch) => {
    dispatch(actions.startCall({ callType: callTypes.delete }));
    try {
        const response = await requestFromServer.deleteGroup(id);
        if (response.status) {
            dispatch(actions.groupDeleted());
            toastHandler(response?.message, "error", "delete-group")
            dispatch(getAllGroups())
        }
    } catch (error) {
        if (error?.response?.data?.message) {
            dispatch(actions.clearEntities());
            toastHandler(error?.response?.data?.errors, "error")
        }
        return 0;
    }
};

