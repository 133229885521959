import React, { createRef, useEffect } from "react";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import SelectFile from "assets/icons/select-file.svg";
import DeleteFile from "assets/icons/delete-1234819.svg";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import Spinner from "components/UI/Spinner.js";
import * as actions from "modules/dashboard/_redux/customer-service-portal/customerServicePortalActions";
import * as Yup from "yup";
import toastHandler from "components/UI/toastHandler/toastHandler";

function NewTicketDialogForm({ onHide }) {
  const dispatch = useDispatch();
  const fileInputRef = createRef();
  
  const { actionsLoading, customerServicesList } = useSelector(
    (state) => ({
      actionsLoading: state.customerServicePortal.actionsLoading,
      customerServicesList: state.customerServicePortal.customerServicesList,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.getCustomerServicesList());
  }, []);

  const validationSchema = Yup.object().shape({
    category: Yup.string().required("Please select category"),
    subject: Yup.string().required("Please enter subject"),
    description: Yup.string().required("Please enter description"),
    media: Yup.string(),
  });

  const initialValues = {
    category: "",
    subject: "",
    description: "",
    media: "",
  };

  const onSuccess = () => {
    toastHandler("Ticket Created Successfully", "success");
    onHide(true);
  };

  const onError = (message) => {
    toastHandler(message, "error");
  };

  const handleSubmit = (values) => {
    const data = new FormData();
    const { category, subject, media, description } = formik.values;
    if (media) {
      data.append("media", media);
    }
    data.append("category", category);
    data.append("subject", subject);
    data.append("description", description);
    dispatch(actions.createTicket(data, onSuccess, onError));
  };

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: initialValues,
    onSubmit: handleSubmit,
  });

  const fileUploadHandler = (e) => {
    const { files } = e?.target;
    if (!files.length) return;
    const file = files[0];
    const mimeType = file.type.split("/")[0];
    if (mimeType !== "image" && mimeType !== "video") {
      fileInputRef.current.value = "";
      toastHandler("Media can only be image or video", "error");
      return;
    }
    formik.setFieldValue("media", file);
  };

  const deleteSelectedFile = () => {
    formik.setFieldValue("media", "");
    fileInputRef.current.value = "";
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="row   newTicket-form-row">
        <div className="col-lg-12 ">
          <div className="form-container">
            <div className="form-box">
              <FloatingLabel
                controlId="floatingSelectGrid"
                label="Category"
                className="categorySelect"
              >
                <Form.Select
                  aria-label="Floating label sinitialValueselect example"
                  name="category"
                  onChange={formik.handleChange}
                  value={formik.values.category}
                >
                  <option value={""} disabled>
                    Select Category
                  </option>
                  {customerServicesList &&
                    customerServicesList?.length > 0 &&
                    customerServicesList?.map((customerServicesList, index) => (
                      <option key={index} value={customerServicesList.id}>
                        {customerServicesList.title}
                      </option>
                    ))}
                </Form.Select>
              </FloatingLabel>
              {formik?.touched?.category && formik?.errors?.category && (
                <Form.Control.Feedback type="d-block invalid">
                  {formik.errors.category}
                </Form.Control.Feedback>
              )}
              <FloatingLabel
                controlId="floatingInput"
                label="Subject"
                className=""
              >
                <Form.Control
                  type="text"
                  name="subject"
                  onChange={formik.handleChange}
                  placeholder="Subject"
                />
              </FloatingLabel>
              {formik?.touched?.subject && formik?.errors?.subject && (
                <Form.Control.Feedback type="d-block invalid">
                  {formik.errors.subject}
                </Form.Control.Feedback>
              )}
              <FloatingLabel
                controlId="floatingInput"
                label="Description"
                className="description-textarea custom-description"
              >
                <div className="form-group">
                  <textarea
                    type="text"
                    className="field-style"
                    onChange={formik.handleChange}
                    value={formik.values.description}
                    name="description"
                  ></textarea>
                </div>
              </FloatingLabel>
              {formik?.touched?.description && formik?.errors?.description && (
                <Form.Control.Feedback type="d-block invalid">
                  {formik.errors.description}
                </Form.Control.Feedback>
              )}
              <div className="file-attachment">
                <label for="file-input" className="custom-file-label">
                  <div>
                    <img src={SelectFile} alt="" />
                  </div>
                  <span>
                    {formik.values.media
                      ? "Choose Attachment"
                      : "Upload File"}
                  </span>
                </label>
                <input
                  type="file"
                  id="file-input"
                  className={
                    formik.values.media
                      ? "custom-file-input"
                      : "custom-file-input no-select-file"
                  }
                  onChange={fileUploadHandler}
                  ref={fileInputRef}
                />
                {formik.values.media ? (
                  <div className="Delete-file">
                    <button onClick={deleteSelectedFile}>
                      <img src={DeleteFile} alt="" />
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="d-flex justify-content-center">
                <Button type="submit" className="text-white">
                  {actionsLoading ? <Spinner /> : "SUBMIT"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default NewTicketDialogForm;
