import React from "react";
import { Modal } from "react-bootstrap";
import NewTicketDialogForm from "./newTicketDialogForm/newTicketDialogForm";
import "./style.scss";


export function NewTicketDialog({ show, onHide }) {
  return (
    <Modal
      show={show}
      onHide={() => onHide(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="new-ticket-div"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Create New Ticket
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <NewTicketDialogForm onHide={onHide} />
      </Modal.Body>
    </Modal>
  );
}
