import { isFunction, isEqual } from "lodash-es";

export const LEADING_TRAILING_SPACES =
  /^[A-Za-z0-9©®Ã™[{!@#\$?'/"%\^\&*\]})\(+=.,_–-]+(?:\s+[A-Za-z0-9!@#\$?'/"%\^\&*\)\(+=.,_–-]+)*$/;

export const NAME_REGEX = /^[ a-zA-Z]+(([',. -][a-zA-Z0-9])?[a-zA-Z0-9]*)*$/;

export const EMAIL_REGEX = /^[ a-zA-Z](([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const ADDRESS_REGEX = /[!@$%^&*(),?"-_:{}|<>]/g

export const REFERRAL_REGEX = /^[a-zA-Z0-9]+$/

export const getPercent = (amount, percent) => {
  return (amount * percent) / 100;
};

export const checkQueryParamsEquality = (nextQueryParams, prevQueryParams) => {
  if (isFunction(nextQueryParams)) {
    nextQueryParams = nextQueryParams(prevQueryParams);
  }

  if (isEqual(prevQueryParams, nextQueryParams)) {
    return prevQueryParams;
  }

  return nextQueryParams;
};

// helper for signup and update profile component
export const modifyValuesWithDialCode = (values, callerPaymentInfo = false) => {
  // modify phone values
  let phone = values.phone.substring(
    values.country_dial_code.toString().length
  );

  let secondary_phone;
  if (!callerPaymentInfo) {
    secondary_phone =
      values?.secondary_phone?.substring(
        values?.secondary_dial_code?.toString().length
      ) || "";
  }

  const updatedValues = {
    ...values,
    phone: `+${values.country_dial_code}-${phone}`,
    ...(!callerPaymentInfo &&
      secondary_phone && {
      secondary_phone: `+${values.secondary_dial_code}-${secondary_phone}`,
    }),
  };

  if (!updatedValues.secondary_phone) {
    delete updatedValues.secondary_country_code;
    delete updatedValues.secondary_phone;
  }

  return updatedValues;
};

export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

export const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};
