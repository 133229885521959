import React, { useEffect, useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import { useFormik } from "formik";
import parse from "html-react-parser";
import * as Yup from "yup";
import "./style.scss";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { postWavie, clearState  } from "modules/dashboard/_redux/wavier/wavierActions";
import { dataURLtoFile } from "utils/helpers";
import { useHistory } from "react-router";
import Spinner from "components/UI/Spinner.js";
import { Form } from "react-bootstrap";
import { getDocument } from "modules/dashboard/_redux/dashboard/dashboardActions";
import { wavierSlice } from "modules/dashboard/_redux/wavier/wavierSlice";
import { handleWavierStatus } from "modules/dashboard/_redux/reservations/reservationActions";


const { actions } = wavierSlice;


export default function WavierAgreement() {

  const signatureRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const defaultUrl = localStorage.getItem("redirectUrl")

  const { user, listLoading,
    actionsLoading,
    document,
    sending,
    wavierFormData,
    wavierQuestions,
    bookingDetails,
    updatedWavier,
    redirectUrl,
    wavierSignature } = useSelector(
      (state) => ({
        user: state.auth.user,
        actionsLoading: state.auth.actionsLoading,
        listLoading: state.dashboard.listLoading,
        sending: state.wavier.listLoading,
        document: state.dashboard.document,
        wavierFormData: state.wavier.wavierFormData,
        wavierQuestions: state.wavier.wavierQuestions,
        wavierSignature: state.wavier.wavierSignature,
        bookingDetails: state.reservation.bookingDetails,
        updatedWavier: state.wavier.updatedWavier,
        redirectUrl: state.wavier.redirectUrl
      }), shallowEqual);

  const token = localStorage.getItem("token");

  var paymentFormData = new FormData();

  for (var key in bookingDetails) {
    paymentFormData.append(key, bookingDetails[key]);
  }

  useEffect(() => {
    if (wavierSignature) {
      signatureRef.current?.fromDataURL(wavierSignature)
    }
    if (!token) {
      history.push("/auth/login")
    }
    if (user?.is_junior) {
      dispatch(getDocument({ type: "junior waiver document" }))
    } else {
      dispatch(getDocument({ type: "waiver document" }))
    }
  }, [])

  useEffect(() => {
    if (wavierSignature) {
      signatureRef.current?.fromDataURL(wavierSignature)
    }
  })


  const valueArray = wavierQuestions?.map((item, index) => {
    if (wavierFormData[`comment${index + 1}`]) {
      return {
        [`questions[${index}][id]`]: item?.id,
        [`questions[${index}][question]`]: item.question,
        [`questions[${index}][answer]`]: wavierFormData[`question${index + 1}`],
        [`questions[${index}][reason]`]: wavierFormData[`comment${index + 1}`],
        [`questions[${index}][is_approved]`]: wavierFormData[`isApproved${index + 1}`] ? 1 : 0
      }
    } else return {
      [`questions[${index}][id]`]: item?.id,
      [`questions[${index}][question]`]: item.question,
      [`questions[${index}][answer]`]: wavierFormData[`question${index + 1}`],
      [`questions[${index}][is_approved]`]: wavierFormData[`isApproved${index + 1}`] ? 1 : 0
    }
  })

  const validationSchema = Yup.object({
    signature: wavierSignature ? Yup.string()
      : Yup.string().required("Signature is required"),
  });
  const initialValues = {
    signature: ""
  }
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      for (var key in valueArray) {
        for (var index in valueArray[key]) {
          formData.append(index, valueArray[key][index]);
        }
      }
      var file = dataURLtoFile(
        signatureRef.current.getTrimmedCanvas().toDataURL("image/png"),
        "image.png"
      );
      formData.append("signature", file);
      if (user?.is_junior) {
        formData.append("guardian_name", wavierFormData?.guardianName)
      }
      dispatch(postWavie(formData));
    },
  });

  useEffect(() => {
    if (updatedWavier) {
      if (!user?.is_junior && redirectUrl?.includes("reserve-stand")) {
        dispatch(handleWavierStatus(true));
      } else {
        dispatch(handleWavierStatus(true));
      }
      history?.push(redirectUrl || defaultUrl)
      dispatch(clearState());
      localStorage.removeItem("defaultUrl")
    }
  }, [updatedWavier])

  const handleSignatureClear = () => {
    signatureRef.current.clear();
    dispatch(actions.clearWavierSignature(null))
    formik.setFieldValue("signature", "");
  };

  const handleBack = () => {
    history.goBack();
  };

  let today = new Date();
  let year = today.getFullYear();
  let month = today.getMonth();
  let day = today.getDate();
  let nextYear = new Date(year + 1, month, day);

  return (
    <div className="wavier-form-agreement">
      {
        (listLoading || actionsLoading) ? <Spinner /> :
          <div className="wavier-form-agreement">
            <div className="row ">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <p className="header">Hunter Wavier</p>
                <div className="col-sm-12">
                  {parse(document?.body.replace(/:date/g, today.toLocaleDateString())
                    .replace(/:expiry_date/g, nextYear.toLocaleDateString())
                    .replace(/:first_name/g, user?.first_name)
                    .replace(/:last_name/g, user?.last_name)
                    .replace(/:guardian_name/g, wavierFormData?.guardianName)
                    || "")}
                </div>
                <div className="inner-container">
                  <div className="row">
                    <div className="col-12">
                      <form onSubmit={formik.handleSubmit}>
                        <div className="form-footer">
                          <div className="section signature-sec">
                            <span className="display-block signature-label">Signature</span>
                            <SignatureCanvas
                              penColor="black"
                              canvasProps={{
                                width: 500,
                                height: 120,
                                className: "sigCanvas",
                              }}
                              ref={signatureRef}
                              onEnd={() => {
                                formik.setFieldValue(
                                  "signature",
                                  signatureRef.current
                                    .getTrimmedCanvas()
                                    .toDataURL("image/png")
                                )
                                dispatch(actions.setWavierSignature(signatureRef.current.toDataURL()))
                              }}
                            />
                            <Form.Control.Feedback type="d-block invalid" className="display-inline-block">
                              {formik?.touched?.signature && formik?.errors?.signature ? formik.errors.signature : ""}
                            </Form.Control.Feedback>
                          </div>
                        </div>
                        <div className="wavier-btn d-flex justify-content-center align-items-center flex-wrap mb-3">
                          <button className="btn btn-outline-primary me-sm-3 mb-md-0 mb-3" onClick={handleBack} type="button">
                            <i className="bi bi-arrow-left-short me-2"></i>
                            <span>GO BACK</span>
                          </button>
                          <button className="btn btn-outline-secondary me-sm-3 mb-md-0 mb-3 cancel"
                            onClick={handleSignatureClear}
                            type="button"
                          >
                            CLEAR SIGNATURE
                          </button>
                          <button className="btn btn-primary text-white mb-md-0 mb-3 next" type="submit">
                            {sending ? <Spinner /> : "SUBMIT FORM"}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      }
    </div>
  );
}
