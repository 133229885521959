import React from "react";
import LoginScreen from "./../../../../assets/images/browse-hunting-site.png";
import PlaceHolderImage from "./../../../../assets/images/browse-hunting-site-1.jpg";
import "./style.scss";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


export default function browseHuntingSite() {
    return (
        <section>
            <div className="inner-container">
                <div className="row justify-content-between">
                    
                    <div className="col-lg-4 col-md-5 order-lg-1 order-md-1 order-sm-2 order-2">
                        <div className="right px-3">
                            <div className="right-inner">
                                <LazyLoadImage
                                    src={LoginScreen}
                                    alt=""
                                    className="lazy-load-image"
                                    placeholderSrc={PlaceHolderImage}
                                    visibleByDefault={LoginScreen}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-6 order-lg-2 order-md-2 order-sm-1 order-1">
                        <div className="left">
                            <div className="left-inner">
                                <h5>Browse Hunting Sites</h5>
                                <p>
                                Explore the available hunting sites within the app. You can use filters to narrow down your search based on location, game species, and other preferences. Review the details, descriptions, and ratings of each hunting site to find the one that suits your needs.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
