import React, { useState } from "react";
import { Form } from "react-bootstrap";
import "./style.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import baseInstance from "services/axiosInstance";
import toastHandler from "components/UI/toastHandler/toastHandler";
import Spinner from "components/UI/Spinner.js";


export default function Feedback() {
  const [loading, setLoading] = useState(false)

  const validationSchema = Yup.object().shape({
    feedback: Yup.string().required("Feedback cannot be empty"),
  });

  const formik = useFormik({
    validationSchema: validationSchema,
    enableReinitialize: true,
    initialValues: {
      feedback: "",
    },
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      let data = new FormData();
      data.append("feedback", values.feedback);
      let response = await baseInstance.post("/feedback", data);
      if (response.status) {
        setLoading(false)
        resetForm();
        toastHandler(response.message, "success");
      } else {
        setLoading(false);
        toastHandler(response.error, "error");
      }
    },
  });

  return (
    <div className="feeeback-wrapper">
      <div className="inner-container">
        <div className="row">
          <div className="col-lg-10 col-12">
            <div className="content pb-4">
              <div className="feedback-top-content">
                <h6 className="d-content-inner-title font-roboto">Feedback</h6>
                <p>Give feedback to iHunt</p>
              </div>
              <Form className="feedback-form" onSubmit={formik.handleSubmit}>
                <div className="form-group">
                  <textarea
                    type="text"
                    className="field-style"
                    placeholder="Type your comment here.."
                    onChange={formik.handleChange}
                    value={formik.values.feedback}
                    name="feedback"
                  ></textarea>
                </div>
                {formik?.touched?.feedback && formik?.errors?.feedback && (
                  <Form.Control.Feedback type="d-block invalid">
                    {formik.errors.feedback}
                  </Form.Control.Feedback>
                )}
                <div className="action">
                  <button className="btn btn-primary text-white" type="submit">
                    {loading ? <Spinner /> : "SUBMIT"}
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
