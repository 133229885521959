import React, { useState, useMemo } from "react";
import { Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "./InvitationCodeModal.scss";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import Spinner from "components/UI/Spinner.js";
import { REFERRAL_REGEX } from "utils/helpers";
import { PinField } from "react-pin-field";
import InvitationNotificationModal from "../invitationNotification-modal/InvitationNotificationModal";
import { verifyStandInvitation } from "modules/dashboard/_redux/stand/standActions";
import { verifyAdditionalHunterInvitation } from "modules/dashboard/_redux/reservations/reservationActions";
import { HunterNotificationModal } from "../../../../modules/dashboard/pages/my-reservation/additionalHunter/notifications/hunterNotificationModal";
import { useHistory, useLocation } from "react-router-dom";
import { useDashboardUIContext } from "modules/dashboard/pages/DashboardUIContext";
import { getReservations } from "modules/dashboard/_redux/reservations/reservationActions";
import axios from "axios";


const InvitationCodeModal = ({ show, onHide, value, setValue }) => {
  const history = useHistory();
  let search = useLocation().search;
  const source = axios.CancelToken.source();
  const activeTab = new URLSearchParams(search).get("activeTab") || "current";
  const [key, setKey] = useState(activeTab);
  const [error, setError] = useState("");
  const [openInvitationNotificationModal, setOpenInvitationNotificationModal] = useState(false);
  const [openHunterInvitationNotificationModal, setOpenHunterInvitationNotificationModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invitationData, setInvitationData] = useState("");
  const [invitationCodeValue, setInvitationCodeValue] = useState("");

  const dispatch = useDispatch();

  const dashboardPageUIContext = useDashboardUIContext();
  const dashboardPageUIProps = useMemo(() => {
    return {
      queryParams: dashboardPageUIContext.reservationQueryParams,
      setQueryParams: dashboardPageUIContext.setReservationQueryParams,
    };
  }, [dashboardPageUIContext]);

  const reload = () => {
    setValue(value + 1)
  }

  const handleClose = () => {
    setError(false);
    formik.resetForm();
    onHide();
  };

  const formInitialValues = {
    invitation_code: "",
  };

  const formValidationSchema = Yup.object().shape({
    invitation_code: Yup.string()
      .required("Invitation Code is required")
      .matches(REFERRAL_REGEX, 'Invitation Code must be alphanumeric with at least one letter'),
  });

  const handleSubmit = (values) => {
    if (window.location.pathname.includes("my-profile/my-stands")) {
      setLoading(true);
      dispatch(verifyStandInvitation(values, onSuccess,  onError));
    } else if (window.location.pathname.includes("my-profile/my-reservations")) {
      setLoading(true);
      dispatch(verifyAdditionalHunterInvitation(values, onVerifySuccess, onError))
    }
  };

  const onError = () => {
    setLoading(false);
  };

  const onSuccess = (response) => {
    setLoading(false);
    setInvitationData(response?.data);
    setOpenInvitationNotificationModal(true);
    handleClose();
    reload();
  };

  const onVerifySuccess = (response) => {
    setLoading(false);
    setInvitationData(response?.data);
    setOpenHunterInvitationNotificationModal(true);
    handleClose();
  };

  const onDone = (status) => {
    if (status == "accepted" || status == "declined") {
      setKey("current");
      history.push("/my-profile/my-reservations");
      dispatch(getReservations({ ...dashboardPageUIProps.queryParams, type: "current" }, source));
    }
  }

  const formik = useFormik({
    validationSchema: formValidationSchema,
    initialValues: formInitialValues,
    onSubmit: handleSubmit,
  });

  const handleInvitationCodeChange = (e) => {
    formik.setFieldValue("invitation_code", e);
    setInvitationCodeValue(e);
  }

  return (
    <>
      <Modal
        className="invitation-code-modal"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Invitation Code
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form onSubmit={formik.handleSubmit}>
              <div className="form-box invitation-code-box">
                  <div className="invitation-pin">
                    <label>Please enter your invitation code</label>
                    <div className="pin">
                      <PinField name="invitation_code" onChange={handleInvitationCodeChange} length={6} />
                    </div>
                    {error ? <p style={{ color: "red" }}>{error}</p> : null}
                    {formik?.touched?.invitation_code && formik?.errors?.invitation_code && (
                      <Form.Control.Feedback type="d-block invalid">
                        {formik.errors.invitation_code}
                      </Form.Control.Feedback>
                    )}
                    <div className="btns">
                      <button className="btn btn-primary text-white" type="submit">{loading ? <Spinner /> : "VERIFY"}</button>
                    </div>
                  </div>
              </div>
            </Form>
        </Modal.Body>
      </Modal>

      {openInvitationNotificationModal && (
        <InvitationNotificationModal 
          show={openInvitationNotificationModal}
          setShow={setOpenInvitationNotificationModal}
          onHide={() => setOpenInvitationNotificationModal(false)}
          invitationData={invitationData}
          invitationCode={invitationCodeValue}
          reload={reload}
        />
      )}

      {openHunterInvitationNotificationModal && (
        <HunterNotificationModal
          show={openHunterInvitationNotificationModal}
          setShow={setOpenHunterInvitationNotificationModal}
          onHide={() => setOpenHunterInvitationNotificationModal(false)}
          invitationData={invitationData}
          invitationCode={invitationCodeValue}
          reload={onDone}
        />
      )}
    </>
  );
};

export default InvitationCodeModal;
