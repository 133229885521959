import React, { useState, useEffect, useMemo, useRef } from 'react';
import Autocomplete from "react-google-autocomplete";
import Calendar from "react-calendar";
import BannerBg1 from "../../assets/images/banner-bg-1.jpg";
import DownloadApp from "./../../assets/images/download-app.png";
import LayerIcon from "../../assets/icons/layer-icon.png";
import ListIcon from "../../assets/icons/list-icon.png";
import MapIcon from "../../assets/icons/map-icon.png";
import { StoreLinksModal } from 'components/UI/modals/storeLinksModal';
import { Swiper, SwiperSlide } from "swiper/react";
import { useLandingPageUIContext } from 'modules/landingPage/pages/LandingPageUIContext';
import SwiperCore, { Autoplay, EffectFade } from 'swiper';
import FloatingActionButton from './floatingActionButton';
import allSpecies from "assets/icons/allSpecies.png";
import moment from "moment";
import { List } from "immutable";
import "swiper/swiper.scss";
import "./banner.scss";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import baseInstance from 'services/axiosInstance';
import Spinner from 'components/UI/Spinner.js';
import { fetchSliderPictures } from 'modules/landingPage/_redux/landingPageActions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { initialFilter } from 'modules/dashboard/pages/DashboardUIHelper';
import { FaMapMarkerAlt } from "react-icons/fa";



export default function BannerHome() {
    SwiperCore.use([Autoplay]);
    const [anyLocation, setAnyLocation] = useState("");
    const [openCalander, setOpenCalander] = useState(false);
    const [openSpecie, setOpenSpecie] = useState(false);
    const [openLocation, setOpenLocation] = useState(false);
    const [openAppLinkModal, setOpenAppLinkModal] = useState(false);
    const [range, setRange] = useState(["", ""]);
    const [species, setSpecies] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchLoader, setSearchLoader] = useState(false);
    const history = useHistory()
    const dispatch = useDispatch()

    const bannerRef = useRef(null);

    // Intersection Observer for the banner
    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };

        const observer = new IntersectionObserver(([entry]) => {
            if (entry?.isIntersecting) {
                startAutoplay();
            } else {
                stopAutoplay();
            }
        }, options);

        if (bannerRef?.current) {
            observer?.observe(bannerRef?.current);
        }

        return () => {
            if (bannerRef?.current) {
                observer?.unobserve(bannerRef?.current);
            }
        };
    }, []);

    const startAutoplay = () => {
        const swiper = document?.querySelector('.mySwiper')?.swiper;
        if (swiper) {
            swiper?.autoplay?.start();
        }
    };

    const stopAutoplay = () => {
        const swiper = document?.querySelector('.mySwiper')?.swiper;
        if (swiper) {
            swiper?.autoplay?.stop();
        }
    };

    const landingPageUIContext = useLandingPageUIContext();
    const landingPageUIProps = useMemo(() => {
        return {
            queryParams: landingPageUIContext.queryParams,
            setQueryParams: landingPageUIContext.setQueryParams,
            showMap: landingPageUIContext.showMap,
            setShowMap: landingPageUIContext.setShowMap,
            currentScreen: landingPageUIContext.currentScreen,
            setCurrentScreen: landingPageUIContext.setCurrentScreen,
            mapState: landingPageUIContext.mapState,
            setMapState: landingPageUIContext.setMapState,
        };
    }, [landingPageUIContext]);

    const { sliderImages } = useSelector(
        (state) => ({
            sliderImages: state.landingPage.sliderImages,
        }),
        shallowEqual
    );

    useEffect(async () => {
        dispatch(fetchSliderPictures())
        landingPageUIProps?.setQueryParams(initialFilter)
        landingPageUIProps.setCurrentScreen("list");
        landingPageUIProps.setShowMap(false);
        setLoading(true);
        const speciesResponse = await baseInstance.get("/get-species");
        speciesResponse?.data?.unshift({
            specie_name: "",
            is_searchable: true
        })
        navigator.geolocation.getCurrentPosition(
            // if user allow to share current location
            (position) => {
              landingPageUIProps?.setMapState({
                ...landingPageUIProps?.mapState,
                center: new List([
                  position.coords.latitude,
                  position.coords.longitude,
                ]),
              });
            },
            // if user don't allow to share current location
            () => {
                landingPageUIProps?.setMapState({
                    ...landingPageUIProps?.mapState,
                    center: new List([0, 0]),
                    zoom: 10,
                });
            }
        );
        setSpecies(speciesResponse.data);
        setLoading(false);
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const onPlaceSelected = (place, value) => {
        setAnyLocation(value?.value)
        landingPageUIProps.setQueryParams({
            ...landingPageUIProps.queryParams,
            sw_lat: place?.geometry?.location?.lat() - 0.01,
            sw_long: place?.geometry?.location?.lng() - 0.01,
            ne_lat: place?.geometry?.location?.lat() + 0.01,
            ne_long: place?.geometry?.location?.lng() + 0.01,
            title: value?.value
        })
        landingPageUIProps?.setMapState({
            ...landingPageUIProps?.mapState,
            center: new List(
                [Number(place?.geometry?.location?.lat()),
                Number(place?.geometry?.location?.lng())]),
            zoom: 15,
        });
        // history.push("/stand")
    }

    const handleSpecie = (specie) => {
        landingPageUIProps.setQueryParams({
            ...landingPageUIProps.queryParams,
            specie: specie,
        });
    }

    const handleSearch = () => {
        setSearchLoader(true);
        setTimeout(() => {
            setSearchLoader(false);
            landingPageUIProps.setQueryParams({
                ...landingPageUIProps.queryParams,
                start_date: range[0] ? moment(range[0]).format("MMM DD YYYY") : range[0],
                end_date: range[1] ? moment(range[1]).format("MMM DD YYYY") : range[1]
            });
            history.push("/stand")
        }, [1000])
    }

    const handleScreen = (screen) => {
        landingPageUIProps.setCurrentScreen(screen)
        landingPageUIProps?.setShowMap(screen == 'map');
        history.push("/stand")
    }

    const getImage = (image) => {
        if (image) {
            return <img
                src={image}
                alt='Banner'
                className='img-fluid' />
        } else {
            return <img
                src={BannerBg1}
                alt='Banner'
                className='img-fluid' />
        }
    }

    const calendarRef = useRef(null);
    const speciesRef = useRef(null);
    const locationRef = useRef(null);

    const handleClickOutside = (event) => {
        if (!(calendarRef && calendarRef.current && calendarRef.current.contains(event.target)) && !isMonthOrYearClicked(event.target)) {
            setOpenCalander(false);
        }
        if (!(speciesRef && speciesRef.current && speciesRef.current.contains(event.target)) && !isMonthOrYearClicked(event.target)) {
            setOpenSpecie(false);
        }
        if (!(locationRef && locationRef.current && locationRef.current.contains(event.target)) && !isMonthOrYearClicked(event.target)) {
            setOpenLocation(false);
        }
    };

    const isMonthOrYearClicked = (target) => {
        if (target.classList.contains("react-calendar__tile") ||
            target.classList.contains("react-calendar__year-view__months__month") ||
            target.classList.contains("react-calendar__decade-view__years__year") ||
            target.classList.contains("react-calendar__century-view__decades__decade")) {
            return true;
        }
        if (target.tagName === "ABBR" && target.getAttribute("aria-label")) {
            return true;
        }
        return false;
    };

    return (
        <div className='baner2-main' ref={bannerRef}>
            <div className='banner-slider-2'>
                <Swiper
                    spaceBetween={0}
                    centeredSlides={true}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    navigation={false}
                    modules={[Autoplay, EffectFade]}
                    className="mySwiper"
                >
                    {
                        sliderImages?.map(image => {
                            return (
                                <SwiperSlide>
                                    {getImage(image?.image)}
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </div>
            <div className='banner2-inner'>
                <div className='container'>
                    <div className='row'>
                        <div className='col'>
                            <ul className='d-flex banner2-list'>
                                <li className='px-2'>
                                    Discover.
                                </li>
                                <li className='px-2'>
                                    Connect.
                                </li>
                                <li className='px-2'>
                                    Hunt.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <ul className='banner2-list-filters'>
                                <li className='any-where' ref={locationRef}>
                                    <button onClick={() => {
                                        setOpenLocation(!openLocation);
                                        setOpenCalander(false)
                                        setOpenSpecie(false)
                                    }}>Anywhere</button>
                                    {
                                        openLocation ?
                                            <div className='location-dropdown'>
                                                <FaMapMarkerAlt className='input-icon' size={20} />
                                                <Autocomplete
                                                    name="searchanywhere"
                                                    id="google-autocomplete-location"
                                                    className="form-control shadow-none"
                                                    onPlaceSelected={(place, value) => onPlaceSelected(place, value)}
                                                    componentRestrictions={{ country: "za" }}
                                                    value={anyLocation}
                                                    onChange={(e) => setAnyLocation(e.target.value)}
                                                    options={{
                                                        fields: ["address_components", "geometry", "place_id"],
                                                        types: [],
                                                    }}
                                                    placeholder="Anywhere"
                                                    onClick={() => {
                                                        setOpenCalander(false)
                                                        setOpenSpecie(false)
                                                    }}
                                                />
                                                <a className='address-btn' onClick={() => {
                                                    setOpenLocation(false)
                                                }}>
                                                    Done
                                                </a>
                                            </div> : null
                                    }
                                </li>
                                <li className='any-time' ref={calendarRef}>
                                    <button onClick={() => {
                                        setOpenCalander(!openCalander)
                                        setOpenSpecie(false)
                                        setOpenLocation(false);
                                    }}>Anytime</button>
                                    {
                                        openCalander ?
                                            <div className="calendar-main">
                                                <Calendar
                                                    className='react-calendar'
                                                    allowPartialRange={true}
                                                    returnValue={"range"}
                                                    value={range}
                                                    onChange={(values) => {
                                                        if (values?.length > 1) {
                                                            setRange([values[0], values[1]]);
                                                        } else {
                                                            setRange([values[0], values[0]]);
                                                        }
                                                    }}
                                                    selectRange={true}
                                                    minDate={new Date()}
                                                />
                                                <a className='calendar-btn' onClick={() => {
                                                    setOpenCalander(false)
                                                    setOpenSpecie(false)
                                                }}>
                                                    Done
                                                </a>
                                            </div> : null
                                    }
                                </li>
                                <li className='any-species' ref={speciesRef}>
                                    <button onClick={() => {
                                        setOpenSpecie(!openSpecie)
                                        setOpenCalander(false)
                                        setOpenLocation(false);
                                    }}>Any Species</button>
                                    {
                                        openSpecie ?
                                            <div className='species-dropdown'>
                                                <ul className='species-dropdown-list'>
                                                    {loading ? <Spinner /> :
                                                        species?.map((specie, index) => {
                                                            if (index == 0) {
                                                                return (
                                                                    <li
                                                                        key={`specieNo-${index}`}
                                                                        onClick={() => handleSpecie(specie?.specie_name)}>
                                                                        <img src={allSpecies} alt="" width="20" />
                                                                        <span className={landingPageUIProps?.queryParams?.specie == specie?.specie_name ? "active" : null} >
                                                                            Any Specie
                                                                        </span>
                                                                    </li>
                                                                )
                                                            } else {
                                                                return (
                                                                    <li
                                                                        key={`specieNo-${index}`}
                                                                        onClick={() => handleSpecie(specie?.specie_name)}>
                                                                        <img src={specie.image || allSpecies} alt="" width="20" />
                                                                        <span className={landingPageUIProps?.queryParams?.specie == specie?.specie_name ? "active" : null}>
                                                                            {specie?.specie_name}
                                                                        </span>
                                                                    </li>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </ul>
                                                <ul className='done-btn'>
                                                    <li>
                                                        <button onClick={() => {
                                                            setOpenSpecie(false)
                                                        }}>Done</button>
                                                    </li>
                                                </ul>
                                            </div> : null

                                    }
                                </li>
                                <li className="search-icon">
                                    <button onClick={handleSearch}>
                                        {searchLoader ? <Spinner /> :
                                            <>
                                                <span>search</span>
                                                <i className="bi bi-search cursor-pointer"></i>
                                            </>
                                        }
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <ul className='banner2-list-btns'>
                                <li onClick={() => handleScreen("map")}>
                                    <a >
                                        <img src={MapIcon} alt='' />
                                        <span> map </span>
                                    </a>
                                </li>
                                <li onClick={() => handleScreen("list")}>
                                    <a >
                                        <img src={ListIcon} alt='' />
                                        <span> list </span>
                                    </a>
                                </li>
                                <li onClick={() => handleScreen("blog")}>
                                    <a >
                                        <img src={LayerIcon} alt='' />
                                        <span> blog </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='banner-bottom'>
                <div className='left'>
                    <a onClick={() => setOpenAppLinkModal(true)}>
                        <img src={DownloadApp} alt='' />
                    </a>
                </div>
            </div>
            <FloatingActionButton />
            <StoreLinksModal show={openAppLinkModal}
                onHide={() => setOpenAppLinkModal(false)}
            />
        </div>
    );
}
