import React, { useEffect, useMemo, useState, useRef } from "react";
import { Form, FormControl } from "react-bootstrap";
import Calendar from "react-calendar";
import {
  getAllSpecies,
  fetchStates,
} from "modules/dashboard/_redux/stand/standActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useDashboardUIContext } from "../../DashboardUIContext";
import { initialTrophyRoomListFilter } from "../../DashboardUIHelper";
import moment from "moment";

export default function Filter() {
  const dispatch = useDispatch();
  const [showCalendar, setShowCalendar] = useState(false);
  useEffect(() => {
    dispatch(getAllSpecies());
    dispatch(fetchStates());
  }, []);

  const dashboardPageUIContext = useDashboardUIContext();
  const dashboardPageUIProps = useMemo(() => {
    return {
      queryParams: dashboardPageUIContext.trophyRoomQueryParams,
      setQueryParams: dashboardPageUIContext.setTrophyRoomQueryParams,
    };
  }, [dashboardPageUIContext]);

  const { states, species } = useSelector(
    (state) => ({
      states: state.stand.states,
      species: state.stand.species,
    }),
    shallowEqual
  );

  const handleMarkedTophieToggle = (marked_trophy) => {
    dashboardPageUIProps.setQueryParams({
      ...dashboardPageUIProps.queryParams,
      page: 1,
      marked_trophy,
    });
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    dashboardPageUIProps.setQueryParams({
      ...dashboardPageUIProps.queryParams,
      page: 1,
      [name]: value,
    });
  };

  const handleFilterReset = () => {
    dashboardPageUIProps.setQueryParams(initialTrophyRoomListFilter);
  };

  const onRangeSelected = (range) => {
    dashboardPageUIProps.setQueryParams({
      ...dashboardPageUIProps.queryParams,
      page: 1,
      start_date: range[0],
      end_date: range[1],
    });
  };

  const calendarRef = useRef();

  const manageOutsideClick = (event) => {
    if (!(calendarRef && calendarRef.current && calendarRef.current.contains(event.target)) && !isMonthOrYearClicked(event.target)) {
      setShowCalendar(false)
    }
  }

  useEffect(() => {
    document.addEventListener("click", manageOutsideClick);
    return () => {
      document.removeEventListener("click", manageOutsideClick);
    }
  }, []);

  const isMonthOrYearClicked = (target) => {
    if (target.classList.contains("react-calendar__tile") ||
    target.classList.contains("react-calendar__year-view__months__month") ||
    target.classList.contains("react-calendar__decade-view__years__year") ||
    target.classList.contains("react-calendar__century-view__decades__decade")) 
    {
      return true;
    }
    if (target.tagName === "ABBR" && target.getAttribute("aria-label")) {
      return true;
    }
    return false;
  };

  return (
    <div className="filter-form-wrapper">
      <Form className="fitler-form-container">
        <div className="row ">
          <div className="col-lg-4 col-md-6 col-sm-12" ref={calendarRef}>
            <div className="field-group ">
              <label>Dates</label>
              <div className="date-calander">
                <FormControl
                  type="text"
                  value={
                    dashboardPageUIProps.queryParams.start_date
                      ? `${moment(
                        dashboardPageUIProps.queryParams.start_date
                      ).format("MMM DD, YYYY")} - ${moment(
                        dashboardPageUIProps.queryParams.end_date
                      ).format("MMM DD, YYYY")}`
                      : ""
                  }
                  name="date"
                  placeholder="Date"
                  className="calendar-input cursor-pointer"
                  readOnly={true}
                  onClick={() => setShowCalendar(!showCalendar)}
                />
                <div
                  className="calendar-btn"
                  onClick={() => setShowCalendar(!showCalendar)}
                >
                  <i className="far fa-calendar"></i>
                </div>
              </div>
              <Calendar
                calendarType={"ISO 8601"}
                className={showCalendar ? "" : "hide"}
                returnValue={"range"}
                selectRange={true}
                onChange={(value) => {
                  onRangeSelected(value);
                  setShowCalendar(false);
                }}
                maxDate={new Date()}
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="field-group ">
              <label>State</label>
              <select
                className="form-control"
                name="state"
                onChange={handleSelectChange}
                value={dashboardPageUIProps.queryParams.state || ""}
              >
                <option value="">Select state </option>
                {states?.length > 0 &&
                  states.map((state, index) => (
                    <option value={state.id} key={index}>
                      {state.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="field-group">
              <label>Species</label>
              <select
                name="specie"
                className="form-control"
                onChange={handleSelectChange}
                value={dashboardPageUIProps.queryParams.specie || ""}
              >
                <option value="">Select specie </option>
                {species?.length > 0 &&
                  species.map((specie, index) => (
                    <option value={specie.id} key={index}>
                      {specie.specie_name}
                    </option>
                  ))}
              </select>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="field-group filter-btns">
              <label>Marked Trophies</label>
              <div className="trophy-btn-wrap">
                <button
                  type="button"
                  onClick={() => {
                    handleMarkedTophieToggle(1);
                  }}
                  className={`btn px-3 px-lg-1 me-2 trophy-btn ${dashboardPageUIProps.queryParams.marked_trophy === 1
                      ? "btn-outline-primary"
                      : "btn-outline-secondary"
                    }`}
                >
                  YES
                </button>
                <button
                  type="button"
                  onClick={() => {
                    handleMarkedTophieToggle(0);
                  }}
                  className={`btn px-3 px-lg-1 me-2 trophy-btn  ${dashboardPageUIProps.queryParams.marked_trophy === 0
                      ? "btn-outline-primary"
                      : "btn-outline-secondary"
                    }`}
                >
                  NO
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-md-12 text-end">
            <button
              type="button"
              className="btn btn-primary text-white reset-btn"
              onClick={handleFilterReset}
            >
              RESET
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
}
