import { Form, Formik } from "formik";
import moment from "moment";
import "rc-slider/assets/index.css";
import React, { useEffect, useState, useRef } from "react";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import "swiper/swiper.scss";
import "../../../../../node_modules/swiper/components/navigation/navigation.scss";
import allSpecies from "../../../.././assets/icons/allSpecies.png";
import baseInstance from "../../../../services/axiosInstance";
import { standDropDownOptions } from "../LandingPageUIHelper";
import SlickSlider from "react-slick";
import "./style.scss";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Tooltip from "react-bootstrap/Tooltip";
import { OverlayTrigger } from "react-bootstrap";


export default function Filter({ onChangeToggle, landingPageUIProps, reset, setReset }) {
  const [rangeToDisplay, setRangeToDisplay] = useState("");
  const [range, setRange] = useState(["", ""]);
  const [clicked, setClicked] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const sliderRef = useRef();
  const [species, setSpecies] = useState([]);
  const [personCapacities, setPersonCapacities] = useState([]);

  let [isActiveSpecie, setIsActiveSpecie] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const dropdownRef = useRef()

  const handleClick = () => {
    setClicked(!clicked)
  }

  const manageOutsideClick = (event) => {
    if (!(dropdownRef && dropdownRef.current && dropdownRef.current.contains(event.target))) {
      setClicked(false)
    }
  }

  useEffect(async () => {

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    const speciesResponse = await baseInstance.get("/get-species");
    speciesResponse?.data?.unshift({
      specie_name: "All",
      is_searchable: true
    })
    const personCapacities = await baseInstance.get("/get-stand-capacity");
    setSpecies(speciesResponse.data);
    const currentSpecie = speciesResponse?.data?.find(item => item.specie_name == landingPageUIProps.queryParams.specie)
    if (currentSpecie) {
      setIsActiveSpecie({ active: currentSpecie?.id })
    }

    const formattedCapacities = personCapacities?.data?.map((capacity) => {
      return {
        value: capacity.id,
        label: capacity.capacity,
      };
    });
    setPersonCapacities(formattedCapacities);

    if (sliderRef.current) {
      sliderRef.current.slickGoTo(0);
    }
    document.addEventListener("click", manageOutsideClick);
    return () => {
      document.removeEventListener("click", manageOutsideClick);
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const formRef = useRef();

  useEffect(() => {
    if (reset) {
      formRef.current.resetForm()
      setIsActiveSpecie(null)
      setReset(false)
    }
  }, [reset])

  useEffect(() => {
    if (range[0] && range[1]) {
      let startDate = moment(range[0]).format("MMM DD YYYY");
      let endDate = moment(range[1]).format("MMM DD YYYY");
      changeQueryParams({
        end_date: endDate,
        start_date: startDate,
      });
      setRangeToDisplay(`${startDate} - ${endDate}`);
      setShowCalendar(false);
    }
  }, [range]);

  const formInitialValues = {
    stand_dropdown_option: standDropDownOptions[0],
    species: [],
    start_price: null,
    end_price: null,
    trophies_amount: null,
    walk_distance: null,
    stand_distance: null,
    person_capacities: null,
    stand_id: null,
    cellular_coverage: 0,
    handicap_accessible: 0,
    is_marked_trail: 0,
    feature: 0,
  };

  const handleSpecieTag = (specie, setFieldValue, oldValues) => {
    setIsActiveSpecie({ active: specie.id });
    changeQueryParams({ specie: specie.specie_name });
    setFieldValue("species", [...oldValues, specie]);
  };

  const handleDefaultTag = () => {
    changeQueryParams({
      specie: "",
    });
  };

  const changeQueryParams = (obj) => {
    landingPageUIProps.setQueryParams({
      ...landingPageUIProps.queryParams,
      ...obj,
      page: 1,
    });
  };

  function SampleNextArrow(props) {
    const { onClick, currentSlide, slideCount } = props;
    const numberOfSlides = slideCount - getSlidesToShow(windowWidth);
    return (
      currentSlide < numberOfSlides ?
        <span
          className="bi bi-chevron-right slick-arrow arrow-right"
          onClick={onClick}
        ></span> : null
    );
  }

  function SamplePrevArrow(props) {
    const { onClick, currentSlide } = props;
    return (
      currentSlide > 0 ?
        <span
          className="bi bi-chevron-left slick-arrow arrow-left"
          onClick={onClick}
        ></span> : null
    );
  }

  var settings = {
    dots: false,
    speed: 500,
    infinite: false,
    slidesToShow: 10,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 9,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };


  const getSlidesToShow = (screenWidth) => {
    if (screenWidth >= 1400) {
      return 10;
    }
    if (screenWidth >= 992 && screenWidth <= 1399) {
      return 9;
    } if (screenWidth >= 768 && screenWidth <= 991) {
      return 7;
    } if (screenWidth >= 576 && screenWidth <= 767) {
      return 5;
    } if (screenWidth >= 321 && screenWidth <= 575) {
      return 4;
    } if (screenWidth >= 0 && screenWidth <= 320) {
      return 3;
    } else {
      return 3;
    }
  };

  return (
    <div className="sticky-section">
      <div className="inner-container ">
        <Formik
          initialValues={formInitialValues}
          onSubmit={(values) => {
          }}
          innerRef={formRef}
        >
          {({ values, setFieldValue }) => {
            return (
              <div className="filter">
                <Form>
                  <div className="choose-filter">
                    <div className="custom-spicies-silder">
                      <SlickSlider ref={sliderRef} {...settings}>
                        {species && species.map(
                          (specie, index) =>
                            specie?.is_searchable ? (
                              <>
                                {index == 0 ? <div
                                  className={
                                    isActiveSpecie?.active
                                      ? "slider-items"
                                      : "slider-items active"
                                  }
                                  onClick={() => {
                                    handleDefaultTag();
                                    setIsActiveSpecie({ active: null });
                                  }}
                                >
                                  <div className="slide-image">
                                    <img src={allSpecies} alt="" />
                                  </div>
                                  <p className="mt-2" style={{ color: "#000" }}>
                                    All
                                  </p>
                                </div> :
                                  <div
                                    className="slider-items"
                                    onClick={() => {
                                      handleSpecieTag(
                                        specie,
                                        setFieldValue,
                                        values.species
                                      );
                                    }}
                                  >
                                    <div
                                      className={
                                        isActiveSpecie?.active === specie.id
                                          ? "slide-image active-img active"
                                          : "slide-image"
                                      }
                                    >
                                      <img src={specie?.image} alt="" />
                                    </div>
                                    <p className="mt-2" title={specie?.specie_name}>{specie?.specie_name}</p>
                                  </div>
                                }
                              </>
                            ) : null
                        )}
                      </SlickSlider>
                    </div>

                    <div className="toggle-listing-map-btn">
                      <DropdownButton
                        className={clicked ? "action-button clicked" : "action-button"}
                        as={ButtonGroup}
                        key={'up'}
                        id={`dropdown-button-drop-up`}
                        drop={'up'}
                        variant="secondary"
                        onClick={handleClick}
                        ref={dropdownRef}
                      >
                        <Dropdown.Item className="map-filter-btn" eventKey="2">
                          <OverlayTrigger
                            placement="left"
                            overlay={<Tooltip id="tooltip1">List View</Tooltip>}
                          >
                            <button onClick={(e) => onChangeToggle('list')}>
                              <i className="bi bi-list-ul" style={landingPageUIProps?.currentScreen == "list" ?
                                { color: '#EA9B45' } : null}></i>
                            </button>
                          </OverlayTrigger>
                        </Dropdown.Item>
                        <Dropdown.Item className="map-filter-btn" eventKey="3">
                          <OverlayTrigger
                            placement="left"
                            overlay={<Tooltip id="tooltip1">Map View</Tooltip>}
                          >
                            <button onClick={(e) => onChangeToggle('map')}>
                              <i className="bi bi-map" style={landingPageUIProps?.currentScreen == "map" ?
                                { color: '#EA9B45' } : null}></i>
                            </button>
                          </OverlayTrigger>
                        </Dropdown.Item>
                        <Dropdown.Item className="map-filter-btn" eventKey="4">
                          <OverlayTrigger
                            placement="left"
                            overlay={<Tooltip id="tooltip1">Blog View</Tooltip>}
                          >
                            <button onClick={(e) => onChangeToggle('blog')}>
                              <i className="bi bi-file-text" style={landingPageUIProps?.currentScreen == "blog" ?
                                { color: '#EA9B45' } : null}></i>
                            </button>
                          </OverlayTrigger>
                        </Dropdown.Item>
                      </DropdownButton>
                    </div>
                  </div>
                </Form>
              </div>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}
