import { Form, Formik } from "formik";
import Slider, { SliderTooltip } from "rc-slider";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import "../../../../../node_modules/swiper/components/navigation/navigation.scss";
import { CustomSelect } from "../../../../components/UI/formComponents/CustomSelect";
import { useLandingPageUIContext } from "../LandingPageUIContext";
import { initialFilter, standDropDownOptions } from "../LandingPageUIHelper";
import { fetchPointOfInterest, fetchSiteTypes, fetchTerrains } from "modules/landingPage/_redux/landingPageActions";
import SwiperCore, { Navigation } from "swiper";
import moment from "moment";
import Calendar from "react-calendar";
import "rc-slider/assets/index.css";
import "swiper/swiper.scss";
import "./style.scss";


const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);
const { Handle } = Slider;

SwiperCore.use([Navigation]);

const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <SliderTooltip
      prefixCls="rc-slider-tooltip"
      overlay={value}
      visible={dragging}
      placement="bottom"
      key={index}
    >
      <Handle {...restProps} />
    </SliderTooltip>
  );
};

export default function FilterButton({ show, setInputValue, setReset, reset }) {
  const [rangeToDisplay, setRangeToDisplay] = useState("");
  const [range, setRange] = useState(["", ""]);
  const [filterState, setFilterState] = useState(false)

  const calendarRef = useRef();
  const dispatch = useDispatch();

  const handleFilterReset = (handleReset) => {
    handleReset();
    setInputValue("");
    setRangeToDisplay("");
    setRange(["", ""]);
    LandingPageUIProps.setQueryParams(initialFilter);
    setReset(!reset)
    setFilterState(!filterState)
  };
  const [showCalendar, setShowCalendar] = useState(false);
  const [siteType, setSiteType] = useState([]);
  const [interstingPoints, setInterstingPoints] = useState([]);
  const [terrain, setTerrain] = useState([]);
  const landingPageUIContext = useLandingPageUIContext();

  const LandingPageUIProps = useMemo(() => {
    return {
      setQueryParams: landingPageUIContext.setQueryParams,
      queryParams: landingPageUIContext.queryParams,
    };
  }, [landingPageUIContext]);

  const { pointOfInterest, siteTypes, terrains } = useSelector(
    (state) => ({
      pointOfInterest: state.landingPage.pointOfInterest,
      siteTypes: state.landingPage.siteTypes,
      terrains: state.landingPage.terrains,
    }),
    shallowEqual
  );

  const manageOutsideClick = (event) => {
    if (!(calendarRef && calendarRef.current && calendarRef.current.contains(event.target))) {
      setShowCalendar(false)
    }
  }

  useEffect(async () => {
    dispatch(fetchPointOfInterest());
    dispatch(fetchTerrains());
    dispatch(fetchSiteTypes());
    if (landingPageUIContext.queryParams?.start_date || landingPageUIContext?.end_date) {
      const dateRange = [
        moment(landingPageUIContext?.queryParams?.start_date)._d,
        moment(landingPageUIContext?.queryParams?.end_date)._d
      ]
      setRange(dateRange)
    }
    document.addEventListener("click", manageOutsideClick);
    return () => {
      document.removeEventListener("click", manageOutsideClick);
    }
  }, []);

  useEffect(() => {
    if (pointOfInterest) {
      setInterstingPoints(pointOfInterest?.map(item => {
        return {
          value: item.id,
          label: item.name,
        }
      }) || []);
    }
  }, [pointOfInterest]);

  useEffect(() => {
    if (siteTypes) {
      setSiteType(siteTypes?.map(item => {
        return {
          value: item.id,
          label: item.type,
        }
      }) || []);
    }
  }, [siteTypes]);

  useEffect(() => {
    if (terrains) {
      setTerrain(terrains?.map(item => {
        return {
          value: item.id,
          label: item.title,
        }
      }) || []);
    }
  }, [terrains]);

  useEffect(() => {
    if (range[0] && range[1]) {
      let startDate = moment(range[0]).format("MMM DD YYYY");
      let endDate = moment(range[1]).format("MMM DD YYYY");
      changeQueryParams({
        end_date: endDate,
        start_date: startDate,
      });
      setRangeToDisplay(`${startDate} - ${endDate}`);
      setShowCalendar(false);
    }
  }, [range]);

  const formInitialValues = {
    stand_dropdown_option: standDropDownOptions[0],
    species: [],
    start_price: 25,
    end_price: 1000,
    end_date: "",
    start_date: "",
    trophies_count_from: 0,
    trophies_count_to: 30,
    walk_distance: null,
    stand_distance: 0,
    person_capacity: null,
    cellular_coverage: null,
    handicap_accessible: null,
    LandingPageUIProps: null,
    is_marked_trail: null,
  };

  const changeQueryParams = (obj) => {
    LandingPageUIProps.setQueryParams({
      ...LandingPageUIProps.queryParams,
      ...obj,
      page: 1,
    });
  };

  const filterOptions = (candidate, input) => {
    if (input) {
      if (candidate.label.toLowerCase().includes(input.toLowerCase()))
        return true;
      if (
        LandingPageUIProps.queryParams.points_of_interest?.some((opt) => {
          if (opt.value === candidate.value) return true;
          else return false;
        })
      )
        return true;
      return false;
    }
    return true;
  };

  const siteTypeFilterOptions = (candidate, input) => {
    if (input) {
      if (candidate.label.toLowerCase().includes(input.toLowerCase()))
        return true;
      if (
        LandingPageUIProps.queryParams.site_type?.some((opt) => {
          if (opt.value === candidate.value) return true;
          else return false;
        })
      )
        return true;
      return false;
    }
    return true;
  };

  const terrainFilterOptions = (candidate, input) => {
    if (input) {
      if (candidate.label.toLowerCase().includes(input.toLowerCase()))
        return true;
      if (
        LandingPageUIProps.queryParams.terrain?.some((opt) => {
          if (opt.value === candidate.value) return true;
          else return false;
        })
      )
        return true;
      return false;
    }
    return true;
  };

  return (
    <div className="">
      <Formik initialValues={formInitialValues} onSubmit={(values) => { }}>
        {({ values, setFieldValue, handleReset }) => {
          return (
            <div className="filter">
              <Form>
                {show ? (
                  <div>
                    <div className="row mx-auto range-filter">
                      <div className="form-group col-lg-4 col-md-6 col-12">
                        <label>Distance from Parking (ft)</label>
                        <Slider
                          min={0}
                          defaultValue={0}
                          marks={{ 0: "0", 15000: "15000" }}
                          max={15000}
                          tipProps={{ visible: true }}
                          value={values.stand_distance || LandingPageUIProps?.queryParams?.stand_distance}
                          step={50}
                          handle={handle}
                          onChange={(e) => {
                            setFieldValue("stand_distance", e);
                          }}
                          onAfterChange={(currentValue) => {
                            changeQueryParams({
                              stand_distance: currentValue,
                            });
                          }}
                          tipFormatter={(value) => `$${value}`}
                        />
                      </div>
                      <div className="form-group col-lg-4 col-md-6 col-12">
                        <label>Trophies Amount</label>
                        <Range
                          min={0}
                          max={30}
                          defaultValue={[0, 15]}
                          tipProps={{ visible: true }}
                          handle={handle}
                          value={[
                            values.trophies_count_from || LandingPageUIProps?.queryParams?.trophies_count_from,
                            values.trophies_count_to || LandingPageUIProps?.queryParams?.trophies_count_to,
                          ]}
                          onChange={(e) => {
                            setFieldValue("trophies_count_from", e[0]);
                            setFieldValue("trophies_count_to", e[1]);
                          }}
                          onAfterChange={(currentValue) =>
                            changeQueryParams({
                              trophies_count_from: currentValue[0],
                              trophies_count_to: currentValue[1],
                            })
                          }
                        />
                      </div>
                      <div className="form-group col-lg-4 col-md-6 col-12">
                        <label>Price</label>
                        <Range
                          min={25}
                          defaultValue={[25, 200]}
                          step={0.1}
                          max={1000}
                          tipProps={{ visible: true }}
                          handle={handle}
                          tipFormatter={(value) => `$${value}`}
                          onAfterChange={(currentValue) => {
                            changeQueryParams({
                              start_price: currentValue[0],
                              end_price: currentValue[1],
                            });
                          }}
                          value={[
                            LandingPageUIProps?.queryParams?.start_price || values.start_price,
                            LandingPageUIProps?.queryParams?.end_price || values.end_price]}
                          onChange={(e) => {
                            setFieldValue("start_price", e[0]);
                            setFieldValue("end_price", e[1]);
                            changeQueryParams({
                              start_price: e[0],
                              end_price: e[1],
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="filter-btm-btns">
                      <form>
                        <div className="row">
                          <div className="form-group col-md-6 col-xl-6 col-xxl-4 avaible-btn">
                            <label>Listing ID</label>
                            <input
                              type="number"
                              onChange={(e) => {
                                setFieldValue("stand_id", e.target.value);
                                if (e.target.value == "0") {
                                  changeQueryParams({
                                    stand_id: "",
                                  });
                                } else
                                  changeQueryParams({
                                    stand_id: e.target.value,
                                  });
                              }}
                              className="form-control"
                              placeholder="Listing ID"
                              value={parseInt(LandingPageUIProps?.queryParams?.stand_id)}
                              key="stand_id"
                            />
                          </div>

                          <div className="form-group col-md-6 col-xl-6 col-xxl-4 avaible-btn">
                            {
                              <>
                                <label>Maximum Sportsman on Property</label>
                                <input
                                  type="number"
                                  onChange={(e) => {
                                    if (e.target.value >= 0) {
                                      setFieldValue(
                                        "person_capacity",
                                        e.target.value
                                      );
                                      if (e.target.value == "0") {
                                        changeQueryParams({
                                          person_capacity: "",
                                        });
                                      } else
                                        changeQueryParams({
                                          person_capacity:
                                            e.target.value,
                                        });
                                    }
                                  }}
                                  className="form-control"
                                  placeholder="Maximum Sportsman on Property"
                                  value={LandingPageUIProps?.queryParams?.person_capacity}
                                  key="person_capacity"
                                />
                              </>
                            }
                          </div>

                          <div className="form-group col-md-6 col-xl-6 col-xxl-4" ref={calendarRef}>
                            <label>Dates</label>
                            <div style={{ position: "relative" }}>
                              <div className="custon-dateRange">
                                <i className="far fa-calendar" onClick={() => setShowCalendar(!showCalendar)}></i>
                                <input
                                  type="text"
                                  placeholder="Select any date"
                                  className="form-control"
                                  value={rangeToDisplay}
                                  onClick={() => setShowCalendar(!showCalendar)}
                                  style={{ height: "39px" }}
                                />
                              </div>
                              <div>
                                <Calendar
                                  calendarType={"ISO 8601"}
                                  className={showCalendar ? "" : "hide"}
                                  value={range}
                                  onChange={(values) => {
                                    setRange(values);
                                  }}
                                  selectRange={true}
                                  minDate={new Date()}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="form-group col-md-6 col-xl-6 col-xxl-4 col-12 avaible-btn">
                            <CustomSelect
                              isMulti={true}
                              onChange={(selectedOption) => {
                                if (selectedOption?.length == 0) {
                                  LandingPageUIProps.setQueryParams({
                                    ...LandingPageUIProps.queryParams,
                                    terrain: []
                                  })
                                } else {
                                  LandingPageUIProps.setQueryParams({
                                    ...LandingPageUIProps.queryParams,
                                    terrain: selectedOption,
                                  })
                                }
                              }}
                              label="Location Terrain"
                              value={LandingPageUIProps?.queryParams?.terrain}
                              options={terrain}
                              filterOptions={terrainFilterOptions}
                              className="points-of-interest-select"
                              show={true}
                              noOptionsMessage={() => "No terrain record found"}
                            />
                          </div>

                          <div className="form-group col-md-6 col-xl-6 col-xxl-4 col-12 avaible-btn">
                            <CustomSelect
                              isMulti={true}
                              onChange={(selectedOption) => {
                                if (selectedOption?.length == 0) {
                                  LandingPageUIProps.setQueryParams({
                                    ...LandingPageUIProps.queryParams,
                                    site_type: []
                                  })
                                } else {
                                  LandingPageUIProps.setQueryParams({
                                    ...LandingPageUIProps.queryParams,
                                    site_type: selectedOption,
                                  })
                                }
                              }}
                              label="Hunting Site"
                              value={LandingPageUIProps?.queryParams?.site_type}
                              options={siteType}
                              filterOptions={siteTypeFilterOptions}
                              className="points-of-interest-select"
                              show={true}
                              noOptionsMessage={() => "No hunting site record found"}
                            />
                          </div>

                          <div className="form-group col-md-6 col-xl-6 col-xxl-4 col-12 avaible-btn">
                            <CustomSelect
                              isMulti={true}
                              onChange={(selectedOption) => {
                                if (selectedOption?.length == 0) {
                                  LandingPageUIProps.setQueryParams({
                                    ...LandingPageUIProps.queryParams,
                                    points_of_interest: []
                                  })
                                } else {
                                  LandingPageUIProps.setQueryParams({
                                    ...LandingPageUIProps.queryParams,
                                    points_of_interest: selectedOption,
                                  })
                                }
                              }}
                              label="Points of Interest"
                              value={LandingPageUIProps?.queryParams?.points_of_interest}
                              options={interstingPoints}
                              filterOptions={filterOptions}
                              className="points-of-interest-select"
                              show={true}
                              noOptionsMessage={() => "No points of interest record found"}
                            />
                          </div>

                          <div className="form-group col-md-6 col-xl-6 col-xxl-4 col-12 avaible-btn">
                            <label for="inputState">Phone Services</label>

                            <div className="availability-btns">
                              <button
                                className={`btn btn-outline-${values.cellular_coverage == 1 || LandingPageUIProps?.queryParams?.cellular_coverage == 1
                                  ? "primary"
                                  : "secondary"
                                  } me-2 ${values.cellular_coverage == 1 || LandingPageUIProps?.queryParams?.cellular_coverage == 1
                                    ? "selected" : ""
                                  }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  changeQueryParams({ cellular_coverage: 1 });
                                  setFieldValue("cellular_coverage", 1);
                                }}
                                type="button"
                              >
                                AVAILABLE
                              </button>
                              <button
                                className={`btn btn-outline-${values.cellular_coverage == 0 || LandingPageUIProps?.queryParams?.cellular_coverage == 0 ? "primary"
                                  : "secondary"
                                  }${values.cellular_coverage == 0 || LandingPageUIProps?.queryParams?.cellular_coverage == 0
                                    ? " selected" : ""
                                  }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setFieldValue("cellular_coverage", 0);
                                  changeQueryParams({ cellular_coverage: 0 });
                                }}
                                type="button"
                              >
                                NOT AVAILABLE
                              </button>
                            </div>
                          </div>

                          <div className="col-md-6 mt-3 col-xl-6 col-xxl-8 col-12 text-end">
                            <button
                              type="button"
                              className="btn btn-primary text-white reset-btn"
                              onClick={() => handleFilterReset(handleReset)}
                            >
                              RESET
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                ) :
                  null
                }
              </Form>
            </div>
          );
        }}
      </Formik >
    </div >
  );
}
