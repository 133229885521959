import React from "react";
import "./style.scss";


export default function bookHunt() {
    return (
        <section className="iframe-block-main">
            <div className="container-fluid px-0">
                <div className="row mx-auto">
                    <div className="col-12">
                        <h3 className="w-100 d-flex justify-content-center">How To Book A Hunt!</h3>
                    </div>
                    <div className="iframe-block-inner">
                        <div className="iframe mx-auto">
                            <iframe width="100%" src="https://www.youtube.com/embed/hK7_44n3Owc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                    </div>
                </div>    
            </div>
        </section>
    );
}
