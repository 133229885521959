import React, { useEffect, useMemo, useState } from "react";
import "./style.scss";
import { useDashboardUIContext } from "../DashboardUIContext";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/reviews/reviewActions";
import { Tab } from "react-bootstrap";
import Tabs from "react-bootstrap/Tabs";
import Pagination from "components/UI/pagination/Pagination";
import { ChallengePop } from "./Challenge/challenge";
import Timer from "assets/images/comment-timer.svg";
import avatar from "assets/images/avatar.png";
import noReview from "assets/images/review-placeholder.png";
import { Link, useHistory } from "react-router-dom";
import baseInstance from "services/axiosInstance";
import { ReviewBackQuestionsModal } from "./ReviewBackQuestions/ReviewBackQuestionsModal";
import Spinner from "components/UI/Spinner.js";


export default function ReviewsDashboard() {
  const dispatch = useDispatch();
  const dashboardPageUIContext = useDashboardUIContext();
  const dashboardPageUIProps = useMemo(() => {
    return {
      queryParams: dashboardPageUIContext.reviewQueryParams,
      setQueryParams: dashboardPageUIContext.setReviewQueryParams,
    };
  }, [dashboardPageUIContext]);

  const { reviewsList, totalRecords, averageRating, listLoading } =
    useSelector(
      (state) => ({
        reviewsList: state.review.reviewsList,
        totalRecords: state.review.reviewsCounter,
        averageRating: state.review.averageRating,
        listLoading: state.review.listLoading
      }),
      shallowEqual
    );

  const history = useHistory();
  const [key, setKey] = useState("hunter");
  const [pageCount, setPageCount] = useState(0);
  const [challangeKey, setChallangeKey] = useState(-1);
  const [ReviewBackModal, setReviewBackModal] = useState(false);
  const [currentCommentIndex, setCurrentCommentIndex] = useState(-1);
  const [challengeModal, setChallengeModal] = useState(false);
  const [updateReview, setUpdateReview] = useState(0);
  const [updateReviewID, setUpdateReviewID] = useState(0);
  const [reviewBackQuestions, setReviewBackQuestions] = useState([]);
  
  useEffect(async () => {
    const response = await baseInstance.get(`review-questions?type=${key}`);
    setReviewBackQuestions(response?.data);
  }, [key]);

  useEffect(() => {
    setPageCount(
      Math.ceil(totalRecords / dashboardPageUIProps?.queryParams?.per_page)
    );
  }, [totalRecords]);

  useEffect(() => {
    if (key === "hunter") {
      dispatch(actions.getReviews(dashboardPageUIProps?.queryParams, "hunter"));
    } else
      dispatch(
        actions.getReviews(dashboardPageUIProps?.queryParams, "landowner")
      );
  }, [dashboardPageUIProps.queryParams, key]);

  const hanldeActiveKeyChange = (k) => {
    setKey(k);
    history.push({
      pathname: "/my-profile/reviews",
      search: "?" + new URLSearchParams({ activeTab: k }).toString(),
    });
    dashboardPageUIProps.setQueryParams({
      per_page: 3,
      page: 1,
    });

    if (k !== key) {
      dispatch(actions.clearEntities());
    }
  };

  return (
    <>
      <div className="reviews-dashboard row">
        <div className="pb-4">
          <h6 className="d-content-inner-title font-roboto mb-0">Reviews</h6>
        </div>
        <div>
          <div className="reviews-details">
            <Tabs
              transition
              defaultActiveKey="hunter"
              id="uncontrolled-tab-example"
              className="mb-3 reviews-tabs"
              onSelect={(k) => hanldeActiveKeyChange(k)}
            >
              <Tab eventKey="hunter" title="Reviews as Hunter">
                {reviewsList?.length ? <div>
                  {" "}
                  <div className="box-reviews  me-4">
                    <div className="fs-14">Total Rating</div>
                    <div className="  fs-18" style={{ fontWeight: "500" }}>
                      <i className="bi text-star fs-20 bi-star-fill me-1"></i>{" "}
                      {averageRating}
                    </div>
                  </div>
                </div> : null}
                {listLoading ? <Spinner /> : reviewsList?.length
                  ? reviewsList?.map((review, index) => (
                    <>
                      {review?.reviews[0]?.type === "landowner" ? (
                        <>
                          <div className="comment-section w-100 fade-in ">
                            <div className={`comment-header`}>
                              <p>
                                {review?.reservation?.stand_detail?.title}
                              </p>
                              <Link
                                to={`/stand/${review?.reservation?.stand_detail?.id}`}
                                className="view-details"
                              >
                                View details
                              </Link>
                            </div>

                            <div className={`comment-body`}>
                              <div className={`reviewer-profile `}>
                                <div className={`user-profile `}>
                                  <img
                                    src={
                                      review?.reviews[0]?.user?.image ||
                                      avatar
                                    } alt=""
                                  ></img>
                                  <div className={`name `}>
                                    {review?.reviews[0]?.user?.first_name +
                                      " " +
                                      review?.reviews[0]?.user?.last_name}
                                  </div>
                                </div>
                                <div className={`rating-section `}>
                                  <i className="bi text-star fs-20 bi-star-fill  "></i>
                                  <p className="rate m-0">
                                    {review?.reviews[0]?.rating}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className={review?.reviews.length == 1 ? `comment-body blur` : `comment-body m-0`}>
                              <p className="comment">
                                {" "}
                                {review?.reviews[0]?.review}
                              </p>
                              <span className="comment-date ">
                                <img
                                  src={Timer}
                                  className="timer-icon"
                                  alt=""
                                ></img>{" "}
                                {review?.reviews[0]?.created_at}
                              </span>
                              {review?.reviews[1]?.type === "hunter" ? (
                                <div className={`post-comment-section `}>
                                  <div className="user-profile">
                                    <div className="d-flex flex-row align-items-center">
                                      <img
                                        src={
                                          review?.reviews[1]?.user?.image ||
                                          avatar
                                        } alt=""
                                      ></img>
                                      <div className="name">
                                        {review?.reviews[1]?.user
                                          ?.first_name +
                                          " " +
                                          review?.reviews[1]?.user?.last_name}
                                      </div>
                                    </div>
                                    <div className="rating-section">
                                      <i className="bi text-star fs-20 bi-star-fill  "></i>
                                      <p className="rate m-0">
                                        {review?.reviews[1]?.rating}
                                      </p>
                                    </div>
                                  </div>

                                  {review?.reviews[1]?.review ? <p className="reply">
                                    {review?.reviews[1]?.review}
                                  </p> : null}
                                  <span className="comment-date">
                                  <img
                                    src={Timer}
                                    className="timer-icon"
                                    alt=""
                                  ></img>{" "}
                                    {review?.reviews[0]?.created_at}
                                  </span>
                                </div>
                              ) : null}
                            </div>
                            <p className={review?.reviews.length == 1 ? "reveal-comment" : "reveal-comment-none"}>Review back to reveal comment</p>

                            {/* when only review is not published and challange === 2 */}

                            {(review?.reviews[0]?.published && !review?.reviews[1]) ||
                              review?.reviews.length == 1 ? (
                              <>
                                <div className="d-flex justify-content-end w-100 pe-4">
                                  {" "}
                                  <button
                                    className="btn btn-outline-primary post-btn rounded-3 text-primary"
                                    onClick={() => {
                                      setReviewBackModal(true);
                                      setCurrentCommentIndex(
                                        review?.reservation?.id
                                      );
                                    }}
                                  >
                                    REVIEW LISTING
                                  </button>
                                </div>
                                {review?.reviews[0]?.published !== true ?
                                  <div className="expires-in">
                                    <i className="bi info-icon bi-info me-2"></i>{" "}
                                    <p>
                                      After{" "}
                                      {review?.reviews[0]?.days_left_to_publish}{" "}
                                      Days review will be posted or you can Post
                                      Now.
                                    </p>
                                  </div> : ''}
                              </>
                            ) : null}
                            {review?.reviews[1]?.published &&
                              review?.reviews[0]?.published ? (
                              <></>
                            ) : null}
                          </div>
                        </>
                      ) : (
                        <div className="comment-section w-100 fade-in">
                          <div className={`comment-header `}>
                            <div>
                              <p>{review?.reservation?.stand_detail?.title}</p>
                              <span>Hunt Date: {review?.reviews[0]?.created_at}</span>
                            </div>
                            <Link
                              to={`/stand/${review?.reservation?.stand_detail?.id}`}
                              className="view-details"
                            >
                              View details
                            </Link>
                          </div>

                          <div className={`comment-body `}>
                            <div className={`reviewer-profile `}>
                              <div className={`user-profile `}>
                                <img
                                  src={
                                    review?.reviews[1]?.user?.image || avatar
                                  } alt=""
                                ></img>
                                <div className={`name `}>
                                  {review?.reviews[1]?.user?.first_name +
                                    " " +
                                    review?.reviews[1]?.user?.last_name}
                                </div>
                              </div>
                              <div className={`rating-section `}>
                                <i className="bi text-star fs-20 bi-star-fill  "></i>
                                <p className="rate m-0">
                                  {review?.reviews[1]?.rating}
                                </p>
                              </div>
                            </div>
                            <p className={`comment `}>
                              {" "}
                              {review?.reviews[1]?.review}
                            </p>
                            <span className="comment-date ">
                              <img src={Timer} className="timer-icon" alt=""></img>{" "}
                              {review?.reviews[1]?.created_at}
                            </span>

                            {review?.reviews[0]?.type === "hunter" ? (
                              <div className={`post-comment-section `}>
                                <div className="user-profile">
                                  <div className="d-flex flex-row align-items-center">
                                    <img
                                      src={
                                        review?.reviews[0]?.user?.image ||
                                        avatar
                                      } alt=""
                                    ></img>
                                    <div className="name">
                                      {review?.reviews[0]?.user?.first_name +
                                        " " +
                                        review?.reviews[0]?.user?.last_name}
                                    </div>
                                  </div>
                                  <div className="rating-section">
                                    <i className="bi text-star fs-20 bi-star-fill  "></i>
                                    <p className="rate m-0">
                                      {review?.reviews[1]?.rating}
                                    </p>
                                  </div>
                                </div>
                                {review?.reviews[0]?.review ? <p className="reply">
                                  {review?.reviews[0]?.review}
                                </p> : null}
                                <span className="comment-date">
                                  <img src={Timer} className="timer-icon" alt=""></img>{" "}
                                  {review?.reviews[0]?.created_at}
                                </span>
                              </div>
                            ) : null}
                            {(review?.reviews[0]?.published && !review?.reviews[1]) ||
                              review?.reviews.length == 1 ? (
                              <>
                                <div className="d-flex justify-content-end w-100 pe-4">
                                  {" "}
                                  <button
                                    className="btn btn-outline-primary post-btn rounded-3 text-primary"
                                    onClick={() => {
                                      setReviewBackModal(true);
                                      setCurrentCommentIndex(
                                        review?.reservation?.id
                                      );
                                    }}
                                  >
                                    REVIEW LISTING
                                  </button>
                                </div>
                                {review?.reviews[1]?.published !== true ?
                                  <div className="expires-in">
                                    <i className="bi info-icon bi-info me-2"></i>{" "}
                                    <p>
                                      After{" "}
                                      {review?.reviews[1]?.days_left_to_publish}{" "}
                                      Days review will be posted or you can Post
                                      Now.
                                    </p>
                                  </div> : ''}
                              </>
                            ) : null}
                            {review?.reviews[0]?.published &&
                              review?.reviews[1]?.published ? (
                              <></>
                            ) : null}
                          </div>
                        </div>
                      )}
                    </>
                  ))
                  : <div className="no-review-mainbox">
                    <div className="row">
                      <div className="col-12">
                        <div className="box">
                          <div className="img">
                            <img src={noReview} alt="" />
                            <span>
                              You have no reviews yet!
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>}
              </Tab>
              <Tab eventKey="landowner" title="Reviews as Landowner">
                <div>
                  {" "}
                  {reviewsList?.length ? <div className="box-reviews  me-4">
                    <div className="fs-14">Total Rating</div>
                    <div className="fw-bold fs-18">
                      <i className="bi text-star fs-20 bi-star-fill me-1"></i>{" "}
                      {averageRating}
                    </div>
                  </div> : null}
                </div>
                {listLoading ? <Spinner /> : reviewsList?.length
                  ? reviewsList?.map((review, index) => (
                    <>
                      {review?.reviews[0]?.type === "hunter" ? (
                        <>
                          <div className="comment-section w-100">
                            <div className={`comment-header `}>
                              <div>
                                <p>
                                  {review?.reservation?.stand_detail?.title}
                                </p>
                                <span>Hunt Date: {review?.reviews[0]?.created_at}</span>
                              </div>
                              <Link
                                to={`/stand/${review?.reservation?.stand_detail?.id}`}
                                className="view-details"
                              >
                                View details
                              </Link>
                            </div>

                            <div className={`comment-body`}>
                              <div className={`reviewer-profile `}>
                                <div className={`user-profile`}>
                                  <img
                                    src={
                                      review?.reviews[0]?.user?.image ||
                                      avatar
                                    } alt=""
                                  ></img>
                                  <div className={`name `}>
                                    {review?.reviews[0]?.user?.first_name +
                                      " " +
                                      review?.reviews[0]?.user?.last_name}
                                  </div>
                                </div>
                                <div className="rating-section">
                                  <i className="bi text-star fs-20 bi-star-fill  "></i>
                                  <p className="rate m-0">
                                    {review?.reviews[0]?.rating}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className={review?.reviews.length === 1 ? `comment-body blur` : `comment-body m-0`}>
                              <p className={`comment `}>
                                {" "}
                                {review?.reviews[0]?.review}
                              </p>
                              <span className="comment-date ">
                                <img
                                  src={Timer}
                                  className="timer-icon"
                                  alt=""
                                ></img>{" "}
                                {review?.reviews[0]?.created_at}
                              </span>

                              {review?.reviews[1]?.type === "landowner" ? (
                                <div className={`post-comment-section `}>
                                  <div className="user-profile">
                                    <div className="d-flex flex-row align-items-center">
                                      <img
                                        src={
                                          review?.reviews[1]?.user?.image ||
                                          avatar
                                        } alt=""
                                      ></img>
                                      <div className="name">
                                        {review?.reviews[1]?.user
                                          ?.first_name +
                                          " " +
                                          review?.reviews[1]?.user?.last_name}
                                      </div>
                                    </div>
                                    <div className="rating-section">
                                      <i className="bi text-star fs-20 bi-star-fill  "></i>
                                      <p className="rate m-0">
                                        {review?.reviews[1]?.rating}
                                      </p>
                                    </div>
                                  </div>
                                  {review?.reviews[1]?.review ? <p className="reply">
                                    {review?.reviews[1]?.review}
                                  </p> : null}
                                  <span className="comment-date">
                                  <img
                                    src={Timer}
                                    className="timer-icon"
                                    alt=""
                                  ></img>{" "}
                                    {review?.reviews[1]?.created_at}
                                  </span>
                                </div>
                              ) : null}
                            </div>
                            <p className={review?.reviews.length == 1 ? "reveal-comment" : "reveal-comment-none"}>Review back to reveal comment</p>
                            {(review?.reviews[0]?.published && !review?.reviews[1]) ||
                              review?.reviews.length == 1 ? (
                              <>
                                <div className="d-flex justify-content-end w-100 pe-4">
                                  {" "}
                                  <button
                                    className="btn btn-outline-primary post-btn rounded-3 text-primary"
                                    onClick={() => {
                                      setReviewBackModal(true);
                                      setCurrentCommentIndex(
                                        review?.reservation?.id
                                      );
                                    }}
                                  >
                                    REVIEW HUNTER
                                  </button>
                                </div>
                                {review?.reviews[0]?.published !== true ?
                                  <div className="expires-in">
                                    <i className="bi info-icon bi-info me-2"></i>{" "}
                                    <p>
                                      After{" "}
                                      {review?.reviews[0]?.days_left_to_publish}{" "}
                                      Days review will be posted or you can Post
                                      Now.
                                    </p>
                                  </div> : ''}
                              </>
                            ) : null}
                            {review?.reviews[1]?.published &&
                              review?.reviews[0]?.published ? (
                              <></>
                            ) : null}
                          </div>
                        </>
                      ) : (
                        <div className="comment-section w-100">
                          <div className={`comment-header `}>
                            <p>{review?.reservation?.stand_detail?.title}</p>
                            <Link
                              to={`/stand/${review?.reservation?.stand_detail?.id}`}
                              className="view-details"
                            >
                              View details
                            </Link>
                          </div>

                          <div className={`comment-body `}>
                            <div className={`reviewer-profile `}>
                              <div className={`user-profile `}>
                                <img
                                  src={
                                    review?.reviews[1]?.user?.image || avatar
                                  } alt=""
                                ></img>
                                <div className={`name `}>
                                  {review?.reviews[1]?.user?.first_name +
                                    " " +
                                    review?.reviews[1]?.user?.last_name}
                                </div>
                              </div>
                              <div className={`rating-section `}>
                                <i className="bi text-star fs-20 bi-star-fill  "></i>
                                <p className={`rate m-0`}>
                                  {review?.reviews[1]?.rating}
                                </p>
                              </div>
                            </div>
                            <p className={`comment `}>
                              {" "}
                              {review?.reviews[1]?.review}
                            </p>
                            <span className="comment-date ">
                              <img src={Timer} className="timer-icon" alt=""></img>{" "}
                              {review?.reviews[1]?.created_at}
                            </span>
                            {review?.reviews[0]?.type === "landowner" ? (
                              <div className={`post-comment-section`}>
                                <div className="user-profile">
                                  <div className="d-flex flex-row align-items-center">
                                    <img
                                      src={
                                        review?.reviews[0]?.user?.image ||
                                        avatar
                                      } alt=""
                                    ></img>
                                    <div className="name">
                                      {review?.reviews[0]?.user?.first_name +
                                        " " +
                                        review?.reviews[0]?.user?.last_name}
                                    </div>
                                  </div>
                                  <div className="rating-section">
                                    <i className="bi text-star fs-20 bi-star-fill  "></i>
                                    <p className="rate m-0">
                                      {review?.reviews[1]?.rating}
                                    </p>
                                  </div>
                                </div>
                                {review?.reviews[0]?.review ? <p className="reply">
                                  {review?.reviews[0]?.review}
                                </p> : null}
                                <span className="comment-date">
                                  <img src={Timer} className="timer-icon" alt=""></img>{" "}
                                  {review?.reviews[0]?.created_at}
                                </span>
                              </div>
                            ) : null}
                            {(review?.reviews[0]?.published && !review?.reviews[1]) ||
                              review?.reviews.length == 1 ? (
                              <>
                                <div className="d-flex justify-content-end w-100 pe-4">
                                  {" "}
                                  <button
                                    className="btn btn-outline-primary post-btn rounded-3 text-primary"
                                    onClick={() => {
                                      setReviewBackModal(true);
                                      setCurrentCommentIndex(
                                        review?.reservation?.id
                                      );
                                    }}
                                  >
                                    REVIEW HUNTER
                                  </button>
                                </div>
                                {review?.reviews[1]?.published !== true ?
                                  <div className="expires-in">
                                    <i className="bi info-icon bi-info me-2"></i>{" "}
                                    <p>
                                      After{" "}
                                      {review?.reviews[1]?.days_left_to_publish}{" "}
                                      Days review will be posted or you can Post
                                      Now.
                                    </p>
                                  </div> : ''}
                              </>
                            ) : null}
                            {review?.reviews[0]?.published &&
                              review?.reviews[1]?.published ? (
                              <></>
                            ) : null}
                          </div>
                        </div>
                      )}
                    </>
                  ))
                  : <div className="no-review-mainbox">
                    <div className="row">
                      <div className="col-12">
                        <div className="box">
                          <div className="img">
                            <img src={noReview} alt="" />
                            <span>
                              You have no reviews yet!
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>}
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>

      {reviewsList.length > 0 ? (
        <div className="inner-container">
          <Pagination
            totalRecords={totalRecords}
            uiProps={dashboardPageUIProps}
          />
        </div>
      ) : null}
      <ChallengePop
        show={challengeModal}
        setKey={setKey}
        key={key}
        challangeKey={challangeKey}
        onHide={() => {
          setChallengeModal(false);
        }}
      />

      <ReviewBackQuestionsModal
        show={ReviewBackModal}
        updateReview={updateReview}
        updateReviewID={updateReviewID}
        currentCommentIndex={currentCommentIndex}
        type={key}
        setKey={setKey}
        questions={reviewBackQuestions}
        onHide={() => {
          setReviewBackModal(false);
        }}
      />
    </>
  );
}
