import toastHandler from "components/UI/toastHandler/toastHandler";
import React from "react";
import { Modal } from "react-bootstrap";
import baseInstance from "services/axiosInstance";
import "./style.scss";


export function ChallengePop({ show, onHide, challangeKey, setKey, key }) {

  const onSubmitChallange = async () => {
    const response = await baseInstance.patch(
      `/challenge-review/${challangeKey}`
    );
    if (response.status) {
      setKey(key);
      toastHandler(response?.message, "success");
      onHide();
    } else {
      setKey(key);
      toastHandler("Something Went Wrong", "error");
    }
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
        centered
        className="challenge-popup "
        size="md"
      >
        <Modal.Header closeButton className="px-4">
          <Modal.Title id="example-modal-sizes-title-lg">Challenge</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4">
          <p>
            You can ask Landowner to reconsider his/her review of you. A
            system-generated message will be delivered to Landowner to
            reconsider his/her review.
          </p>
        </Modal.Body>
        <Modal.Footer className=" pt-0">
          <button
            type="button"
            className="btn btn-outline-secondary px-3 border-fifth me-2"
            onClick={() => onHide()}
          >
            CANCEL
          </button>
          <> </>
          <button
            type="button"
            className="btn btn-primary text-white"
            onClick={onSubmitChallange}
          >
            YES
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
