import axios from "../../../../services/axiosInstance";


export function getStandCapacity() {
  return axios.get("get-stand-capacity");
}

export function getAllSiteTypes() {
  return axios.get("get-site-types");
}

export function getAllSpecies() {
  return axios.get("get-species");
}

export function getAllStates() {
  return axios.get("get-states");
}

export function deleteStand(id) {
  return axios.delete(`stand/${id}`);
}

export function toggleStand(id, body) {
  return axios.patch(`toggle-stand-status/${id}`, body, {
    headers: {
      "Accept": "application/json",
    },
  });
}

export function getStandById(id) {
  return axios.get(`stand-detail/${id}`);
}

export function getStandDetails(id) {
  return axios.get(`stand-detail/${id}`);
}

export function getStands(queryParams, source) {
  return axios.get("get-stands", {
    params: queryParams,
    cancelToken: source.token,
  });
}

export function getFollowedStands(queryParams) {
  return axios.get("followed-stands", { params: queryParams });
}

export function addFollowStands(id) {
  return axios.post(`follow-stand/${id}`);
}

export function getInactiveStands(queryParams, source) {
  return axios.get("inactive-stands", {
    params: queryParams,
    cancelToken: source.token,
  });
}

export function updateDeaftStand(stand, id) {
  return axios.post(`update-draft-stand/${id}`, stand);
}

export function getStandReservedDates(id) {
  return axios.get(`stand-reserved-dates/${id}`);
}

export function getAllUserVouchers(queryParams) {
  return axios.get(`user-vouchers`, { params: queryParams });
}

export function getStandReservations(id, queryParams) {
  return axios.get(`reserve-stands-landowner/${id}`, { params: queryParams });
}

export function getHuntingDetails(id, queryParams) {
  return axios.get(`hunting-detail/${id}`, { params: queryParams });
}

export function filterUser(params) {
  return axios.get(`get-all-users`, {
    params
  });
}

export function changeInviteStatus(id, body) {
  return axios.post(`process-stand-transfer/${id}`, body);
}

export function standInvitation(id, body) {
  return axios.post(`stand-invitation/${id}`, body);
}

export function verifyStandInvitation(body) {
  return axios.post(`stand-invitation-verification`, body);
}

export function standInvitationAction(body) {
  return axios.post(`stand-invitation-action`, body);
}

export function cancelStandTransferInvitaion(id) {
  return axios.put(`stand-invitation-cancel/${id}`);
}