import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import SignatureCanvas from "react-signature-canvas";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ConfirmationModal } from "components/UI/modals/confirmation-modal/confirmationModal";
import { useHistory } from "react-router-dom";
import parse from "html-react-parser";
import { fetchLeasingMarkup, postLeaseApplication } from "modules/landingPage/_redux/landingPageActions";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Form } from "react-bootstrap";
import Spinner from "components/UI/Spinner.js";
import { dataURLtoFile } from "utils/helpers";
import toastHandler from "components/UI/toastHandler/toastHandler";
import { loadUser } from "modules/auth/_redux/authActions";

export const stepOneValidationSchema = Yup.object({
  confirmation: Yup.bool().oneOf([true], "Terms and Condition must be checked"),
  signature: Yup.string().required("Signature is required"),
});

export default function LeasingApplication() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);

  //access state
  const { leasingMarkup, user, isLoading, actionsLoading } = useSelector(
    (state) => ({
      leasingMarkup: state.landingPage.leasingMarkup,
      user: state.auth.user,
      isLoading: state.landingPage.listLoading,
      actionsLoading: state.landingPage.actionsLoading,
    }),
    shallowEqual
  );
  const signatureRef = useRef(null);

  const handleSignatureClear = () => {
    signatureRef.current.clear();
    formik.setFieldValue("signature", "");
  };

  useEffect(() => {
    if (user && user.property_identification_name) {
      dispatch(fetchLeasingMarkup());
    } else {
      setShowModal(true);
    }
  }, []);

  const handleModalClose = (confirmation) => {
    if (confirmation) {
      history.push({
        pathname: `/my-profile/my-profile`,
        state: { redirect: "/leasing-application" },
      });
    } else {
      history.push("/");
    }
    setShowModal(false);
  };

  const onSuccess = () => {
    dispatch(loadUser());
    toastHandler("Lease Application Submitted Successfully", "success");
    history.push("/");
  };

  const onError = (message) => {
    toastHandler(message, "error");
  };

  const handleSubmit = () => {
    const formData = new FormData();
    var file = dataURLtoFile(
      signatureRef.current.getTrimmedCanvas().toDataURL("image/png"),
      "image.png"
    );
    formData.append("signature", file);
    dispatch(postLeaseApplication(formData, onSuccess, onError));
  };

  const formik = useFormik({
    initialValues: {
      confirmation: false,
      signature: "",
    },
    validationSchema: stepOneValidationSchema,
    onSubmit: handleSubmit,
  });

  return !isLoading || !user ? (
    <div className="mt-5 mb-5">
      <form onSubmit={formik.handleSubmit}>
        <div className="inner-container">
          {parse(leasingMarkup || "")}
          <div className="term-condition">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
                checked={formik.values.confirmation}
                onChange={(e) => {
                  formik.setFieldValue("confirmation", e.target.checked);
                }}
              />
              <label
                className="form-check-label text-black"
                for="exampleCheck1"
              ></label>
              <p>
                I have read and accept the{" "}
                <span className="text-primary">terms & condition</span>
              </p>
            </div>
            {formik?.touched?.confirmation && formik?.errors?.confirmation && (
              <Form.Control.Feedback type="d-block invalid">
                {formik.errors.confirmation}
              </Form.Control.Feedback>
            )}
          </div>
          <div className="signature-box">
            <p className="text-black">
              Please put your digital Signature Below.
            </p>
            <div className="user-signature">
              <SignatureCanvas
                penColor="black"
                canvasProps={{
                  width: 500,
                  height: 100,
                  className: "sigCanvas",
                }}
                ref={signatureRef}
                onEnd={() =>
                  formik.setFieldValue(
                    "signature",
                    signatureRef.current
                      .getTrimmedCanvas()
                      .toDataURL("image/png")
                  )
                }
              />

              {formik?.touched?.signature && formik?.errors?.signature && (
                <Form.Control.Feedback type="d-block invalid">
                  {formik.errors.signature}
                </Form.Control.Feedback>
              )}
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-primary text-white m-1"
                  type="submit"
                  disabled={
                    !formik.values.confirmation || !formik.values.signature
                  }
                >
                  {" "}
                  {actionsLoading ? (
                    <Spinner />
                  ) : (
                    `SUBMIT`
                  )}
                </button>
                <button
                  className="btn btn-danger text-white m-1"
                  onClick={handleSignatureClear}
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <ConfirmationModal
        show={showModal}
        onHide={handleModalClose}
        title={"Attention"}
        message={"You need to provide Property Identification Name"}
        yesString={"Allow"}
        noString={"Deny"}
        onlyYes={false}
      />
    </div>
  ) : (
    <div className="d-flex justify-content-center">
      <Spinner />
    </div>
  );
}
