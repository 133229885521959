import React, { useEffect, useMemo, useState } from "react";
import { Table } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Link, useParams } from "react-router-dom";
import * as actions from "../../../../_redux/stand/standActions";
import avatar from "../../../../../../assets/images/avatar.png";
import Equalizer from "../../../../../../assets/icons/equalizer-line 1.svg";
import Pagination from "components/UI/pagination/Pagination";
import { useDashboardUIContext } from "modules/dashboard/pages/DashboardUIContext";
import NoRecordFound from "utils/noRecordFound";
import Spinner from "components/UI/Spinner.js";
import "./style.scss";


export default function StandBookings({ id, standName }) {
  const dashboardPageUIContext = useDashboardUIContext();
  const dashboardPageUIProps = useMemo(() => {
    return {
      queryParams: dashboardPageUIContext.bookingsQueryParams,
      setQueryParams: dashboardPageUIContext.setBookingsQueryParams,
    };
  }, [dashboardPageUIContext]);

  const params = useParams();
  const tab = params?.history;
  const [filter, setFilter] = useState("all");
  const history = useHistory();
  const search = useLocation().search;
  const page = new URLSearchParams(search).get("page") || 1;
  const handleBack = () => {
    const queryParams = { ...dashboardPageUIProps.queryParams };
    queryParams.page = 1;
    dispatch(actions.clearEntities(0));
    history.goBack();
    dashboardPageUIProps.setQueryParams(queryParams);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (!id || !tab || !page) return;
    const queryParams = { ...dashboardPageUIProps.queryParams };
    const fetchType = tab === "activetab" ? "history" : "current";
    if (filter === "all") {
      queryParams.type = fetchType;
    } else {
      queryParams.status = filter;
    }
    dispatch(actions.fetchStandReservations(id, queryParams));
    return () => {
      dispatch(actions.clearEntities());
    };
  }, [id, tab, filter, dashboardPageUIProps.queryParams]);

  const { standReservations, totalBookings, actionsLoading } = useSelector(
    (state) => ({
      actionsLoading: state.stand.actionsLoading,
      standReservations: state.stand.standReservations,
      totalBookings: state.stand.totalBookings,
      actionsLoading: state.stand.actionsLoading
    }),
    shallowEqual
  );

  return (
    <>
      <div className="pb-4">
        <h6 className="d-content-inner-title font-roboto mb-0">{standName}</h6>
        <div className="goback d-flex align-items-center my-4">
          <div className="top" onClick={handleBack}>
            <i className="bi bi-arrow-left-short me-2 fs-24"></i>
            <span className="fs-18">Go back</span>
          </div>
        </div>

        {tab && tab === "activetab" ? (
          <div className="filter-wrapper m-5">
            <div className="filter-btn">
              <img src={Equalizer} alt="" />
              <select
                value={filter}
                className="select-field"
                onChange={(e) => {
                  setFilter(e.target.value);
                }}
              >
                <option value={"completed"}>Completed</option>
                <option value={"canceled"}>Canceled</option>
                <option value={"rejected"}>Rejected</option>
                <option value={"all"}>All</option>
              </select>
            </div>
          </div>
        ) : (
          <div className="filter-wrapper m-5">
            <div className="filter-btn">
              <img src={Equalizer} alt="" />
              <select
                value={filter}
                className="select-field"
                onChange={(e) => {
                  setFilter(e.target.value);
                }}
              >
                <option value={"pending"}>Pending</option>
                <option value={"approved"}>Approved</option>
                <option value={"all"}>All</option>
              </select>
            </div>
          </div>
        )}
        <div className="stand-details-table table-left-align">
          {
            actionsLoading ? <Spinner /> :
              standReservations?.length ?
                <Table responsive>
                  <thead>
                    <tr className="text-fifth">
                      <th>HUNTER</th>
                      <th>BOOKING DATE</th>
                      <th>EARNINGS</th>
                      {tab && <th>STATUS</th>}
                      <th colSpan="2">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {standReservations?.length
                      ? standReservations?.map((reservation, index) => (
                        <tr>
                          <td>
                            <Link
                              to={`/my-profile/user-review/${reservation?.user?.id}`}
                              className="text-decoration-none full-name-link"
                            >
                              {" "}
                              <img
                                className="tbl-img-user me-2"
                                src={reservation?.user?.image || avatar}
                                alt=""
                              />
                              <div className="full-name">
                                {" "}
                                {reservation?.user?.first_name}{" "}
                                {reservation?.user?.last_name}
                              </div>
                            </Link>
                          </td>
                          <td>
                            {reservation?.start_date} - {reservation?.end_date}
                          </td>
                          <td className="fw-bold">${reservation?.amount}</td>
                          {tab && tab != 0 ? (
                            <td>
                              {reservation?.status === "completed" ? (
                                <p style={{ color: "#27AB34", marginTop: "15px" }}>
                                  Completed
                                </p>
                              ) : reservation?.status === "canceled" ? (
                                <p style={{ color: "#D60000", marginTop: "15px" }}>
                                  Canceled
                                </p>
                              ) : (
                                <p style={{ color: "#D60000", marginTop: "15px" }}>
                                  Rejected
                                </p>
                              )}
                            </td>
                          ) : (
                            <td>
                              {reservation?.status === "approved" ? (
                                <p style={{ color: "#DF9C56", marginTop: "15px" }}>
                                  Approved
                                </p>
                              ) : (
                                <p style={{ color: "#E9BF45", marginTop: "15px" }}>
                                  Pending
                                </p>
                              )}
                            </td>
                          )}
                          <td>
                            <Link
                              to={`/my-profile/reservation-details/${reservation?.id}`}
                              className="text-decoration-line"
                              style={{ color: "#DF9C56", marginTop: "15px" }}
                            >
                              Reservation Detail
                            </Link>
                          </td>
                        </tr>
                      ))
                      : null}
                  </tbody>
                </Table> : <NoRecordFound />
          }
          {standReservations?.length > 0 ? (
            <div className="inner-container">
              <Pagination
                totalRecords={totalBookings}
                uiProps={dashboardPageUIProps}
                history={history}
              />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}
