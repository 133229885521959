import { createSlice } from "@reduxjs/toolkit";

const initialDashboardState = {
  error: null,
  actionsLoading: null,
  listLoading: null,
  species: null,
  siteTypes: null,
  standCapacities: null,
  states: null,
  standsList: [],
  inActiveStandList: [],
  bookedStandList: [],
  bookingHistoryList: [],
  totalStands: 0,
  selectedStand: null,
  standDetails: null,
  reservedDates: null,
  userVouchers: [],
  totalVouchers: 0,
  standInvites: [],
  totalInvites: 0,
  standReservations: [],
  huntingDetails: [],
  filteredUser: [],
  statusCode: 0,
  totalBookings: 0,
  isAddressSelected: false,
  inActiveStandLoading: false,
  bookedStandLoading: false,
  bookingHistoryLoading: false,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const standSlice = createSlice({
  name: "stand",
  initialState: initialDashboardState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    standCapacitiesFetched: (state, action) => {
      const { entities } = action.payload;
      state.error = null;
      state.standCapacities = entities;
    },
    speciesFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.species = entities;
    },
    siteTypesFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.siteTypes = entities;
    },
    clearEntities: (state, action) => {
      state.species = null;
      state.siteTypes = null;
      // state.standsCapacities = null;
      state.standsList = [];
      state.selectedStand = null;
      state.standDetails = null;
      state.standReservations = null;
    },
    getAllStatesFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.states = entities;
    },
    // standsList fetched
    standsFetched: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.standsList = action.payload.entities;
      state.totalStands = action.payload.totalRecords;
    },

    inActiveStandRequest: (state) => {
      state.inActiveStandLoading = true;
      state.inActiveStandList = [];
      state.error = null;
      state.totalStands = null;
    },

    inActiveStandFetched: (state, action) => {
      state.error = null;
      state.inActiveStandLoading = false;
      state.inActiveStandList = action.payload.entities;
      state.totalStands = action.payload.totalRecords;
    },

    inActiveStandRequestFailed: (state, action) => {
      state.inActiveStandLoading = false;
      state.inActiveStandList = [];
      state.error = action.payload?.error;
      state.totalStands = null;
    },

    bookedStandRequest: (state) => {
      state.bookedStandLoading = true;
      state.bookedStandList = [];
      state.error = null;
      state.totalStands = null;
    },

    bookedStandFetched: (state, action) => {
      state.error = null;
      state.bookedStandLoading = false;
      state.bookedStandList = action.payload.entities;
      state.totalStands = action.payload.totalRecords;
    },

    bookedStandRequestFailed: (state, action) => {
      state.bookedStandLoading = false;
      state.bookedStandList = [];
      state.error = action.payload?.error;
      state.totalStands = null;
    },

    bookingHistoryRequest: (state) => {
      state.bookingHistoryLoading = true;
      state.bookingHistoryList = [];
      state.error = null;
      state.totalStands = null;
    },

    bookingHistoryFetched: (state, action) => {
      state.error = null;
      state.bookingHistoryLoading = false;
      state.bookingHistoryList = action.payload.entities;
      state.totalStands = action.payload.totalRecords;
    },

    bookingHistoryRequestFailed: (state, action) => {
      state.bookingHistoryLoading = false;
      state.bookingHistoryList = [];
      state.error = action.payload?.error;
      state.totalStands = null;
    },

    favouriteStandAdded: (state) => {
      state.error = null;
      state.listLoading = false;
    },

    followStandAdded: (state) => {
      state.error = null;
      state.listLoading = false;
    },

    standDetailFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.selectedStand = action.payload.selectedStand;
    },

    standDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },

    standToggled: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },

    // Updating the draft stand
    updatedDraftStand: (state, action) => {
      state.error = null;
      state.totalStands = action.payload.totalCount;
      state.standsList = [...state.standsList, action.payload.entity];
      state.selectedStand = action.payload.entity;
    },
    // stand details
    standDetailsFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.standDetails = action.payload.selectedStand;
    },
    // reserved dates for stand fetched
    reservedDatesForStandFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.reservedDates = action.payload.reservedDates;
    },
    userVouchersFetched: (state, action) => {
      const { entities, totalEntities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.userVouchers = entities;
      state.totalVouchers = totalEntities;
    },
    standReservationsFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.standReservations = action.payload.standReservations;
      state.totalBookings = action.payload.totalRecords
    },
    huntingDetailsFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.huntingDetails = action.payload.details;
    },
    filteredUserFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.filteredUser = action.payload.details;
      state.statusCode = action.payload.statusCode;
    },
    ClearFetchedUsers: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.filteredUser = [];
      state.statusCode = 200;
    },
    standTransfered: (state) => {
      state.error = null;
      state.actionsLoading = false;
    },
    standCancelled: (state) => {
      state.error = null;
      state.actionsLoading = false;
    },
    standInvitesFetched: (state, action) => {
      const { entities, totalEntities } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.standInvites = entities;
      state.totalInvites = totalEntities;
    },
    standStatusChanged: (state) => {
      state.error = null;
      state.actionsLoading = false;
    },
    AddressStatusChanged: (state, action) => {
      state.isAddressSelected = action?.payload;
    },
    standInvitationVerified: (state) => {
      state.error = null;
      state.actionsLoading = false;
    },
  },
});
