import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import Spinner from "components/UI/Spinner.js";
import Timer from "assets/images/comment-timer.svg";
import avatar from "assets/images/avatar.png";
import { getReviewQuestions, updateReview } from "modules/dashboard/_redux/reviews/reviewActions";
import { Form } from "react-bootstrap";
import * as Yup from "yup";
import "./style.scss";
import toastHandler from "components/UI/toastHandler/toastHandler";

export default function ReservationReview({
  reservationDetail,
  dispatch,
  reload,
  setReload
}) {

  const [openForm, setOpenForm] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { listLoading, questionsList, actionsLoading, isUpdated, user } = useSelector(
    (state) => ({
      questionsList: state.review.questionsList,
      listLoading: state.review.listLoading,
      actionsLoading: state.review.actionsLoading,
      isUpdated: state.review.isUpdated,
      user: state.auth.user

    }),
    shallowEqual
  );

  let singleReview = null
  let hunterReview = null
  let landownerReview = null;
  let isThisMyReview = false;
  let blurViewDescription = ""
  let actionButtonTitle = ""

  if (reservationDetail?.reviews?.length > 1) {
    hunterReview = reservationDetail.reviews.filter((item) => {
      return item?.type == "hunter"
    })[0]
    landownerReview = reservationDetail.reviews.filter((item) => {
      return item?.type == "landowner"
    })[0]
  }


  if (reservationDetail?.reviews?.length == 1) {
    singleReview = reservationDetail.reviews.filter((item, index) => {
      return index == 0
    })[0]
    if (singleReview)
      isThisMyReview = singleReview?.user?.id === user?.id;
    blurViewDescription = singleReview?.type == "hunter" ?
      "Review hunter to reveal comment" : "Review listing to reveal comment"
    actionButtonTitle = singleReview?.type == "hunter" ? "Review hunter" : "Review listing"
  }


  const onReviewSubmit = (values) => {
    setIsLoading(true);
    const rating = sumWeights(values)
    const data = new FormData();
    data.append("rating", rating);
    if (values.comment) {
      data.append("review", values.comment);
    }
    if (user && user.id == reservationDetail?.stand_detail?.user?.id) {
      data.append("type", "landowner")
    } else data.append("type", "hunter")
    dispatch(updateReview(data, reservationDetail?.id, "post", onSuccess, onError));
  };

  const sumWeights = (values) => {
    let sum = 0;
    for (let index = 1; index <= questionsList?.length; index++) {
      sum = sum + parseInt(values[`question${index}`]);
    }
    return sum;
  }

  const schemaObject = {}

  questionsList && questionsList.length > 0 && questionsList.map((question, index) => {
    schemaObject[`question${index + 1}`] = Yup.number().min(0).required()
  })

  const validationSchema = Yup.object(schemaObject);


  const questions = {};
  questionsList && questionsList.length > 0 && questionsList.map((question, index) => {
    questions[`question${index + 1}`] = -1
  })

  //eslint-disable-next-line
  const formik = useFormik({
    initialValues: {
      ...questions,
      rating: 0,
      review: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onReviewSubmit(values);
    },
  });

  //eslint-disable-next-line
  useEffect(() => {
    if (user && user.id == reservationDetail?.stand_detail?.user?.id) {
      dispatch(getReviewQuestions('landowner'))
    } else {
      dispatch(getReviewQuestions('hunter'))
    }
  }, [])

  //eslint-disable-next-line
  useEffect(() => {
  }, [listLoading, questionsList, actionsLoading, isUpdated])

  //eslint-disable-next-line
  useEffect(() => {
    if (reservationDetail?.reviews === null) return;
    formik.setFieldValue("rating", reservationDetail?.reviews?.hunter?.rating);
    formik.setFieldValue("review", reservationDetail?.reviews?.hunter?.review);
  }, [reservationDetail]);


  const onSuccess = (message) => {
    setIsLoading(false);
    toastHandler(message, "success");
    setReload(!reload)
    setOpenForm(false)
  };

  const onError = (message) => {
    setIsLoading(false);
    toastHandler(message, "error");
  };

  return (
    <div className="col-xxl-6">
      <div className="res-review-wrapper">
        <div className="row">
          <div className="col-12">
            <div className="feedback-top-area">
              <div className="title-area">
                <h3>{`${reservationDetail.reviews?.length > 0
                  ? "Review"
                  : "Give Feedback"
                  }`}</h3>
              </div>
            </div>
          </div>
          <div className="col-12">
            {reservationDetail?.reviews?.length > 1 ?
                <div className="review-comments-section">
                  <div className="comment-section w-100">
                    <div className="comment-body">
                      <div className="reviewer-profile">
                        <div className="user-profile">
                          <img src={landownerReview?.user?.image || avatar} alt=""></img>
                          <div className="name">{landownerReview?.user?.first_name + " " + landownerReview?.user?.last_name}</div>
                        </div>
                        <div className="rating-section">
                          <i className="bi text-star fs-20 bi-star-fill  "></i>
                          <p className="rate m-0">{landownerReview?.rating}</p>
                        </div>
                      </div>
                      <p className="comment">
                        {landownerReview?.review}
                      </p>
                      <span className="comment-date">
                        <img src={Timer} className="timer-icon" alt=""></img>
                        {landownerReview?.created_at}
                        {
                          landownerReview?.challenged == 2 && (
                            <span className="edited-comment">Edited</span>
                          )
                        }
                      </span>
                      <div className={`post-comment-section `}>
                        <div className="user-profile">
                          <div className="d-flex flex-row align-items-center">
                            <img
                              src={hunterReview?.user?.image || avatar}
                              alt=""
                            ></img>
                            <div className="name">{hunterReview?.user?.first_name + " " + hunterReview?.user?.last_name}</div>
                          </div>
                          <div className="rating-section">
                            <i className="bi text-star fs-20 bi-star-fill"></i>
                            <p className="rate m-0">
                              {hunterReview?.rating}
                            </p>
                          </div>
                        </div>
                        <p className="comment">
                          {hunterReview?.review}
                        </p>
                        <span className="comment-date">
                          <img src={Timer} className="timer-icon" alt=""></img>
                          {hunterReview?.created_at}
                        </span>
                      </div>
                    </div>
                    {
                      hunterReview?.challenged == 1 && (
                        <div className="d-flex justify-content-end w-100 pe-4">
                          <button
                            className="btn btn-outline-primary post-btn rounded-3 text-primary"
                            onClick={() => {
                              setOpenForm(!openForm)
                            }}
                          >
                            EDIT REVIEW
                          </button>
                        </div>
                      )
                    }
                  </div>
                </div> :
              reservationDetail?.reviews?.length == 1 ?
                <div className="review-comments-section">
                  <div className="comment-section w-100">
                    <div className="comment-body">
                      <div className="reviewer-profile">
                        <div className="user-profile">
                          <img src={singleReview?.user?.image || avatar} alt=""></img>
                          <div className="name">{singleReview?.user?.first_name + " " + singleReview?.user?.last_name}</div>
                        </div>
                        <div className={singleReview?.user?.id != user?.id ? "rating-section blur" : "rating-section"}>
                          <i className="bi text-star fs-16 bi-star-fill"></i>
                          <p className="rate m-0">{singleReview?.rating}</p>
                        </div>
                      </div>
                      <p className={singleReview?.user?.id != user?.id ? "comment blur" : "comment"}>
                        {singleReview?.review}
                      </p>
                      <span className={singleReview?.user?.id != user?.id ? "comment-date blur" : "comment-date"}>
                        <img src={Timer} className="timer-icon" alt=""></img>
                        {singleReview?.created_at}
                        {
                          singleReview?.challenged == 2 && (
                            <span className="edited-comment">Edited</span>
                          )
                        }
                      </span>
                    </div>
                    {singleReview?.user?.id != user?.id ?
                      <p className="reveal-comment">{blurViewDescription}</p> : null}
                    {
                      singleReview?.challenged == 1 && reservationDetail?.current_user_type == "main_hunter" ?
                        <div className="d-flex justify-content-end w-100 pe-4">
                          <button
                            className="btn btn-outline-primary post-btn rounded-3 text-primary"
                            onClick={() => {
                              setOpenForm(!openForm)
                            }}
                          >
                            EDIT REVIEW
                          </button>
                        </div> : null

                    }
                    {singleReview?.user?.id != user?.id ?
                      <div className="d-flex justify-content-end w-100 pe-4">
                        <button
                          className="btn btn-outline-primary post-btn rounded-3 text-primary"
                          onClick={() => {
                            setOpenForm(!openForm)
                          }}
                        >
                          {actionButtonTitle}
                        </button>
                      </div> : null}
                  </div>
                </div> : null
            }
            {
              listLoading ? <Spinner /> :
                ((openForm || reservationDetail?.reviews?.length == 0) &&
                  (reservationDetail?.current_user_type == "main_hunter" ||
                    reservationDetail?.current_user_type == "landowner")) && (
                  <div className="feedback-section px-0">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="max-height-302">
                            {
                              questionsList && questionsList.length > 0 && questionsList.map((question, index) => {
                                return <div className="question">
                                  <p>{question.question}</p>
                                  <div className="d-flex flex-row align-items-center">
                                    <div className="radio-holder d-flex flex-row align-items-center me-4">
                                      <input
                                        type="radio"
                                        id={`Yes${index + 1}`}
                                        name={`question${index + 1}`}
                                        onChange={() => {
                                          formik.setFieldValue(
                                            `question${index + 1}`,
                                            question.options[0].weightage
                                          )
                                        }}
                                      />
                                      <label for={`Yes${index + 1}`} className="fs-14 text-darkblack">
                                        {question.options[0].option}
                                      </label>
                                    </div>
                                    <div className="radio-holder d-flex flex-row align-items-center me-4">
                                      <input
                                        type="radio"
                                        id={`No${index + 1}`}
                                        name={`question${index + 1}`}
                                        onChange={() =>
                                          formik.setFieldValue(
                                            `question${index + 1}`,
                                            question.options[1].weightage
                                          )
                                        }
                                      />
                                      <label for={`No${index + 1}`} className="fs-14 text-darkblack">
                                        {question.options[1].option}
                                      </label>
                                    </div>
                                  </div>
                                  {formik.touched[`question${index + 1}`] && formik.errors[`question${index + 1}`] && (
                                    <Form.Control.Feedback type="d-block invalid">
                                      please Select any Option
                                    </Form.Control.Feedback>
                                  )}
                                </div>
                              })
                            }
                          </div>
                        </div>
                        <div className="col-md-12">
                          <p className="mt-4">
                            Write something about your experience.
                          </p>
                          <div className="type-feedback">
                            <textarea name="comment" placeholder="Type your review..." value={formik.values.comment} onChange={formik.handleChange}></textarea>
                          </div>
                          <div className="text-end">
                            <button className="btn btn-primary rounded-3 text-white d-block mr-auto" disabled={!(formik.isValid && formik.dirty)}>
                              {isLoading ? <Spinner /> : "SUBMIT"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                )
            }
          </div>
        </div>
      </div>
    </div >
  );
}
