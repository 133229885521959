import axios from "../../../../services/axiosInstance";


export function getWavierQuestions() {
    return axios.get(`/waiver-questions`);
}

export function postWavie(data) {
    return axios.post(`/waiver`, data, {
        headers: {
            "Content-Type": "multipart/form-data",
            "Accept": "application/json"
        },
    });
}