import { createSlice } from "@reduxjs/toolkit";

const initialReviewState = {
  error: null,
  actionsLoading: null,
  listLoading: null,
  reviewsList: [],
  totalReviews: 0,
  averageRating: null,
  reviewsCounter: null,
  updatedWavier: null,
  redirectUrl: null
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const wavierSlice = createSlice({
  name: "wavier",
  initialState: initialReviewState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    wavierQuestionsFetched: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.wavierQuestions = action.payload.entities;
    },

    wavierQuestionsSubmitted: (state, action) => {
      state.updatedWavier = action.payload.entities;
      state.error = null;
      state.listLoading = false;
    },
    clearUserWavierStatus: (state, action) => {
      state.updatedWavier = null;
      state.error = null;
      state.listLoading = false;
    },
    clearWavierQuestions: (state, action) => {
      state.wavierQuestions = [];
      state.listLoading = false;
    },

    clearEntities: (state, action) => {
      state.wavierQuestions = [];
      state.listLoading = false;
    },

    setWavierFormData: (state, action) => {
      state.wavierFormData = action.payload;
    },

    clearWavierFormData: (state, action) => {
      state.wavierFormData = null;
    },

    setWavierSignature: (state, action) => {
      state.wavierSignature = action.payload;
    },

    clearWavierSignature: (state, action) => {
      state.wavierSignature = null;
    },

    setRedirectUrl: (state, action) => {
      state.redirectUrl = action.payload
    },

    clearRedirectUrl: (state) => {
      state.redirectUrl = null
    }
  },
});
