import React from "react";
import Accordion from 'react-bootstrap/Accordion';


export default function Collapsee({ heading, content }) {
  return (
    <div className="faq-item">
      <Accordion alwaysOpen>
        <Accordion.Header className="faq-questiond font-roboto heading">{heading}</Accordion.Header>
        <Accordion.Body>
          <div className="faq-answer" id="example-collapse-text">
            <p className="text-tertiary content">{content}</p>
          </div>
        </Accordion.Body>
      </Accordion>
    </div>
  );
}
