import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Send from "assets/icons/chat-send.svg";
import { sendMessageToThread } from "../../../dashboard/_redux/chat/chatActions";
import "./style.scss";
import moment from "moment";
import defaultAvatar from "assets/images/default-avatar.png";
import { useLocation } from "react-router";
import Spinner from "components/UI/Spinner.js";


export default function ChatUi({
  thread_id,
  thread,
  user,
  optionalName = "User",
  showInput,
  showMessage
}) {
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const chatState = useSelector((state) => state.chat);
  const location = useLocation();
  const relativeTime = (_date) => {
    if (_date)
      return moment(_date)
        .add(moment().utcOffset(), "minutes")
        .fromNow();
    else return moment().fromNow();
  };
  const sendMessage = () => {
    const trimmedMessage = message.trim();
    if (trimmedMessage) {
      setLoading(true);
      dispatch(sendMessageToThread(thread_id, trimmedMessage, user?.id, onSuccess));
      setMessage("");
    }
  };
  const onSuccess = () => {
    setLoading(false);
  }

  useEffect(() => {
    let chatWindow = document.getElementById("chat-window");
    var xH = chatWindow.scrollHeight;
    chatWindow.scrollTo(0, xH);
  }, [thread]);

  return (
    <div className="chat">
      <div className="chat-header">
        <img
          src={location?.state?.user?.image || user.image || defaultAvatar}
          onError={(e) => (e.target.src = defaultAvatar)}
          alt=""
        />
        <div className="ms-3">
          <p className="fw-bold">{optionalName}</p>
        </div>
      </div>
      <div className="chat-messages overflow-auto" id="chat-window">
        {thread &&
          thread?.map((_row, index) => (
            <>
              {chatState.activeUser?.id != _row.user?.id ? (
                <div className="recieved" key={"recieved" + index}>
                  <div>
                    <img
                      src={_row.user?.image}
                      alt=""
                      onError={(e) => (e.target.src = defaultAvatar)}
                    />
                  </div>
                  <div className="bg-white">
                    <p className="mb-0 card-stack">{_row.message}</p>
                    <span>{relativeTime(_row.date)}</span>
                  </div>
                </div>
              ) : (
                <div className="sent" key={("sent", index)}>
                  <div>
                    <p className="mb-0 card-stack">{_row.message}</p>
                    <span>{relativeTime(_row.date)}</span>
                  </div>
                </div>
              )}
            </>
          ))}
      </div>
      {showInput ?
        <div className="Chat-footer mt-2 d-flex align-items-center">
          <div className="input d-inline-flex">
            <textarea
              type="text"
              onChange={(e) => setMessage(e.target.value)}
              value={message}
            />
          </div>
          <div className="send-box">
            {loading ? <Spinner /> : <img src={Send} alt="" onClick={sendMessage} />}
          </div>
        </div> : null}
      {
        showMessage ?
          <p className="strona">After 7 days from reservation completion, chatting with other users will no longer be available.</p> :
          null
      }
    </div>
  );
}
