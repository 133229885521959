import React, { useMemo } from "react";
import {
  GoogleMap,
  Marker,
  withGoogleMap
} from "react-google-maps";
import "./style.scss";

export default function MapLocation({ standDetails }) {
  const MapWithAMarker = useMemo(
    () =>     
      withGoogleMap(
        (props) => (
          <GoogleMap
            defaultZoom={15}
            defaultCenter={{
              lat: standDetails?.property_address_latitude,
              lng: standDetails?.property_address_longitude,
            }}
            options={{ gestureHandling: 'none', disableDefaultUI: true}}
          >
            <Marker
              position={{
                lat: standDetails?.property_address_latitude,
                lng: standDetails?.property_address_longitude,
              }}
            />
          </GoogleMap>
        )
      ),
    [standDetails]
  );
  return (
    <div className="map">
      <div className="mt-5 mb-3 fs-22 fw-bold">Property Location</div>
      <MapWithAMarker
        containerElement={<div style={{ height: `400px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    </div>
  );
}
