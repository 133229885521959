import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import defaultStandImage from "../../../../assets/images/additional-hunt.png";
import Addresspin from "../../../../assets/images/pin.svg";
import Spinner from "components/UI/Spinner.js";
import "./style.scss";
import { standInvitationAction } from "modules/dashboard/_redux/stand/standActions";
import toastHandler from "components/UI/toastHandler/toastHandler";
import { LandownerAgreementModal } from "../landownerAgreementModal";
import { useHistory } from "react-router-dom";

export default function InvitationNotificationModal({ show, setShow, onHide, invitationData, invitationCode, reload }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [btnId, setBtnId] = useState("")
    const [type, setType] = useState("")
    const [showAgreementModal, setShowAgreementModal] = useState(false);

    const onSuccess = (message) => {
        setLoading(false);
        setBtnId("")
        toastHandler(message, "success")
        setShow(false)
        history.replace("/my-profile/my-stands");
        reload()
    }
    const onError = () => {
        setLoading(false);
        setBtnId("")
    }

    const onStatusChange = (type) => {
        if (type == "accepted") {
            setType(type)
            setShowAgreementModal(true);
        } else {
            setLoading(true)
            setBtnId(type)
            const formData = new FormData();
            formData.append("invitation_code", invitationCode);
            formData.append("type", type);
            dispatch(standInvitationAction(formData, onSuccess, onError))
        }
    }

    let message = ""

    const getButton = () => {
        return <div role="group" className="dropdown justify-content-end d-flex mt-3">
            <button type="button"
                className="btn px-3 d-inline-flex flex-grow-1 justify-content-center text-uppercase me-3 py-1"
                style={{ border: "1px solid red", color: "red" }}
                onClick={() => { onStatusChange("declined") }}
                disabled={loading}
            >
                {
                    loading && btnId == "declined" ?
                    <Spinner /> : "Decline"
                }
            </button>
            <button
                className="btn btn-primary text-white px-3 d-inline-flex flex-grow-1 justify-content-center text-uppercase py-1"
                onClick={() => { onStatusChange("accepted") }}
                disabled={loading}
            >
                {
                    loading && btnId == "accepted" ?
                    <Spinner /> : "Accept"
                }
            </button>
        </div>
    }

    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                size="md"
                aria-labelledby=""
                centered
                className="pending-state-notification-modal"
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="content default">
                                <div className="img">
                                    <img src={invitationData?.stand?.image}
                                        onError={(ev) => (ev.target.src = defaultStandImage)}
                                        alt="" />
                                </div>
                                <div className="inner-content">
                                    <div className="heading d-flex align-items-center justify-content-center">
                                        <h6>{invitationData?.stand?.title}</h6>
                                    </div>
                                    <div className="location d-flex align-items-start justify-content-center w-auto">
                                        <img src={Addresspin} alt="" />
                                        <p className="h-auto text-center">{invitationData?.stand?.address}</p>
                                    </div>
                                    <hr />
                                    {
                                        <>
                                            <div className="email">
                                                <span>Invited by</span>
                                                <span>
                                                    {invitationData?.user?.first_name} {invitationData?.user?.last_name}
                                                </span>
                                                <span> ({invitationData?.user?.email})</span>
                                            </div>
                                        </>
                                    }
                                </div>
                                <p className="message">{message}</p>
                                {getButton()}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {
                type ?
                    <LandownerAgreementModal
                        show={showAgreementModal}
                        setShow={setShowAgreementModal}
                        setShowInvitationModal={setShow}
                        stand={invitationData?.stand}
                        standId={invitationData?.stand?.id}
                        type={type}
                        reload={reload}
                        invitationCode={invitationCode}
                    /> : null
            }
        </>
    );
}
