const staging = {
    REACT_APP_BASE_URL: 'https://ihunt.vteamslabs.com/api/public/api/v5',
    WEAHTER_APP_ID: 'a7ae2a92212b9b8d0da87f118409652c',
    VERSE_API_KEY: 'fa1ff1dc21ddfc60afc866c737ac5995',
    STRIPE_URL: 'ca_LiZhVMzpWxuepjxCne1B1htZbuNHnusU',
    STRIPE_PUBLISH_KEY: 'pk_test_51Kdf8fLo9HEsv1UZLUq6pfY6JD54hn3Rx0JOojK80DpZuTSh5hPZCQ1ucN5UrMl67uXazQaQcDCj2lVV4WpifRlO00V0Spe6mG',
    GA_TRACKING_ID: 'G-W1R464B3VF',
    APP_URL_SCHEME: 'ihunt-beta://',
    FIREBASE: {
        apiKey: "AIzaSyDq8kWruN-LJd2xg2cBc4gIaCh9PLRcIcw",
        authDomain: "i-hunt-ea7a9.firebaseapp.com",
        projectId: "i-hunt-ea7a9",
        storageBucket: "i-hunt-ea7a9.appspot.com",
        messagingSenderId: "543132847370",
        appId: "1:543132847370:web:77a77839db0761abbabef3",
        measurementId: "G-LD8DDZV9FY"
    },
    IMAGE_COMPRESSOR: {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
    }
};

const prod = {
    REACT_APP_BASE_URL: 'https://ihunt.com/api/public/api/v5',
    WEAHTER_APP_ID: 'a7ae2a92212b9b8d0da87f118409652c',
    VERSE_API_KEY: 'fa1ff1dc21ddfc60afc866c737ac5995',
    STRIPE_URL: 'ca_LiZh2HMxh8xVGGLAG0HiPehrW41UXBQq',
    STRIPE_PUBLISH_KEY: 'pk_live_51Kdf8fLo9HEsv1UZnxZWl4pPuP4p4p3EPdS1FkMwadd42Yt3f75IsSX5F2VCxOrT1MZ3PoIgmp8zIr4Q9qLnuv2T00aHtdWnJC',
    GA_TRACKING_ID: 'G-BMSY3LM5XZ',
    APP_URL_SCHEME: 'ihunt://',
    FIREBASE: {
        apiKey: "AIzaSyDq8kWruN-LJd2xg2cBc4gIaCh9PLRcIcw",
        authDomain: "i-hunt-ea7a9.firebaseapp.com",
        projectId: "i-hunt-ea7a9",
        storageBucket: "i-hunt-ea7a9.appspot.com",
        messagingSenderId: "543132847370",
        appId: "1:543132847370:web:b4fa861b6c65df86babef3",
        measurementId: "G-7BRT2GPWQP"
    },
    IMAGE_COMPRESSOR: {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
    }
};

const config = process.env.REACT_APP_STAGE === "production" ? prod : staging;

export default {
    // Add common config values here
    SKIP_PREFLIGHT_CHECK: true,
    ...config,
};