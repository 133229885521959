import React from "react";
import { Route, Switch } from "react-router";
import { AuthUIProvider } from "./AuthUIContext";
import ForgotPassword from "./forgot-password";
import Login from "./login";
import PasswordResetSuccess from "./password-reset-success";
import ResetPassword from "./reset-password";
import Signup from "./signup";
import EmailVerfication from "./email-verification-modal";
import EmailVerficationConfirmationComponent from "./email-verification-link-page";
import VerifyAuth from "./verify-auth/VerifyAuth";
import PageNotFound from "components/UI/pageNotFound";


export function AuthPage({ history }) {
  // PUSHING NESTED ROUTES
  const AuthUIEvents = {
    openEmailVerificationModal: () => {
      history.push("/auth/email-verification");
    },
  };

  return (
    <>
      <AuthUIProvider authUIEvents={AuthUIEvents}>
        <Switch>
          <Route exact path="/auth/signup">
            {({ history, match }) => match !== null && <VerifyAuth> <Signup /> </VerifyAuth>}
          </Route>
          <Route exact path="/auth/login">
            {({ history, match }) => match !== null && <VerifyAuth> <Login /> </VerifyAuth>}
          </Route>
          <Route exact path="/auth/forgot-password">
            {({ history, match }) => match !== null && <ForgotPassword />}
          </Route>
          <Route exact path="/auth/reset-password">
            {({ history, match }) => match !== null && <ResetPassword />}
          </Route>
          <Route exact path="/auth/reset-password/success">
            {({ history, match }) => match !== null && <PasswordResetSuccess />}
          </Route>
          <Route exact path="/auth/email-verification">
            {({ history, match }) => (
              <EmailVerfication
                show={match != null}
                onHide={() => {
                  history.push("/auth/login");
                }}
              />
            )}
          </Route>
          <Route exact path="/auth/email-verification">
            {({ history, match }) => match !== null && <Login />}
          </Route>
          <Route exact path="/auth/email-verified">
            {({ history, match }) =>
              match !== null && <EmailVerficationConfirmationComponent />
            }
          </Route>
          <Route path="*" component={PageNotFound} />
        </Switch>
      </AuthUIProvider>
    </>
  );
}

export default AuthPage;
