import { combineReducers } from "redux";
import { authSlice } from "../modules/auth/_redux/authSlice";
import { landingPageSlice } from "../modules/landingPage/_redux/landingPageSlice";
import { dashboardSlice } from "../modules/dashboard/_redux/dashboard/dashboardSlice";
import { standSlice } from "../modules/dashboard/_redux/stand/standSlice";
import { reservationSlice } from "../modules/dashboard/_redux/reservations/reservationSlice";
import { trophySlice } from "../modules/dashboard/_redux/trophies/trophySlice";
import { chatSlice } from "../modules/dashboard/_redux/chat/chatSlice";
import { reviewSlice } from "../modules/dashboard/_redux/reviews/reviewSlice";
import { customerServicePortalSlice } from "../modules/dashboard/_redux/customer-service-portal/customerServicePortalSlice";
import { wavierSlice } from "modules/dashboard/_redux/wavier/wavierSlice";
import { groupSlice } from "modules/dashboard/_redux/groups/groupSlice";

export default combineReducers({
  auth: authSlice.reducer,
  landingPage: landingPageSlice.reducer,
  dashboard: dashboardSlice.reducer,
  stand: standSlice.reducer,
  reservation: reservationSlice.reducer,
  trophy: trophySlice.reducer,
  chat: chatSlice.reducer,
  review: reviewSlice.reducer,
  customerServicePortal: customerServicePortalSlice.reducer,
  wavier: wavierSlice.reducer,
  group: groupSlice.reducer
});
