import React, { useMemo } from "react";
import { useDashboardUIContext } from "../../../DashboardUIContext";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import * as actions from "../../../../../auth/_redux/authActions";
import { useDispatch } from "react-redux";
import "./style.scss";


export function LogOutConfirmationModal() {
  const landingPageUIContext = useDashboardUIContext();
  const dispatch = useDispatch();
  const history = useHistory();
  const handleLogout = () => {
    dispatch(actions.logout(navigateOnLogout));
  };

  const navigateOnLogout = () => {
    history.push("/");
  };
  const langingPageUIProps = useMemo(() => {
    return {
      showLogoutConfirmationModal:
        landingPageUIContext.showLogoutConfirmationModal,
      handleLogoutModalClose:
        landingPageUIContext.handleLogoutConfirmationModalClose,
    };
  }, [landingPageUIContext]);
  return (
    <div>
      <Modal
        show={langingPageUIProps.showLogoutConfirmationModal}
        onHide={langingPageUIProps.handleLogoutModalClose}
        aria-labelledby="example-modal-sizes-title-lg"
        centered
        className="logout-popup"
        size="md"
      >
        <Modal.Header style={{
          borderBottom: "1px solid gray !important"
        }} bordered>
          <Modal.Title id="example-modal-sizes-title-lg">
            Logout Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>Are you sure you want to logout of your account</span>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            onClick={langingPageUIProps.handleLogoutModalClose}
            className="btn btn-outline-secondary px-3 border-fifth me-2"
          >
            CANCEL
          </button>
          <> </>
          <button
            type="button"
            onClick={handleLogout}
            className="btn btn-primary text-white"
          >
            YES
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
