import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import defaultStandImage from "../../../../../../assets/images/additional-hunt.png";
import Addresspin from "../../../../../../assets/images/pin.svg";
import profileAvatar from "assets/images/default-avatar.png";
import UserCard from "components/UI/contactButton";
import {
    additionalHunterInvitationAction, resetInvitationDetails,
    revokeAdditionalHunterInvitation, getAllAdditionalHuntersInvitations,
    handleWavierStatus
} from "modules/dashboard/_redux/reservations/reservationActions";
import Spinner from "components/UI/Spinner.js";
import { useHistory } from "react-router-dom";
import "./style.scss";
import { setRedirectUrl } from "modules/dashboard/_redux/wavier/wavierActions";
import { reservationSlice } from "modules/dashboard/_redux/reservations/reservationSlice";


export function HunterNotificationModal({ show, setShow, invitationData, invitationCode, reload }) {
    const [currentId, setCurrentId] = useState("");
    const [type, setType] = useState("")
    const [loading, setLoading] = useState(false);

    const { actions } = reservationSlice;

    const onHidePreConditionModal = () => {
        dispatch(actions.setShowPreConditionModal({ status: false, error: '' }))
        setLoading(false);
        localStorage.removeItem("invitationCode")
        localStorage.removeItem("type")
    }

    const handleTabClose = () => {
        localStorage.removeItem("invitationCode");
        localStorage.removeItem("type")
    };
    
    useEffect(() => {
        window.addEventListener('beforeunload', handleTabClose);

        return () => {
            window.removeEventListener('beforeunload', handleTabClose);
        };
    }, []);

    const dispatch = useDispatch();
    const history = useHistory();

    let message = ""

    const {
        invitationDetails,
        wavierAccepted,
        user, actionsLoading, showPreConditionModal, showPreConditionError } = useSelector(
            (state) => ({
                invitationDetails: state.reservation.invitationDetails,
                user: state.auth.user,
                actionsLoading: state.reservation.actionsLoading,
                wavierAccepted: state.reservation.wavierAccepted,
                showPreConditionModal: state.reservation.showPreConditionModal,
                showPreConditionError: state.reservation.showPreConditionError
            }),
            shallowEqual
        );

    useEffect(() => {
        if (wavierAccepted) {
            const invitationCode = localStorage.getItem("invitationCode")
            const type = localStorage.getItem("type")
            handlePendingStatus(type, invitationCode)
        }
    }, [wavierAccepted])

    const isMainHunter = () => {
        if ((user && user?.id == invitationDetails?.reservation?.user?.id) || (invitationData?.reservation?.current_user_type == "main_hunter") || (invitationDetails?.reservation?.current_user_type == "main_hunter")) {
            return true
        } else return false
    }

    const getImage = () => {
        if (invitationDetails) {
            if (isMainHunter()) {
                return invitationDetails?.user?.image || profileAvatar
            } else {
                if (invitationDetails?.reservation?.stand_detail?.pictures?.length > 0) {
                    return invitationDetails?.reservation?.stand_detail?.pictures[0]?.picture
                } else return defaultStandImage
            }
        } else {
            if (isMainHunter() && invitationData?.user) {
                return invitationData?.user?.image || profileAvatar
            } else if (isMainHunter() && !invitationData?.user) {
                if (invitationData?.reservation?.stand_detail?.pictures?.length > 0) {
                    return invitationData?.reservation?.stand_detail?.pictures[0]?.picture
                } else return defaultStandImage
            } else {
                if (invitationData?.reservation?.stand_detail?.pictures?.length > 0) {
                    return invitationData?.reservation?.stand_detail?.pictures[0]?.picture
                } else return defaultStandImage
            }
        }
    }

    const getTitle = () => {
        if (invitationDetails) {
            if (isMainHunter()) {
                return `${invitationDetails?.user?.first_name} ${invitationDetails?.user?.last_name}`
            } else return invitationDetails?.reservation?.stand_detail?.title
        } else {
            if (isMainHunter() && invitationData?.user) {
                return `${invitationData?.user?.first_name} ${invitationData?.user?.last_name}`
            } else if (isMainHunter() && !invitationData?.user) {
                return invitationData?.reservation?.stand_detail?.title
            } else return invitationData?.reservation?.stand_detail?.title
        }
    }

    if (invitationDetails?.status == "declined") {
        message = `${invitationDetails?.user?.first_name} ${invitationDetails?.user?.last_name} has declined the invitation`
    } else if (invitationDetails?.status.includes("revoked")) {
        if (isMainHunter()) {
            if (invitationDetails?.status == "revoked_by_admin") {
                message = "iHunt Admin has revoked this hunter's access"
            } else {
                message = "You have revoked this hunter's access"
            }
        } else {
            if (invitationDetails?.status == "revoked_by_admin") {
                message = "iHunt Admin has revoked your hunt access"
            } else
                message = `${invitationDetails?.reservation?.user?.first_name} ${invitationDetails?.reservation?.user?.last_name} has revoked your hunt access`
        }
    } else if (invitationDetails?.status == "revoked") {
        if (isMainHunter()) {
            message = `${invitationDetails?.user?.first_name} ${invitationDetails?.user?.last_name} has revoked this invitation`
        } else message = "You have revoked this invitation"
    } else if (invitationDetails?.status == "expired") {
        if (isMainHunter()) {
            message = `${invitationDetails?.user?.first_name} ${invitationDetails?.user?.last_name}'s invitation has been expired`
        } else message = "Your invitation has been expired"
    }

    const getButton = () => {
        if (invitationDetails) {
            if (invitationDetails?.status == "pending") {
                return <div role="group" className="dropdown justify-content-end d-flex mt-3">
                    <button type="button"
                        className="btn px-3 d-inline-flex flex-grow-1 justify-content-center text-uppercase me-3 py-1"
                        style={{ border: "1px solid red", color: "red" }}
                        onClick={() => handlePendingStatus("declined", invitationCode)}
                        disabled={actionsLoading}
                    >
                        {
                            currentId == invitationDetails?.id && actionsLoading && actionsLoading && type == "declined" ?
                                <Spinner /> : "Decline"
                        }
    
                    </button>
                    <button
                        className="btn btn-primary text-white px-3 d-inline-flex flex-grow-1 justify-content-center text-uppercase py-1"
                        onClick={() => handlePendingStatus("accepted", invitationCode)}
                        disabled={actionsLoading}
                    >
                        {
                            currentId == invitationDetails?.id && (actionsLoading || loading) && type == "accepted" ?
                                <Spinner /> : "Accept"
                        }
                    </button>
                </div>
            }
            if (invitationDetails?.status == "accepted") {
                if (isMainHunter()) {
                    return <div role="group" className="dropdown justify-content-end d-flex mt-3">
                        <UserCard
                            user={invitationDetails?.user}
                            subject="Reply On Funds Request Decline"
                            reservationId={invitationDetails?.reservation?.id}
                            showInput={true}
                            showChat={true} />
                        <button type="button"
                            className="btn px-2 d-inline-flex flex-grow-1 justify-content-center text-uppercase ms-2"
                            style={{ border: "1px solid red", color: "red" }}
                            onClick={() => handleRevokeAccess(invitationDetails?.id)}
                        >
                            {
                                actionsLoading && currentId == invitationDetails?.id ?
                                    <Spinner /> : "Revoke Access"
                            }
                        </button>
                    </div>
                } else {
                    return <div role="group" className="dropdown justify-content-end d-flex mt-3">
                        <button
                            className="btn btn-primary text-white px-1 d-inline-flex flex-grow-1 justify-content-center text-uppercase me-1 font-13"
                            onClick={() => openDetails("stand_detail")}
                        >
                            Listing Details
                        </button>
                        <button
                            className="btn btn-primary text-white px-1 d-inline-flex flex-grow-1 justify-content-center text-uppercase font-13"
                            onClick={() => openDetails("reservation_detail")}
                        >
                            Reservation Details
                        </button>
                    </div>
                }
            } else {
                return <div role="group" className="dropdown justify-content-end d-flex mt-3">
                    <button
                        className="btn btn-primary text-white px-3 d-inline-flex flex-grow-1 justify-content-center text-uppercase py-1"
                        onClick={onHide}
                    >
                        Okay
                    </button>
                </div>
            }
        } else {
            return <div role="group" className="dropdown justify-content-end d-flex mt-3">
                <button type="button"
                    className="btn px-3 d-inline-flex flex-grow-1 justify-content-center text-uppercase me-3 py-1"
                    style={{ border: "1px solid red", color: "red" }}
                    onClick={() => handlePendingStatus("declined", invitationCode)}
                    disabled={loading}
                >
                    {
                        loading && currentId == "declined" ?
                            <Spinner /> : "Decline"
                    }

                </button>
                <button
                    className="btn btn-primary text-white px-3 d-inline-flex flex-grow-1 justify-content-center text-uppercase py-1"
                    onClick={() => handlePendingStatus("accepted", invitationCode)}
                    disabled={loading}
                >
                    {
                        loading && currentId == "accepted" ?
                            <Spinner /> : "Accept"
                    }
                </button>
            </div>
        }
    }

    const openDetails = (to) => {
        onHide();
        history.push(to == "stand_detail" ?
            `/stand/${invitationDetails?.reservation?.stand_detail?.id}` :
            `/my-profile/reservation-details/${invitationDetails?.reservation?.id}`)
    }

    const handlePendingStatus = (type, invitationCode) => {
        setLoading(true)
        setCurrentId(type);
        setType(type)
        const data = new FormData()
        data.append("invitation_code", invitationCode);
        data.append("type", type)
        if (type == "declined") {
            dispatch(additionalHunterInvitationAction(data, type, onRejectSuccess, onError))
        } else {
            dispatch(additionalHunterInvitationAction(data, type, onSuccess, onError, onPreConditionError, onDone))
        }
        dispatch(handleWavierStatus(false));
    }

    const onError = () => {
        setLoading(false);
    }

    const onPreConditionError = () => {
        if (!localStorage.getItem("invitationCode")) {
            localStorage.setItem("invitationCode", invitationCode)
            localStorage.setItem("type", "accepted")
        }
        setLoading(false);
    };

    const submitWavierHandler = () => {
        dispatch(setRedirectUrl("/my-profile/my-reservations"))
        localStorage.setItem("redirectUrl", "/my-profile/my-reservations")
        history.push("/wavier-form");
    }

    const onRevoked = (type) => {
        if (type == "revoked") {
            dispatch(getAllAdditionalHuntersInvitations({ id: invitationDetails?.reservation?.id, type: "accepted" }))
        } else {
            dispatch(getAllAdditionalHuntersInvitations({ id: invitationDetails?.reservation?.id, type: "" }))
        }
        setLoading(false);
        setShow(false);
        dispatch(resetInvitationDetails())
    }

    const handleRevokeAccess = (id) => {
        setLoading(true)
        setCurrentId(id)
        dispatch(revokeAdditionalHunterInvitation(id, onRevoked))
    }

    const onSuccess = () => {
        setLoading(false);
        setShow(false);
        setCurrentId("");
        localStorage.removeItem("invitationCode")
        localStorage.removeItem("type")
    }

    const onDone = (type) => {
        if (type == "accepted") {
            history.replace("/my-profile/my-reservations")
            dispatch(resetInvitationDetails())
            reload(type);
        }
    }

    const onRejectSuccess = () => {
        setLoading(false);
        setShow(false);
        setCurrentId("");
        history.replace("/my-profile/my-reservations")
        dispatch(resetInvitationDetails())
        reload("declined");
    }

    const onHide = () => {
        if (showPreConditionModal) {
            onHidePreConditionModal();
        } else if (!showPreConditionModal) {
            if ((window.location.pathname.includes("my-profile/my-reservations")) && (!actionsLoading || !loading)) {
                dispatch(resetInvitationDetails())
                setShow(false)
                history.replace("my-reservations");
            } else if (!actionsLoading || !loading) {
                dispatch(resetInvitationDetails())
                setShow(false)
            }
        }
    }

    return (
        <>
            <Modal
                show={show ? show : showPreConditionModal}
                onHide={onHide}
                size={!showPreConditionModal ? "md" : ""}
                aria-labelledby=""
                centered
                className={!showPreConditionModal ? "pending-state-notification-modal" : "" }
                backdrop="static"
            >
                {!showPreConditionModal ? 
                <Modal.Header closeButton>
                </Modal.Header> :
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">Waiver Required</Modal.Title>
                </Modal.Header>}
                
                {!showPreConditionModal ? 
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className={`content ${invitationDetails?.status == "accepted" ? 'accepted' : invitationDetails ? invitationDetails?.status?.replace(/_/g, "-") : "pending"}`}>
                                <div className="img">
                                    <img src={getImage()}
                                        onError={(ev) => (ev.target.src = defaultStandImage)}
                                        alt="" 
                                    />
                                    <figcaption>
                                        <i className="dot"></i>
                                        {invitationDetails?.status == "accepted"
                                            ? 'accepted' : invitationDetails ? invitationDetails?.status?.replace(/_/g, "-") : "pending"}</figcaption> 

                                </div>
                                <div className="inner-content">
                                    <div className="heading d-flex align-items-center justify-content-center">
                                        <h6>{getTitle()}</h6>
                                    </div>
                                    {
                                        !isMainHunter() || (isMainHunter() && !['accepted', 'declined', 'expired', 'revoked', 'revoked_by_admin'].includes(invitationDetails?.status)) ?
                                            <div className="location d-flex align-items-start justify-content-center w-auto">
                                                <img src={Addresspin} alt="" />
                                                <p className="h-auto text-center">{invitationDetails?.reservation?.stand_detail?.address ? invitationDetails?.reservation?.stand_detail?.address : invitationData?.reservation?.stand_detail?.address}</p>
                                            </div> : null
                                    }
                                    {
                                        isMainHunter() && invitationDetails?.user ?
                                            <div className="email">
                                                <span>
                                                    {invitationDetails?.user?.email ? invitationDetails?.user?.email : invitationData?.user?.email}
                                                </span>
                                            </div> :
                                            <>
                                                <div className="price">
                                                    <span>{invitationDetails?.reservation?.start_date ? invitationDetails?.reservation?.start_date : invitationData?.reservation?.start_date} -{" "}
                                                        {invitationDetails?.reservation?.end_date ? invitationDetails?.reservation?.end_date : invitationData?.reservation?.end_date}</span>
                                                </div>
                                                <div className="email">
                                                    <span>Invited by</span>
                                                    <span>
                                                        {`${invitationDetails?.reservation?.user?.first_name ? invitationDetails?.reservation?.user?.first_name : invitationData?.reservation?.user?.first_name} ${invitationDetails?.reservation?.user?.last_name ? invitationDetails?.reservation?.user?.last_name : invitationData?.reservation?.user?.last_name}`}

                                                    </span>
                                                    <span> ({invitationDetails?.reservation?.user?.email ? invitationDetails?.reservation?.user?.email : invitationData?.reservation?.user?.email})</span>
                                                </div>
                                            </>
                                    }
                                </div>
                                <p className="message">{message}</p>
                                {getButton()}
                            </div>
                        </div>
                    </div>
                </Modal.Body> :
                <Modal.Body className="pd-4">
                    <span>{showPreConditionError}</span>
                </Modal.Body>}
                {showPreConditionModal ? 
                <Modal.Footer>
                    <button
                        type="button"
                        onClick={submitWavierHandler}
                        className="btn btn-primary text-white"
                    >
                        SUBMIT WAIVER
                    </button>
                </Modal.Footer> : null}
            </Modal>
        </>
    );
}
