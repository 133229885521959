import axios from "../../../../services/axiosInstance";

export function getReservations(queryParams, source) {
  return axios.get("reserve-stands", {
    params: queryParams,
    cancelToken: source.token,
  });
}

export function createReservation(body) {
  return axios.post("reserve-stand", body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function getReservationPricing(body) {
  return axios.post("reservation-payment-summary", body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function verifyVoucher(body, standId) {
  return axios.post(`verify-voucher/${standId}`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function verifyReferral(body) {
  return axios.post("verify-referral", body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function getReservationDetail(reservationId) {
  return axios.get(`reservation-detail/${reservationId}?api_version=1`);
}

export function addTrophy(body, reservationId) {
  return axios.post(`add-trophy/${reservationId}`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function cancelReservation(reservationId) {
  return axios.post(`cancel-reservation/${reservationId}`);
}

export function addBlockOutDay(body, standId) {
  return axios.post(`block-out-day/${standId}`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function deleteBlockOutDay(blockoutDayId) {
  return axios.delete(`block-out-day/${blockoutDayId}`, {
    headers: {
      "Accept": "application/json",
    },
  });
}

export function deletePendingReservation() {
  return axios.delete(`pending-reservation`, {
    headers: {
      "Accept": "application/json",
    },
  });
}

export function sendFundRequest(params) {
  return axios.post(`fund-transfer/${params?.id}`, params?.body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function toggleFundRequest(id, data) {
  return axios.post(`handle-fund-transfer/${id}`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function cancelFundRequest(id) {
  return axios.patch(`cancel-fund-transfer/${id}`);
}

export function getIhuntSettings(params) {
  return axios.get(`settings`, { params: params });
}

export function revertFundRequest() {
  return axios.patch(`revert-fund-transfer`);
}

export function getFundDetails(id) {
  return axios.get(`fund-transfer-detail/${id}`);
}

export function additionalHunterInvitation(id, body) {
  return axios.post(`additional-hunter-invitation/${id}`, body);
}

export function verifyAdditionalHunterInvitation(body) {
  return axios.post(`additional-hunter-invitation-verification`, body);
}

export function additionalHunterInvitationAction(body) {
  return axios.post(`additional-hunter-invitation-action`, body);
}

export function getAllAdditionalHuntersInvitations(params) {
  return axios.get(`additional-hunter-all-invitation/${params.id}`, { params: { type: params.type } });
}

export function cancelAdditionalHunterInvitation(id) {
  return axios.put(`additional-hunter-invitation-cancel/${id}`);
}

export function getAdditionalHunterInvitationDetails(id) {
  return axios.get(`additional-hunter-invitation/detail/${id}`);
}

export function revokeAdditionalHunterInvitation(id) {
  return axios.delete(`revoke-additional-hunter-invitation/${id}`);
}

export function handleHuntRequest(id, body) {
  return axios.post(`/handle-hunt-request/${id}`, body);
}