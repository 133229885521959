import { createSlice } from "@reduxjs/toolkit";

const initialChatState = {
  chatThread: [],
  activeThread: {},
  activeUser: {},
  ResponseCode: null
};

export const chatSlice = createSlice({
  name: "chats",
  initialState: initialChatState,
  reducers: {
    activeUser: (state, action) => {
      state.activeUser = action.payload;
    },
    chatThread: (state, action) => {
      state.chatThread = action.payload.chat
      state.ResponseCode = action.payload.status;
    },
    setActiveThread: (state, action) => {
      state.activeThread = action.payload;
    },
    pushNewMessage: (state, action) => {
      state.chatThread.push(action.payload);
    },
    sendMessage: (state, action) => {
      if (action.payload)
        state.chatThread = [
          ...state.chatThread,
          {
            message: action.payload.message,
            user: {
              id: state.activeUser?.id,
            },
          },
        ];
    },
  },
});
