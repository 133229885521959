import React from "react";
import CheckOutReviewImg from "./../../../../assets/images/check-out-review.png";
import PlaceHolderImage from "./../../../../assets/images/check-out-review-1.jpg";
import "./style.scss";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


export default function checkOutReview() {
    return (
        <section>
            <div className="inner-container">
                <div className="row justify-content-between">
                    <div className="col-lg-4 col-md-5 order-lg-1 order-md-1 order-sm-2 order-2">
                        <div className="right px-3">
                            <div className="right-inner">
                                <LazyLoadImage
                                    src={CheckOutReviewImg}
                                    alt=""
                                    className="lazy-load-image"
                                    placeholderSrc={PlaceHolderImage}
                                    visibleByDefault={CheckOutReviewImg}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-6 order-lg-2 order-md-2 order-sm-1 order-1">
                        <div className="left">
                            <div className="left-inner">
                                <h5>Check-Out and Leave a Review</h5>
                                <p>
                                After your hunting experience, use the app to check-out from the hunting location. This step confirms your departure and allows you to provide feedback by leaving a review. Your review can help other hunters make informed decisions when selecting hunting sites in the future.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
