import toastHandler from "components/UI/toastHandler/toastHandler";
import * as requestFromServer from "./reservationCRUD";
import { callTypes, reservationSlice } from "./reservationSlice";

const { actions } = reservationSlice;

export const getReservations = (queryParams, source) => async (dispatch) => {
  if (queryParams.type == "current" || queryParams.status === "all" || queryParams.status == "approved" || queryParams.status == "pending") {
    dispatch(getActiveReservations(queryParams, source))
  };
  if (queryParams.status == "invitation") {
    dispatch(getInvitations(queryParams, source))
  };
  if (queryParams.type == "history" || queryParams.status == "all" || queryParams.status == "completed" || queryParams.status == "canceled" || queryParams.status == "rejected") {
    dispatch(getReservationHistory(queryParams, source))
  };
  if (queryParams.status == "cancel") {
    dispatch(getCancelledReservation(queryParams, source))
  };
}

export const getInvitations = (queryParams, source) => async (dispatch) => {
  try {
    dispatch(actions.invitationRequest())
    const response = await requestFromServer.getReservations(queryParams, source);
    if (response.status) {
      await dispatch(
        actions.invitationFetched({
          entities: response.data,
          totalRecords: response.total_records,
          type: "invitation"
        })
      );
    }
  } catch (error) {
    dispatch(actions.invitationRequestFailed());
  }
}

export const getActiveReservations = (queryParams, source) => async (dispatch) => {
  dispatch(actions.activeReservationsRequest());
  try {
    const response = await requestFromServer.getReservations(queryParams, source);
    if (response.status) {
      await dispatch(
        actions.activeReservationsFetched({
          entities: response.data,
          totalRecords: response.total_records,
        })
      );
    }
  } catch (error) {
    dispatch(actions.activeReservationsRequestFailed());
  }
};

export const getReservationHistory = (queryParams, source) => async (dispatch) => {
  dispatch(actions.reservationHistoryRequest());
  try {
    const response = await requestFromServer.getReservations(queryParams, source);
    if (response.status) {
      await dispatch(
        actions.reservationHistoryFetched({
          entities: response.data,
          totalRecords: response.total_records,
        })
      );
    }
  } catch (error) {
    dispatch(actions.reservationHistoryRequestFailed());
  }
};

export const getCancelledReservation = (queryParams, source) => async (dispatch) => {
  dispatch(actions.cancelledReservationRequest());
  try {
    const response = await requestFromServer.getReservations(queryParams, source);
    if (response.status) {
      await dispatch(
        actions.cancelledReservationFetched({
          entities: response.data,
          totalRecords: response.total_records,
        })
      );
    }
  } catch (error) {
    dispatch(actions.cancelledReservationRequestFailed());
  }
};

export const clearEntities = () => (dispatch) => {
  dispatch(actions.clearEntities());
};

export const createReservation = (body, onError, onSuccess, onPreConditionError, onDateError, onConflictError) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    dispatch(actions.setShowPreConditionModal({ status: false, error: '' }));
    const response = await requestFromServer.createReservation(body);
    const data = response.data;
    dispatch(
      actions.reservationCreated({
        clientSecret: data.intent,
        publishableKey: data.publishableKey,
        processCompletionTitle: data.process_completion_title,
        processCompletionDescription: data.process_completion_description,
        huntApprovalSetting: data.hunt_approval_setting,
      })
    );
    onSuccess(data);
  } catch (error) {
    if (error?.response?.data) {
      if (error?.response?.data?.precondition_failure_type === "hunter_waiver") {
        dispatch(actions.setShowPreConditionModal({ status: true, error: error?.response?.data?.errors }));
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      } else if (error?.response?.data?.precondition_failure_type === "verify_last_login") {
        dispatch(actions.setShowPreConditionModal({ status: false, error: '' }));
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        onPreConditionError();
      } else {
        if (error?.response?.status === 422) {
          onDateError(error?.response?.data?.errors);
        } else if (error?.response?.status === 409) {
          onConflictError(error?.response?.data?.errors);
        }
        dispatch(actions.setShowPreConditionModal({ status: false, error: '' }));
        onError(error?.response?.data?.errors);
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      }
    }
  }
};

export const resetLoader = () => async (dispatch) => {
  dispatch(actions.catchError({ callType: callTypes.action }));
}

export const getReservationPricing = (body, onDateError) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.getReservationPricing(body);
    const data = response.data;
    dispatch(
      actions.reservationPricing(data)
    );
  } catch (error) {
    if (error?.response?.data?.message) {
      if (error?.response?.status === 422 && !error?.response?.data?.errors.includes("The reserve dates field is required when none of is yearly rental / season id are present.") && !error?.response?.data?.errors.includes("The selected season is invalid..")) {
        onDateError(error.response.data.errors);
      }
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    }
  }
};

export const clearPricing = () => (dispatch) => {
  dispatch(actions.clearPricing())
}

export const resetPaymentIntent = () => async (dispatch) => {
  dispatch(actions.resetPaymentIntent());
};

export const verifyVoucher =
  (body, standId, onSuccess, onError) => async (dispatch) => {
    dispatch(actions.startVerifyVoucherCall());
    try {
      const response = await requestFromServer.verifyVoucher(body, standId);
      const data = response.data;
      dispatch(actions.voucherVerified());
      onSuccess(data);
    } catch (error) {
      if (error?.response?.data?.message) {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        onError(error?.response?.data?.message);
      }
    }
  };

export const verifyReferral =
  (body, onSuccess, onError) => async (dispatch) => {
    dispatch(actions.startVerifyReferralCall());
    try {
      const response = await requestFromServer.verifyReferral(body);
      const data = response.data;
      dispatch(actions.referralVerified());
      onSuccess(data);
    } catch (error) {
      if (error?.response?.data?.errors) {
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        onError(error?.response?.data?.errors);
      }
    }
  };

export const getReservationDetail = (reservationId) => async (dispatch) => {
  dispatch(actions.requestReservationDetail())
  try {
    const response = await requestFromServer.getReservationDetail(
      reservationId
    );
    if (response.status) {
      dispatch(
        actions.reservationDetailFetched({
          reservationDetail: response.data,
        }));
    }
  } catch (error) {
    dispatch(actions.catchError({ error }));
    dispatch(actions.reservationDetailFetchedFailed())
  }
};

export const addTrophy = (data, reservationId, fileUrl, onSuccess) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.addTrophy(data, reservationId);
    if (response.status) {
      dispatch(actions.trophyPosted({ fileUrl }));
      onSuccess(response.message);
    }
  } catch (error) {
    dispatch(actions.catchError({ error }));
    if (error?.response?.data?.message) {
      toastHandler(error.response.data.errors, "error");
    }
  }
};

export const cancelReservation =
  (reservationId, onSuccess, onError, callerReservationDetail = false) =>
    async (dispatch) => {
      dispatch(actions.startCall({ callType: callTypes.action }));
      try {
        const response = await requestFromServer.cancelReservation(reservationId);
        if (response.status) {
          dispatch(
            actions.reservationCancelled({
              id: reservationId,
              callerReservationDetail,
            })
          );
          onSuccess();
        }
      } catch (error) {
        if (error?.response?.data?.message) {
          toastHandler(error.response.data.errors, "error");
        }
        onError();
        dispatch(actions.catchError({ error }));
        return 0;
      }
    };

export const clearReservationList = () => (dispatch) => {
  dispatch(actions.clearReservationsList());
};


export const addBlockOutDay = (data, standId, onSuccess, onError) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.addBlockOutDay(data, standId);
    if (response.status) {
      setTimeout(() => {
        dispatch(actions.blockoutDayPosted());
        onSuccess()
      }, [1000])
    }
  } catch (error) {
    dispatch(actions.catchError({ error }));
    onError()
  }
};

export const deleteBlockOutDay = (blockoutDayId, onSuccess, onError) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.deleteBlockOutDay(blockoutDayId);
    if (response.status) {
      setTimeout(() => {
        dispatch(actions.blockoutDayDeleted());
        onSuccess()
      }, 1000);
    }
  } catch (error) {
    dispatch(actions.catchError({ error }));
    onError()
  }
};

export const deletePendingReservation = () => async (dispatch) => {
  try {
    await requestFromServer.deletePendingReservation();
  } catch (error) {
    dispatch(actions.catchError({ error }));
  }
};


export const sendFundRequest = (params, onSuccess, onError) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.sendFundRequest(params);
    if (response.status) {
      dispatch(actions.requestSent());
      onSuccess(response?.message)
    }
  } catch (error) {
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    onError(error?.response?.data?.errors)
  }
};

export const toggleFundrequestStatus = (id, data, onSuccess, onError) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.toggleFundRequest(id, data);
    if (response.status) {
      if (response.data) {
        const data = response.data;
        dispatch(
          actions.reservationCreated({
            clientSecret: data.intent,
            publishableKey: data.publishableKey,
          }))
      } else {
        dispatch(actions.requestSent());
      }
      onSuccess(response?.message, "approved")
    }
  } catch (error) {
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    if (onError) {
      onError(error?.response?.data?.errors)
    }
  }
};

export const cancelFundRequest = (id, onSuccess, onError) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.cancelFundRequest(id);
    if (response.status) {
      dispatch(actions.requestSent());
      onSuccess(response?.message)
    }
  } catch (error) {
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    onError(error?.response?.data?.errors)
  }
};

export const getIhuntSettings = (params) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.getIhuntSettings(params);
    if (response.status) {
      dispatch(actions.settingFetched({ settings: response?.data[0] }));
    }
  } catch (error) {
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
};

export const revertFundRequest = () => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.revertFundRequest();
    if (response.status) {
      dispatch(actions.requestSent());
    }
  } catch (error) {
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
};
//
export const getFundsNotification = (id) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  try {
    const response = await requestFromServer.getFundDetails(id);
    if (response.status) {
      dispatch(actions.fundNotificationfetched(response.data))
    }
  } catch (error) {
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
}

export const clearFundNotifications = () => async (dispatch) => {
  dispatch(actions.resetFundNotifications());
}

export const handleNotificationClick = (flag) => async (dispatch) => {
  dispatch(actions.handleNotificationClicked({ flag: flag }));
}

export const resetInvitationDetails = () => async (dispatch) => {
  dispatch(actions.resetInvitationDetails());
}

export const createBookingDetails = (data) => async (dispatch) => {
  dispatch(actions.setBookingDetails(data));
}

export const clearBookingDetails = (data) => async (dispatch) => {
  dispatch(actions.removeBookingDetails(data));
}

export const changeAdditionalHunterTab = (tab) => (dispatch) => {
  dispatch(actions.changeAdditionalHunterTab(tab))
}

export const resetAdditionalHunterTab = (tab) => (dispatch) => {
  dispatch(actions.changeAdditionalHunterTab(tab))
}

export const handleWavierStatus = (flag) => (dispatch) => {
  dispatch(actions.Setwavierstatus(flag))
}

export const additionalHunterInvitation = (id, body, onSuccess, onError) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.additionalHunterInvitation(id, body);
    if (response.status) {
      dispatch(actions.requestSent());
      onSuccess()
    }
  } catch (error) {
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    if (error?.response?.data?.message) {
      toastHandler(error.response.data.errors, "error");
    }
    onError()
    return 0;
  }
};

export const verifyAdditionalHunterInvitation = (body, onSuccess, onError) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.verifyAdditionalHunterInvitation(body);
    if (response.status) {
      dispatch(actions.additionalHunterInvitationVerified());
      onSuccess(response)
    }
  } catch (error) {
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    if (error?.response?.data?.message) {
      toastHandler(error.response.data.errors, "error");
    }
    onError()
    return 0;
  }
};

export const additionalHunterInvitationAction = (body, status, onSuccess, onError, onPreConditionError, onDone) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    dispatch(actions.setShowPreConditionModal({ status: false, error: '' }));
    const response = await requestFromServer.additionalHunterInvitationAction(body);
    if (response.status) {
      dispatch(actions.requestSent());
      onSuccess();
      toastHandler(response?.message, "success")
      if (onDone) {
        onDone(status)
      }
    }
  } catch (error) {
    if (error?.response?.data) {
      if (error?.response?.data?.precondition_failure_type === "hunter_waiver") {
        dispatch(actions.setShowPreConditionModal({ status: true, error: error?.response?.data?.errors }));
        onPreConditionError();
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      } else {
        dispatch(actions.setShowPreConditionModal({ status: false, error: '' }));
        onError();
        dispatch(actions.catchError({ error, callType: callTypes.action }));
        toastHandler(error?.response?.data?.errors, "error")
      }
    }
  }
};

export const getAllAdditionalHuntersInvitations = (params) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  if (params.type == "accepted") {
    try {
      const response = await requestFromServer.getAllAdditionalHuntersInvitations(params);
      if (response.status) {
        dispatch(actions.FetchedJoinedHuntersList({ data: response }));
      }
    } catch (error) {
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    }
  } else {
    try {
      const response = await requestFromServer.getAllAdditionalHuntersInvitations(params);
      if (response.status) {
        dispatch(actions.FetchedSentInvitesToHunters({ data: response }));
      }
    } catch (error) {
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    }
  }
};

export const cancelAdditionalHunterInvitation = (id, onSuccess) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.cancelAdditionalHunterInvitation(id);
    if (response.status) {
      dispatch(actions.requestSent());
      toastHandler(response?.message, "success")
      onSuccess()
    }
  } catch (error) {
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    toastHandler(error?.response?.data?.errors, "error")
  }
};

export const getAdditionalHunterInvitationDetails = (id) => async (dispatch) => {
  dispatch(actions.requestInvitationDetails());
  try {
    const response = await requestFromServer.getAdditionalHunterInvitationDetails(id);
    if (response.status) {
      dispatch(actions.invitationDetailsfetched(response.data))
    }
  } catch (error) {
    dispatch(actions.catchError({ error, callType: callTypes.list }));
    toastHandler(error?.response?.data?.errors, "error")
  }
}

export const revokeAdditionalHunterInvitation = (id, onSuccess) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.revokeAdditionalHunterInvitation(id);
    if (response.status) {
      dispatch(actions.requestSent());
      toastHandler(response?.message, "success")
      onSuccess("revoked")
    }
  } catch (error) {
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    toastHandler(error?.response?.data?.errors, "error")
  }
};

export const handleHuntRequest = (id, body, onSuccess, onError) => async(dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.handleHuntRequest(id, body);
    if (response.status) {
      dispatch(actions.requestSent());
      onSuccess(response?.message);
    }
  } catch (error) {
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    onError(error?.response?.data?.errors)
  }
};

export const showRejectionReasonModal = (status) => (dispatch) => {
  dispatch(actions.showRejectionReasonModal(status))
}

export const showRejectionReason = (status) => (dispatch) => {
  dispatch(actions.showRejectionReason(status))
}