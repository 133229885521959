import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import "./style.scss"
import { ButtonGroup, Dropdown, FloatingLabel, Form } from "react-bootstrap";
import toastHandler from "components/UI/toastHandler/toastHandler";
import { fetchStandVouchers, getStandVoucherById, updateStandVoucher, deleteStandVoucher } from "modules/landingPage/_redux/landingPageActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Calendar from "react-calendar";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { format } from "date-fns";
import { useFormik } from "formik";
import moment from "moment";
import * as Yup from "yup";
import Spinner from "components/UI/Spinner.js";


const CreateVoucherConfirmationModal = ({ 
  standId,
  voucherId,
  setVoucherId,
  show,
  onHide,
  discountOption,
  setDiscountOption,
  fieldToggle,
  setFieldToggle,
  voucherName,
  setVoucherName,
  voucherCode,
  setVoucherCode,
  expiryType,
  setExpiryType,
  discountType,
  setDiscountType,
  successMessage,
  setSuccessMessage,
  successDescription,
  setSuccessDescription,
}) => {

  const { voucherDetails } = useSelector(
    (state) => ({
      voucherDetails: state.landingPage.voucherDetails,
    }),
    shallowEqual
  );

  const [loading, setLoading] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showEditVoucherModal, setShowEditVoucherModal] = useState(false);

  const calendarRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    if (show && (standId || voucherId)) {
      dispatch(getStandVoucherById(voucherId));
    }
  }, [show, standId, voucherId, dispatch]);
  
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <i
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }} className="fas fa-ellipsis-h fa-lg" />
  ));

  const onDeleteSuccess = (message) => {
    toastHandler(message, "success");
    onHide();
  }

  const onError = () => {
    setLoading(false)
  }

  const handleDeleteStandVoucher = (id) => {
    dispatch(deleteStandVoucher(id, onDeleteSuccess, onError))
  }

  useEffect(() => {
    document.addEventListener("click", manageOutsideClick);
    return () => {
      document.removeEventListener("click", manageOutsideClick);
    }
  }, []);

  const manageOutsideClick = (event) => {
    if (!(calendarRef && calendarRef.current && calendarRef.current.contains(event.target)) && !isMonthOrYearClicked(event.target)) {
      setShowCalendar(false)
    }
  }

  const isMonthOrYearClicked = (target) => {
    if (target.classList.contains("react-calendar__tile") ||
    target.classList.contains("react-calendar__year-view__months__month") ||
    target.classList.contains("react-calendar__decade-view__years__year") ||
    target.classList.contains("react-calendar__century-view__decades__decade")) 
    {
      return true;
    }
    if (target.tagName === "ABBR" && target.getAttribute("aria-label")) {
      return true;
    }
    return false;
  };

  const onEditHandler = (id, name, discount, discount_type, max_usage, expiry_date, expiry_type) => {
    setShowEditVoucherModal(true);
    setVoucherId(id);
    setVoucherName(name);
    setDiscountType(discount_type === "percentage" ? `${discount}%` : `$${discount}`);
    setExpiryType(expiry_type === "max_usage" ? `${max_usage} users limit` : expiry_date);
    setDiscountOption(discount_type);
    setFieldToggle(expiry_type);
    formik.setFieldValue("voucher", name);
    formik.setFieldValue("discount", discount);
    formik.setFieldValue("discountType", discount_type);
    formik.setFieldValue("expiryType", expiry_type);
    expiry_type === "max_usage" ? formik.setFieldValue("maxUsage", max_usage) : formik.setFieldValue("expiryDate", expiry_date);
  }

  const EditVoucherSchema = Yup.object({
    expiryType: Yup.string().required(),

    expiryDate: Yup.date().when("expiryType", {
      is: (expiryType) => expiryType === "expiry_date",
      then: Yup.date().required("Expiry Date is a required field"),
    }),

    maxUsage: Yup.number()
      .max(99999, "Upto 5 digits are allowed")
      .when("expiryType", {
        is: (expiryType) => expiryType === "max_usage",
        then: Yup.number().required("Max Usage is a required field"),
      }),
    voucher: Yup.string()
      .min(3, "Voucher should be at least 3 characters")
      .max(50, "Voucher should be less than 50 characters")
      .required("Voucher is a required field"),

    discount: Yup.number()
      .max(99999, "Upto 5 digits are allowed")
      .required("Discount is a required field"),
  });

  const formInitialValues = {
    voucher: voucherDetails?.name || voucherName || "",
    discount: voucherDetails?.discount || discountType || "",
    expiryDate: (voucherDetails?.expiry_date && moment(new Date(voucherDetails?.expiry_date)).format("MMM DD yyyy")) || (fieldToggle === "expiry_date" && moment(new Date(expiryType)).format("MMM DD yyyy")) || "",
    maxUsage: voucherDetails?.max_usage || (fieldToggle === "max_usage" && expiryType) || "",
    discountType: voucherDetails?.discount_type || discountOption || "",
    expiryType: voucherDetails?.expiry_type || fieldToggle || "",
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    const formData = {
      name: values.voucher,
      discount_type: values.discountType,
      discount: values.discount,
      amount: values.discount,
      expiry_type: values.expiryType,
    };
    if (fieldToggle == "expiry_date") {
      const date_format = format(new Date(values.expiryDate), "MMM dd yyyy");
      formData["expiry_date"] = date_format;
    } else {
      formData["max_usage"] = values.maxUsage;
    }
    dispatch(updateStandVoucher(voucherDetails?.id, formData, onSuccess, onError));
  };

  const onSuccess = (response) => {
    dispatch(fetchStandVouchers(standId, true))
    setLoading(false);
    onHideHandler();
    setVoucherId(response?.id);
    setVoucherName(response?.name);
    setVoucherCode(response?.code);
    {response?.expiry_type === "max_usage"
      ? setExpiryType(`${response?.max_usage} users limit`)
      : setExpiryType(response?.expiry_date)
    }
    {response?.discount_type === "percentage" ? (
      setDiscountType(`${response?.discount}%`)
    ) : (
      setDiscountType(`$${response?.discount}`)
    )}
    setSuccessMessage("Your Voucher Is Updated!");
    setSuccessDescription("Your voucher has been updated and is ready to be shared with your friends and family.");
    dispatch(getStandVoucherById(voucherId));
  }

  const formik = useFormik({
    validationSchema: EditVoucherSchema,
    initialValues: formInitialValues,
    onSubmit: handleSubmit,
  });

  const onHideHandler = () => {
    setDiscountOption("percentage");
    setFieldToggle("max_usage");
    formik.resetForm();
    setShowEditVoucherModal(false);
  }

  return (
    <>
      <Modal className="create-voucher-confirmation-modal" show={show} onHide={onHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {successMessage}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="modal-body-text">{successDescription}</p>
          <form className="create-voucher-confirmation-form">
            <div className="created-voucher-section">
              <div className="content">
                <h4 className="discount-title">{voucherName}</h4>
                <p className="expires">
                  Expires: {expiryType}
                  <span className="discount">{discountType}</span>
                </p>
              </div>
              <div className="toggle-menu">
                <Dropdown as={ButtonGroup}>
                  <Dropdown.Toggle as={CustomToggle} drop="down-centered" />
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => { onEditHandler(voucherDetails?.id, voucherDetails?.name, voucherDetails?.discount, voucherDetails?.discount_type, voucherDetails?.max_usage, voucherDetails?.expiry_date, voucherDetails?.expiry_type) }}>Edit</Dropdown.Item>
                    <Dropdown.Item className="red-dropdown-item" onClick={() => {handleDeleteStandVoucher(voucherId)}}>Delete</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

            <div className="modal-action-btns">
              <button 
                className="modal-action-btn outlined" 
                type="button" 
                onClick={() => {
                  onHide();
                }}
              >
                SHARE LATER
              </button>
              <button 
                className="modal-action-btn filled"
                type="button"
                onClick={() => {
                  navigator.clipboard.writeText(voucherCode);
                  toastHandler("Code Copied Successfully", "success");
                  onHide();
                }}
              >
                SHARE NOW
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {showEditVoucherModal && <Modal className="create-voucher-modal" show={show} onHide={onHideHandler} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Edit Voucher
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="modal-body-text">Give a voucher to friends and family so they can enjoy your location at a discounted rate.</p>
          <Form className="create-voucher-form" onSubmit={formik.handleSubmit}>
            <FloatingLabel label="Voucher Name">
              <Form.Control
                type="text"
                name="voucher"
                id="voucher"
                value={formik.values.voucher}
                onChange={(e) => {
                  formik.setFieldValue("voucher", e.target.value);
                }}
                className={formik?.touched?.voucher && formik?.errors?.voucher ? "form-control-error fs-14" : "fs-14"}
              />
            </FloatingLabel>
            {formik?.touched?.voucher && formik?.errors?.voucher && (
              <Form.Control.Feedback type="d-block invalid">
                {formik.errors.voucher}
              </Form.Control.Feedback>
            )}

            <div className="discount-section">
              <div className="discount-section-left">
                <FloatingLabel label={discountOption === "percentage" ? "%" : "$"}>
                  <Form.Control
                    type="number"
                    name="discount"
                    id="discount"
                    value={formik.values.discount}
                    onChange={(e) =>  {
                      formik.setFieldValue("discount", e.target.value);
                    }}
                    className={formik?.touched?.discount && formik?.errors?.discount ? "form-control-error fs-14" : "fs-14"}
                  />
                </FloatingLabel>
                {formik?.touched?.discount && formik?.errors?.discount && (
                  <Form.Control.Feedback type="d-block invalid">
                    {formik.errors.discount}
                  </Form.Control.Feedback>
                )}
              </div>
              <div className='discount-section-right'>
                <input
                  type="radio"
                  className="btn-check"
                  name="discountType"
                  id="percentage"
                  autocomplete="off"
                  value="percentage"
                  checked={discountOption==="percentage"}
                  onClick={() => {
                    formik.setFieldValue("discountType", "percentage");
                    setDiscountOption("percentage")
                  }}
                />
                <label className="btn btn-secondary" htmlFor="percentage">%</label>

                <input
                  type="radio"
                  className="btn-check"
                  name="discountType"
                  id="amount"
                  autocomplete="off"
                  value="amount"
                  checked={discountOption==="amount"}
                  onClick={() => {
                    formik.setFieldValue("discountType", "amount");
                    setDiscountOption("amount")
                  }}
                />
                <label className="btn btn-secondary" htmlFor="amount">$</label>
              </div>
            </div>

            <div className="toggle-usage-and-expiry">
              <div>
                <label htmlFor="max" className="label">
                  <input
                    id="max"
                    type="radio"
                    name="expiryType"
                    value="max_usage"
                    className="radio-input"
                    checked={fieldToggle==="max_usage"}
                    onClick={() => {
                      formik.setFieldValue("expiryType", "max_usage");
                      setFieldToggle("max_usage");
                    }}
                  />
                  <span>Usage Limit</span>
                </label>
              </div>

              <div>
                <label htmlFor="expiry" className="label">
                  <input
                    id="expiry"
                    type="radio"
                    name="expiryType"
                    value="expiry_date"
                    className="radio-input"
                    checked={fieldToggle==="expiry_date"}
                    onClick={() => {
                      formik.setFieldValue("expiryType", "expiry_date");
                      setFieldToggle("expiry_date");
                    }}
                  />
                  <span>Expiry Date</span>
                </label>
              </div>
            </div>

            {
              fieldToggle === "max_usage" ? (
                <FloatingLabel label="Users Per Voucher">
                  <Form.Control
                    type="number"
                    name="maxUsage"
                    id="maxUsage"
                    value={formik.values.maxUsage}
                    onChange={(e) =>  {
                      formik.setFieldValue("maxUsage", e.target.value);
                    }}
                    className={formik?.touched?.maxUsage && formik?.errors?.maxUsage ? "form-control-error fs-14" : "fs-14"}
                  />
                  {formik?.touched?.maxUsage && formik?.errors?.maxUsage && (
                    <Form.Control.Feedback type="d-block invalid">
                      {formik.errors.maxUsage}
                    </Form.Control.Feedback>
                  )}
                </FloatingLabel>
              ) : (
                <FloatingLabel label="Expiry Date" ref={calendarRef}>
                  <Form.Control
                    type="text"
                    name="expiryDate"
                    id="expiryDate"
                    value={formik.values.expiryDate}
                    onChange={(e) =>  {
                      formik.setFieldValue("expiryDate", e.target.value);
                    }}
                    onClick={() => setShowCalendar(!showCalendar)}
                    readOnly={true}
                    className={formik?.touched?.expiryDate && formik?.errors?.expiryDate ? "form-control-error fs-14 cursor-pointer" : "fs-14 cursor-pointer"}
                  />
                  <div
                    className="input-icon"
                    onClick={() => setShowCalendar(!showCalendar)}
                  >
                    <i className="far fa-calendar"></i>
                  </div>
                  <div className="voucher-detail-calender">
                    <Calendar
                      calendarType={"ISO 8601"}
                      className={showCalendar ? "" : "hide"}
                      onChange={(value) => {
                        formik.setFieldValue(
                          "expiryDate",
                          moment(value).format("MMM DD YYYY")
                        );
                        setShowCalendar(false);
                      }}
                      minDate={new Date()}
                      value={moment(formik?.values?.expiryDate)._d}
                      tileClassName={({ date, view }) => {
                        if (formik?.values?.expiryDate === moment(date).format("MMM DD YYYY")) {
                          return "voucher-created-date"
                        }
                      }}
                    />
                  </div>
                  {formik?.touched?.expiryDate && formik?.errors?.expiryDate && (
                    <Form.Control.Feedback type="d-block invalid">
                      {formik.errors.expiryDate}
                    </Form.Control.Feedback>
                  )}
                </FloatingLabel>
              )
            }

            <div className='modal-action-btns'>
              <button className='modal-action-btn outlined' type='button' onClick={onHideHandler}>CANCEL</button>
              <button className='modal-action-btn filled' type='submit'>
              {loading ? <Spinner /> : "UPDATE"}
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>}
    </>
  );
}

export default CreateVoucherConfirmationModal;
