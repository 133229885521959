import axios from "../../../../services/axiosInstance";

export function createTicket(data) {
  return axios.post("create-ticket", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function getCustomerServicesList() {
  return axios.get("customer-service-category");
}

export function getTickets(params) {
  return axios.get("tickets", { params });
}

export function getTicketDetails(ticketId) {
  return axios.get(`ticket-detail/${ticketId}`);
}

export function addCommentToTicket(ticketId, data) {
  return axios.post(`add-comment/${ticketId}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
