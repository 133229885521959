import { DeletedStand } from "components/UI/modals/deleted-stand/DeletedStand";
import React, { useEffect } from "react";
import { Redirect, Route } from "react-router";
import DashboardLayout from "../../../hoc/dashboardLayout/dashboardLayout";
import ChangePassword from "./change-password";
import { DashboardUIProvider } from "./DashboardUIContext";
import MyProfile from "./my-profile";
import MyGroups from "./my-groups";
import MyReservations from "./my-reservation";
import MyStands from "./ownerDashboard/mystands/index";
import FollowedStands from "./ownerDashboard/mystands/followedStands";
import StandBookings from "./ownerDashboard/mystands/stand-bookings";
import ReservationDetails from "./reservations/my-reservation/reservation-details";
import ReviewsDashboard from "./reviews";
import TrophiesDashboard from "./trophies";
import TrophiesRoom from "./TrophiesRoom";
import Vouchers from "./vouchers";
import Chat from "./chat";
import Payments from "./payments";
import Feedback from "./feedback";
import { LogOutConfirmationModal } from "./ownerDashboard/navlinks/logoutButtonConfirmationModal/logoutButtonConfirmationModal";
import TicketDetailPage from "./customer-service-portal/ticketDetailPage/ticketDetailPage";
import CustomerServicePortal from "./customer-service-portal";
import UserReview from "./reviews/UserReview/userReview";
import AdditionalHunter from "./my-reservation/additionalHunter";
import InvitationCodeRedirector from "./ownerDashboard/mystands/invitationCodeRedirector/InvitationCodeRedirector";


export function DashboardPage({ history }) {
  // PUSHING NESTED ROUTES
  const DashboardUIEvents = {
    openStandDetailPage: (id) => {
      history.push(`/stand/${id}`);
    },
    openReservationCancelModal: (id) => {
      history.push(`${id}`);
    },
  };

  const queryParams = new URLSearchParams(window.location.search);
    let status = queryParams.get("approve");

  useEffect(() => {document.title = 'My Dashboard | iHunt'}, []);

  return (
    <DashboardUIProvider dashboardUIEvents={DashboardUIEvents}>
      <DashboardLayout>
        <Route exact path="/my-profile">
          <Redirect to="my-profile/my-stands" />
        </Route>
        <Route exact path="/my-profile/my-reservations"
          render={({ match }) =>
            match !== null && !window.location.search.includes("invitation_code") ? (
              <MyReservations />
            ) : (
              <InvitationCodeRedirector />
            )
          }
        />
        <Route exact path="/my-profile/reservation-details/:id/additional-hunter">
          {({ history, match }) => match !== null && <AdditionalHunter />}
        </Route>
        <Route exact path="/my-profile/my-profile">
          {({ history, match }) => match !== null && <MyProfile />}
        </Route>
        <Route exact path="/my-profile/my-groups">
          {({ history, match }) => match !== null && <MyGroups />}
        </Route>
        <Route exact path="/my-profile/change-password">
          {({ history, match }) => match !== null && <ChangePassword />}
        </Route>
        <Route exact path="/my-profile/my-stands"
          render={({ match }) =>
            match !== null && !window.location.search.includes("invitation_code") ? (
              <MyStands />
            ) : (
              <InvitationCodeRedirector />
            )
          }
        />

        <Route exact path="/my-profile/followed-stands">
          {({ history, match }) => match !== null && <FollowedStands />}
        </Route>

        <Route exact path="/my-profile/stand-bookings/:id/:standName/:history">
          {({ history, match }) =>
            match !== null && (
              <StandBookings
                id={match && match.params.id}
                standName={match && match.params.standName}
              />
            )
          }
        </Route>
        <Route exact path="/my-profile/trophies">
          {({ history, match }) => match !== null && <TrophiesDashboard />}
        </Route>

        <Route exact path="/my-profile/trophies-room">
          {({ history, match }) => match !== null && <TrophiesRoom />}
        </Route>
        <Route exact path="/my-profile/customer-portal/ticket/:id">
          {({ history, match }) =>
            match !== null && <TicketDetailPage id={match && match.params.id} />
          }
        </Route>
        <Route exact path="/my-profile/customer-portal/tickets">
          {({ history, match }) => match !== null && <CustomerServicePortal />}
        </Route>
        <Route exact path="/my-profile/vouchers">
          {({ history, match }) => match !== null && <Vouchers />}
        </Route>
        <Route exact path="/my-profile/payments">
          {({ history, match }) => match !== null && <Payments />}
        </Route>
        <Route exact path="/my-profile/feedback">
          {({ history, match }) => match !== null && <Feedback />}
        </Route>
        <Route exact path="/my-profile/reservation-details/:id">
          {({ history, match }) =>
            match !== null && (
              <ReservationDetails id={match && match.params.id} />
            )
          }
        </Route>
        <Route exact path="/my-profile/reservation-details/:id"
          render={({ match }) =>
            match !== null && window.location.search.includes("approve") && (
              <ReservationDetails id={match && match.params.id} status={status} />
            )
          }
        />
        <Route exact path="/my-profile/reviews">
          {({ history, match }) => match !== null && <ReviewsDashboard />}
        </Route>

        <Route exact path="/my-profile/user-review/:id">
          {({ history, match }) => match !== null && <UserReview />}
        </Route>
        <Route exact path="/my-profile/chat/:id/:name/:userId">
          {({ history, match, user = { id: match?.params?.userId } }) =>
            match !== null && (
              <Chat
                id={match && match.params.id}
                name={match && match.params.name}
                user={user}
                showInput={true}
                showMessage={false}
              />
            )
          }
        </Route>

        <Route exact path="/my-profile/my-reservations/deleted-stand">
          {({ history, match }) =>
            match !== null && (
              <DeletedStand
                onHide={() => {
                  history.push("/my-profile/my-reservations");
                }}
                show={match !== null}
              />
            )
          }
        </Route>
        <Route path="my-profile/*">
          {({ history, match }) => (
            <LogOutConfirmationModal
              show={match !== null}
              id={match && match.params.id}
              onHide={() => {
                history.goBack();
              }}
            />
          )}
        </Route>
      </DashboardLayout>
    </DashboardUIProvider>
  );
}

export default DashboardPage;
