import React from "react";
import { Modal } from "react-bootstrap";
import andriodImg from "../../../assets/icons/android.png";
import googlePlay from "../../../assets/icons/google-play.svg";
import appleImg from "../../../assets/icons/apple.png";
import appleImgb from "../../../assets/icons/apple_black.svg";
import crossImg from "../../../assets/icons/cross.png";

export function StoreLinksModal({ show, onHide }) {
  const appStoreLink = "https://apps.apple.com/us/app/i-hunt/id1596269965";
  const playStoreLink = "https://play.google.com/store/apps/details?id=com.tso.ihunt";

  return (
    <div>
      <Modal
        className="store-link-modal"
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
        centered
      >
        <button onClick={onHide}>
          <img src={crossImg} alt="" />
        </button>
        <Modal.Body>
          <div className="modal-body-btn">
            <a className="left" target="_blank" href={appStoreLink} rel="noreferrer">
              <div>
                <img className="white" src={appleImg} alt="" />
                <img className="black" src={appleImgb} alt="" />
              </div>
            </a>
            <div className="hr"></div>
            <a className="right" target="_blank" href={playStoreLink} rel="noreferrer">
              <div className="right">
                <img className="andriod" src={andriodImg} alt="" />
                <img className="playstore" src={googlePlay} alt="" />
              </div>
            </a>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
