import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import Chat from "assets/icons/msg-chat.svg";
import { setActiveThread } from "modules/dashboard/_redux/chat/chatActions";
import Dropdown from 'react-bootstrap/Dropdown';
import toastHandler from "components/UI/toastHandler/toastHandler";
import ChatModal from "components/UI/modals/chat-modal/ChatModal";
import "./style.scss";


export default function UserCard({ user, subject, reservationId, showInput, showChat, showMessage }) {
    const [show, setShow] = useState(false);
    const location = useLocation();
    const history = useHistory();

    const toastId = history.location.pathname;
    const dispatch = useDispatch();
    const handleClose = () => setShow(false);
    const handleShow = () => {
        dispatch(
            setActiveThread({
                id: 225,
                name: user?.name,
                img: user?.image,
            })
        );

        setShow(true);
    };

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <button
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }} className="btn btn-primary text-uppercase text-white">CONTACT</button>
    ));

    return (
        <>
            <Dropdown>
                <Dropdown.Toggle as={CustomToggle} />
                <Dropdown.Menu style={{ minWidth: "100%" }}>
                    {
                        showChat ?
                            <Dropdown.Item onClick={() => {
                                reservationId ? handleShow() :
                                    location?.state?.from === true &&
                                        location?.state?.reservationId ? handleShow() :
                                        toastHandler("You can contact landowner only after booking", "error", toastId)
                            }
                            }>
                                <div className="tel">
                                    <span className="cursor-pointer" onClick={() => {
                                        reservationId ? handleShow() :
                                            location?.state?.from === true &&
                                                location?.state?.reservationId ? handleShow() :
                                                toastHandler("You can contact landowner only after booking", "error", toastId)
                                    }
                                    }>
                                        <img src={Chat} alt="" />
                                    </span>
                                </div>
                            </Dropdown.Item> : null
                    }
                    <Dropdown.Item onClick={() => window.location = `mailto:${user?.email}?subject=${subject}&body=`}>
                        <div className="tel">
                            <span className="cursor-pointer" onClick={() => window.location = `mailto:${user?.email}?subject=${subject}&body=`}>
                                <i class="bi bi-envelope-fill" />
                            </span>
                        </div>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <ChatModal
                show={show}
                handleClose={handleClose}
                id={reservationId || location?.state?.reservationId}
                name={`${user?.first_name} ${user?.last_name}`}
                user={user}
                showInput={showInput}
                showMessage={showMessage}
            />
        </>
    );
}
