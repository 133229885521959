import React, { useEffect } from "react";
import "./style.scss";
import Book from "../../../../assets/images/book.png";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/landingPageActions";

export default function Banner() {
  const dispatch = useDispatch();

  const BIBLE_ID = `9879dbb7cfe39e4d-01`;

  useEffect(() => {
    dispatch(actions.fetchVerse(BIBLE_ID));
  }, []);

  const { verse } = useSelector(
    (state) => ({
      verse: state.landingPage.verse,
    }),
    shallowEqual
  );

  return (
    <div className="banner position-relative">
      <div className="inner-container ">
        <div className="row">
          <div className="col-lg-8">
            <p className="mb-0 subtitle">THE HOLY BIBLE</p>
            <h2 className="text-white pb-3">Daily Verse</h2>
            <div className="d-flex mt-5 mbl-pb-200">
              <p className="quote">"</p>
              <div>
                <p className="text-white verse-txt">{verse && verse.text}</p>
                {verse && <p className="ref-verse">{`${verse.reference}`}</p>}
              </div>
            </div>
          </div>
          <div className="col-lg-4 img-section">
            <div>
              <img src={Book} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
