import { toast } from "react-toastify";

const toastHandler = (msg, type, Id) => {
  const setting = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    toastId: Id ? Id : null,
    style: {
      "--toastify-icon-color-success": "#ea9b45",
      "-toastify-color-progress-success": "#ea9b45",
    },
  };

  switch (type) {
    case "default":
      toast(msg, setting);
      break;

    case "info":
      toast.info(msg, setting);
      break;

    case "success":
      toast.success(msg, setting);
      break;

    case "warning":
      toast.warn(msg, setting);
      break;

    case "error":
      toast.error(msg, setting);
      break;

    default:
      break;
  }
};

export default toastHandler;
