export const initialFilter = {
  per_page: 8,
  page: 1,
  sort_order: "recent"
};

export const initialReservationListFilter = {
  per_page: 9,
  page: 1,
};

export const initialVoucherListFilter = {
  per_page: 9,
  page: 1,
  status: "current",
};

export const initialTrophyListFilter = {
  per_page: 9,
  page: 1,
};

export const initialUserReviewFilter = {
  per_page: 2,
  page: 1,
};

export const initialBookingsFilter = {
  per_page: 8,
  page: 1,
};

export const initialReviewListFilter = {
  per_page: 3,
  page: 1,
  id: 0,
};

export const initialTransactionListFilter = {
  per_page: 10,
  page: 1,
};

export const initialTrophyRoomListFilter = {
  per_page: 12,
  page: 1,
  marked_trophy: null,
  start_date: "",
  end_date: "",
  specie: "",
  state: "",
};

export const initialCustomerPortalTicketsListFilter = {
  per_page: 10,
  page: 1,
  status: "",
  category: "",
};

export const ticketStatuses = [
  { title: "All", value: "" },
  { title: "Open", value: "open" },
  { title: "Hold", value: "hold" },
  { title: "Resolved", value: "resolved" },
];
