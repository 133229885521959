import { useFormik } from "formik";
import React, { useEffect, useState, useRef } from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import Autocomplete from "react-google-autocomplete";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ADDRESS_REGEX } from "utils/helpers";
import Spinner from "components/UI/Spinner.js";
import profileAvatar from "assets/images/default-avatar.png";
import newMember from "assets/icons/new.gif";
import toastHandler from "components/UI/toastHandler/toastHandler";
import * as actions from "modules/auth/_redux/authActions";
import { modifyValuesWithDialCode } from "utils/helpers";
import Calendar from "react-calendar";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import imageCompression from "browser-image-compression";
import startsWith from "lodash.startswith";
import { useHistory } from "react-router-dom";
import moment from "moment";
import config from "config";
import * as Yup from "yup";
import "./style.scss";
import { debounce } from "lodash";


export default function MyProfile() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [userImage, setUserImage] = useState({});
  const [error, setError] = useState("");
  const [showCalendar, setShowCalendar] = useState(false);
  const [isPhonValid, setIsPhonValid] = useState(false);
  const [isSecandryPhonValid, setIsSecandryPhonValid] = useState(false);
  const [disableResetButton, setdisableResetButton] = useState(true);

  const calendarRef = useRef();

  const toastId = history.location.pathname;

  const maxDate = new Date();

  const fileInputRef = React.createRef();

  const { currentUser, isLoading, states, profilePictureLoading } = useSelector(
    (state) => ({
      currentUser: state.auth.user,
      isLoading: state.auth.actionsLoading,
      profilePictureLoading: state.auth.profilePictureUploading,
      states: state.stand.states,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.loadUser());
  }, [dispatch]);

  const [monthlyEmail, setMonthlyEmail] = useState(currentUser?.monthly_email_enabled)

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    isSecandryPhon: Yup.boolean(),
    phone: Yup.string().required("Phone Number is required")
      .test('len', 'Invalid Phone number', val => isPhonValid),
    secondary_phone: Yup.string().when("isSecandryPhon", {
      is: true,
      then: Yup.string()
        .test('len', 'Invalid Phone number', val => isSecandryPhonValid),
      otherwise: Yup.string().notRequired(),
    }),
    country_code: Yup.string(),
    secondary_country_code: Yup.string(),
    country_dial_code: Yup.string(),
    secondary_dial_code: Yup.string(),
    birthday: Yup.string().required("Birthday is required"),
    address: Yup.string()
      .required('Address is required')
      .matches(ADDRESS_REGEX, 'Address must be alphanumeric with at least one letter')
      .min(10, 'Address must be at least 10 characters long'),
    city: Yup.string().nullable().notRequired("City is required"),
    state_name: Yup.string().nullable().notRequired("State is required"),
    zipcode: Yup.string().nullable().notRequired("Zipcode is required"),
    country: Yup.string().nullable().notRequired("Country is required"),
  });

  const fileUploadHandler = async (file) => {
    const compressedFile = await imageCompression(
      file,
      config.IMAGE_COMPRESSOR
    );
    let data = new FormData();
    data.append("image", compressedFile);
    dispatch(actions.uploadImage(data, setError));
  };

  const handleSucess = (message) => {
    toastHandler(message, "success", toastId);
  };

  const onError = (message) => {
    toastHandler(message, "error", toastId);
  }

  const handleSubmit = async (values) => {
    setError("");
    if (userImage?.file) fileUploadHandler(userImage?.file);
    const updatedValues = modifyValuesWithDialCode(values);
    dispatch(actions.updateProfile(updatedValues, onError, handleSucess));
  };

  const formInitialValues = {
    first_name: currentUser?.first_name || "",
    last_name: currentUser?.last_name || "",
    phone: currentUser?.phone?.replace("-", "").replace("+", "") || "",
    secondary_phone:
      currentUser?.secondary_phone?.replace("-", "").replace("+", "") || "",
    country_code: currentUser?.country_code || "",
    secondary_country_code: currentUser?.secondary_country_code || "",
    country_dial_code: currentUser?.phone?.split("-")[0].substring(1),
    secondary_dial_code: currentUser?.secondary_phone
      ?.split("-")[0]
      .substring(1),
    birthday: currentUser?.birthday,
    state_name: currentUser?.state?.name || "",
    state_code: currentUser?.stand?.state_code,
    zipcode: currentUser?.zip,
    address: currentUser?.address,
    city: currentUser?.city,
    country: currentUser?.country,
    isSecandryPhon: false
  };

  //eslint-disable-next-line
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: formInitialValues,
    onSubmit: handleSubmit,
  });

  //eslint-disable-next-line
  useEffect(() => {
    document.addEventListener("click", manageOutsideClick);
    return () => {
      document.removeEventListener("click", manageOutsideClick);
    }
  }, []);

  //eslint-disable-next-line
  useEffect(() => {
    if (!states?.length || !currentUser?.state) return;
    const stateId =
      states.find((state) => state.id === currentUser.state.id)?.id || 0;
    formik.setFieldValue("state", stateId.toString());
  }, [states]);

  const handleEmail = (e) => {
    setMonthlyEmail(() => !monthlyEmail);
    const formData = new FormData();
    formData.append('is_enabled', e.target.checked ? 1 : 0);
    dispatch(actions.monthlyEmail(formData, setError, handleSucess))
  }

  const ValidatePhonNumber = (phone, country) => {
    let length = country.format?.replace(/[^.]/g, '')?.length;
    if (length > 15) {
      length = length - 1;
    }
    if ((startsWith(phone, country.dialCode) ||
      startsWith(country.dialCode, phone)) &&
      phone?.length == length) {
      return true
    } else return false
  }

  const handleAddressInputKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const manageOutsideClick = (event) => {
    if (!(calendarRef && calendarRef.current && calendarRef.current.contains(event.target)) && !isMonthOrYearClicked(event.target)) {
      setShowCalendar(false)
    }
  }

  const isMonthOrYearClicked = (target) => {
    if (target.classList.contains("react-calendar__tile") ||
    target.classList.contains("react-calendar__year-view__months__month") ||
    target.classList.contains("react-calendar__decade-view__years__year") ||
    target.classList.contains("react-calendar__century-view__decades__decade")) 
    {
      return true;
    }
    if (target.tagName === "ABBR" && target.getAttribute("aria-label")) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div className="pb-3">
        <h6 className="d-content-inner-title font-roboto mb-0">Profile</h6>
      </div>
      <div className="d-profile-middle-content">
        <div>
          <div className="p-user-details font-roboto">
            <input
              type="file"
              ref={fileInputRef}
              accept="image/png, image/jpeg"
              hidden
              onChange={(e) => {
                const selectedFile = e.target.files[0];
                const allowedExtensions = /\.(jpg|jpeg|png)$/i;
                if (!allowedExtensions.test(selectedFile.name)) {
                  toastHandler("File not supported. We accept png and jpg", "error")
                  fileInputRef.current.value = "";
                  return;
                }
                setUserImage({
                  file: e.target.files[0],
                  blob: URL.createObjectURL(e.target.files[0]),
                })
              }
              }
            />

            {currentUser?.image ? (
              <>
                <div className="position-relative">
                  <label
                    htmlFor="profile"
                    style={{
                      marginBottom: "0rem",
                      position: "absolute",
                      bottom: "0px",
                      left: "45px",
                      background: "#EA9B45",
                      borderRadius: "50%",
                      textAlign: "center",
                      padding: "0.2rem 0.3rem",
                      cursor: "pointer",
                      height: "26px",
                      width: "26px",
                    }}
                    onClick={() => {
                      fileInputRef.current.click();
                    }}
                  >
                    {!profilePictureLoading ? (
                      <i
                        className="fal fa-camera"
                        aria-hidden="true"
                        style={{ color: "white", fontSize: "0.8rem" }}
                      ></i>
                    ) : (
                      <Spinner />
                    )}
                  </label>
                  <img
                    className="profile-pic cursor-pointer"
                    src={userImage.blob || currentUser.image || profileAvatar}
                    onClick={() => {
                      fileInputRef.current.click();
                    }}
                    title="Click to update profile picture"
                    alt=""
                  />
                </div>
              </>
            ) : (
              <>
              <div className="position-relative">
                <label
                  htmlFor="profile"
                  style={{
                    marginBottom: "0rem",
                    position: "absolute",
                    bottom: "0px",
                    left: "45px",
                    background: "#EA9B45",
                    borderRadius: "50%",
                    textAlign: "center",
                    padding: "0.2rem 0.3rem",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    fileInputRef.current.click();
                  }}
                >
                  {!profilePictureLoading ? (
                    <i
                      className="fal fa-camera"
                      aria-hidden="true"
                      style={{ color: "white", fontSize: "0.8rem" }}
                    ></i>
                  ) : (
                    <Spinner />
                  )}
                </label>
                <img
                  src={userImage.blob || profileAvatar}
                  className="upload-icon  cursor-pointer"
                  onClick={() => {
                    fileInputRef.current.click();
                  }}
                  alt="ProfileImage"
                />
                <a
                  className="mt-2 cursor-pointer"
                  onClick={() => {
                    fileInputRef.current.click();
                  }}
                ></a></div>
              </>
            )}
            <div className="p-user-data">
              <div className="p-user-name fw-bold">
                {`${currentUser?.first_name} ${currentUser?.last_name}`}
                {(!currentUser?.rating || currentUser?.rating === 0) ? <img src={newMember} alt="new member" /> : ''}
              </div>
              <small>{currentUser?.email}</small>
              <div className="blog-checkbox">
                <label className="toggle d-flex">
                  <span className="toggle-label-show" style={{ fontSize: "14px" }}>Enable Monthly Email</span>
                  <input className="toggle-checkbox" type="checkbox" onChange={handleEmail} checked={monthlyEmail}></input>
                  <div className="toggle-switch"></div>
                </label>
              </div>
            
            {currentUser?.referral_code && (<button className="referral-code"
              onClick={debounce(() => {
                navigator.clipboard
                  .readText()
                  .then((text) => {
                    if (text !== currentUser?.referral_code) {
                      navigator.clipboard.writeText(
                        currentUser?.referral_code
                      );
                      toastHandler(
                        "Code Copied Successfully",
                        "success"
                      );
                    } else {
                      toastHandler(
                        "Already Copied",
                        "error",
                        toastId
                      );
                    }
                  });
              }, 1000)}
            >
              <span>Referral Code: <span>{currentUser?.referral_code}</span></span>
            </button>)}</div>
          </div>
          {error && (
            <Form.Control.Feedback type="d-block invalid">
              {error}
            </Form.Control.Feedback>
          )}
          <Form className="mt-4" onSubmit={formik.handleSubmit}>
            <FloatingLabel
              className="mb-3"
              controlId="floatingInputGrid"
              label="First Name"
            >
              <Form.Control
                type="text"
                name="first_name"
                placeholder="First Name"
                onChange={(e) => {
                  formik.setFieldValue("first_name", e.target.value)
                  setdisableResetButton(false);
                }}
                value={formik.values.first_name}
              />
            </FloatingLabel>
            {formik?.touched?.first_name && formik?.errors?.first_name && (
              <Form.Control.Feedback type="d-block invalid">
                {formik.errors.first_name}
              </Form.Control.Feedback>
            )}
            <FloatingLabel
              className="mb-3"
              controlId="floatingInputGrid"
              label="Last Name"
            >
              <Form.Control
                type="text"
                name="last_name"
                placeholder="Last Name"
                onChange={(e) => {
                  formik.setFieldValue("last_name", e.target.value)
                  setdisableResetButton(false);
                }}
                value={formik.values.last_name}
              />
            </FloatingLabel>
            {formik?.touched?.last_name && formik?.errors?.last_name && (
              <Form.Control.Feedback type="d-block invalid">
                {formik.errors.last_name}
              </Form.Control.Feedback>
            )}
            <div className="position-relative">
              <PhoneInput
                copyNumbersOnly={false}
                value={formik.values.phone}
                onChange={(value, country) => {
                  setdisableResetButton(false);
                  formik.setFieldValue("phone", value);
                  formik.setFieldValue("country_code", country.countryCode);
                  formik.setFieldValue("country_dial_code", country.dialCode);
                }}
                containerClass="phone-input-container mb-3"
                inputClass="intl-tel-input phone-input"
                isValid={(inputNumber, country) => {
                  if (country) {
                    setIsPhonValid(ValidatePhonNumber(inputNumber, country))
                    return ValidatePhonNumber(inputNumber, country)
                  } else {
                    setIsPhonValid(false);
                    return false
                  }
                }}
              />
            </div>
            {formik?.touched?.phone && formik?.errors?.phone && (
              <Form.Control.Feedback type="d-block invalid" className="mb-2">
                {formik.errors.phone}
              </Form.Control.Feedback>
            )}
            <div className="position-relative">
              <PhoneInput
                copyNumbersOnly={false}
                value={formik.values.secondary_phone}
                onChange={(e, country) => {
                  if (e.length > 0) {
                    setdisableResetButton(false)
                    formik.setFieldValue("isSecandryPhon", true)
                  } else {
                    formik.setFieldValue("isSecandryPhon", false)
                  }
                  formik.setFieldValue("secondary_phone", e);
                  formik.setFieldValue(
                    "secondary_country_code",
                    country.countryCode
                  );
                  formik.setFieldValue("secondary_dial_code", country.dialCode);
                }}
                containerClass="phone-input-container mb-3"
                inputClass="intl-tel-input secondary-phone-input"
                isValid={(inputNumber, country) => {
                  if (country) {
                    setIsSecandryPhonValid(ValidatePhonNumber(inputNumber, country))
                    return ValidatePhonNumber(inputNumber, country)
                  } else {
                    setIsSecandryPhonValid(false);
                    return false
                  }
                }}
              />
              {formik?.touched?.secondary_phone &&
                formik?.errors?.secondary_phone && (
                  <Form.Control.Feedback type="d-block invalid">
                    {formik.errors.secondary_phone}
                  </Form.Control.Feedback>
                )}
            </div>
            <FloatingLabel
              className="mb-3"
              controlId="floatingInputGrid"
              label="Date of Birth"
              ref={calendarRef}
            >
              <Form.Control
                type="text"
                name="birthday"
                placeholder="Date of birth"
                className="cursor-pointer"
                onChange={(e) => {
                  formik.setFieldValue("birthday", e.target.value)
                  setdisableResetButton(false);
                }}
                value={formik.values.birthday}
                onClick={() => setShowCalendar(!showCalendar)}
                readOnly={true}
              />
              <div
                className="input-icon"
                onClick={() => setShowCalendar(!showCalendar)}
              >
                <i className="far fa-calendar"></i>
              </div>
              <div className="profile-detail-calender">
                <Calendar
                  calendarType={"ISO 8601"}
                  defaultValue={
                    formik.values.birthday
                      ? new Date(
                        moment(formik.values.birthday).get("year"),
                        moment(formik.values.birthday).get("month"),
                        moment(formik.values.birthday).get("date")
                      )
                      : new Date(
                        maxDate.setFullYear(maxDate.getFullYear() - 18)
                      )
                  }
                  className={showCalendar ? "" : "hide"}
                  onChange={(value) => {
                    setdisableResetButton(false);
                    formik.setFieldValue(
                      "birthday",
                      moment(value).format("MMM DD YYYY")
                    );
                    setShowCalendar(false);
                  }}
                  tileClassName={({ date, view }) => {
                    if (formik?.values?.birthday === moment(date).format("MMM DD YYYY")) {
                      return "birth-date"
                    }
                  }}
                  tileDisabled={(e) => {
                    return e.date > new Date()
                  }}
                />
              </div>
            </FloatingLabel>
            {formik?.touched?.birthday && formik?.errors?.birthday && (
              <Form.Control.Feedback type="d-block invalid">
                {formik.errors.birthday}
              </Form.Control.Feedback>
            )}
            <FloatingLabel
              className="mb-3"
              controlId="floatingInputGrid"
              label="Address"
            >
              <Autocomplete
                name="address_field"
                id="address_field"
                className="form-control shadow-none"
                onPlaceSelected={(place, value) => {
                  setdisableResetButton(false);
                  formik?.setFieldValue('address', value?.value)
                  formik?.setFieldValue('city', place.address_components.find(component => component.types?.includes('locality'))?.long_name || "");
                  formik?.setFieldValue("zipcode", place.address_components.find(component => component.types?.includes('postal_code'))?.long_name || "")
                  formik?.setFieldValue("state_name", place.address_components.find(component => component.types?.includes('administrative_area_level_1'))?.long_name || "")
                  formik?.setFieldValue("state_code", place.address_components.find(component => component.types?.includes('administrative_area_level_1'))?.short_name || "")
                  formik?.setFieldValue("country", place.address_components.find(component => component.types?.includes('country'))?.long_name)
                }}
                componentRestrictions={{ country: "za" }}
                onChange={(e) => {
                  if (!e.target.value) {
                    formik?.setFieldValue('city', "");
                    formik?.setFieldValue("zipcode", "")
                    formik?.setFieldValue("state_name", "")
                    formik?.setFieldValue("country", "")
                  }
                  formik.setFieldValue("address", e.target.value)
                }}
                onKeyPress={handleAddressInputKeyPress}
                value={formik.values.address}
                options={{
                  fields: ["address_components", "geometry", "place_id"],
                  types: [],
                }}
                placeholder="Enter a Location..."
              />
              {formik?.touched?.address && formik?.errors?.address && (
                <Form.Control.Feedback type="d-block invalid">
                  {formik.errors.address}
                </Form.Control.Feedback>
              )}
            </FloatingLabel>
            <FloatingLabel
              className="mb-3"
              controlId="floatingInputGrid"
              label="Zipcode"
            >
              <Form.Control
                type="string"
                name="zipcode"
                placeholder="Zipcode"
                onChange={(e) => {
                  formik.setFieldValue("zipcode", e.target.value)
                  setdisableResetButton(false);
                }}
                value={formik.values.zipcode}
              />
              {formik?.touched?.zipcode && formik?.errors?.zipcode && (
                <Form.Control.Feedback type="d-block invalid">
                  {formik.errors.zipcode}
                </Form.Control.Feedback>
              )}
            </FloatingLabel>
            <div className="row mx-auto">
              <div className="col-md-6 pe-1 ps-0">
                <FloatingLabel
                  className="mb-3 mt-0"
                  controlId="floatingInputGrid"
                  label="City"
                >
                  <Form.Control
                    type="text"
                    name="city"
                    placeholder="City"
                    onChange={(e) => {
                      formik.setFieldValue("city", e.target.value)
                      setdisableResetButton(false);
                    }}
                    value={formik.values.city || "N/A"}
                    disabled
                  />
                  {formik?.touched?.city && formik?.errors?.city && (
                    <Form.Control.Feedback type="d-block invalid">
                      {formik.errors.city}
                    </Form.Control.Feedback>
                  )}
                </FloatingLabel>
              </div>
              <div className="col-md-6 ps-1 pe-0">
                <FloatingLabel
                  className="mb-3 mt-0"
                  controlId="floatingSelectGrid"
                  label="State"
                >
                  <Form.Control
                    type="text"
                    name="state"
                    placeholder="State"
                    onChange={(e) => {
                      formik.setFieldValue("state_name", e.target.value)
                      setdisableResetButton(false);
                    }}
                    value={formik.values.state_name || "N/A"}
                    disabled
                  />
                  {formik?.touched?.state && formik?.errors?.state_name && (
                    <Form.Control.Feedback type="d-block invalid">
                      {formik.errors.state_name}
                    </Form.Control.Feedback>
                  )}
                </FloatingLabel>
              </div>
            </div>
            <FloatingLabel
              className="mb-3 mt-0"
              controlId="floatingInputGrid"
              label="Country"
            >
              <Form.Control
                type="text"
                name="country"
                placeholder="Country"
                onChange={(e) => {
                  formik.setFieldValue("country", e.target.value)
                  setdisableResetButton(false);
                }}
                value={formik.values.country || "N/A"}
                disabled
              />
              {formik?.touched?.country && formik?.errors?.country && (
                <Form.Control.Feedback type="d-block invalid">
                  {formik.errors.country}
                </Form.Control.Feedback>
              )}
            </FloatingLabel>
            <div className="d-flex justify-content-between pb-5">
              <button
                className="btn  btn-outline-secondary "
                type="button"
                onClick={() => {
                  formik.resetForm();
                  setdisableResetButton(true);
                }}
                disabled={disableResetButton}
              >
                RESET
              </button>
              <button className="btn btn-primary text-white" type="submit">
                {isLoading ? <Spinner /> : "UPDATE"}
              </button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}
