import React from "react";
import Select from "react-select";

export const CustomSelect = (props) => {
  const { isMulti, onChange, options, value, error, isDisabled, label, filterOptions, noOptionsMessage } = props;

  const customStyles = () => ({
    control: (base) => ({
      ...base,
      fontFamily: 'Roboto',
      border: '1px solid #928F8F',
    }),
    menuPortal: (base) => ({ ...base, zIndex: 102 }),
    valueContainer: (base) => ({
      ...base,
      padding: "0 8px",
      maxHeight: '37px',
      overflow: 'auto',
      "&::-webkit-scrollbar": {
        width: "3px",
        height: "3px",
        borderRadius: "3px",
        backgroundColor: "#f5f5f5",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#A9A9A9",
        borderRadius: "3px",
      },
    }),
    menuList: (base) => ({
      ...base,
      "&::-webkit-scrollbar": {
        width: "8px",
        height: "3px",
        borderRadius: "6px",
        backgroundColor: "#f5f5f5",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#A9A9A9",
        borderRadius: "6px",
      },
    }),
    multiValue: () => ({
      width: "auto",
      float: "left",
      display: "flex",
      backgroundColor: "#F2F2F2",
      borderRadius: "5px",
      margin: "5px 2.5px",
      height: "25px",
      alignItems: "center",
      fontSize: "14px",
      paddingBlock: "5px",
      paddingInline: "10px 6px",
      maxWidth: "140px",
    }),
    multiValueLabel: (base) => ({
      ...base,
      color: "#212529",
      fontSize: "13px",
      padding: "0",
      paddingLeft: "0",
    }),
    multiValueRemove: (base) => ({
      ...base,
      borderRadius: "50%",
      paddingRight: "0",
      paddingLeft: "3px",
      ":hover": {
        color: "#DE350B",
        backgroundColor: "#F2F2F2",
      },
    }),
    placeholder:(base)=>({...base, color: "#6c757d",fontSize: "14px"}),
  });

  const singleValueStyles = () => ({
    menuPortal: (base) => ({ ...base, zIndex: 102 }),
    menuList: (base) => ({
      ...base,
      "&::-webkit-scrollbar": {
          width: "8px",
          height: "3px",
          borderRadius: "6px",
          backgroundColor: "#f5f5f5",
      },
      "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#A9A9A9",
          borderRadius: "6px",
      },
    }),
  });

  return (
    <div>
      <label>{label}</label>
      <Select
        isDisabled={isDisabled}
        menuPortalTarget={document.body}
        styles={isMulti ? customStyles(value) : singleValueStyles(value)}
        placeholder={"Please choose " + label}
        isMulti={isMulti}
        value={value}
        onChange={onChange}
        options={options}
        closeMenuOnSelect={isMulti ? false : true}
        hideSelectedOptions={false}
        noOptionsMessage={noOptionsMessage}
        theme={(theme) => ({
          ...theme,
          borderRadius: 5,
          colors: {
            ...theme.colors,
            text: "orangered",
            primary: "#EA9B45 !important",
          },
        })}
        filterOption={filterOptions}
      />
      <p style={{ color: "red", fontSize: ".9rem" }}>{error}</p>
    </div>
  );
};
