import Pagination from "components/UI/pagination/Pagination";
import React, { useEffect, useMemo, useState } from "react";
import { Tab, Tabs, Dropdown, DropdownButton, ButtonGroup } from "react-bootstrap";
import CustomSpinner from "../../../../../components/layout/Loader/Spinner";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import * as actions from "../../../_redux/stand/standActions";
import { getAllGroups } from "modules/dashboard/_redux/groups/groupActions";
import { useDashboardUIContext } from "../../DashboardUIContext";
import InvitationCodeModal from "components/UI/modals/invitationCode-modal/InvitationCodeModal";
import CreateGroupModal from "components/UI/modals/create-group-modal/CreateGroupModal";
import StandItem from "./standItem/StandItem";
import Equalizer from "assets/icons/equalizer-line 1.svg";
import NoRecordFound from "utils/noRecordFound";
import axios from "axios";
import "./style.scss";


export default function MyStands() {
  const history = useHistory();
  const [groupFilter, setGroupFilter] = useState("")
  const [key, setKey] = useState("");
  const [filter, setFilter] = useState("all");
  const [value, setValue] = useState(0);
  const [openInvitationCodeModal, setOpenInvitationCodeModal] = useState(false);
  const [openCreateGroupModal, setOpenCreateGroupModal] = useState(false);

  const search = useLocation().search;
  const page = new URLSearchParams(search).get("page") || 1;
  const activeTab = new URLSearchParams(search).get("activeTab") || "listing";
  const source = axios.CancelToken.source();

  const dispatch = useDispatch();
  const dashboardPageUIContext = useDashboardUIContext();
  const dashboardPageUIProps = useMemo(() => {
    return {
      queryParams: dashboardPageUIContext.standQueryParams,
      setQueryParams: dashboardPageUIContext.setStandQueryParams,
    };
  }, [dashboardPageUIContext]);

  useEffect(() => {
    if (!activeTab) return;
    dashboardPageUIProps.setQueryParams({
      ...dashboardPageUIProps.queryParams,
      page: parseInt(page),
      status: activeTab === "listing" ? "all" : activeTab,
    });
    if (activeTab === "listing") {
      dispatch(
        actions.getStands({
          ...dashboardPageUIProps.queryParams,
          page: parseInt(page),
          status: "all",
          'groups[0]': groupFilter ? groupFilter : null,
        }, source)
      );
    }
    setKey(activeTab);
    setFilter("all");
  }, [activeTab]);

  const {
    listLoading,
    inActiveStandLoading,
    bookedStandLoading,
    bookingHistoryLoading,
    inActiveStandList,
    bookedStandList,
    bookingHistoryList,
    standsList,
    totalRecords,
    groups,
  } = useSelector(
    (state) => ({
      listLoading: state.stand.listLoading,
      inActiveStandLoading: state.stand.inActiveStandLoading,
      bookedStandLoading: state.stand.bookedStandLoading,
      bookingHistoryLoading: state.stand.bookingHistoryLoading,
      bookingHistoryList: state.stand.bookingHistoryList,
      standsList: state.stand.standsList,
      inActiveStandList: state.stand.inActiveStandList,
      bookedStandList: state.stand.bookedStandList,
      totalRecords: state.stand.totalStands,
      groups: state.group.groups
    }),
    shallowEqual
  );

  useEffect(() => {
      dispatch(getAllGroups())
    return () => {
      if (source) {
        source.cancel();
      }
    };
  }, []);

  useEffect(() => {
    if (!page) return;
    let param = dashboardPageUIProps?.queryParams
    if (groupFilter) {
      param = { ...param, 'groups[0]': groupFilter }
    }
    dispatch(
      actions.getStands({
        ...param,
        page: parseInt(page),
        status:
          activeTab === "reserved"
            ? "reserved"
            : activeTab === "history"
              ? "history"
              : activeTab === "inactive"
                ? "inactive"
                :
                filter,
      }, source)
    );
  }, [page, value, filter, groupFilter]);

  const handleGroupFilter = (group) => {
    if (group == groupFilter) {
      setGroupFilter(null);
    } else
      setGroupFilter(group)
  }

  const hanldeActiveKeyChange = (key) => {
    setKey(key);
    setFilter("all");
    let param = dashboardPageUIProps?.queryParams
    if (groupFilter) {
      param = { ...param, 'groups[0]': groupFilter }
    }
    history.push({
      pathname: "/my-profile/my-stands",
      search: "?" + new URLSearchParams({ activeTab: key }).toString(),
    });
    dispatch(
      actions.getStands({
        ...param,
        status: key == "listing" ? 'all' : key || filter,
        page: 1
      }, source)
    );
    if (activeTab !== key) dispatch(actions.clearEntities());
  };

  useEffect(() => {
    return () => {
      dispatch(actions.clearStandsList());
    };
  }, []);

  return (
    <>
      <div className="pb-4 d-flex align-items-center justify-content-between position-relative">
        <h6 className="d-content-inner-title font-roboto mb-0">My Listing</h6>
        <div className="toggle-listing-map-btn action-btn-2 position-relative w-auto">
          <DropdownButton
            className="action-button"
            as={ButtonGroup}
            key={'up'}
            id={`dropdown-button-drop-up`}
            drop={'up'}
            variant="secondary"
          >
            <Dropdown.Item className="map-filter-btn" onClick={() => setOpenCreateGroupModal(true)} eventKey="1">
              Create A Group
            </Dropdown.Item>
            <Dropdown.Item className="map-filter-btn" onClick={() => setOpenInvitationCodeModal(true)} eventKey="2">
              Enter Invitation code
            </Dropdown.Item>
            <Dropdown.Item className="map-filter-btn" eventKey="3">
              Cancel
            </Dropdown.Item>
          </DropdownButton>
        </div>
      </div>
      {
        activeTab != "ownership" ?
          <div className="new-tags-v2">
            <div className="inner-tags d-flex" style={{
                      flexWrap: "wrap",
                      gap: "10px"
                    }}> 
              {groups && groups?.map((item, index) => {
                return (
                  <>
                    {
                      index == 0 ?
                        <div className={groupFilter == "" ? "tags-item active" : "tags-item hover"} onClick={() => handleGroupFilter("")}>
                          <span>All</span>
                        </div> : null
                    }
                    <div className={groupFilter == item?.name ? "tags-item active" : "tags-item hover"} onClick={() => handleGroupFilter(item?.name)}>
                      <span>{item.name}</span>
                    </div>
                  </>
                )
              })}
            </div>
          </div> : null
      }
      <div className="my-land">
        <div className="tabs">
          <Tabs
            transition
            defaultActiveKey="listing"
            id="uncontrolled-tab-example"
            activeKey={key}
            onSelect={(k) => hanldeActiveKeyChange(k)}
            className="mb-3"
          >
            <Tab eventKey="listing" title="Listing">
              <div className="filter-wrapper">
                <div className="filter-btn">
                  <img src={Equalizer} alt="" />
                  <select
                    value={filter}
                    className="select-field"
                    onChange={(e) => {
                      setFilter(e.target.value);
                    }}
                  >
                    <option value={"approved"}>Approved</option>
                    <option value={"rejected"}>Rejected</option>
                    <option value={"pending"}>Pending</option>
                    <option value={"all"}>All</option>
                  </select>
                </div>
              </div>
              <div className="row ">
                {standsList && standsList?.length > 0 ? (
                  <>
                    {standsList?.map((stand, index) => (
                      <StandItem
                        currentTab={"listing"}
                        stand={stand}
                        key={index}
                        value={value}
                        setValue={setValue}
                        groups={groups}
                      />
                    ))}
                    <div className="inner-container">
                      <Pagination
                        totalRecords={totalRecords}
                        uiProps={dashboardPageUIProps}
                        caller={"myStands"}
                        activeTab={key}
                        history={history}
                        key={"listing"}
                      />
                    </div>
                  </>
                ) : !listLoading ? (
                  <NoRecordFound
                    message="No listing found"
                    className="no-record"
                  />
                ) : (
                  <CustomSpinner />
                )}
              </div>
            </Tab>
            <Tab eventKey="reserved" title="Bookings">
              <div className="row">
                {
                  bookedStandList && bookedStandList?.length > 0 ? (
                    <>
                      {bookedStandList?.map((stand, index) => (
                        <StandItem
                          currentTab="reserved"
                          stand={stand}
                          key={index}
                          value={value}
                          setValue={setValue}
                        />
                      ))}
                      <div className="inner-container">
                        <Pagination
                          totalRecords={totalRecords}
                          uiProps={dashboardPageUIProps}
                          caller={"myStands"}
                          activeTab={key}
                          history={history}
                          key={"reserved"}
                        />
                      </div>
                    </>
                  ) : !bookedStandLoading ? (
                    <NoRecordFound
                      message="No reserved listing found"
                      className="no-record"
                    />
                  ) : (
                    <CustomSpinner />
                  )}
              </div>
            </Tab>
            <Tab eventKey="history" title="History">
              <div className="row ">
                {
                  bookingHistoryList && bookingHistoryList?.length > 0 ? (
                    <>
                      {bookingHistoryList?.map((stand, index) =>
                        stand ? (
                          <StandItem
                            currentTab="history"
                            stand={stand}
                            key={index}
                            value={value}
                            setValue={setValue}
                          />
                        ) : (
                          <CustomSpinner />
                        )
                      )}
                      <div className="inner-container">
                        <Pagination
                          totalRecords={totalRecords}
                          uiProps={dashboardPageUIProps}
                          caller={"myStands"}
                          activeTab={key}
                          history={history}
                          key={"history"}
                        />
                      </div>
                    </>
                  ) : !bookingHistoryLoading ? (
                    <NoRecordFound
                      message="No history listing found"
                      className="no-record"
                    />
                  ) : (
                    <CustomSpinner />
                  )}
              </div>
            </Tab>
            <Tab eventKey="inactive" title="In Active">
              <div className="row">
                {
                  inActiveStandList && inActiveStandList?.length > 0 ? (
                    <>
                      {inActiveStandList?.map((stand, index) => (
                        <StandItem
                          currentTab="inactive"
                          stand={stand}
                          key={index}
                          value={value}
                          setValue={setValue}
                          groups={groups}
                        />
                      ))}
                      <div className="inner-container">
                        <Pagination
                          totalRecords={totalRecords}
                          uiProps={dashboardPageUIProps}
                          caller={"myStands"}
                          activeTab={key}
                          history={history}
                          key={"inactive"}
                        />
                      </div>
                    </>
                  ) : !inActiveStandLoading ? (
                    <NoRecordFound
                      message="No inactive listing found"
                      className="no-record"
                    />
                  ) : (
                    <CustomSpinner />
                  )}
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
      <InvitationCodeModal 
        show={openInvitationCodeModal}
        onHide={() => setOpenInvitationCodeModal(false)}
        value={value}
        setValue={setValue}
      />
      <CreateGroupModal
        show={openCreateGroupModal}
        onHide={() => setOpenCreateGroupModal(false)}
      />
    </>
  );
}
