import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import Navlinks from "../../modules/dashboard/pages/ownerDashboard/navlinks";
import "./style.scss";


function DashboardLayout({ children }) {
  const [show, setShow] = useState(false);
  const handleLeftNav = () => {
    setShow(!show);
  }

  const { pathname, search } = useLocation();
  const contentRef = useRef(null);

  useEffect(() => {
    const content = contentRef.current;
    const newComment = search?.includes("new-comment");
    content.style.scrollBehavior = "smooth";
  
    const scrollCommentsSection = () => {
      const commentsSection = content.querySelector(".comments-section");
      if (commentsSection) {
        commentsSection.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    };
  
    if (newComment) {
      scrollCommentsSection();
    } else {
      content.scrollTop = 0;
    }
  
    if (newComment && !content.querySelector(".comments-section")) {
      setTimeout(scrollCommentsSection, 1000);
    }
  }, [pathname, search]);

  return (
    <div className="inner-container">
      <div className="mt-5">
        <div className="d-flex align-items-center">
          <i className="fa fa-bars me-3 d-lg-none d-md-inlineflex fs-5 cursor-pointer hamburger-dashboard-icon" onClick={handleLeftNav}></i>
        <h3 className="mb-0">My Dashboard</h3>
        </div>
        <div className="dashboard position-relative mt-3 mb-5">
          <div className={show ? "nav-overlay-show" : "nav-overlay"} onClick={handleLeftNav}>

          </div>
          <div className={show ? "d-navbar nav-show" : "d-navbar"}>
            <Navlinks />
          </div>
          <div className="d-content" ref={contentRef}>{children}</div>
        </div>
      </div>
    </div>
  );
}

export default DashboardLayout;
