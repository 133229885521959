import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import toastHandler from "components/UI/toastHandler/toastHandler";
import { handleHuntRequest, showRejectionReasonModal } from "modules/dashboard/_redux/reservations/reservationActions";
import Spinner from "components/UI/Spinner.js";
import { useHistory } from "react-router";


const HuntConfirmModal = ({ id, show, showRejectionReason, rejectionReason, getRequest }) => {
  
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validationSchema = Yup.object({
    rejection_reason: Yup.string().required("Reason is required")
  });

  const formik = useFormik({
      initialValues: {
        rejection_reason: ""
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
          setLoading(true);
          setIsSubmitting(true);
          const data = new FormData();
          data.append("status", "rejected");
          data.append("rejection_reason", values.rejection_reason);
          dispatch(handleHuntRequest(id, data, onSuccess, onError))
      },
  });

  const onSuccess = (message) => {
    if (window.location.pathname.includes(`my-profile/reservation-details/${id}`) && window.location.search.includes(`?approve=`)) {
      history.replace(`/my-profile/reservation-details/${id}`);
    }
    setIsSubmitting(false);
    setLoading(false);
    formik.resetForm();
    toastHandler(message, "success");
    dispatch(getRequest())
    dispatch(showRejectionReasonModal(false));
  }
  const onError = (message) => {
    setIsSubmitting(false);
    setLoading(false);
    toastHandler(message, "error");
    formik.resetForm();
  }

  return (
    <Modal
      show={show}
      onHide={() => {
        dispatch(showRejectionReasonModal(false));
        formik.resetForm();
        setIsSubmitting(false);
        setLoading(false);
        history.replace(`/my-profile/reservation-details/${id}`);
      }}
      className="transfer-model hunt-rejection-modal"
      centered
    >
      {
        <>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-content-start align-items-start">
              <div className="d-flex justify-content-start align-items-center flex-column">
                <h4>Rejection Reason</h4>
              </div>
            </div>
            {!showRejectionReason ? <form onSubmit={formik.handleSubmit}>
              <div className="textarea-main my-3">
                <label>Rejection Reason</label>
                <textarea
                  name="rejection_reason"
                  className="form-control"
                  value={formik.values.rejection_reason}
                  onChange={formik.handleChange}
                ></textarea>
              </div>
              {formik?.touched?.rejection_reason &&
                formik?.errors?.rejection_reason && (
                  <div style={{ color: "red", marginTop: -15, marginBottom: 10 }}>
                      {formik?.errors?.rejection_reason}
                  </div>
              )}
              <button
                className="btn btn-primary text-white px-3 w-100 text-uppercase d-flex justify-content-center align-items-center text-center flex-grow-1"
                type="submit"
                disabled={isSubmitting}
              >
                { loading ? <Spinner /> : "submit" }
              </button>
            </form> :
            <div className="d-flex justify-content-start mt-3">
              <p className="mb-0">
                {rejectionReason}
              </p>
            </div>}
          </Modal.Body>
        </>
      }
    </Modal>
  );
};

export default HuntConfirmModal;
