import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

const VerifyAuth = (props) => {
  const { token } = useSelector((state) => ({
    token: state.auth.token,
  }));
  return !token ? (
    <React.Fragment>{props.children}</React.Fragment>
  ) : (
    <Redirect to="/" replace />
  );
};

export default VerifyAuth;
