import React from "react";
import Section2Img from "./../../../../assets/images/hunting-exprience.jpg";
import PlaceHolderImage from "./../../../../assets/images/hunting-exprience-1.jpg";
import "./style.scss";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


export default function huntingExperience() {
    return (
        <section className="d-flex p-0 w-100">
            <div className="hunter-experience">
                <div className="inner-container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="left me-0">
                                <div className="left-inner rounded-5">
                                    <LazyLoadImage
                                        src={Section2Img}
                                        alt=""
                                        className="lazy-load-image"
                                        placeholderSrc={PlaceHolderImage}
                                        visibleByDefault={Section2Img}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="right ps-xl-5 ms-xl-5">
                                <div className="right-inner ps-xl-5">
                                    <p>
                                    iHunt is a free app to book your next hunting experience. The only time it costs you is when you book a hunt. To use the iHunt app for a hunting experience, follow these simple steps:</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
