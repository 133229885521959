import React, { useEffect, useState } from "react";


export default function AmountInfo({ transactions }) {

  const [initialTransactionValues, setInitialTransactionValues] = useState({
    earnings: 0,
    spendings: 0,
    ready_for_transfer: 0,
    bucks: 0,
    transferred: 0,
  });

  useEffect(() => {
    if (transactions) {
      setInitialTransactionValues({
        earnings: transactions.earnings !== undefined ? transactions.earnings : initialTransactionValues.earnings,
        spendings: transactions.spendings !== undefined ? transactions.spendings : initialTransactionValues.spendings,
        ready_for_transfer: transactions.ready_for_transfer !== undefined ? transactions.ready_for_transfer : initialTransactionValues.ready_for_transfer,
        bucks: transactions.bucks !== undefined ? transactions.bucks : initialTransactionValues.bucks,
        transferred: transactions.transferred !== undefined ? transactions.transferred : initialTransactionValues.transferred,
      });
    }
  }, [transactions]);

  return (
    <div className="w-100">
      <div className="card-price card-price-v2 d-flex mb-3 flex-wrap">
        <div className="detail mt-1">
          <div className="price"><span>${transactions && transactions.earnings !== undefined ? transactions.earnings : initialTransactionValues.earnings}</span></div>
          <p className="title green">Total Earnings</p>
        </div>
      </div>
      <div className="card-price card-price-v2 d-flex mb-3 flex-wrap">
        <div className="detail mt-1">
          <div className="price">${transactions && transactions.spendings !== undefined ? transactions.spendings : initialTransactionValues.spendings}</div>
          <p className="title red">Total Spendings</p>
        </div>
      </div>
      <div className="card-price card-price-v2 d-flex mb-3 flex-wrap">
        <div className="detail mt-1">
          <div className="price">${transactions && transactions.ready_for_transfer !== undefined ? transactions.ready_for_transfer : initialTransactionValues.ready_for_transfer}</div>
          <p className="title green">Ready For Transfer</p>
        </div>
      </div>
      <div className="card-price card-price-v2 d-flex mb-3 flex-wrap">
        <div className="detail mt-1">
          <div className="price">${transactions && transactions.bucks !== undefined ? transactions.bucks : initialTransactionValues.bucks}</div>
          <p className="title orange">iHunt Bucks</p>
        </div>
      </div>
      <div className="card-price card-price-v2 d-flex mb-3 flex-wrap">
        <div className="detail mt-1">
          <div className="price">${transactions && transactions.transferred !== undefined ? transactions.transferred : initialTransactionValues.transferred}</div>
          <p className="title red">Transfer To Stripe</p>
        </div>
      </div>
    </div>
  );
}
