import React, { useEffect } from 'react';
import facebookIcon from "../../assets/icons/facebook.png";
import logo from "../../assets/icons/logo.svg";
import youTubeIcon from "../../assets/icons/youtube.png";
import "./banner.scss";
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { fetchFacebookLink } from 'modules/landingPage/_redux/landingPageActions';
import { getIhuntSettings } from 'modules/dashboard/_redux/reservations/reservationActions';


export default function Footer2() {

    const dispatch = useDispatch();

    const { facebookLink, youtubeLink } = useSelector(
        (state) => ({
            facebookLink: state.landingPage.facebookLink,
            youtubeLink: state.reservation.ihuntSettings?.value
        }),
        shallowEqual
    );

    useEffect(() => {
        dispatch(fetchFacebookLink())
        //to get the youtube link
        dispatch(getIhuntSettings({ key: "Youtube Link" }))
    }, [])

    return (
        <div className='footer-2'>
            <div className='container'>
                <div className='row'>
                    <div className='col'>
                        <div className='footer-top'>
                            <ul>
                                <li><a>Discover.</a></li>
                                <li><a>Connect.</a></li>
                                <li><a>Hunt.</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container-fluid px-0'>
                <div className='row mx-auto'>
                    <div className='col px-0'>
                        <div className='footer-middle'>
                            <ul>
                                <li><a href={facebookLink} target="_blank" className="icon-left">
                                    <img src={facebookIcon} alt='' />
                                </a></li>
                                <li><a className="icon-middle" >
                                    <img src={logo} alt='' />
                                </a></li>
                                <li><a href={youtubeLink} className="icon-right" target="_blank">
                                    <img src={youTubeIcon} alt='' />
                                </a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col'>
                        <div className='footer-top second'>
                            <ul>
                                <li>
                                    <Link to="/privacy">PRIVACY</Link>
                                </li>
                                <li>
                                    <Link to="/terms">TERMS</Link>
                                </li>
                                <li>
                                    <Link to="/Tutorials">TUTORIALS</Link>
                                </li>
                                <li>
                                    <Link to="/faqs">FAQS</Link>
                                </li>
                                <li>
                                    <Link to="/about">ABOUT</Link>
                                </li>
                                <li>
                                    <Link to="/contact">CONTACT US</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='copy-right'>
                            <p>© COPYRIGHT {new Date().getFullYear()}. iHunt LLC. RIGHTS RESERVED</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}
