import React, { useEffect } from "react";
import ChatUi from "modules/landingPage/pages/ChatUi";
import { useSelector, useDispatch } from "react-redux";
import {
  getChatThread,
  setActiveThread,
  me,
} from "../../_redux/chat/chatActions";
import { useHistory } from "react-router-dom";

export default function Index({ id, name, user, showInput, showMessage }) {
  const chatState = useSelector((state) => state.chat);
  const notificationsCount = useSelector(state => state?.landingPage?.notificationsCount)

  const history = useHistory()

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(me());
    dispatch(getChatThread(id, user?.id));

    if (Object.keys(chatState.activeThread).length == 0) {
      dispatch(
        setActiveThread({
          id,
          name,
        })
      );
    }

    return () => {
      dispatch(setActiveThread({}));
    };
  }, []);

  useEffect(() => {
    const chatId = history.location.pathname.split("/")[3];

    dispatch(me());
    dispatch(getChatThread(chatId, user?.id));

    if (Object.keys(chatState.activeThread).length == 0) {
      dispatch(
        setActiveThread({
          id,
          name,
        })
      );
    }
  }, [history.location.pathname, notificationsCount]);

  useEffect(() => {
    dispatch(me());
    dispatch(getChatThread(id, user?.id));

    if (Object.keys(chatState.activeThread).length == 0) {
      dispatch(
        setActiveThread({
          id,
          name,
        })
      );
    }

  }, [history.location.pathname, notificationsCount])

  const canShowInput = () => {
    if (chatState?.ResponseCode !== 200) {
      return false
    } else if (showInput)
      return true
    else return false;
  }

  return (
    <div>
      {
        <ChatUi
          thread_id={id}
          thread={chatState.chatThread}
          user={{ ...chatState.activeThread, ...user }}
          optionalName={name}
          showInput={canShowInput()}
          showMessage={showMessage || false}
        />
      }
    </div>
  );
}
