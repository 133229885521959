import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Calendar from "react-calendar";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import "./style.scss";
import { FloatingLabel, Form } from "react-bootstrap";
import CalendarIcon from "assets/icons/calendar.svg";
import EyeOffIcon from "assets/icons/feather-icon - eye-off.svg";
import EyeOnIcon from "assets/icons/feather-eye.svg";


const AccountInfo = ({
  formik,
  children,
  setIsPhonValid,
  setIsSecandryPhonValid,
  ValidatePhonNumber,
  error
 }) => {

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const maxDate = new Date();

  const [showCalendar, setShowCalendar] = useState(false);

  const calendarRef = useRef();

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    document.addEventListener("click", manageOutsideClick);
    return () => {
      document.removeEventListener("click", manageOutsideClick);
    }
  }, []);

  const manageOutsideClick = (event) => {
    if (!(calendarRef && calendarRef.current && calendarRef.current.contains(event.target)) && !isMonthOrYearClicked(event.target)) {
      setShowCalendar(false)
    }
  }

  const isMonthOrYearClicked = (target) => {
    if (target.classList.contains("react-calendar__tile") ||
    target.classList.contains("react-calendar__year-view__months__month") ||
    target.classList.contains("react-calendar__decade-view__years__year") ||
    target.classList.contains("react-calendar__century-view__decades__decade")) 
    {
      return true;
    }
    if (target.tagName === "ABBR" && target.getAttribute("aria-label")) {
      return true;
    }
    return false;
  };

  return <div className="form-box">
    {error && !error.includes("email") && (
      <Form.Control.Feedback type="d-block invalid">
        {error}
      </Form.Control.Feedback>
    )}
    <Form onSubmit={formik.handleSubmit}>
      <FloatingLabel controlId="floatingInputGrid" label="First Name">
        <Form.Control
          type="text"
          name="first_name"
          placeholder="First Name"
          onChange={formik.handleChange}
          value={formik.values.first_name}
        />
      </FloatingLabel>
      {formik?.touched?.first_name && formik?.errors?.first_name && (
        <Form.Control.Feedback type="d-block invalid">
          {formik.errors.first_name}
        </Form.Control.Feedback>
      )}
      <FloatingLabel controlId="floatingInputGrid" label="Last Name">
        <Form.Control
          type="text"
          name="last_name"
          placeholder="Last Name"
          onChange={formik.handleChange}
          value={formik.values.last_name}
        />
      </FloatingLabel>

      {formik?.touched?.last_name && formik?.errors?.last_name && (
        <Form.Control.Feedback type="d-block invalid">
          {formik.errors.last_name}
        </Form.Control.Feedback>
      )}
      <FloatingLabel controlId="floatingInputGrid" label="Email">
        <Form.Control
          type="email"
          name="email"
          placeholder="Email"
          onChange={formik.handleChange}
          value={formik.values.email}
        />
      </FloatingLabel>

      {(formik?.touched?.email && formik?.errors?.email) ||
        error.includes("email") ? (
        <Form.Control.Feedback type="d-block invalid">
          {formik.errors.email || error}
        </Form.Control.Feedback>
      ) : null}
      <div className="position-relative">
        <PhoneInput
          copyNumbersOnly={false}
          value={formik.values.phone}
          onChange={(value, country) => {
            formik.setFieldValue("phone", value);
            formik.setFieldValue("country_code", country.countryCode);
            formik.setFieldValue("country_dial_code", country.dialCode);
          }}
          containerClass="phone-input-container mb-3 d-flex"
          inputClass="intl-tel-input phone-input"
          isValid={(inputNumber, country) => {
            if (country) {
              setIsPhonValid(ValidatePhonNumber(inputNumber, country))
              return ValidatePhonNumber(inputNumber, country)
            } else {
              setIsPhonValid(false);
              return false
            }
          }}
        />
      </div>
      {formik?.touched?.phone && formik?.errors?.phone && (
        <Form.Control.Feedback type="d-block invalid">
          {formik.errors.phone}
        </Form.Control.Feedback>
      )}
      {/* secondary phone */}
      <div className="position-relative">
        <PhoneInput
          copyNumbersOnly={false}
          value={formik.values.secondary_phone}
          onChange={(e, country) => {
            if (e.length > 0) {
              formik.setFieldValue("isSecandryPhon", true)
            } else {
              formik.setFieldValue("isSecandryPhon", false)
            }
            formik.setFieldValue("secondary_phone", e);
            formik.setFieldValue(
              "secondary_country_code",
              country.countryCode
            );
            formik.setFieldValue("secondary_dial_code", country.dialCode);
          }}
          containerClass="phone-input-container mb-3 d-flex"
          inputClass="intl-tel-input secondary-phone-input"
          isValid={(inputNumber, country) => {
            if (country) {
              setIsSecandryPhonValid(ValidatePhonNumber(inputNumber, country))
              return ValidatePhonNumber(inputNumber, country)
            } else {
              setIsSecandryPhonValid(false);
              return false
            }
          }}
        />
        {formik?.touched?.secondary_phone &&
          formik?.errors?.secondary_phone && (
            <Form.Control.Feedback type="d-block invalid">
              {formik.errors.secondary_phone}
            </Form.Control.Feedback>
          )}
      </div>

      <FloatingLabel controlId="floatingInputGrid" label="Date of Birth"
        ref={calendarRef}
      >
        <Form.Control
          type="text"
          name="birthday"
          placeholder="Date of birth"
          onChange={formik.handleChange}
          className="cursor-pointer"
          value={formik.values.birthday}
          onClick={() => setShowCalendar(!showCalendar)}
          readOnly={true}
        />
        <div
          className="input-icon"
          onClick={() => setShowCalendar(!showCalendar)}
        >
          <img src={CalendarIcon} alt="" />
        </div>
        <div>
          <Calendar
            calendarType={"ISO 8601"}
            defaultValue={
              new Date(maxDate.setFullYear(maxDate.getFullYear() - 18))
            }
            className={showCalendar ? "" : "hide"}
            onChange={(value) => {
              formik.setFieldValue(
                "birthday",
                moment(value).format("MMM DD YYYY")
              );
              setShowCalendar(false);
            }}
            tileDisabled={(e) => {
              return e.date > new Date()
            }}
          />
        </div>
      </FloatingLabel>
      {formik?.touched?.birthday && formik?.errors?.birthday && (
        <Form.Control.Feedback type="d-block invalid">
          {formik.errors.birthday}
        </Form.Control.Feedback>
      )}
      <FloatingLabel controlId="floatingInputGrid" label="Password">
        <Form.Control
          type={showPassword ? "text" : "password"}
          name="password"
          placeholder="Password"
          onChange={formik.handleChange}
          value={formik.values.password}
        />
        <div
          className="input-icon"
          onClick={() => {
            setShowPassword(!showPassword);
          }}
        >
          <img src={showPassword ? EyeOnIcon : EyeOffIcon} alt="" />
        </div>
      </FloatingLabel>
      {formik?.touched?.password && formik?.errors?.password && (
        <Form.Control.Feedback type="d-block invalid">
          {formik.errors.password}
        </Form.Control.Feedback>
      )}
      <FloatingLabel
        controlId="floatingInputGrid"
        label="Confirm Password"
      >
        <Form.Control
          type={showConfirmPassword ? "text" : "password"}
          name="password_confirmation"
          placeholder="Confirm Password"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.password_confirmation}
        />
        <div
          className="input-icon"
          onClick={() => {
            setShowConfirmPassword(!showConfirmPassword);
          }}
        >
          <img
            src={showConfirmPassword ? EyeOnIcon : EyeOffIcon}
            alt=""
          />
        </div>
      </FloatingLabel>
      {formik?.touched?.password_confirmation &&
        formik?.errors?.password_confirmation && (
          <Form.Control.Feedback type="d-block invalid">
            {formik.errors.password_confirmation}
          </Form.Control.Feedback>
      )}
      {children}
    </Form>
  </div>;
};

export default AccountInfo;
