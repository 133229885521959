import React from "react";
import { Modal } from "react-bootstrap";
import "./style.scss";
import Andriod from "../../../../../../../assets/images/andriod.png";
import Apple from "../../../../../../../assets/images/apple.png";
import Mobile from "../../../../../../../assets/images/HM.png";
export function EditStandModal({ show, onHide }) {
  return (
    <Modal
      show={show}
      onHide={() => onHide(false)}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter "
      centered
      className="edit-stand-div"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="edit-stand-popup position-relative">
          <div className="row">
            <div className="col-lg-7 text-section">
              <h2 className="text-secondary mb-4">Download Our Mobile App!</h2>
              <p className="text-tertiary fs-18">
                Join thousands of learners who have got benchmark results.
                Download our App available at Apple Store and Google Play.
              </p>
              <a
                target="_blank"
                href="https://apps.apple.com/us/app/i-hunt/id1596269965"
              >
                <img className="mt-4" src={Apple} alt="" />
              </a>
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.tso.ihunt"
              >
                <img className="mt-4" src={Andriod} alt="" />
              </a>
            </div>
            <div className="col-lg-3 img-section">
              <div>
                <img src={Mobile} alt="" />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
