import React, { useEffect, useRef, useState, useMemo } from "react";
import Form from 'react-bootstrap/Form';
import "./style.scss";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import TransferAmountModal from "./transfer-amount-modal/TransferAmountModal";
import PaymentInformationModal from "./payment-information-modal/PaymentInformationModal";
import TransactionHistoryTable from "./transaction-history-table/TransactionHistoryTable";
import AmountStatsGraph from "./amount-stats-graph/AmountStatsGraph";
import SettingsIconG from "assets/icons/feather-setting-g.png";
import AmountInfo from "./amount-info/AmountInfo";
import Select from "react-select";
import Calendar from "react-calendar";
import SearchIcon from "assets/icons/search.svg";
import moment from "moment";
import * as actions from "../../_redux/dashboard/dashboardActions";
import { useDashboardUIContext } from "../DashboardUIContext";
import { useLocation, useHistory } from "react-router-dom";
import baseInstance from "services/axiosInstance";
import { loadUser } from "modules/auth/_redux/authActions";
import toastHandler from "components/UI/toastHandler/toastHandler";
import LoadingOverlay from "react-loading-overlay";


export default function Payments() {

  const [showTransferAmountModal, setShowTransferAmountModal] = useState(false);

  const [showPaymentInformationModal, setShowPaymentInformationModal] = useState(false);

  const [showDateRangeInput, setShowDateRangeInput] = useState(false);

  const [showSearchField, setShowSearchField] = useState(false);

  const [showCalendar, setShowCalendar] = useState(false);

  const [rangeToDisplay, setRangeToDisplay] = useState("");

  const [range, setRange] = useState(["", ""]);

  const [selectedOptions, setSelectedOptions] = useState([]);

  const [selectedDateRadio, setSelectedDateRadio] = useState(null);

  const [selectedStandRadio, setSelectedStandRadio] = useState(null);

  const [activeTab, setActiveTab] = useState("");

  const [showRadioButtons, setShowRadioButtons] = useState(false);

  const [showClearFilterButton, setShowClearFilterButton] = useState(false);

  const [searchText, setSearchText] = useState("");
  
  const [options, setOptions] = useState([]);

  const [amountInfoMail, setAmountInfoMail] = useState("");
  
  const [isLoading, setIsLoading] = useState(false);

  const [fromTab, setFromTab] = useState("");

  const [searchLoading, setSearchLoading] = useState(false);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const calendarRef = useRef();

  const dispatch = useDispatch();

  const location = useLocation();

  const history = useHistory();

  const dashboardPageUIContext = useDashboardUIContext();
  const dashboardPageUIProps = useMemo(() => {
    return {
      queryParams: dashboardPageUIContext.transactionsListQueryParams,
      setQueryParams: dashboardPageUIContext.setTransactionListQueryParams,
    };
  }, [dashboardPageUIContext]);

  const { currentUser, filteredStands, transactionsList, totalRecords, listLoading, transactionTab } = useSelector(
    (state) => ({
      currentUser: state?.auth?.user,
      filteredStands: state?.dashboard?.filteredStands,
      transactionsList: state.dashboard.transactionHistoryList,
      totalRecords: state.dashboard.totalTransactionRecords,
      listLoading: state.dashboard.listLoading,
      transactionTab: state.dashboard.transactionTab,
    }),
    shallowEqual
  );

  const storedFilters = localStorage.getItem("paymentFilters");

  useEffect(() => {
    if (!storedFilters) {
      dispatch(actions.changeTransactionTab("allDates"));
      setSelectedDateRadio(null);
      setSelectedStandRadio(null);
    }
    const queryParams = { ...dashboardPageUIProps.queryParams };
    queryParams.page = 1;
    delete queryParams.all_stands;
    dashboardPageUIProps.setQueryParams(queryParams);
  }, []);

  useEffect(() => {
    let additionalParams = { };
  
    if (selectedDateRadio === "allDates" && selectedStandRadio === "allStands") {
      additionalParams = { ...dashboardPageUIProps.queryParams, all_dates: 1, all_stands: 1 };
    } else if (selectedDateRadio === "allDates") {
      if (fromTab === "allDates") {
        additionalParams = { ...dashboardPageUIProps.queryParams, all_dates: 1 };
        additionalParams.page = 1;
        delete additionalParams.all_stands;
        dashboardPageUIProps.setQueryParams(additionalParams);
        setFromTab("");
      } else {
        if (selectedStandRadio !== "allStands") {
          additionalParams = { ...dashboardPageUIProps.queryParams, all_dates: 1 };
          delete additionalParams.all_stands;
        } else {
          additionalParams = { ...dashboardPageUIProps.queryParams, all_dates: 1 };
        }
      }
    } else if (selectedStandRadio === "allStands") {
      if (fromTab === "allStands") {
        additionalParams = { ...dashboardPageUIProps.queryParams, all_stands: 1 };
        additionalParams.page = 1;
        delete additionalParams.all_dates;
        dashboardPageUIProps.setQueryParams(additionalParams);
        setFromTab("");
      } else {
        if (selectedDateRadio !== "allDates") {
          additionalParams = { ...dashboardPageUIProps.queryParams, all_stands: 1 };
          delete additionalParams.all_dates;
        } else {
          additionalParams = { ...dashboardPageUIProps.queryParams, all_stands: 1 };
        }
      }
    }

    if (((selectedDateRadio === "selectDates" && !range[0] && !range[1]) && !selectedStandRadio) || ((selectedStandRadio === "selectStands" && selectedOptions.length < 1) && !selectedDateRadio) || (selectedDateRadio === "selectDates" && selectedStandRadio === "selectStands" && (!range[0] && !range[1] && selectedOptions.length < 1))) {
      return;
    }
  
    if (range[0] && range[1]) {
      const startDate = moment(range[0]).format("MMM DD YYYY");
      const endDate = moment(range[1]).format("MMM DD YYYY");
      additionalParams = {
        ...additionalParams,
        ...dashboardPageUIProps.queryParams,
        start_date: startDate,
        end_date: endDate,
      };
      setRangeToDisplay(`${startDate} - ${endDate}`);
      delete additionalParams.all_dates;
      if (selectedStandRadio !== "allStands") {
        delete additionalParams.all_stands;
      }
    }
    if (selectedOptions.length > 0) {
      const selectedValues = selectedOptions.map(option => option.value);
  
      const standIdObject = {};
      selectedValues.forEach((value, index) => {
        standIdObject[`stand_id[${index}]`] = value;
      });
      additionalParams = {
        ...additionalParams,
        ...dashboardPageUIProps.queryParams,
        ...standIdObject
      };
      delete additionalParams.all_stands;
      if (selectedDateRadio !== "allDates") {
        delete additionalParams.all_dates;
      }
    }

    if (Object.keys(additionalParams).length > 0) {
      dispatch(actions.getTransactions(additionalParams));
    }
  }, [selectedDateRadio, selectedStandRadio, range, selectedOptions, dashboardPageUIProps.queryParams]);

  useEffect(() => {
    if ((range[0] && range[1]) || selectedOptions.length > 0) {
      setShowClearFilterButton(true);
    } else {
      setShowClearFilterButton(false);
    }
  }, [selectedOptions, range]);

  const handleTabSelect = (tab) => {
    dispatch(actions.changeTransactionTab(tab));
    handleShowRadioButtons(tab);
    setFromTab(tab);
    localStorage.removeItem("paymentFilters");
  };

  const handleShowRadioButtons = (tab) => {
    if (tab === "allDates") {
      setSelectedDateRadio("allDates");
      setSelectedStandRadio("");
      setActiveTab("allDates");
      setShowDateRangeInput(false);
      setShowSearchField(false);
      setShowClearFilterButton(false);
      setRangeToDisplay("");
      setRange(["", ""]);
      setSelectedOptions([]);
    } else if (tab === "allStands") {
      setSelectedStandRadio("allStands");
      setSelectedDateRadio("");
      setActiveTab("allStands");
      setShowSearchField(false);
      setShowDateRangeInput(false);
      setShowClearFilterButton(false);
      setRangeToDisplay("");
      setRange(["", ""]);
      setSelectedOptions([]);
    }
  };

  const handleRadioSelect = (radio) => {
    if (radio === "allDates" && selectedStandRadio !== "allStands") {
      if (selectedOptions.length > 0) {
        setShowClearFilterButton(true);
      } else {
        setShowClearFilterButton(false);
      }
      setSelectedDateRadio("allDates");
      setShowDateRangeInput(false);
      setActiveTab("");
      setRange(["", ""]);
      setRangeToDisplay("");
    } else if (radio === "allDates" && selectedStandRadio === "allStands") {
      setSelectedDateRadio("allDates");
      setSelectedStandRadio("allStands");
      setShowDateRangeInput(false);
      setShowClearFilterButton(false);
      setActiveTab("");
      setRange(["", ""]);
      setRangeToDisplay("");
    } else if (radio === "selectDates" && selectedStandRadio !== "allStands") {
      setSelectedDateRadio("selectDates");
      setShowDateRangeInput(true);
      setActiveTab("");
    } else if (radio === "selectDates" && selectedStandRadio === "allStands") {
      setSelectedDateRadio("selectDates");
      setShowDateRangeInput(true);
      setActiveTab("");
    } else if (radio === "allStands" && selectedDateRadio !== "allDates") {
      if (range[0] && range[1]) {
        setShowClearFilterButton(true);
      } else {
        setShowClearFilterButton(false);
      }
      setSelectedStandRadio("allStands");
      setShowSearchField(false);
      setActiveTab("");
      setSelectedOptions([]);
    } else if (radio === "allStands" && selectedDateRadio === "allDates") {
      setSelectedStandRadio("allStands");
      setSelectedDateRadio("allDates");
      setShowDateRangeInput(false);
      setShowSearchField(false);
      setShowClearFilterButton(false);
      setActiveTab("");
      setSelectedOptions([]);
    } else if (radio === "selectStands" && selectedDateRadio !== "allDates") {
      setSelectedStandRadio("selectStands");
      setShowSearchField(true);
      setActiveTab("");
    } else if (radio === "selectStands" && selectedDateRadio === "allDates") {
      setSelectedStandRadio("selectStands");
      setShowSearchField(true);
      setActiveTab("");
    }
  };

  useEffect(() => {
    if (storedFilters) {
      const { dateRadio, standRadio, dateRange, selectedStandOptions } = JSON.parse(storedFilters);
      setSelectedDateRadio(dateRadio);
      setSelectedStandRadio(standRadio);
      setRange(dateRange);
      setSelectedOptions(selectedStandOptions);
      setShowClearFilterButton(true);
    } else {
      setActiveTab(transactionTab);
      if (transactionTab === "allDates") {
        setSelectedDateRadio("allDates");
      }
    }
  }, [transactionTab]);

  const clearFilters = () => {
    localStorage.removeItem("paymentFilters");
    setSelectedDateRadio(null);
    setSelectedStandRadio(null);
    setShowDateRangeInput(false);
    setShowSearchField(false);
    setShowCalendar(false);
    setShowRadioButtons(false);
    setShowClearFilterButton(false);
    setRangeToDisplay("");
    setRange(["", ""]);
    setSelectedOptions([]);
    dispatch(actions.changeTransactionTab("allDates"));
    setActiveTab(transactionTab);
    if (transactionTab === "allDates") {
      setSelectedDateRadio("allDates");
    }
  };

  const handleCloseTransferAmountModal = () =>
    setShowTransferAmountModal(false);

  const handleClosePaymentInformationModal = () =>
    setShowPaymentInformationModal(false);

  const handleShowTransferAmountModal = () => {
    if (currentUser?.payment_info !== null && currentUser?.payment_info?.email !== null) {
      setShowTransferAmountModal(true);
    } else {
      setShowPaymentInformationModal(true);
    }
  };

  const manageOutsideClick = (event) => {
    if (!(calendarRef && calendarRef.current && calendarRef.current.contains(event.target))) {
      setShowCalendar(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", manageOutsideClick);
    return () => {
      document.removeEventListener("click", manageOutsideClick);
    }
  }, []);

  useEffect(() => {
    let timeout = "";
    if (searchText) {
      setSearchLoading(true);
      timeout = setTimeout(() => {
        dispatch(actions.searchUserStand(searchText)).then(() => {
          setSearchLoading(false);
        });
      }, [800]);
    }
    return () => clearTimeout(timeout);
  }, [searchText]);

  useEffect(() => {
    if (searchText.trim() === "") {
      setIsMenuOpen(false);
    } else {
      setIsMenuOpen(true);
    }
  }, [searchText]);

  useEffect(() => {
    const usersStands = filteredStands.map((item) => {
      return { id: item?.id, title: `${item?.title}`, standpic: item?.image, address: item?.address };
    });
    setOptions(usersStands);
  }, [filteredStands]);

  useEffect(() => {
    if ((selectedDateRadio === "selectDates" && range[0] && range[1]) || (selectedStandRadio === "selectStands" && selectedOptions.length > 0)) {
      localStorage.setItem("paymentFilters", JSON.stringify({
        standRadio: selectedStandRadio,
        dateRadio: selectedDateRadio,
        dateRange: range,
        selectedStandOptions: selectedOptions
      }));
    }
  }, [selectedDateRadio, selectedStandRadio, range, selectedOptions]);
  
  const standOptions = options.map((stand) => ({
    value: stand.id,
    label: stand.title,
    image: stand.standpic,
    address: stand.address
  }));

  const formatOptionLabel = ({ value, label, address }, { context }) => {
    const stand = options.find((stand) => stand.id === value);
    const selected = selectedOptions.some(selectedOption => selectedOption.value === value);
    const handleClick = () => {
      if (selected) {
        const optionToRemove = selectedOptions.find(option => option.value === value);
        handleRemoveSelectedValue(optionToRemove);
      }
    };
    return (
      <div className={`${context === "menu" ? 'options-custom' : 'options-custom border-0'} ${selected ? 'selected' : ''}`} onClick={handleClick}>
        
          {context === "menu" ? (
            <img
              src={stand?.standpic}
              alt={value}
            />
          ) : null}
        <div className="id-stand-address">
          <p className="id-stand mb-0">ID:<span className="id ps-1">{value}</span><span className="px-2">|</span><span className="stand-name">{label}</span></p>
          <p className="address mb-0">{address}</p>
        </div>
        
      </div>
    );
  };

  const renderSelectedValues = () => {
    if (selectedOptions.length > 0) {
      return selectedOptions.map((option) => (
        <div key={option.value} className="options-custom-values">
          <img src={option.image} alt="stand pic" />
          <div className="id-stand-address">
            <p className="id-stand mb-0"><span className="id"><span className="pe-1">ID:</span>{option.value}</span><span className="px-2">|</span><span className="stand-name">{option.label}</span></p>
            <p className="address mb-0">{option.address}</p>
          </div>
          <i className="far fa-times" 
          style={{cursor: "pointer", height: "10px", width: "10px", lineHeight: "10px", position: "absolute", top: "10px", right: "10px"}} 
          onClick={() => {
            handleRemoveSelectedValue(option);
            {!range[0] && !range[1] && setShowClearFilterButton(false)}
          }}></i>
        </div>
      ));
    }
  };

  const handleRemoveSelectedValue = (valueToRemove) => {
    setSelectedOptions(selectedOptions.filter(option => option.value !== valueToRemove.value));
    localStorage.removeItem("paymentFilters");
  };

  useEffect(async () => {
    let urlParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(urlParams.entries());
    if (params.code) {
      setIsLoading(true);
      let retrievedObject = localStorage.getItem("testObject");
      let parsedObject = JSON.parse(retrievedObject);
      delete parsedObject.country_dial_code;
      let response = await baseInstance.post("user-payment-info", {
        ...parsedObject,
        code: params.code,
        business_type: "Individual",
      });
      if (response.status) {
        toastHandler(response.message, "success");
        await dispatch(loadUser());
        history.push("/my-profile/payments");
        if (response?.data?.email) {
          setAmountInfoMail(response?.data?.email);
          setShowTransferAmountModal(true);
          setIsLoading(false);
        } else {
          setShowPaymentInformationModal(true);
        }
      } else toastHandler(response.message, "error");
    }
  }, [location.search]);

  return (
    <LoadingOverlay active={isLoading} spinner>
      <div className="payments">
        <div className="payments-inner">
          <div className="d-flex justify-content-between align-items-center">
            <h3 className="fs-6 fw-bolder text-seventh font-roboto">
              Payment Information
            </h3>
            <div className="transfer-to-strip-btn" onClick={handleShowTransferAmountModal}>
              <img
                className="ms-3 cursor-pointer"
                src={SettingsIconG}
                alt="settings"
              />
              <button
                className="btn btn-primary text-white"
              >
                TRANSFER TO STRIPE
              </button>
            </div>
          </div>
          <div className="my-land mt-2">
            <div className="tabs payment-tabs-v2 mr-110">
              <div className="tab-btn w-100">
                <div className="nav nav-tabs">

                <div className="tab-btns">
                  <a
                    className={`${activeTab === "allDates" ? 'tab-items underline-view' : 'tab-items simple-view'}`}
                    onClick={() => handleTabSelect("allDates")}
                  >
                    All Dates
                  </a>
                  <a
                    className={`${activeTab === "allStands" ? 'tab-items underline-view' : 'tab-items simple-view'}`}
                    onClick={() => handleTabSelect("allStands")}
                  >
                    All Listing
                  </a>
                </div>
                <div className="filter-new-btns">
                  {showClearFilterButton && 
                    <button type="button" className="clear-filter" onClick={clearFilters}>Clear Filter</button>
                  }
                  <button
                    className="btn btn-primary text-white"
                    onClick={() => setShowRadioButtons(!showRadioButtons)}
                  >
                    Filter
                  </button>
                </div>
                </div>
              </div>
              {showRadioButtons && <Form>
                <div className="payments-radios-main">
                  <div className="left">
                    <span className="main-label">select Date</span>
                    <div className="form-check-main" style={{ backgroundColor: ((activeTab === "allDates" || selectedDateRadio === "allDates")) ? "#F5F5F5" : "transparent" }} onClick={() => handleRadioSelect("allDates")}>
                      <Form.Check
                        label="All Dates"
                        name="dateOption"
                        type="radio"
                        id="allDates"
                        checked={((activeTab === "allDates" || selectedDateRadio === "allDates"))}
                        onChange={() => handleRadioSelect("allDates")}
                      />
                    </div>
                    <div className="form-check-main" style={{ backgroundColor: selectedDateRadio === "selectDates" ? "#F5F5F5" : "transparent" }} onClick={() => handleRadioSelect("selectDates")}>
                      <Form.Check
                        label="Select Dates"
                        name="dateOption"
                        type="radio"
                        id="selectDates"
                        checked={selectedDateRadio === "selectDates"}
                        onChange={() => handleRadioSelect("selectDates")}
                      />                        
                      <div ref={calendarRef} className="mt-2">
                        <div style={{ position: "relative" }}>
                          <div className="custon-dateRange">
                            <i className="far fa-calendar" onClick={() => {
                              if (range[0] && range[1]) {
                                setRange([
                                  moment(range[0])._d,
                                  moment(range[1])._d,
                                ])
                              }
                              setShowCalendar(!showCalendar)
                            }}></i>
                            <input
                              type="text"
                              placeholder="Select any date"
                              className="form-control"
                              value={rangeToDisplay}
                              onClick={() => {
                                if (range[0] && range[1]) {
                                  setRange([
                                    moment(range[0])._d,
                                    moment(range[1])._d,
                                  ])
                                }
                                setShowCalendar(!showCalendar)
                              }}
                            />
                          </div>
                          <div>
                            <Calendar
                              calendarType={"ISO 8601"}
                              className={showCalendar ? "" : "hide"}
                              value={range}
                              onChange={(values) => {
                                const start_date = values[0];
                                const end_date = values[1] ? values[1] : start_date;
                                setRange([start_date, end_date]);
                                setShowClearFilterButton(true);
                              }}
                              selectRange={true}
                              allowPartialRange={true}
                              returnValue={"range"}
                              maxDate={new Date()}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="right">
                  <span className="main-label">select Listing</span>
                    <div className="form-check-main" style={{ backgroundColor: ((activeTab === "allStands" || selectedStandRadio === "allStands")) ? "#F5F5F5" : "transparent" }} onClick={() => handleRadioSelect("allStands")}>
                      <Form.Check
                        label="All Listing"
                        name="standOption"
                        type="radio"
                        id="allStands"
                        checked={((activeTab === "allStands" || selectedStandRadio === "allStands"))}
                        onChange={() => handleRadioSelect("allStands")}
                      />
                    </div>
                    <div className="form-check-main" style={{ backgroundColor: selectedStandRadio === "selectStands" ? "#F5F5F5" : "transparent" }} onClick={() => handleRadioSelect("selectStands")}>
                      <Form.Check
                        label="Search and Select Listing"
                        name="standOption"
                        type="radio"
                        id="selectStands"
                        checked={selectedStandRadio === "selectStands"}
                        onChange={() => handleRadioSelect("selectStands")}
                      />
                      <>
                        <Select
                          name="stand_id"
                          value={selectedOptions}
                          onInputChange={(value) => setSearchText(value)}
                          onChange={(selectedOption) => {
                            setSelectedOptions(selectedOption);
                            setShowClearFilterButton(true);
                          }}
                          options={searchLoading ? [] : standOptions}
                          formatOptionLabel={formatOptionLabel}
                          menuIsOpen={isMenuOpen}
                          closeMenuOnSelect={!showSearchField}
                          openMenuOnClick={false}
                          hideSelectedOptions={false}
                          placeholder="Search any listing"
                          isSearchable={true}
                          isClearable={true}
                          isMulti
                          controlShouldRenderValue={false}
                          isLoading={searchLoading}
                          noOptionsMessage={() => "No record found"}
                          theme={(theme) => ({
                            ...theme,
                            height: "48px !important",
                            borderRadius: "12px",
                            colors: {
                              ...theme.colors,
                              text: "orangered",
                              primary: "#EA9B45 !important",
                            },
                          })}
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              borderColor: state.isFocused ? "#EA9B45" : "#212529",
                              boxShadow: state.isFocused ? "0 0 0 1px #EA9B45" : "#212529",
                              cursor: "text",
                              height: "48px !important",
                              paddingLeft: '32px',
                              backgroundImage: `url(${SearchIcon})`,
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: '10px center',
                              backgroundSize: '20px 20px',
                              marginTop: "0.5rem"
                            }),
                            placeholder: (style) => ({
                              ...style,
                              fontSize: "14px !important",
                              lineHeight: "16px !important",
                              color: "#A8A8A8 !important",
                            }),
                            option: (style) => ({
                              ...style,
                              backgroundColor: "none",
                              cursor: "pointer",
                            }),
                            indicatorsContainer: () => ({
                              display: 'none !important',
                            }),
                            multiValue: () => ({
                              width: "auto",
                              float: "left",
                              display: "flex",
                              padding: "3px 0",
                              borderRadius: "7px",
                              margin: "5px",
                              height: "60px",
                              alignItems: "flex-start",
                              fontSize: "14px",
                              border: "1px solid #CBCBCB",
                            }),
                            multiValueRemove: (base) => ({
                              ...base,
                              borderRadius: "50%",
                              ":hover": {
                                color: "#212529",
                              },
                            }),
                            menuList: (base) => ({
                              ...base,
                              "&::-webkit-scrollbar": {
                                  width: "8px",
                                  height: "3px",
                                  borderRadius: "6px",
                                  backgroundColor: "#f5f5f5",
                              },
                              "&::-webkit-scrollbar-thumb": {
                                  backgroundColor: "#A9A9A9",
                                  borderRadius: "6px",
                              },
                            }),
                          }}
                        />
                        {selectedOptions.length > 0 && <div className="options-custom-values-box">
                          {renderSelectedValues()}
                        </div>
                        }
                      </>
                    </div>
                  </div>
                </div>
              </Form>}
              <div className="payment-card mt-4">
                <div className="row">
                  <div className="col-xxl-4 col-12 order-xxl-1 order-2 mt-xxl-0 mt-4">
                    <AmountInfo
                      transactions={transactionsList}
                    />
                  </div>
                  <div className="col-xxl-8 col-12 order-xxl-2 order-1 ">
                    <div className="chart border-0">
                      <AmountStatsGraph />
                    </div>
                  </div>
                </div>
              </div>
              <TransactionHistoryTable transactions={transactionsList} records={totalRecords} isLoading={listLoading} uiProps={dashboardPageUIProps} dateRadio={selectedDateRadio} standRadio={selectedStandRadio} dateRange={range} selectedStandOptions={selectedOptions} />
            </div>
          </div>
        </div>
      </div>
      <PaymentInformationModal
        show={showPaymentInformationModal}
        onHide={handleClosePaymentInformationModal}
      />
      <TransferAmountModal
        show={showTransferAmountModal}
        onHide={handleCloseTransferAmountModal}
        setShowPaymentInformationModal={setShowPaymentInformationModal}
        amountInfoMail={amountInfoMail}
      />
    </LoadingOverlay>
  );
}
