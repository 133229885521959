import React, { useEffect, useState } from "react";
import { ReactComponent as Percent } from "assets/icons/percent.svg";
import toastHandler from "components/UI/toastHandler/toastHandler";
import { useHistory } from "react-router";
import NoRecordFound from "utils/noRecordFound";
import moment from "moment";
import "./style.scss";
import CreateVoucherModal from "components/UI/modals/create-voucher-modal/CreateVoucherModal";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchStandVouchers } from "modules/landingPage/_redux/landingPageActions";


export default function Vouchers({ land }) {
  
  const [showCreateVoucherModal, setShowCreateVoucherModal] = useState(false);
  const history = useHistory();
  const Id = history.location.pathname;
  const standId = history.location.pathname.split("/")[2]
  const dispatch = useDispatch();

  const {
    voucherList,
    user,
    newRequest
  } = useSelector(
      (state) => ({
          voucherList: state.landingPage.voucherList,
          user: state.auth.user,
          newRequest: state.landingPage.newRequest
      }),
      shallowEqual
  );

  useEffect(() => {
    localStorage.getItem("token") && (land?.user?.id === (user && user?.id)) && dispatch(fetchStandVouchers(standId, true))
  }, [newRequest, dispatch, standId])

  const isDisabled = (voucher) => {
    if (voucher?.expiry_type == "expiry_date") {
      const today = moment().startOf('day');
      const expiryDate = moment(voucher.expiry_date).startOf('day');
      return expiryDate < today;
    } if (voucher?.expiry_type == "max_usage") {
      return voucher.max_usage <= 0
    }
  }

  return (
    <>
      <div className={`vouchers-tab-content ${voucherList.length === 0 ? 'no-vouchers' : ''}`}>
        {
          localStorage.getItem("token") && (land?.user?.id === (user && user?.id)) &&
          <button className="create-btn" onClick={() => setShowCreateVoucherModal(true)}>CREATE A VOUCHER</button>
        }
        <div className={`row voucher-tab-cards ${voucherList.length === 0 ? '' : 'voucher-boxes'} fade-in`}>
          {voucherList && voucherList.length > 0 ? (
            voucherList.map((voucher, index) => (
              <div className="col-xl-6 col-lg-6 col-xxl-4 mb-4" key={index}>
                <div className="overflow-hidden">
                  <div className="vchr-box">
                    <div className="vchr-top-content-holder">
                      <div className="upperdetails">
                        <Percent />
                        <div className="mt-neg-2">
                          <div className="mb-1 fs-18 fw-bold oneline-text">
                            {voucher.name}
                          </div>
                          <div className="fs-14">
                            <span className="text-tertiary me-1">EXP: </span>
                            <span>
                              {voucher?.expiry_type === "max_usage"
                                ? `${voucher.max_usage} users limit`
                                : voucher?.expiry_date}
                            </span>
                          </div>
                        </div>
                      </div>
                      {voucher.discount_type === "percentage" ? (
                        <div className="discount">{voucher.discount}% Off</div>
                      ) : (
                        <div className="discount">${voucher.discount} Off</div>
                      )}
                    </div>

                    <hr className="vchr-box-sep my-0" />
                    <div className="vchr-bottom ">
                      <p>
                        {voucher?.expiry_type === "max_usage" && (
                          <>
                            <span>Remaining:</span>{" "}
                            {voucher.max_usage - voucher.redeem_count}
                          </>
                        )}
                      </p>
                      <button
                        className="btn btn-secondary"
                        onClick={() => {
                          navigator.clipboard.writeText(voucher?.code);
                          toastHandler("Code Copied Successfully", "success", Id);
                        }}
                        style={isDisabled(voucher) ? {
                          pointerEvents: "none",
                          border: "1px solid #999999",
                          backgroundColor: "#cccccc",
                          color: "#666666"
                        } : null}
                      >
                        SHARE
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <NoRecordFound message="No voucher added yet" />
          )}
        </div>
      </div>
      <CreateVoucherModal
        show={showCreateVoucherModal}
        onHide={() => setShowCreateVoucherModal(false)}
        land={land}
      />
    </>
  );
}
