import { trophySlice, callTypes } from "./trophySlice";
import * as requestFromServer from "./trophyCRUD";

const { actions } = trophySlice;

export const getTrophies = (queryParams) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  try {
    const response = await requestFromServer.getTrophies(queryParams);
    if (response.status) {
      dispatch(
        actions.trophiesFetched({
          entities: response.data,
          totalRecords: response.total_records || 0,
        })
      );
    }

    if (response.data?.length === 0) {
      dispatch(actions.clearEntities());
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      dispatch(actions.clearEntities());
    }
    return 0;
  }
};
