import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const StandPostSkeleton = () => {
  return (
    <div className="col-lg-12 col-md-6 col-sm-12">
      <div className="carusal-item">
        <Skeleton height={300} />
      </div>
      <div className="course-details">
        <div className="detail-top-skeleton">
          <div className="row col-md-12">
            <h5>
              <Skeleton />
            </h5>
            <p>
              <Skeleton />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StandPostSkeleton;
