import { ReactComponent as Pin } from "assets/icons/pindark.svg";
import User from "assets/images/reservation-img.png";
import { ConfirmationModal } from "components/UI/modals/confirmation-modal/confirmationModal";
import toastHandler from "components/UI/toastHandler/toastHandler";
import {
  cancelReservation,
  toggleFundrequestStatus,
  revertFundRequest,
  getReservationDetail,
  getFundsNotification,
  handleHuntRequest,
  showRejectionReasonModal,
  showRejectionReason
} from "modules/dashboard/_redux/reservations/reservationActions";
import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import config from "config";
import ReservationReview from "./reservation-review/ReservationReview";
import UserCard from "modules/landingPage/pages/usercard";
import Calendar from "react-calendar";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Trophy from "./trophy/Trophy";
import NoRecordFound from "utils/noRecordFound";
import RequestFundsModal from "components/UI/modals/requestFundsModal";
import ConfirmModal from "components/UI/modals/confirmModal";
import "./style.scss";
import Spinner from "components/UI/Spinner.js";
import PaymentModal from "components/UI/modals/paymentModal";
import DeclinedStatusModal from "../../fundsNotifications/declinedStatusModal";
import PendingStatusModal from "../../fundsNotifications/pendingStatusModal";
import PaidStatusModal from "../../fundsNotifications/paidStatusModal";
import CancelStatusModal from "../../fundsNotifications/cancelStatusModal";
import CustomSpinner from "components/layout/Loader/Spinner";
import { Link } from "react-router-dom";
import { setAuthStatus } from "modules/landingPage/_redux/landingPageActions";
import HuntConfirmModal from "components/UI/modals/huntConfirmModal";


const promise = loadStripe(config.STRIPE_PUBLISH_KEY);

export default function ReservationDetails({ id, status }) {
  const [key, setKey] = useState("sent");
  const [showCalendar, setShowCalendar] = useState(false);
  const [amount, setAmount] = useState(null);
  const [showPaymentModal, setShowPaymentModal] = useState(false)
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [showPendingModal, setShowPendingModal] = useState(false);
  const [showPaidModal, setShowPaidModal] = useState(false);
  const [showCanceleStatusModal, setShowCanceleStatusModal] = useState(false);
  const [showDeclineReason, setShowDeclineReason] = useState("");
  const [paymentPayLoad, setPaymentPayLoad] = useState({});
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const reservedDateRangeCalanderRef = useRef();

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showCancelErrorModal, setShowCancelErrorModal] = useState(false);
  const [showRequestFundModal, setShowRequestFundModal] = useState(false);
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const [desc, setDesc] = useState("");
  const [type, setType] = useState("")
  const [reload, setReload] = useState(false);
  const [requestID, setrequestID] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isApprovalInitiated, setIsApprovalInitiated] = useState(false);
  const [reservationStatus, setReservationStatus] = useState("");

  const actionStatus = localStorage.getItem("status");

  const handleConfirmModalSubmit = (confirmed) => {
    if (confirmed) {
      dispatch(
        cancelReservation(
          id,
          onReservationCancelSuccess,
          onReservationCancelError,
          true
        )
      );
    } else {
      setShowCancelModal(false);
    }
  };

  const onReservationCancelSuccess = () => {
    setShowCancelModal(false);
    toastHandler("Reservation canceled successfully!", "success");
    history.push({
      pathname: "/my-profile/my-reservations",
      search: "?" + new URLSearchParams({ activeTab: 'history' }).toString(),
    });
  };

  const onReservationCancelError = () => {
    setShowCancelModal(false);
  };

  const handleBack = () => {
    history.goBack();
  };

  const openRequestFundModal = () => {
    setShowRequestFundModal(true)
  }

  const closeRequestFundModal = () => {
    setShowRequestFundModal(false)
  }

  const openDescModal = (desc, type, id, amount) => {
    setrequestID(id);
    setType(type);
    setDesc(desc);
    setShowDescriptionModal(true);
    if (amount) {
      localStorage.setItem("amount", amount)
      setAmount(amount)
    }
  }

  const handlePayment = (id, amount, type) => {
    setAmount(amount || paymentPayLoad?.amount);
    setrequestID(id || paymentPayLoad?.id);
    setType(type || paymentPayLoad?.type)
    localStorage.setItem("amount", amount || paymentPayLoad?.amount)
    const data = new FormData();
    data.append("status", "paid");
    dispatch(toggleFundrequestStatus(id || paymentPayLoad?.id, data, onSuccess))
  }

  const onSuccess = () => {
    dispatch(setAuthStatus(false));
    setShowPaymentModal(true);
  }

  const { reservationDetail,
    isLoading,
    listLoading,
    user,
    clientSecret,
    actionsLoading,
    fundNotification,
    clickedOnNotification,
    gettingReservationDetail,
    authStatus,
    rejectionReasonModal,
    rejectionReason
  } = useSelector(
    (state) => ({
      reservationDetail: state.reservation.reservationDetail,
      isLoading: state.reservation.actionsLoading,
      listLoading: state.reservation.listLoading,
      clientSecret: state.reservation.clientSecret,
      user: state.auth.user,
      actionsLoading: state.reservation.actionsLoading,
      fundNotification: state.reservation.fundNotification,
      clickedOnNotification: state.reservation.clickedOnNotification,
      gettingReservationDetail: state.reservation.gettingReservationDetail,
      authStatus: state.landingPage.authStatus,
      rejectionReasonModal: state.reservation.rejectionReasonModal,
      rejectionReason: state.reservation.rejectionReason
    }),
    shallowEqual
  );

  useEffect(() => {
    if (authStatus && !paymentSuccess) {
      handlePayment();
    }
  }, [authStatus]);

  const options = {
    // passing the client secret obtained in step 2
    clientSecret: clientSecret,
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
      theme: "stripe",
    },
  };

  useEffect(() => {
    if (history?.location?.search == "?show_calendar=true") {
      setShowCalendar(true)
    }
    document.addEventListener("click", manageOutsideClick);
    return () => {
      document.removeEventListener("click", manageOutsideClick);
    }
  }, [])

  useEffect(() => {
    if (!id) return;
    dispatch(getReservationDetail(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, reload, clickedOnNotification]);

  useEffect(() => {
    if (fundNotification) {
      if (fundNotification.status === "pending" && reservationDetail && !gettingReservationDetail) {
        setShowPendingModal(true);
        setPaymentSuccess(true);
      }
      if (fundNotification.status === "declined" && reservationDetail && !gettingReservationDetail) {
        setShowDeclineModal(true);
      }
      if (fundNotification.status === "paid" && reservationDetail && !gettingReservationDetail) {
        setShowPaidModal(true);
      }
      if (fundNotification.status === "cancelled" && reservationDetail && !gettingReservationDetail) {
        setShowCanceleStatusModal(true);
      }
    }
  }, [fundNotification, gettingReservationDetail])

  useEffect(() => {
    if (!reservationDetail) return;
    if (reservationDetail.status === "canceled") {
      setReservationStatus(reservationDetail.status);
    } else if (reservationDetail.status === "completed") {
      setReservationStatus(reservationDetail.status);
    } else if (
      moment().isBetween(
        reservationDetail.start_date,
        reservationDetail.end_date
      )
    ) {
      setReservationStatus("current");
    } else if (moment().isAfter(reservationDetail.end_date)) {
      setReservationStatus("past");
    } else if (moment().isBefore(reservationDetail.start_date)) {
      setReservationStatus("future");
    }
  }, [reservationDetail]);

  const getStatusClass = () => {
    return reservationDetail?.status === "canceled" || reservationDetail?.status === "rejected" ? "cancel-txt fw-medium" :
      reservationDetail?.status === "paid" || reservationDetail?.status === "completed" ? "completed-txt fw-medium" :
      reservationDetail?.status === "approved" ? "approved-txt fw-medium" :
        "pending-txt fw-medium"
  }

  const openCalander = () => {
    setShowCalendar(!showCalendar)
  }

  const manageOutsideClick = (event) => {
    if (!(reservedDateRangeCalanderRef && reservedDateRangeCalanderRef.current && reservedDateRangeCalanderRef?.current?.contains(event.target)) && !isMonthOrYearClicked(event.target)) {
      setShowCalendar(false)
    }
  }

  const isMonthOrYearClicked = (target) => {
    if (target.classList.contains("react-calendar__tile") ||
    target.classList.contains("react-calendar__year-view__months__month") ||
    target.classList.contains("react-calendar__decade-view__years__year") ||
    target.classList.contains("react-calendar__century-view__decades__decade")) 
    {
      return true;
    }
    if (target.tagName === "ABBR" && target.getAttribute("aria-label")) {
      return true;
    }
    return false;
  };

  const getStyle = (status) => {
    if (status === "paid" || status === "transferred") {
      return { color: "#27AB34", marginTop: "15px" }
    } else if (status === "declined") {
      return { color: "#D60000", marginTop: "15px", textDecoration: "underline", cursor: "pointer" }
    } else if (status === "cancelled") {
      return { color: "#D60000", marginTop: "15px" }
    }
    else if (status === "pending") {
      return { color: "#EA9B45", marginTop: "15px" }
    } else if (status === "processing") {
      return { color: "#0566C8", marginTop: "15px" }
    }
  }

  const isLogedInUser = () => {
    if (user && reservationDetail?.stand_detail?.user?.id == user?.id) {
      return true;
    } else return false
  }

  const getRefundAmountSum = () => {
    var sum = 0;
    reservationDetail?.refunds.map(item => {
      sum += item?.refund_amount
    })
    return sum;
  }

  const getDateRange = (startDate, endDate) => {
    let currentDate = moment(startDate);
    let dateRangeArray = [];
    while (currentDate <= moment(endDate)) {
      dateRangeArray.push(currentDate.format("DD-MM-YYYY"))
      currentDate = currentDate.add(1, "days")
    }
    return dateRangeArray;
  }

  var range = ["", ""]
  if (reservationDetail?.start_date || reservationDetail?.end_date && !showCalendar) {
    range = getDateRange(reservationDetail.start_date, reservationDetail.end_date)
  }

  const handleDeclinedStatusClick = async (id, status, reason) => {
    if (status === "declined") {
      await dispatch(getFundsNotification(id))
      setShowDeclineModal(true);
      setShowDeclineReason(reason);
    } else return
  }

  const approveHunt = () => {
    setLoading(true);
    setIsSubmitting(true);
    const data = new FormData();
    data.append("status", "approved");
    dispatch(handleHuntRequest(id, data, onApproveSuccess, onApproveError))
  }

  const onApproveSuccess = (message) => {
    if (window.location.pathname.includes(`my-profile/reservation-details/${id}`) && window.location.search.includes(`?approve=`)) {
      history.replace(`/my-profile/reservation-details/${id}`);
      setLoading(true);
      setIsSubmitting(true);
    }
    setLoading(false);
    setIsSubmitting(false);
    toastHandler(message, "success");
    dispatch(getReservationDetail(id));
  }
  const onApproveError = (message) => {
    setLoading(false);
    setIsSubmitting(false);
    toastHandler(message, "error");
  }

  useEffect(() => {
    if ((window.location.pathname.includes(`my-profile/reservation-details/${id}`) && window.location.search.includes(`?approve=`)) || (window.location.pathname.includes(`my-profile/reservation-details/${id}`) && actionStatus)) {
      if ((status === "true" || actionStatus === "true") && reservationDetail && reservationDetail?.can_perform_approval && !isApprovalInitiated) {
        setLoading(true);
        setIsSubmitting(true);
        const data = new FormData();
        data.append("status", "approved");
        dispatch(handleHuntRequest(id, data, onApproveSuccess, onApproveError));
        setIsApprovalInitiated(true);
        localStorage.removeItem("status");
      } else if ((status === "true" || actionStatus === "true") && reservationDetail && (user && user.id !== reservationDetail?.stand_detail?.user?.id)) {
        toastHandler("Unauthorized to perform this action", "error");
        history.replace(`/my-profile/reservation-details/${id}`);
        localStorage.removeItem("status");
      } else if ((status === "true" || actionStatus === "true") && reservationDetail && reservationDetail?.status === "approved") {
        toastHandler("Reservation is already approved", "error");
        history.replace(`/my-profile/reservation-details/${id}`);
        localStorage.removeItem("status");
      } else if ((status === "true" || actionStatus === "true") && reservationDetail && reservationDetail?.status === "canceled") {
        toastHandler("Reservation is already canceled", "error");
        history.replace(`/my-profile/reservation-details/${id}`);
        localStorage.removeItem("status");
      } else if ((status === "true" || actionStatus === "true") && reservationDetail && reservationDetail?.status === "completed") {
        toastHandler("Reservation is already completed", "error");
        history.replace(`/my-profile/reservation-details/${id}`);
        localStorage.removeItem("status");
      } else if ((status === "true" || actionStatus === "true") && reservationDetail && reservationDetail?.status === "rejected") {
        toastHandler("Reservation is already rejected", "error");
        history.replace(`/my-profile/reservation-details/${id}`);
        localStorage.removeItem("status");
      } else if ((status === "false" || actionStatus === "false") && reservationDetail && reservationDetail?.can_perform_approval) {
        dispatch(showRejectionReasonModal(true));
        dispatch(showRejectionReason(false));
        localStorage.removeItem("status");
      } else if ((status === "false" || actionStatus === "false") && reservationDetail && (user && user.id !== reservationDetail?.stand_detail?.user?.id)) {
        toastHandler("Unauthorized to perform this action", "error");
        history.replace(`/my-profile/reservation-details/${id}`);
        localStorage.removeItem("status");
      } else if ((status === "false" || actionStatus === "false") && reservationDetail && reservationDetail?.status === "approved") {
        toastHandler("Reservation is already approved", "error");
        history.replace(`/my-profile/reservation-details/${id}`);
        localStorage.removeItem("status");
      } else if ((status === "false" || actionStatus === "false") && reservationDetail && reservationDetail?.status === "canceled") {
        toastHandler("Reservation is already canceled", "error");
        history.replace(`/my-profile/reservation-details/${id}`);
        localStorage.removeItem("status");
      } else if ((status === "false" || actionStatus === "false") && reservationDetail && reservationDetail?.status === "completed") {
        toastHandler("Reservation is already completed", "error");
        history.replace(`/my-profile/reservation-details/${id}`);
        localStorage.removeItem("status");
      } else if ((status === "false" || actionStatus === "false") && reservationDetail && reservationDetail?.status === "rejected") {
        toastHandler("Reservation is already rejected", "error");
        history.replace(`/my-profile/reservation-details/${id}`);
        localStorage.removeItem("status");
      }
    }
  }, [reservationDetail, isApprovalInitiated, actionStatus])

  useEffect(() => { document.title = 'Reservation Details | iHunt' }, []);

  return (<div className="reservation-details">
    <div clasName="pb-4">
      <h6 className="d-content-inner-title font-roboto mb-0">
        Reservation Details
      </h6>
    </div>
    {
      gettingReservationDetail ? <CustomSpinner /> :
        reservationDetail ?
          <>
            <div className="top-bar mt-4 d-flex justify-content-between align-items-center flex-wrap">
              <div className="top" onClick={handleBack}>
                <i className="bi bi-arrow-left-short me-2"></i>
                <span>Go back</span>
              </div>
              {
                reservationDetail?.can_perform_approval ? 
                  <div className="d-flex gap-2">
                    <button type="button" className="btn btn-primary text-white text-uppercase" style={{minWidth: 125, }} disabled={isSubmitting || loading} onClick={approveHunt}>{ isSubmitting || loading || isLoading || actionsLoading ? <Spinner /> : "APPROVE"}</button>
                    <button type="button" className="btn px-3 text-uppercase" onClick={() => {
                      dispatch(showRejectionReasonModal(true));
                      dispatch(showRejectionReason(false));
                    }} style={{ border: "1px solid red", color: "red",minWidth: 125, }} disabled={isSubmitting || loading || isLoading || actionsLoading}>REJECT</button>
                  </div> : null
              }
              {
                reservationDetail?.can_cancel ?
                  <div
                    className="cancel-txt fs-14 cursor-pointer"
                    onClick={() => setShowCancelModal(true)}
                  >
                    CANCEL RESERVATION
                  </div> : null
              }
            </div>
            <div className="row">
              <div className="col-xxl-6">
                <div className="res-detail-box p-4 mt-4 d-flex align-items-center justify-content-between flex-wrap">
                  <div className="res-stand-info d-flex align-items-center">
                    <div>
                      <img
                        className="img-res-details"
                        src={reservationDetail?.stand_detail?.image || User}
                        onError={(e) => (e.target.src = User)}
                        alt="" />
                    </div>
                    <div className="mr-land-info">
                      <h5 className="mb-1">{reservationDetail?.stand_detail?.title}</h5>
                      <div className="d-flex">
                        <Pin className="me-2 mw-12"  style={{
                          minWidth: 10
                        }} />
                        <span className="address-span text-tertiary">
                          {reservationDetail?.stand_detail?.address}
                        </span>
                      </div>
                    </div>
                  </div>
                  {isLogedInUser() || reservationDetail?.current_user_type == "additional_hunter" ?
                    <UserCard
                      title="Hunter"
                      user={reservationDetail?.user}
                      subject={reservationDetail?.stand_detail?.title}
                      reservationId={id}
                      showInput={reservationDetail?.is_allowed_chat}
                      showMessage={!reservationDetail?.is_allowed_chat} /> :
                    <UserCard
                      title="Owner"
                      user={reservationDetail?.stand_detail?.user}
                      subject={reservationDetail?.stand_detail?.title}
                      reservationId={id}
                      showInput={reservationDetail?.is_allowed_chat}
                      showMessage={!reservationDetail?.is_allowed_chat}
                    />}

                </div>
              </div>
              <div className="col-xxl-6">
                <div className="res-detail-box pb-3 mt-4">
                  <div className="text-tertiary res-box-title">DETAILS</div>
                  <div className="d-flex res-pay-line align-items-center justify-content-between">
                    <div className="text-secondary fw-medium">Reservation ID</div>
                    <div className="text-secondary fw-medium">{reservationDetail?.id}</div>
                  </div>
                  <div className="d-flex res-pay-line align-items-center justify-content-between">
                    <div className="text-secondary fw-medium">Reservation Status</div>
                    <div className={getStatusClass()}>
                      {reservationDetail?.status?.charAt(0).toUpperCase()
                        + reservationDetail?.status?.slice(1)}
                    </div>
                  </div>
                  {
                    reservationDetail?.status === "rejected" ? 
                      <div className="d-flex res-pay-line align-items-center justify-content-between">
                        <div className="text-secondary fw-medium"></div>
                        <div className="fw-medium text-right approved-txt cursor-pointer" onClick={() => {
                          dispatch(showRejectionReasonModal(true));
                          dispatch(showRejectionReason(true));
                        }}>
                          <i className="fa fa-eye fw-sm pe-1"></i>View Reason
                        </div>
                      </div> : null
                  }
                  <div className="d-flex res-pay-line align-items-center justify-content-between">
                    <div className="text-secondary fw-medium">Reservation Date</div>
                    <div className="text-secondary fw-medium text-right">
                      {moment(reservationDetail?.start_date).format("MMM DD, YYYY")} - {moment(reservationDetail?.end_date).format("MMM DD, YYYY")}
                    </div>
                  </div>
                  <div ref={reservedDateRangeCalanderRef}>
                    <div className="d-flex res-pay-line align-items-center justify-content-between">
                      <div className="text-secondary fw-medium"></div>
                      <div
                        className="approved-txt fw-medium cursor-pointer"
                        onClick={openCalander}
                      >
                        <svg onClick={openCalander} xmlns="http://www.w3.org/2000/svg" width="18.3" height="18.3" viewBox="0 0 18.3 18.3" style={{ marginRight: "5px" }}>
                          <path id="Path_599" data-name="Path 599" d="M15.68,1.406H14.063V.563a.563.563,0,0,0-1.125,0v.844H5.063V.563a.562.562,0,1,0-1.125,0v.844H2.32A2.323,2.323,0,0,0,0,3.727V15.68A2.323,2.323,0,0,0,2.32,18H15.68A2.323,2.323,0,0,0,18,15.68V3.727A2.323,2.323,0,0,0,15.68,1.406ZM2.32,2.531H3.938v.563a.562.562,0,1,0,1.125,0V2.531h7.875v.563a.563.563,0,0,0,1.125,0V2.531H15.68a1.2,1.2,0,0,1,1.2,1.2V5.063H1.125V3.727A1.2,1.2,0,0,1,2.32,2.531ZM15.68,16.875H2.32a1.2,1.2,0,0,1-1.2-1.2V6.188h15.75V15.68A1.2,1.2,0,0,1,15.68,16.875Z" transform="translate(0.15 0.15)" fill="#ea9b45" stroke="#ea9b45" stroke-width="0.3" />
                        </svg>
                        <span className="fw-medium ml-3">View on calendar</span>
                      </div>
                    </div>
                    <div className="reservation-details-calender">
                      <Calendar
                        allowPartialRange={true}
                        className={showCalendar ? "" : "hide"}
                        calendarType={"ISO 8601"}
                        tileClassName={({ date, view }) => {
                          if (range?.find(x => x === moment(date).format("DD-MM-YYYY"))) {
                            return 'reserved-date'
                          }
                        }}
                        selectRange={false}
                        minDate={moment(reservationDetail?.start_date)._d}
                        value={reservationDetail?.start_date ? [moment(reservationDetail?.start_date)._d,
                        moment(reservationDetail?.end_date)._d] : ["", ""]}
                      />
                    </div>
                  </div>

                  {
                    reservationDetail?.current_user_type == "additional_hunter" ? null :
                      <div className="d-flex res-pay-line align-items-center justify-content-between">
                        <Link to={{
                          pathname: `${id}/additional-hunter`,
                          state: { ...history.location.state },
                        }} className="underline-view">Additional Hunters</Link>
                      </div>
                  }

                </div>
              </div>
              {reservationDetail?.current_user_type == "additional_hunter" ? null : <div className="col-xxl-12">
                <div className="res-detail-box py-3 payment-details-v2">
                  <div className="text-tertiary res-box-title">PAYMENTS</div>
                  <div className="d-flex res-pay-line align-items-center justify-content-between">
                    <div className="text-secondary fw-medium">Sub Total</div>
                    <div className="text-secondary fw-medium">${reservationDetail?.subtotal}</div>
                  </div>
                  <div className="d-flex res-pay-line align-items-start justify-content-between">
                    <div className="text-secondary fw-medium">Discount</div>
                    <div className="text-secondary fw-medium">
                      <span className="text-secondary fw-medium d-flex justify-content-end w-100">${reservationDetail?.discount}</span>
                      <div className={reservationDetail?.multi_day_discount || reservationDetail?.voucher_discount || reservationDetail?.referral_discount ?
                        "res-detail-box table-responsive border-bottom-0 mt-1 additional-table" :
                        "table-responsive border-bottom-0 mt-1 additional-table"} >
                        <table className="w-100 table align-middle mb-0">
                          {
                            reservationDetail?.multi_day_discount ?
                              <thead>
                                <tr>
                                  <td className="p-2 "><span className="text-secondary fw-medium flex-fill">Multi Days Discount</span></td>
                                  <td className="p-2 text-end">
                                    <span className="text-secondary fw-medium flex-fill">
                                      ${reservationDetail?.multi_day_discount}
                                    </span></td>
                                </tr>
                              </thead> : null
                          }
                          {reservationDetail?.voucher_discount ?
                            <tbody>
                              <tr>
                                <td className="p-2 "><span className="text-secondary fw-medium flex-fill">Voucher Discount</span></td>
                                <td className="p-2 text-end">
                                  <span className="text-secondary fw-medium flex-fill">
                                    ${reservationDetail?.voucher_discount}
                                  </span>
                                </td>
                              </tr>
                            </tbody> : null
                          }
                          {reservationDetail?.referral_discount ?
                            <tbody>
                              <tr>
                                <td className="p-2 "><span className="text-secondary fw-medium flex-fill">Referral Discount</span></td>
                                <td className="p-2 text-end">
                                  <span className="text-secondary fw-medium flex-fill">
                                    ${reservationDetail?.referral_discount}
                                  </span>
                                </td>
                              </tr>
                            </tbody> : null
                          }
                        </table>
                      </div>
                    </div>
                  </div>
                  {
                    reservationDetail?.status === "canceled" ?
                      <div className="d-flex res-pay-line align-items-center justify-content-between">
                        <div className="text-secondary fw-medium">Cancelation Fee</div>
                        <div className="text-secondary fw-medium">
                          <span className="text-secondary fw-medium d-flex justify-content-end w-100">${reservationDetail?.cancellation_fee && reservationDetail?.cancellation_fee > 0 ? reservationDetail?.cancellation_fee : 0}</span>
                        </div>
                      </div> : null
                  }
                  {reservationDetail?.refunds?.length > 0 ?
                    <div className="d-flex res-pay-line align-items-center justify-content-between">
                      <div className="text-secondary fw-medium">Refunds Details</div>
                      <div className="text-secondary fw-medium">
                        <span className="text-secondary fw-medium d-flex justify-content-end w-100">${getRefundAmountSum()}</span>
                        <div className="res-detail-box table-responsive border-bottom-0 mt-1">
                          <table className="w-100 table align-middle mb-0">
                            <thead>
                              <tr>
                                <td className="p-2 "><span className="text-secondary fw-medium flex-fill">Date</span></td>
                                <td className="p-2 "><span className="text-secondary fw-medium flex-fill">Status</span></td>
                                <td className="p-2 text-end"><span className="text-secondary fw-medium flex-fill">Amount</span></td>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                reservationDetail?.refunds?.map((item, index) => {
                                  return <tr key={`refund-${index}-refund`}>
                                    <td className="p-2 "><span className="text-secondary fw-medium flex-fill">{item?.date}</span></td>
                                    <td className="p-2 "><span className="text-secondary fw-medium flex-fill">{item?.status}</span></td>
                                    <td className="p-2 text-end"><span className="text-secondary fw-medium flex-fill">${item?.refund_amount}</span></td>
                                  </tr>
                                })
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div> : null}
                  <div className="d-flex res-pay-line align-items-center justify-content-between  pt-4 mt-4 footer">
                    <div className="fw-bold">{reservationDetail?.amount_description}</div>
                    <div className="fw-bold">${reservationDetail?.amount}</div>
                  </div>
                </div>
              </div>}
              {reservationDetail?.current_user_type == "additional_hunter" ? null : <div className="col-12">
                <div className="res-detail-box pb-5 funds-request-v2">
                  <div className="text-tertiary res-box-title">FUNDS REQUEST</div>
                  <div className="my-land res-pay-line position-relative">
                    {
                      reservationDetail?.is_allowed_fund_request ?
                        <button className="btn btn-primary text-white mx-auto d-flex w-auto " onClick={openRequestFundModal}>REQUEST FUNDS</button> :
                        null
                    }
                    <div className="tabs mt-0 me-0">
                      <Tabs
                        defaultActiveKey="sent"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                        activeKey={key}
                        onSelect={(k) => {
                          setKey(k);
                        }}
                      >
                        <Tab eventKey="sent" title="Sent">
                          <div className="stand-details-table">
                            {
                              listLoading ? <Spinner /> :
                                reservationDetail?.sent_funds_request?.length == 0 ? <NoRecordFound /> :
                                  <div className="table-responsive">
                                    <table className="table mb-0">
                                      <thead>
                                        <tr>
                                          <th className="fw-medium">Requested</th>
                                          <th className="fw-medium">Fee</th>
                                          <th className="fw-medium">Received</th>
                                          <th className="fw-medium" style={{minWidth: 102}}>Request Date</th>
                                          <th className="fw-medium" style={{minWidth: 125}}>Transaction Date</th>
                                          <th className="fw-medium">Status</th>
                                          <th className="fw-medium">Description</th>
                                          <th></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {
                                          reservationDetail?.sent_funds_request && reservationDetail?.sent_funds_request?.map(item => {
                                            return (
                                              <tr key={`${item.id}-sent-fund`}>
                                                <td data-label="Requested" className="fw-medium">${item?.requested_amount}</td>
                                                <td data-label="Fee" className="fw-medium">{item?.transaction_fee_percentage}%</td>
                                                <td data-label="Received" className="fw-medium">
                                                  {item?.transaction_amount ?
                                                    `$${item?.transaction_amount}` : "N/A"}
                                                </td>
                                                <td data-label="Request Date" className="fw-medium">{item?.request_date || "N/A"}</td>
                                                <td data-label="Transaction Date" className="fw-medium">{item?.transaction_date || "N/A"}</td>
                                                <td data-label="Status"><p style={getStyle(item?.status)} onClick={() => { handleDeclinedStatusClick(item?.id, item?.status, item?.decline_reason) }}>
                                                  {item?.status.charAt(0).toUpperCase()
                                                    + item?.status.slice(1)}
                                                </p></td>
                                                <td data-label="Description"> <span className="underline-view" onClick={() => openDescModal(item?.description, "desc")}>view</span> </td>
                                                <td>
                                                  {
                                                    item?.status === "pending" && reservationDetail?.current_user_type == "main_hunter" ?
                                                      <button
                                                        type="button"
                                                        className="btn px-3 mx-lg-auto d-flex w-auto"
                                                        style={{ border: "1px solid red", color: "red" }}
                                                        onClick={() =>
                                                          openDescModal("Are you sure you want to cancel this request ?", "cancel", item?.id)
                                                        }>
                                                        CANCEL
                                                      </button> : null
                                                  }
                                                </td>
                                              </tr>
                                            )
                                          })
                                        }
                                      </tbody>
                                    </table>
                                  </div>
                            }
                            <div className="inner-container"></div>
                          </div>
                        </Tab>
                        <Tab eventKey="recieved" title="Received">
                          {reservationDetail?.received_funds_request?.length > 0 ? <div className="stand-details-table">
                            <div className="table-responsive">
                              <table className="table mb-0">
                                <thead>
                                  <tr>
                                    <th className="fw-bold">Requested</th>
                                    <th className="fw-bold">Paid</th>
                                    <th className="fw-bold">Request Date</th>
                                    <th className="fw-bold">Transaction Date</th>
                                    <th className="fw-bold">Status</th>
                                    <th className="fw-bold">Description</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    reservationDetail?.received_funds_request && reservationDetail?.received_funds_request?.map((item, index) => {
                                      return (
                                        <tr key={`${index}-received-fund-request`}>
                                          <td className="fw-medium">${item?.requested_amount}</td>
                                          <td className="fw-medium">
                                            {item?.transaction_amount ?
                                              `$${item?.transaction_amount}` : "N/A"}
                                          </td>
                                          <td className="fw-medium">{item?.request_date || "N/A"}</td>
                                          <td className="fw-medium">{item?.transaction_date || "N/A"}</td>
                                          <td>
                                            <p style={getStyle(item?.status)} onClick={() => { handleDeclinedStatusClick(item?.id, item?.status, item?.decline_reason) }}>
                                              {
                                                item?.status.charAt(0).toUpperCase()
                                                + item?.status.slice(1)
                                              }
                                            </p>
                                          </td>
                                          <td data-label="Description"> <span className="underline-view" onClick={() => openDescModal(item?.description, "desc")}>view</span> </td>
                                          <td>
                                            {item?.status === "pending" && (reservationDetail?.current_user_type == "main_hunter" || reservationDetail?.current_user_type == "landowner") ?
                                              <div className="d-flex justify-content-center">
                                                <button
                                                  type="button"
                                                  className="btn px-3 me-2 d-flex w-auto"
                                                  style={{ border: "1px solid red", color: "red" }}
                                                  onClick={() => openDescModal("Are you sure you want to reject this funds request ?", "declined", item?.id)}
                                                  disabled={actionsLoading}
                                                >
                                                  DECLINE
                                                </button>
                                                <button className="btn btn-primary text-white px-3"
                                                  onClick={() => {
                                                    setPaymentPayLoad({
                                                      id: item?.id,
                                                      amount: item?.requested_amount,
                                                      type: "paid"
                                                    })
                                                    handlePayment(item?.id, item?.requested_amount, "paid")}
                                                  }
                                                >
                                                  {actionsLoading && requestID == item?.id && type == "paid" ?
                                                    <Spinner /> : "PAY"}
                                                </button>
                                              </div> : null
                                            }
                                          </td>
                                        </tr>
                                      )
                                    })
                                  }
                                </tbody>
                              </table>
                            </div>
                            <div className="inner-container"></div>
                          </div> : <NoRecordFound />}
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>}
            </div>
            {
              (reservationStatus === "past" || reservationStatus === "completed") &&
                reservationDetail?.status === "completed" ? (
                <div className="row">
                  <Trophy
                    reservationDetail={reservationDetail}
                    dispatch={dispatch}
                  />
                  {
                    reservationDetail?.current_user_type == "main_hunter" ||
                      reservationDetail?.current_user_type == "landowner" ?
                      <ReservationReview
                        reservationDetail={reservationDetail}
                        dispatch={dispatch}
                        isLoading={isLoading}
                        reload={reload}
                        setReload={setReload}
                      /> : null
                  }
                </div>
              ) : null
            }

            <ConfirmationModal
              show={showCancelModal}
              onHide={handleConfirmModalSubmit}
              title={"Confirmation"}
              message={reservationDetail?.cancellation_policy}
              yesString={"OK, PROCEED"}
              noString={"NOT NOW"}
              onlyYes={false}
              isLoading={isLoading}
            />
            <ConfirmationModal
              show={showCancelErrorModal}
              onHide={() => {
                setShowCancelErrorModal(false);
              }}
              title={"Error"}
              message={"Reservation cannot be canceled"}
              yesString={"OK"}
              onlyYes={true}
            />
            <RequestFundsModal
              show={showRequestFundModal}
              onHide={closeRequestFundModal}
              id={id}
              setShowRequestFundModal={setShowRequestFundModal}
              getRequest={() => getReservationDetail(id)}
            />
            <ConfirmModal
              show={showDescriptionModal}
              setShow={setShowDescriptionModal}
              type={type}
              text={desc}
              getRequest={() => getReservationDetail(id)}
              id={requestID}
              setShowPaymentModal={setShowPaymentModal}
              amount={amount}
            />
            {
              clientSecret != null &&
              <Elements stripe={promise} options={options}>
                <PaymentModal
                  amount={amount}
                  showPaymentModal={showPaymentModal}
                  setShowPaymentModal={setShowPaymentModal}
                  setShowDescriptionModal={setShowDescriptionModal}
                  anyRequest={() => getReservationDetail(id)}
                  onFailed={() => revertFundRequest()}
                />
              </Elements>
            }
            <PendingStatusModal
              show={showPendingModal}
              setShow={setShowPendingModal}
              setShowPaymentModal={setShowPaymentModal}
              paymentSuccess={paymentSuccess}
              setPaymentSuccess={setPaymentSuccess}
            />
            <PaidStatusModal
              show={showPaidModal} setShow={setShowPaidModal} />
            <DeclinedStatusModal
              show={showDeclineModal} setShow={setShowDeclineModal} reason={showDeclineReason} />
            <CancelStatusModal show={showCanceleStatusModal} setShow={setShowCanceleStatusModal} />
            <HuntConfirmModal
              id={id}
              show={rejectionReasonModal} 
              showRejectionReason={rejectionReason}
              rejectionReason={reservationDetail?.rejection_reason}
              getRequest={() => getReservationDetail(id)}
            />
          </> : <NoRecordFound />}
  </div>
  );
}