import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { getAllGroups, deleteGroup } from "modules/dashboard/_redux/groups/groupActions";
import Spinner from "components/UI/Spinner.js";
import NoRecordFound from "utils/noRecordFound";
import "./style.scss"


export default function MyGroups() {
  const [groupId, setGroupId] = useState("");
  const dispatch = useDispatch();

  const { groups, listLoading, deleteActionsLoading } = useSelector(
    (state) => ({
      groups: state.group.groups,
      listLoading: state.group.listLoading,
      deleteActionsLoading: state.group.deleteActionsLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getAllGroups());
  }, [])


  const deleteGroupHandler = (id) => {
    setGroupId(id)
    dispatch(deleteGroup(id))
  }

  return (
    <>
      <div className="pb-3">
        <h6 className="d-content-inner-title font-roboto mb-0">My Groups</h6>
      </div>
      <div className="d-profile-middle-content mw-100 w-100 justify-content-start align-items-start">
        <div className="filter-btm-btns mw-100 w-100 px-0">
          {
            groups?.length == 0 ? <NoRecordFound message="No group found" /> :
              <div className="new-tags-v2 ps-0">
                <div className="inner-tags d-flex" style={{
                  flexWrap: "wrap",
                  gap: "10px"
                }}>
                  {listLoading ? <Spinner /> :
                    groups?.map(item => {
                      return (
                        <div className="tags-item hover">
                          <span>
                            {groupId == item?.id && deleteActionsLoading ? <Spinner /> : item?.name}
                          </span>
                          {deleteActionsLoading && item.id == groupId ? null :
                            <i className="fa fa-times ms-2" onClick={() => deleteGroupHandler(item?.id)}>
                            </i>}
                        </div>
                      )
                    })
                  }
                </div>
              </div>
          }
        </div>
      </div>
    </>
  );
}
