import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Spinner from "components/UI/Spinner.js";
import "./UnAuthorizedModal.scss";

export default function UnAuthorizedModal({
  show,
  onHide,
  title,
  message
}) {
  const [loading, setLoading] = useState(false);
  const handleRedirect = () => {
    setLoading(true);
    window.location.replace("/auth/login")
  }
  return (
      <div>
          <Modal
              show={show}
              onHide={onHide}
              aria-labelledby="example-modal-sizes-title-lg"
              centered
              backdrop="static"
          >
              <Modal.Header>
                  <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
              </Modal.Header>
              <Modal.Body className="pd-4" style={{
                  textAlign: "justify"
              }}>
                  <span>{message}</span>
              </Modal.Body>
              <Modal.Footer>
                  <button
                      type="button"
                      onClick={handleRedirect}
                      className="btn btn-primary text-white"
                  >
                    {loading ? (
                      <Spinner/>
                    ) : (
                      "LOGIN"
                    )}
                  </button>
              </Modal.Footer>
          </Modal>
      </div>
  );
}
