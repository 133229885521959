import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ReactComponent as LocationPin } from "assets/icons/pindark.svg";
import ReferralIcon from "assets/icons/referral.svg";
import VoucherIcon from "assets/icons/voucher.svg";
import defaultImage from "assets/images/default.png";
import { useFormik } from "formik";
import TopBar from "modules/landingPage/pages/topBar.js";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Form, Modal, Table } from "react-bootstrap";
import Spinner from "components/UI/Spinner.js";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as reservationActions from "../../../_redux/reservations/reservationActions";
import * as reduxActions from "../../../_redux/stand/standActions";
import PaymentForm from "../payment-form/PaymentForm";
import "./style.scss";
import toastHandler from "components/UI/toastHandler/toastHandler";
import { useHistory, useLocation } from "react-router";
import config from "config";
import LoadingOverlay from "react-loading-overlay";
import { clearState, setRedirectUrl } from "modules/dashboard/_redux/wavier/wavierActions";
import { CancelationPolicyModal } from "components/UI/modals/cancelationPolicyModal";
import { setAuthStatus, setReservationLoader } from "modules/landingPage/_redux/landingPageActions";
import { reservationSlice } from "modules/dashboard/_redux/reservations/reservationSlice";
import { getHuntingDetails } from "modules/dashboard/_redux/stand/standCrud";
import VoucherModal from "components/UI/modals/voucher-modal/VoucherModal";
import ReferralModal from "components/UI/modals/referral-modal/ReferralModal";
import CalendarModal from "components/UI/modals/calendar-modal/CalendarModal";

const promise = loadStripe(config.STRIPE_PUBLISH_KEY);

const { actions } = reservationSlice;

export default function CreateReservation({ id }) {
  const history = useHistory();
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);
  const [checkDiscount, setCheckDiscount] = useState(false);
  const [paying, setPaying] = useState(false);
  const [isReserved, setIsreserved] = useState("No");
  const [discount, setDiscount] = useState(0);
  const [voucherType, setVoucherType] = useState("");
  const [voucherId, setVoucherId] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [reserveDates, setReserveDate] = useState(null);
  const [checked, setChecked] = useState("")
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [storedAvailableDates, setStoredAvailableDates] = useState(null);
  const [calendarLoading, setCalendarLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showDateErrorModal, setShowDateErrorModal] = useState(false);
  const [showReferralModal, setShowReferralModal] = useState(false);
  const [showVoucherModal, setShowVoucherModal] = useState(false);
  const [dateErrorMessage, setDateErrorMessage] = useState("");
  const [showCalendarModal, setShowCalendarModal] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const localReferralInfo = localStorage.getItem("referralInfo");
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const referralSource = params.get('ref');

  if (referralSource) {
    if (!localReferralInfo) {
      const currentDate = new Date().getTime();
      const referralInfo = {
        ref: referralSource,
        date: currentDate
      };
      localStorage.setItem("referralInfo", JSON.stringify(referralInfo));
    } else {
      const { ref, date } = JSON.parse(localReferralInfo);
      const currentDate = new Date().getTime();
      const thirtyDaysInMillis = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds

      if (currentDate - date > thirtyDaysInMillis) {
        localStorage.removeItem("referralInfo");
        const newReferralInfo = {
          ref: referralSource,
          date: currentDate
        };
        localStorage.setItem("referralInfo", JSON.stringify(newReferralInfo));
      }
    }
  }

  const onHide = () => {
    setShow(false)
  }

  const showModal = () => {
    setShow(true)
  }

  const onHidePreConditionModal = () => {
    dispatch(actions.setShowPreConditionModal({ status: false, error: '' }))
    setLoading(false);
    setLoader(false);
    dispatch(setReservationLoader(false));
  }

  let data = {};

  const {
    actionsLoading,
    standDetails,
    huntingDetails,
    clientSecret,
    reservationActionsLoading,
    pricingDetails,
    user,
    bookingDetails,
    authStatus,
    wavierAccepted,
    showPreConditionModal,
    showPreConditionError,
    processCompletionTitle,
    processCompletionDescription,
    huntApprovalSetting,
    reservationLoader
  } = useSelector(
    (state) => ({
      actionsLoading: state.stand.actionsLoading,
      reservationActionsLoading: state.reservation.actionsLoading,
      standDetails: state.stand.standDetails,
      huntingDetails: state.stand.huntingDetails,
      clientSecret: state.reservation.clientSecret,
      pricingDetails: state.reservation.pricingDetails,
      user: state.auth.user,
      bookingDetails: state.reservation.bookingDetails,
      authStatus: state.landingPage.authStatus,
      wavierAccepted: state.reservation.wavierAccepted,
      showPreConditionModal: state.reservation.showPreConditionModal,
      showPreConditionError: state.reservation.showPreConditionError,
      processCompletionTitle: state.reservation.processCompletionTitle,
      processCompletionDescription: state.reservation.processCompletionDescription,
      huntApprovalSetting: state.reservation.huntApprovalSetting,
      reservationLoader: state.landingPage.reservationLoader
    }),
    shallowEqual
  );

  const sortedAvailableDates = !storedAvailableDates ? huntingDetails?.available_dates?.slice()?.sort((a, b) => {
    return new Date(a.date) - new Date(b.date)
  }) : storedAvailableDates?.available_dates?.slice()?.sort((a, b) => {
    return new Date(a.date) - new Date(b.date)
  });

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [id]);

  useEffect(() => {
    if (authStatus) {
      formik.submitForm();
      dispatch(setAuthStatus(false));
    }
  }, [authStatus]);

  useEffect(() => {
    if (wavierAccepted) {
      var paymentFormData = new FormData();

      for (var key in bookingDetails) {
        paymentFormData.append(key, bookingDetails[key]);
      }
      dispatch(setReservationLoader(true));
      dispatch(reservationActions?.createReservation(paymentFormData, onError, onSuccess, onPreConditionError, onDateError, onConflictError));
      dispatch(reservationActions?.handleWavierStatus(false));
    }
  }, [wavierAccepted])

  useEffect(() => {
    const selectedStartDate = localStorage.getItem("selectedStartDate");
    if (selectedStartDate) {
      setStartDate(moment(selectedStartDate)?.format('MMM DD YYYY'));
      setEndDate(moment(selectedStartDate)?.subtract(1, 'day')?.add(1, 'year')?.format('MMM DD YYYY'));
      localStorage.removeItem("selectedStartDate");
    }
  }, [showCalendarModal]);

  const formik = useFormik({
    initialValues: {
      stand_id: id,
      season_id: "",
      amount: null,
      reserved_dates: bookingDetails?.reserveDates || [null, null],
      hunter_booking_amount: null,
      landowner_booking_amount: null,
      discount: 0,
      multi_day_discounts: 0,
      multi_day_discount_id: null,
      discountSum: 0,
      voucher_id: null,
      voucher_type: null,
      referral_code: null,
      total_days: null,
      sub_total: null,
      days_and_prices: [],
      cancellation_policy: true,
    },
    onSubmit: (values) => {
      // clear error if any
      setLoading(true);
      setError("");

      const currDate = moment().startOf('day');
      const reservedStartDate = moment(values.reserved_dates[0]).startOf('day');
      const reservedEndDate = moment(values.reserved_dates[1]).startOf('day');

      if (reservedStartDate.isSame(currDate) && reservedEndDate.isSame(currDate)) {
        setShowConfirmationModal(true);
        setLoading(false);
      } else {
        reserveStand(values);
      }
    },
  });

  const reserveStand = async (values) => {
    if (!values?.reserved_dates[0] && checked != "yearly" && checked != "season") {
      values.reserved_dates = bookingDetails?.reserveDates
    }
    const data = new FormData();
    data.append("stand_id", values.stand_id);
    data.append("current_date", moment().format("MMM DD YYYY HH:MM"));

    if (checked == "yearly") {
      data.append("is_yearly_rental", 1)
      data.append("start_date", (startDate && startDate) || filteredYearlyRentals?.start_date)
      data.append("end_date", (endDate && endDate) || filteredYearlyRentals?.end_date)
    }
    if (checked == "season") {
      data.append("season_id", formik.values.season_id)
    }

    if (values?.discount > 0) {
      if (values?.voucher_id && values?.voucher_type) {
        data.append("voucher_id", values?.voucher_id);
        data.append("voucher_type", values?.voucher_type);
      }
      if (values?.referral_code) {
        data.append("referral_code", values?.referral_code);
      }
    }

    // preparing reserved dates list
    let dateIndex = 0;
    let currentDate = values?.reserved_dates[0];

    if (checked == "dateRange") {
      while (
        moment(currentDate).isSameOrBefore(
          // adding recovery period in end date to check if they are not booked
          moment(values?.reserved_dates[1])
        )
      ) {
        if (!getTileDisabled({ date: currentDate })) {
          data.append(
            `reserve_dates[${dateIndex}]`,
            moment(currentDate)?.format("MMM DD YYYY")
          );
          dateIndex += 1;
        }
        currentDate = moment(currentDate).add(1, "d");
      }
    }
    if (checked == "season") {
      data["season_id"] = formik?.values?.season_id;
    }

    const localReferralInfo = localStorage.getItem("referralInfo");
    let referralSource = null;
    if (localReferralInfo) {
      const { ref, date } = JSON.parse(localReferralInfo);
      const currentDate = new Date().getTime();
      const thirtyDaysInMillis = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds
      if (currentDate - date <= thirtyDaysInMillis) {
        referralSource = ref;
      } else {
        localStorage.removeItem("referralInfo");
      }
    }

    if (referralSource) {
      data.append("referral_source", referralSource);
    }

    // api call
    dispatch(reservationActions?.createReservation(data, onError, onSuccess, onPreConditionError, onDateError, onConflictError));
    setIsreserved("Yes")
  };

  const handleModalProceed = () => {
    setLoading(true);
    setShowConfirmationModal(false);
    reserveStand(formik.values);
  };

  const onSuccess = (response) => {
    if (pricingDetails?.total_amount === 0) {
      dispatch(setReservationLoader(true));
      const process_completion_title = response.process_completion_title;
      const process_completion_description = response.process_completion_description;
      history.push(`/success?process_completion_title=${process_completion_title}&process_completion_description=${process_completion_description}`);
      dispatch(setReservationLoader(false));
    }
    setLoading(false);
    setLoader(false);
    dispatch(setAuthStatus(false));
    localStorage.removeItem("referralInfo");
  };

  const onError = (message) => {
    setLoading(false);
    //toastHandler(message, "error")
    // setError(message);
  };

  const onPreConditionError = () => {
    setLoading(false);
    setLoader(false);
  };

  const onDateError = (errorMessage) => {
    setShowDateErrorModal(true);
    setDateErrorMessage(errorMessage);
  };

  const onConflictError = (conflictMessage) => {
    setLoading(false);
    toastHandler(conflictMessage, "error");
  };

  const handleCacheFlush = () => {
    setShowDateErrorModal(false);
    setIsreserved("No");
    dispatch(reduxActions.getStandById(id))
      .then(() => {
        const existingData = JSON.parse(localStorage.getItem(`stand_${id}`));
        if (existingData) {
          const updatedData = { ...existingData, timestamp: standDetails?.booking_availability_updated_at };
          storeDetailsInLocalStorage(id, updatedData);
        }

        localStorage.removeItem(`stand_${id}`);
        dispatch(reservationActions?.clearBookingDetails());
        setChecked("");
        dispatch(reservationActions.clearPricing());
        setReserveDate(null);
        formik?.resetForm();
        dispatch(reservationActions.deletePendingReservation());
        formik.setFieldValue("reserved_dates", [null, null]);

        const currentDate = moment().format("MMM DD YYYY HH:mm");
        const currentMonth = moment().format("MMMM");
        const currentYear = moment().format("YYYY");
        getHuntingDetails(id, { date: currentDate, month: currentMonth, year: currentYear }).then((response) => {
          setCalendarLoading(true);
          setTimeout(() => {
            const timestamp = standDetails?.booking_availability_updated_at;
            const huntDetail = response?.data;
            const dataToStore = { timestamp, huntDetail };
            storeDetailsInLocalStorage(id, dataToStore);
            setStoredAvailableDates({ available_dates: huntDetail?.available_dates });
            setCalendarLoading(false);
          }, 500);
        });
        window.location.reload();
      })
  };

  const submitWavierHandler = () => {
    if (!formik?.values?.reserved_dates[0] && checked != "yearly" && checked != "season") {
      formik.values.reserved_dates = bookingDetails?.reserveDates
    }
    const data = new FormData();
    data.append("stand_id", formik.values.stand_id);
    data.append("current_date", moment().format("MMM DD YYYY HH:MM"));

    if (checked == "yearly") {
      data.append("is_yearly_rental", 1)
      data.append("start_date", (startDate && startDate) || filteredYearlyRentals?.start_date)
      data.append("end_date", (endDate && endDate) || filteredYearlyRentals?.end_date)
    }
    if (checked == "season") {
      data.append("season_id", formik.values.season_id)
    }

    if (formik?.values?.discount > 0) {
      if (formik?.values?.voucher_id && formik?.values?.voucher_type) {
        data.append("voucher_id", formik?.values?.voucher_id);
        data.append("voucher_type", formik?.values?.voucher_type);
      }
      if (formik?.values?.referral_code) {
        data.append("referral_code", formik?.values?.referral_code);
      }
    }

    // preparing reserved dates list
    let dateIndex = 0;
    let currentDate = formik?.values?.reserved_dates[0];

    if (checked == "dateRange") {
      while (
        moment(currentDate).isSameOrBefore(
          // adding recovery period in end date to check if they are not booked
          moment(formik?.values?.reserved_dates[1])
        )
      ) {
        if (!getTileDisabled({ date: currentDate })) {
          data.append(
            `reserve_dates[${dateIndex}]`,
            moment(currentDate)?.format("MMM DD YYYY")
          );
          dateIndex += 1;
        }
        currentDate = moment(currentDate).add(1, "d");
      }
    }
    if (checked == "season") {
      data["season_id"] = formik?.values?.season_id;
    }

    const localReferralInfo = localStorage.getItem("referralInfo");
    let referralSource = null;
    if (localReferralInfo) {
      const { ref, date } = JSON.parse(localReferralInfo);
      const currentDate = new Date().getTime();
      const thirtyDaysInMillis = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds
      if (currentDate - date <= thirtyDaysInMillis) {
        referralSource = ref;
      } else {
        localStorage.removeItem("referralInfo");
      }
    }

    if (referralSource) {
      data.append("referral_source", referralSource);
    }

    let object = {};
    data.forEach(function (value, key) {
      object[key] = value;
    });
    object["reserveDates"] = formik?.values?.reserved_dates;
    object["totalDays"] = formik?.values?.total_days;
    dispatch(reservationActions?.createBookingDetails(object));
    localStorage.setItem("amount", pricingDetails?.total_amount);
    dispatch(setRedirectUrl(`/reserve-stand/${formik?.values.stand_id}`))
    localStorage.setItem("redirectUrl", `/reserve-stand/${formik?.values.stand_id}`)
    history.push("/wavier-form");
  };

  //  function to a date price
  const getDayPrice = (date) => {
    const isDate = !storedAvailableDates ? huntingDetails?.available_dates?.length &&
      huntingDetails?.available_dates?.find((available_date) =>
        moment(date).isSame(available_date.date, "D")
      ) : storedAvailableDates?.available_dates?.length &&
    storedAvailableDates?.available_dates?.find((available_date) =>
      moment(date).isSame(available_date.date, "D")
    )
    if (isDate) {
      return isDate?.description;
    }
  };


  // get custom calendar tile content
  const getTileContent = ({ activeStartDate, date, view }) => {
    const isLoading = actionsLoading || calendarLoading && moment(date).format("MMMM YYYY") === moment(activeStartDate).format("MMMM YYYY");
    const isDisabled = getTileDisabled({ date });

    // If the date is less than current date and enabled, or it's already disabled, set description as N/A
    if ((moment(date).isBefore(moment().startOf("day")) && !isDisabled)) {
      return view === "month" && standDetails ? (
        <div>
          {isLoading ? (
            <Spinner />
          ) : (
            <p>N/A</p>
          )}
        </div>
      ) : null;
    }

    // If none of the above conditions are met, return the default content
    return view === "month" && standDetails ? (
      <div>
        {isLoading ? (
          <Spinner />
        ) : (
          <p>{getDayPrice(date)}</p>
        )}
      </div>
    ) : null;
  };

  const getDateRange = (startDate, endDate) => {
    let currentDate = moment(startDate);
    let dateRangeArray = [];
    while (currentDate <= moment(endDate)) {
      dateRangeArray?.push(currentDate.format("DD-MM-YYYY"));
      currentDate = currentDate.add(1, "days");
    }
    return dateRangeArray;
  };

  const blockoutDays = [];

  standDetails?.blockout_days.map((item) => {
    const blockoutRange = getDateRange(item?.start_date, item?.end_date);
    blockoutDays.push(...blockoutRange);
  });

  // on range selection check if user selected a range with disabled dates
  const onRangeSelected = (range) => {
    setChecked("dateRange")
    if (!range) return;
    if (range.length === 1) {
      setCheckDiscount(!checkDiscount);
      range.push(range[0]);
      formik.setFieldValue("reserved_dates", range);
      formik.setFieldValue("total_days", 1);
      return
    }

    // disabled day is inbetween ignore that -_-
    let currentDate = range[0];
    let disabledDaysCount = 0;
    let dateCount = 0;
    while (
      moment(currentDate).isSameOrBefore(
        // adding recovery period in end date to check if they are not booked
        moment(range[1])
      )
    ) {
      if (getTileDisabled({ view: "month", date: currentDate })) {
        disabledDaysCount++;
      }
      if (!getTileDisabled({ view: "month", date: currentDate })) {
        dateCount++;
      }

      currentDate = moment(currentDate).add(1, "d");
    }

    formik.setFieldValue("reserved_dates", range);
    formik.setFieldValue("total_days", dateCount);

    // create map between prices and days
    let daysAndPrices = [];
    let dayPrice;
    let totalPrice = 0;

    // reinitialized currentDate
    currentDate = range[0];

    while (moment(currentDate).isSameOrBefore(moment(range[1]))) {
      // if disabled flag is true then break loop
      dayPrice = getDayPrice(currentDate);

      // find if same price day exists in array
      let dayPriceIndex = daysAndPrices?.findIndex(
        (dayAndPrice) => dayAndPrice?.price === dayPrice
      );

      // if day exist then update counter
      if (!getTileDisabled({ view: "month", date: currentDate })) {
        if (dayPriceIndex > -1) {
          daysAndPrices[dayPriceIndex].days += 1;
        } else {
          // push new record
          daysAndPrices.push({ days: 1, price: dayPrice });
        }

        // update total price
        totalPrice += dayPrice;
      }
      // update current date
      currentDate = moment(currentDate).add(1, "d");
    }
    formik.setFieldValue("sub_total", totalPrice && parseFloat(totalPrice)?.toFixed(2));
    formik.setFieldValue("days_and_prices", daysAndPrices);
    setCheckDiscount(!checkDiscount);
  };

  const getTileDisabled = ({ date }) => {
    return !(sortedAvailableDates?.length &&
      sortedAvailableDates?.find((available_date) =>
        moment(date).isSame(available_date.date, "D") && available_date.is_available == 1
      ))
  };

  //use dispatch hook to call api methods
  const dispatch = useDispatch();

  //check on id change and call api
  useEffect(() => {
    if (!id) return;
    formik.setFieldValue("stand_id", id);
    dispatch(reduxActions.getStandById(id));
    dispatch(reduxActions.getStandReservedDates(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // stripe
  const options = {
    // passing the client secret obtained in step 2
    clientSecret: clientSecret,
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
      theme: "stripe",
    },
  };

  useEffect(() => {
    if (!clientSecret) {
      if (isReserved == "Yes") {
        history.push(`/success?process_completion_title=${processCompletionTitle}&process_completion_description=${processCompletionDescription}`)
      }
      setIsreserved("No")
      return
    };
    setShowPaymentModal(true);
  }, [clientSecret, actionsLoading]);

  // cleanup hook
  useEffect(() => {
    dispatch(clearState());
    if (!bookingDetails) {
      dispatch(reservationActions.deletePendingReservation())
    }
    if (bookingDetails) {
      const paymentData = { ...bookingDetails };
      setReserveDate(paymentData?.reserveDates || []);
      formik.setFieldValue("reserved_dates", bookingDetails?.reserveDates || [], [])
      data["current_date"] = moment().format("MMM DD YYYY HH:MM");
      data["api_version"] = 1;
      if (paymentData?.is_yearly_rental) {
        setChecked("yearly")
      }
      if (paymentData?.reserveDates[0]) {
        setChecked("dateRange");
      }
      if (paymentData?.season_id) {
        setChecked("season")
      }
      delete paymentData["reserveDates"];
      delete paymentData["totalDays"]

      let paymentFormData = new FormData();

      for (var key in paymentData) {
        paymentFormData.append(key, paymentData[key]);
      }

      dispatch(reservationActions.getReservationPricing(paymentFormData));

    }
    return () => {
      setShowPaymentModal(false);
      dispatch(reservationActions.resetPaymentIntent());
    };
  }, []);

  useEffect(() => {
    if (!history.location.search) {
      formik.resetForm();
      formik.setFieldValue("reserved_dates", [null, null])
      dispatch(reservationActions.clearPricing())
    }
  }, [history.location.search]);

  useEffect(() => {
    if (user && standDetails) {
      if (user?.id === standDetails?.user?.id) {
        toastHandler("You can't book your own listing", "error");
        history.push("/");
      }
    }
  }, [standDetails, user]);

  const AddDateRange = (start, end, index) => {
    while (
      moment(start).isSameOrBefore(
        // adding recovery period in end date to check if they are not booked
        moment(end)
      )
    ) {
      if (!getTileDisabled({ view: "month", date: start }) && checked !== "yearly") {
        data[`reserve_dates[${index}]`] = moment(start).format("MMM DD YYYY");
        index += 1;
      }
      start = moment(start).add(1, "d");
    }
  }

  const openYealyBooking = () => {
    setChecked("yearly")
    formik.resetForm();
    formik.setFieldValue("reserved_dates", [null, null])
    dispatch(reservationActions.clearPricing())
    data = {}
    data["stand_id"] = formik?.values.stand_id;
    data["is_yearly_rental"] = 1;
    data["current_date"] = moment().format("MMM DD YYYY HH:MM");
    data["start_date"] = (startDate && startDate) || filteredYearlyRentals?.start_date;
    data["end_date"] = (endDate && endDate) || filteredYearlyRentals?.end_date;
    data["api_version"] = 1;

    if (discount > 0) {
      if ((formik?.values?.voucher_id || voucherId) && (formik?.values?.voucher_type || voucherType)) {
        data["voucher_id"] = formik?.values?.voucher_id || voucherId;
        data["voucher_type"] = formik?.values?.voucher_type || voucherType;
      }
      if (formik?.values?.referral_code || referralCode) {
        data["referral_code"] = formik?.values?.referral_code || referralCode;
      }
    }

    let paymentFormData = new FormData();

    for (var key in data) {
      paymentFormData.append(key, data[key]);
    }
    if (!localStorage?.getItem("formData")) {
      dispatch(reservationActions?.getReservationPricing(paymentFormData, onDateError));
    }
  };

  const onSeasonSelect = (season_id) => {
    setChecked("season");
    formik.setFieldValue("reserved_dates", [null, null])
    formik.setFieldValue("season_id", season_id)
    data = {};
    data["stand_id"] = formik?.values.stand_id;
    data["season_id"] = season_id;
    data["current_date"] = moment().format("MMM DD YYYY HH:MM");
    data["api_version"] = 1;
    let paymentFormData = new FormData();

    for (var key in data) {
      if (!key.includes("reserve_dates")) {
        paymentFormData.append(key, data[key]);
      }
    }
    if (!localStorage?.getItem("formData")) {
      dispatch(reservationActions?.getReservationPricing(paymentFormData, onDateError));
    }
  }

  useEffect(() => {
    data = {}
    data["stand_id"] = formik?.values.stand_id
    data["current_date"] = moment().format("MMM DD YYYY HH:MM");
    data["api_version"] = 1;
    if (checked == "yearly") {
      data["is_yearly_rental"] = 1
      data["start_date"] = (startDate && startDate) || (filteredYearlyRentals?.start_date)
      data["end_date"] = (endDate && endDate) || (filteredYearlyRentals?.end_date)
    }
    if (checked == "season") {
      data["season_id"] = formik?.values?.season_id;
    }

    if (checked == "dateRange") {
      AddDateRange(formik.values?.reserved_dates && formik.values?.reserved_dates[0] || null,
        formik.values?.reserved_dates && formik.values?.reserved_dates[1] || null, 0)
    }

    if (discount > 0 || formik?.values?.discount > 0) {
      if ((formik?.values?.voucher_id || voucherId) && (formik?.values?.voucher_type || voucherType)) {
        data["voucher_id"] = formik?.values?.voucher_id || voucherId;
        data["voucher_type"] = formik?.values?.voucher_type || voucherType;
      }
      if (formik?.values?.referral_code || referralCode) {
        data["referral_code"] = formik?.values?.referral_code || referralCode;
      }
    }

    let paymentFormData = new FormData();

    for (var key in data) {
      paymentFormData.append(key, data[key]);
    }

    if (!localStorage?.getItem("formData")) {
      dispatch(reservationActions?.getReservationPricing(paymentFormData, onDateError));
    }
  }, [checkDiscount, discount]);

  const getRange = () => {
    if (checked == "dateRange") {
      if (bookingDetails?.reserveDates) {
        return `${moment(bookingDetails?.reserveDates[0]).format("MMM DD YYYY")} - ${moment(bookingDetails?.reserveDates[1]).format("MMM DD YYYY")}`
      }
      else return `${pricingDetails?.start_date} - ${pricingDetails?.end_date}`
    }
    else {
      return;
    }
  };

  const existingData = JSON.parse(localStorage.getItem(`stand_${id}`));
  const localSeasons = existingData?.huntDetail?.seasonal_hunting?.filter(season => !season?.is_booked);
  const localYearly = existingData?.huntDetail?.yearly_rental;

  const seasons = huntingDetails?.seasonal_hunting ? huntingDetails?.seasonal_hunting?.filter(season => !season?.is_booked) : localSeasons;
  const yearly = huntingDetails?.yearly_rental ? huntingDetails?.yearly_rental : localYearly;

  //eslint-disable-next-line
  useEffect(() => { document.title = 'Reserve Listing | iHunt' }, []);

  // Caching mechanism
  //eslint-disable-next-line
  useEffect(() => {
    if (standDetails) {
      const existingData = JSON.parse(localStorage.getItem(`stand_${id}`));
      const currentDate = moment().format("MMM DD YYYY HH:mm");
      const currentMonth = moment().format("MMMM");
      const currentYear = moment().format("YYYY");
      if (!existingData) {
        getHuntingDetails(id, { date: currentDate, month: currentMonth, year: currentYear }).then((response) => {
          setCalendarLoading(true);
          setTimeout(() => {
            const timestamp = standDetails?.booking_availability_updated_at;
            const huntDetail = response?.data;
            const dataToStore = { timestamp, huntDetail };
            storeDetailsInLocalStorage(id, dataToStore);
            setStoredAvailableDates({ available_dates: huntDetail?.available_dates });
            setCalendarLoading(false);
          }, 500);
        });
      } else {
        const standHuntDetails = existingData?.huntDetail;
        const storedDates = standHuntDetails?.available_dates || [];
        const currentMonthAvailable = storedDates.some(date => {
          const storedDateMonth = moment(date.date, "MMM DD YYYY").format("MMMM");
          return storedDateMonth === currentMonth;
        });
        if (existingData?.timestamp === standDetails?.booking_availability_updated_at && currentMonthAvailable) {
          // Check for the specified month data from storedDates if available, then set storedDates and if not, then fetch data from server.
          setStoredAvailableDates({ available_dates: storedDates });
        } else if (existingData?.timestamp === standDetails?.booking_availability_updated_at && !currentMonthAvailable) {
          getHuntingDetails(id, { date: currentDate, month: currentMonth, year: currentYear }).then((response) => {
            setCalendarLoading(true);
            setTimeout(() => {
              const huntDetail = response?.data;
              const dataToStore = { huntDetail };
              storeDetailsInLocalStorage(id, dataToStore);
              setStoredAvailableDates({ available_dates: huntDetail?.available_dates });
              setCalendarLoading(false);
            }, 500);
          });
        } else {
          localStorage.removeItem(`stand_${id}`);
          getHuntingDetails(id, { date: currentDate, month: currentMonth, year: currentYear }).then((response) => {
            setCalendarLoading(true);
            setTimeout(() => {
              const timestamp = standDetails?.booking_availability_updated_at;
              const huntDetail = response?.data;
              const dataToStore = { timestamp, huntDetail };
              storeDetailsInLocalStorage(id, dataToStore);
              setStoredAvailableDates({ available_dates: huntDetail?.available_dates });
              setCalendarLoading(false);
            }, 500);
          });
        }
      }
    }
  }, [standDetails]);

  const storeDetailsInLocalStorage = (id, details) => {
    localStorage.setItem(`stand_${id}`, JSON.stringify(details));
  };

  const handleActiveStartDateChange = ({ activeStartDate }) => {
    // Add a condition to check for the dates in local storage. If there are then use those dates else the below implementation.
    const currentDate = moment().format("MMM DD YYYY HH:mm");
    const activeMonth = moment(activeStartDate).format("MMMM");
    const activeYear = moment(activeStartDate).format("YYYY");
    const existingData = JSON.parse(localStorage.getItem(`stand_${id}`));

    const standHuntDetails = existingData?.huntDetail;
    const storedDates = standHuntDetails?.available_dates || [];
    const standSeason = standHuntDetails?.seasonal_hunting;
    const standYearly = standHuntDetails?.yearly_rental;
    const currentMonthAvailable = storedDates.some(date => {
      const storedDateMonth = moment(date.date, "MMM DD YYYY").format("MMMM");
      const storedDateYear = moment(date.date, "MMM DD YYYY").format("YYYY");
      return storedDateMonth === activeMonth && storedDateYear === activeYear;
    });
    if (currentMonthAvailable) {
      setStoredAvailableDates({ available_dates: storedDates });
    } else {
      getHuntingDetails(id, { date: currentDate, month: activeMonth, year: activeYear }).then((response) => {
        setCalendarLoading(true);
        setTimeout(() => {
          const timestamp = standDetails?.booking_availability_updated_at;
          const huntDetail = response?.data;
          const mergedDates = [...storedDates, ...huntDetail.available_dates];
          const dataToStore = { timestamp, huntDetail: { ...huntDetail, available_dates: mergedDates, seasonal_hunting: standSeason, yearly_rental: standYearly } };
          storeDetailsInLocalStorage(id, dataToStore);
          setStoredAvailableDates({ available_dates: mergedDates });
          setCalendarLoading(false);
        }, 500);
      });
    }
  };

  const currentDate = moment();

  const filteredSeasons = seasons?.filter(season => {
    const endDate = moment(season.end_date, 'MMM DD YYYY');
    return endDate.isSameOrAfter(currentDate) && !(endDate.isSame(currentDate));
  });

  const filteredYearlyRentals = yearly ? {
    ...yearly,
    start_date: (startDate && startDate) || moment(yearly.start_date, 'MMM DD YYYY').isBefore(currentDate) ?
      currentDate.format('MMM DD YYYY') :
      (moment(yearly.start_date, 'MMM DD YYYY').isSame(currentDate)) ?
        currentDate.add(1, 'day').format('MMM DD YYYY') :
        yearly.start_date,
    end_date: (endDate && endDate) || moment(yearly.start_date, 'MMM DD YYYY').isSame(currentDate) ?
      currentDate.add(1, 'year').format('MMM DD YYYY') :
      yearly.end_date
  } : null;

  const getSummaryDateRange = () => {
    if (checked === "season" && formik?.values?.season_id) {
      const selectedSeason = filteredSeasons.find(season => season?.id === Number(formik?.values?.season_id));
      return selectedSeason ? `${selectedSeason?.start_date} - ${selectedSeason?.end_date}` : "";
    } else if (checked === "yearly") {
      return `${(startDate && startDate) || filteredYearlyRentals?.start_date} - ${(endDate && endDate) || filteredYearlyRentals?.end_date}`;
    } else if (checked === "dateRange" && formik?.values?.reserved_dates) {
      return getRange();
    }
    return "";
  };

  return (
    <LoadingOverlay active={loader || reservationActionsLoading || loading} spinner>
      <div>
        <TopBar />
        <div className="inner-container">
          <h6 className="d-content-inner-title">
            Book Your Adventure
          </h6>
          <div className="row reservation-process">
            <div className="col-lg-6">
              <Form className="dates-calender">
                <div className="col-md-12">
                  <div className="daterangewrapper">
                    <div className="reservation_event_wrapper">
                      <div className="form-group">
                        <Calendar
                          allowPartialRange={true}
                          calendarType={"ISO 8601"}
                          tileContent={getTileContent}
                          tileDisabled={(e) => {
                            let disabledTile = getTileDisabled(e);
                            return disabledTile;
                          }}

                          returnValue={"range"}
                          selectRange={true}
                          onChange={onRangeSelected}
                          value={reserveDates || formik?.values?.reserved_dates}
                          defaultActiveStartDate={new Date(sortedAvailableDates &&
                            sortedAvailableDates[0]?.date) >= new Date() ? new Date(sortedAvailableDates &&
                              sortedAvailableDates[0]?.date) : new Date()}
                          minDate={new Date(sortedAvailableDates &&
                            sortedAvailableDates[0]?.date) >= new Date() ? new Date(sortedAvailableDates &&
                              sortedAvailableDates[0]?.date) : new Date()}
                          maxDate={moment(sortedAvailableDates &&
                            sortedAvailableDates[sortedAvailableDates?.length - 1]?.date)?.add(3, "y")?._d}
                          onActiveStartDateChange={handleActiveStartDateChange}
                        />
                      </div>

                      {
                        filteredSeasons && filteredSeasons?.length > 0 ? (
                          <div className="seasonal-tabs">
                            {filteredSeasons?.map((season) => (
                              !season?.is_booked && (
                                <div className="reservation_event" key={season?.id}>
                                  <div className="radio">
                                    <label className="cursor-pointer" onClick={(e) => onSeasonSelect(e.target.value)}>
                                      <input type="radio" name="myGroupName" value={season?.id}></input>
                                      <span>
                                        {season?.title}
                                        <span className="date">
                                          {season?.start_date} - {season?.end_date}
                                        </span>
                                      </span>
                                    </label>
                                  </div>
                                  <div className="price">
                                    <label>Price</label>
                                    ${season?.whole_season_rate}
                                  </div>
                                </div>
                              )
                            ))}
                          </div>
                        ) : null
                      }
                      {
                        filteredYearlyRentals &&
                        <div className="yearly-container">
                          <div className="reservation_event" >
                            <div className="radio">
                              <label className="cursor-pointer" onClick={checked != "yearly" ? () => openYealyBooking() : null}>
                                <input type="radio" checked={checked == "yearly"} name="myGroupName" ></input>
                                <span>
                                  {filteredYearlyRentals?.title}
                                </span>
                              </label>
                              <span className="yearly-date">
                                <span className="start-date" onClick={() => setShowCalendarModal(true)}>{(startDate && startDate) || filteredYearlyRentals?.start_date}</span> - <span className="end-date">{(endDate && endDate) || filteredYearlyRentals?.end_date}</span>
                              </span>
                            </div>
                            <div className="price">
                              <label className="text-end">Price</label>
                              ${filteredYearlyRentals?.yearly_rate}
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </Form>
            </div>
            <div className="col-md-6">
              <div className="res-summary-box">
                <h4 className="mb-4">Summary</h4>
                <div className="res-item d-flex justify-content-between">
                  <div className="d-inline-flex">
                    <img
                      src={standDetails?.image || defaultImage}
                      onError={(e) => (e.target.src = defaultImage)}
                      alt="listing image"
                    />
                    <div>
                      <div>
                        <strong>{standDetails?.title}</strong>
                      </div>
                      <div className="res-location">
                        <LocationPin /> {standDetails?.address}
                      </div>
                    </div>
                  </div>
                </div>
                {
                  pricingDetails?.start_date &&
                  <>
                    <div className="fs-14 d-flex justify-content-between mb-3">
                      <span>Total Days</span>
                      <strong>
                        {pricingDetails?.number_of_days}
                      </strong>
                    </div>
                    <div className="fs-14 d-flex justify-content-between mb-3">
                      <span>Date range</span>
                      <strong>
                        {getSummaryDateRange()}
                      </strong>
                    </div>
                  </>
                }
                {(pricingDetails?.start_date || localStorage?.getItem("formData")) && (
                  <>
                    <div className="pricing-details-wrapper">
                      <div className="sub-total-section">
                        <span className="sub-total-label">Sub Total</span>
                        <span className="sub-total-amount">${pricingDetails?.sub_total}</span>
                      </div>
                      {pricingDetails?.total_discount > 0 && (
                        <div className="discount-section">
                          <span className="discount-label">Discount</span>
                          <span className="discount-amount">${pricingDetails?.total_discount}</span>
                        </div>
                      )}
                      <div className="discount-breakdown-section">
                        {pricingDetails?.multi_day_discount > 0 && (
                          <div className="multi-day-discount-section">
                            <span className="multi-day-discount-label">Multi Day Discount</span>
                            <span className="multi-day-discount-amount">${pricingDetails?.multi_day_discount}</span>
                          </div>
                        )}
                        {discount > 0 && pricingDetails?.voucher_discount && (
                          <div className="voucher-discount-section">
                            <span className="voucher-discount-label">Voucher Discount</span>
                            <span className="voucher-discount-amount">${pricingDetails?.voucher_discount}</span>
                          </div>
                        )}
                        {pricingDetails?.referral_discount > 0 && (
                          <div className="referral-discount-section">
                            <span className="referral-discount-label">Referral Discount</span>
                            <span className="referral-discount-amount">${pricingDetails?.referral_discount}</span>
                          </div>
                        )}
                      </div>

                      <div className="total-amount-section">
                        <span className="total-amount-label">Total Amount</span>
                        <span className="total-amount">${pricingDetails?.total_amount}</span>
                      </div>
                    </div>
                    <div className="summary-apply-btns">
                      <button className={pricingDetails?.referral_discount && pricingDetails?.referral_discount > 0 ? `summary-apply-btn applied` : `summary-apply-btn`} onClick={() => setShowReferralModal(true)}><img src={ReferralIcon} alt="referral" />{pricingDetails?.referral_discount && pricingDetails?.referral_discount > 0 ? 'Referral Applied' : 'Apply A Referral'}</button>
                      <button className={pricingDetails?.voucher_discount ? `summary-apply-btn applied` : `summary-apply-btn`} onClick={() => setShowVoucherModal(true)}><img src={VoucherIcon} alt="voucher" />{pricingDetails?.voucher_discount ? 'Voucher Applied' : 'Apply A Voucher'}</button>
                    </div>
                    {pricingDetails?.cancellation_policy ? <div className="res-cancelation mt-3">
                      <Form.Group className="mb-4">
                        <Form.Check
                          className="cancellation-poicy-check-box mr-10"
                          type="checkbox"
                          checked={formik?.values?.cancellation_policy || false}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "cancellation_policy",
                              e.target.checked
                            );
                          }}
                          isInvalid={!formik?.values?.cancellation_policy}
                        />
                        <p className="cancellation-policy" onClick={showModal}>
                          View Cancelation Policy
                        </p>
                      </Form.Group>
                    </div> : null}
                    <button
                      type="button"
                      className="btn btn-primary full-w text-white"
                      onClick={() => formik.handleSubmit(formik.values)}
                      disabled={!formik?.values?.cancellation_policy || reservationLoader}
                    >
                      {actionsLoading || reservationActionsLoading || loading || reservationLoader ? (
                        <Spinner />
                      ) : "COMPLETE RESERVATION"}
                    </button>
                    {error && (
                      <Form.Control.Feedback type="d-block text-center invalid">
                        {error}
                      </Form.Control.Feedback>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>

          <Modal
            show={showPaymentModal}
            onHide={() => {
              if (!paying) {
                setShowPaymentModal(false);
                dispatch(reservationActions?.clearBookingDetails());
                setChecked("");
                dispatch(reservationActions.clearPricing());
                setReserveDate(null);
                formik?.resetForm();
                dispatch(reservationActions.deletePendingReservation());
                dispatch(setReservationLoader(false));
                setLoading(false);
                setLoader(false);
              }
            }}
            backdrop="static"
          >
            <Modal.Header closeButton>
            </Modal.Header>
            {clientSecret !== null && (
              <Elements stripe={promise} options={options}>
                <PaymentForm amount={formik.values.amount || pricingDetails?.total_amount}
                  setPaying={setPaying}
                  processCompletionTitle={processCompletionTitle}
                  processCompletionDescription={processCompletionDescription}
                  huntApprovalSetting={huntApprovalSetting} />
              </Elements>
            )}
          </Modal>
        </div>
        <Modal
          show={showPreConditionModal}
          onHide={onHidePreConditionModal}
          aria-labelledby="example-modal-sizes-title-lg"
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">Waiver Required</Modal.Title>
          </Modal.Header>
          <Modal.Body className="pd-4">
            <span>{showPreConditionError}</span>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              onClick={submitWavierHandler}
              className="btn btn-primary text-white"
            >
              SUBMIT WAIVER
            </button>
          </Modal.Footer>
        </Modal>
        <CancelationPolicyModal show={show}
          onHide={onHide}
          cancelationPolicy={pricingDetails?.cancellation_policy} />
        <Modal show={showConfirmationModal} onHide={() => setShowConfirmationModal(false)} centered backdrop="static">
          <Modal.Header>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <span>You are creating a reservation for the current day. Please be aware that your reservation may not be available until approved by the landowner. This could result in shorter availability than desired for same day reservations.</span>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-outline-secondary px-3 border-fifth me-2" onClick={() => setShowConfirmationModal(false)}>
              CANCEL
            </button>
            <button type="button" className="btn btn-primary text-white" onClick={handleModalProceed}>
              PROCEED
            </button>
          </Modal.Footer>
        </Modal>
        <Modal show={showDateErrorModal} onHide={() => setShowDateErrorModal(false)} centered backdrop="static">
          <Modal.Header>
            <Modal.Title>Date Unavailable</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <span>{dateErrorMessage}</span>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-primary text-white" onClick={handleCacheFlush}>
              OK
            </button>
          </Modal.Footer>
        </Modal>
        <VoucherModal
          show={showVoucherModal}
          handleClose={() => setShowVoucherModal(false)}
          formik={formik}
          standId={standDetails?.id}
          setDiscount={setDiscount}
          setVoucherType={setVoucherType}
          setVoucherId={setVoucherId}
          subTotal={pricingDetails?.sub_total}
        />
        <ReferralModal
          show={showReferralModal}
          handleClose={() => setShowReferralModal(false)}
          formik={formik}
          setDiscount={setDiscount}
          setReferralCode={setReferralCode}
        />
        <CalendarModal
          show={showCalendarModal}
          handleClose={() => setShowCalendarModal(false)}
          startDate={filteredYearlyRentals?.start_date}
          onDateSelect={(date) => {
            setStartDate(moment(date)?.format('MMM DD YYYY'));
            setEndDate(moment(date)?.subtract(1, 'day')?.add(1, 'year')?.format('MMM DD YYYY'));
          }}
        />
      </div>
    </LoadingOverlay>
  );
}
