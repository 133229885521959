import React from "react";
import PaymentConfirmationImg from "./../../../../assets/images/payment-confirmation.png";
import PlaceHolderImage from "./../../../../assets/images/payment-confirmation-1.jpg";
import "./style.scss";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


export default function paymentConfirmation() {
    return (
        <section>
            <div className="inner-container">
                <div className="row justify-content-between">
                    
                    <div className="col-lg-4 col-md-5 order-lg-1 order-md-1 order-sm-2 order-2">
                        <div className="right px-3">
                            <div className="right-inner">
                                <LazyLoadImage
                                    src={PaymentConfirmationImg}
                                    alt=""
                                    className="lazy-load-image"
                                    placeholderSrc={PlaceHolderImage}
                                    visibleByDefault={PaymentConfirmationImg}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-6 order-lg-2 order-md-2 order-sm-1 order-1">
                        <div className="left">
                            <div className="left-inner">
                                <h5>Payment and Confirmation</h5>
                                <p>
                                Enter your credit card information through the app's secure payment system to complete the booking process. Once the payment is processed, you will receive an email confirmation with the details of your reservation.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
