import toastHandler from "components/UI/toastHandler/toastHandler";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Spinner from "components/UI/Spinner.js";
import * as Yup from "yup";
import tick from "./../../../assets/images/circularTick.svg"
import { toggleFundrequestStatus, cancelFundRequest, clearFundNotifications } from "modules/dashboard/_redux/reservations/reservationActions";
import { transferAmount } from "modules/dashboard/_redux/dashboard/dashboardActions";


export default function ConfirmModal({
    show,
    setShow,
    type = "prompt",
    text,
    id,
    getRequest,
    amount,
    openPendingModal,
    from
}) {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [responseStatus, setResponseStatus] = useState("")

    const {
        actionsLoading,
    } = useSelector(
        (state) => ({
            actionsLoading: state.reservation.actionsLoading,
        }),
        shallowEqual
    );

    const validationSchema = Yup.object({
        reason: Yup.string().required("Reason is required")
    });

    const formik = useFormik({
        initialValues: {
            reason: ""
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setIsSubmitting(true);
            const data = new FormData();
            data.append("status", type);
            data.append("decline_reason", values.reason);
            dispatch(toggleFundrequestStatus(id, data, onSuccess, onError))
        },
    });

    const updateRequestState = () => {
        if (type == "cancel") {
            dispatch(cancelFundRequest(id, onSuccess, onError))
        } if (type == "transfer") {
            setLoading(true)
            const data = new FormData();
            data.append("amount", amount || localStorage.getItem("amount"));
            dispatch(transferAmount(data, onTransferAmountSuccess, onError));
        }
    }

    const onTransferAmountSuccess = () => {
        setLoading(false)
        setShow(false);
        toastHandler("Amount transferred successfully", "success");
    };

    const onSuccess = (message) => {
        if (type == "declined") {
            setIsSubmitting(false);
            formik.resetForm()
            toastHandler(message, "success");
            dispatch(clearFundNotifications())
            setResponseStatus("success")
            dispatch(getRequest())
            setShow(false);
        } else {
            setIsSubmitting(false);
            formik.resetForm()
            toastHandler(message, "success");
            setResponseStatus("success")
            setShow(false)
            dispatch(getRequest())
        }
    }
    const onError = (message) => {
        setIsSubmitting(false);
        toastHandler(message, "error");
        formik.resetForm();
        openPendingModal(false)
    }

    const handleCancel = () => {
        if (!isSubmitting) {
            formik.resetForm()
            setShow(false)
            if (from == "pendingModal") {
                openPendingModal(true)
            }
        }
    }

    return (
        <Modal
            show={show}
            onHide={handleCancel}
            className="transfer-model "
            centered
        >
            {
                type == "declined" ?
                    <>
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>
                            {
                                responseStatus == "success" ?
                                    <div className="d-flex justify-content-center align-items-center flex-column">
                                        <h4>Payment Request</h4>
                                        <p className="">Has Been Declined</p>
                                    </div> :
                                    <div className="d-flex justify-content-center align-items-center flex-column">
                                        <h4>Reason For Declining</h4>
                                        <p className="mb-0">{text}</p>
                                    </div>
                            }
                            {
                                responseStatus == "success" ?
                                    <div className="d-flex justify-content-center text-center">
                                        <button
                                            className="btn btn-primary text-nowrap text-white px-5 mx-auto d-flex"
                                            onClick={() => setShow(false)}
                                            type="button"
                                        >
                                            OK
                                        </button>
                                    </div> :
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className="d-flex justify-content-center text-center">
                                            <textarea name="reason"
                                                className="form-control my-3"
                                                value={formik.values.reason}
                                                onChange={formik.handleChange} >
                                            </textarea>
                                        </div>

                                        {formik?.touched?.reason &&
                                            formik?.errors?.reason && (
                                                <div style={{ color: "red", marginTop: -5 }}>
                                                    {formik?.errors?.reason}
                                                </div>
                                            )}
                                        <div className="d-flex justify-content-center text-center">
                                            <button
                                                className="btn btn-primary text-nowrap text-white px-3 mx-auto d-flex justify-content-center align-items-center text-center flex-grow-1 me-3"
                                                onClick={handleCancel}
                                                type="button"
                                                disabled={isSubmitting}
                                            >
                                                CANCEL
                                            </button>
                                            <button
                                                className="btn px-3 d-flex justify-content-center align-items-center text-center flex-grow-1 "
                                                type="submit"
                                                disabled={isSubmitting}
                                                style={{ border: "1px solid red", color: "red" }}
                                            >
                                                {
                                                    actionsLoading || loading ? <Spinner /> : "DECLINE"
                                                }
                                            </button>
                                        </div>
                                    </form>
                            }

                        </Modal.Body>
                    </> :
                    <>
                        <Modal.Header closeButton className="d-flex flex-column-reverse px-5 pt-5" >
                            <div>
                                {
                                    type == "desc" ?
                                        <h3>Description</h3> :
                                        <div className="d-flex flex-column justify-content-center">
                                            <img src={tick} alt="" className="mx-auto mb-2" />
                                            <h3>Confirmation</h3>
                                        </div>
                                }
                            </div>
                        </Modal.Header>
                        <Modal.Body>

                            <div className="d-flex justify-content-center text-center">
                                <p>{text}</p>
                            </div>
                            {
                                type != "desc" ?
                                    <button
                                        className="btn btn-primary text-nowrap text-white px-5 mx-auto d-flex"
                                        onClick={updateRequestState}
                                    >
                                        {
                                            actionsLoading || loading ? <Spinner /> : "YES"
                                        }
                                    </button> : null
                            }
                        </Modal.Body>
                    </>
            }
        </Modal>
    );
}
