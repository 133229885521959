import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import parse from "html-react-parser";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getDocument } from "modules/dashboard/_redux/dashboard/dashboardActions";
import CustomSpinner from "components/layout/Loader/Spinner";


export default function LandingPageWavierModal({ show, setShow, type }) {

    const dispatch = useDispatch()

    useEffect(() => {
        if (show) {
            if (type == "hunter") {
                dispatch(getDocument({ type: "waiver document" }))
            } else {
                dispatch(getDocument({ type: "lease document" }))
            }
        }
    }, [show])

    const { document, listLoading } = useSelector(
        (state) => ({
            listLoading: state.dashboard.listLoading,
            document: state.dashboard.document
        }), shallowEqual);

    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            className="faq-modal"
            aria-labelledby="example-modal-sizes-title-lg"
            centered
            size="xl"
        >
            <Modal.Header closeButton>
                {
                    type == "hunter" ?
                        <h4>Hunter Waiver</h4> : null
                }
            </Modal.Header>
            <Modal.Body>
                {listLoading ? <CustomSpinner /> : parse(document?.body.replace(/:date/g, " ")
                    .replace(/:first_name/g, " ")
                    .replace(/:last_name/g, " ")
                    || "", {
                    replace: domNode => {
                        if (domNode?.attribs && domNode?.attribs?.class == "signature") {
                            return <span></span>;
                        }
                        if (domNode?.attribs && domNode?.attribs?.src == ":signature") {
                            return <span></span>;
                        }
                    }
                })}
            </Modal.Body>
        </Modal>
    )
}