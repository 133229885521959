import React, { useState, useMemo, useRef } from "react";
import GoogleMapReact from "google-map-react";
import useSupercluster from "use-supercluster";
import "./style.scss";
import mapMarker from "../../../../assets/icons/map-marker-icon.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


const Marker = ({ children }) => children;
const ClusteringMap = ({ markers, center, handleMapChange }) => {
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(10);
  const history = useHistory()
  const mapRef = useRef();
  const data = useMemo(() => {
    let res = markers;

    res = res.map((stand) => {
      return {
        type: "Feature",
        properties: {
          cluster: false,
          id: stand?.id,
          specie: stand?.available_specie?.image,
          price: stand?.price,
          isFeatured: stand?.popular
        },
        geometry: {
          type: "Point",
          coordinates: [
            parseFloat(stand.property_address_longitude),
            parseFloat(stand.property_address_latitude),
          ],
        },
      };
    });
    return res;
  }, [markers.length]);

  const { clusters, supercluster } = useSupercluster({
    points: data,
    bounds,
    zoom,
    options: { radius: 75 },
  });

  //  {/ bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_KEY }} /}
  return (
    <div>
      <div style={{ height: "100vh", width: "100%" }}>
        <GoogleMapReact
          defaultCenter={{ lat: center[0], lng: center[1] }}
          center={{ lat: center[0], lng: center[1] }}
          defaultZoom={10}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map }) => {
            mapRef.current = map;
          }}
          onChange={({ bounds, center, zoom }) => {
            setZoom(zoom);
            setBounds([
              bounds.nw.lng,
              bounds.se.lat,
              bounds.se.lng,
              bounds.nw.lat,
            ]);
            handleMapChange(bounds, center, zoom)
          }}
          options={{
            gestureHandling: "greedy",
            mapTypeControl: true,
          }}
        >
          {clusters &&
            clusters.map((cluster) => {
              const [longitude, latitude] = cluster.geometry.coordinates;
              const {
                cluster: isCluster,
                point_count: pointCount,
                specie: specie,
                price: price,
                isFeatured: isFeatured
              } =
                cluster.properties;

              if (isCluster) {
                let size = (pointCount * 20) / data.length;

                return (
                  <Marker
                    lat={latitude}
                    lng={longitude}
                    key={`cluster-${cluster.id}`}
                    className="cluster-marker"
                  >
                    <div
                      className="cluster-marker"
                      style={{ width: size + "px", height: size + "px" }}
                    >
                      <div className="img">
                        <img src={mapMarker} alt="" />
                      </div>
                      <span>{pointCount}</span>
                    </div>
                  </Marker>
                );
              } else {
                return (
                  <Marker key={cluster.id} lat={latitude} lng={longitude}>
                    <div className="marker" style={{
                      background: isFeatured ? '#EA9B45' : null
                    }} onClick={() => { history?.push(`/stand/${cluster?.properties?.id}`) }}>
                      <div className="price-with-icon d-flex flex-column justify-content-end align-items-center">
                        {specie ? <img className="mb-2 me-0" src={specie} alt="" /> : null}
                        {price ? <span>${price}</span> : null}
                      </div>
                    </div>
                  </Marker>
                );
              }
            })}
        </GoogleMapReact>
      </div>
    </div>
  );
};

export default ClusteringMap;