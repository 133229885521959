import { createSlice } from "@reduxjs/toolkit";

const initialDashboardState = {
  error: null,
  actionsLoading: null,
  listLoading: null,
  transactionHistoryList: [],
  totalTransactionRecords: 0,
  amountInfo: null,
  amountGraphData: null,
  trophiesRoomList: [],
  trophiesRoomTotalRecords: 0,
  showJuniorHunterModal: false,
  filteredStands: [],
  transactionTab: "allDates",
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialDashboardState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
        state.searched = false;
      } else {
        state.actionsLoading = true;
        state.searched = false;
      }
    },
    // transaction list fetched
    transactionsFetched: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.transactionHistoryList = action.payload.entities;
      state.totalTransactionRecords = action.payload.totalRecords;
    },
    thresholdAmountUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    amountTransfered: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    amountInfoFetched: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.amountInfo = action.payload.amountInfo;
    },
    amountGraphDataFetched: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.amountGraphData = action.payload.amountGraphData;
    },
    trophiesRoomListFetched: (state, action) => {
      const { data, total_records } = action.payload;
      state.error = null;
      state.listLoading = false;
      state.trophiesRoomList = data;
      state.trophiesRoomTotalRecords = total_records;
    },
    documentFetched: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.document = action.payload;
    },
    clearDocument: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.document = [];
    },
    handleJuniorHunterModal: (state, action) => {
      state.showJuniorHunterModal = action.payload.flag
    },
    usersStandsFiltered: (state, action) => {
      state.listLoading = false;
      state.filteredStands = action.payload.stands;
      state.error = null;
    },
    changeTransactionTab: (state, action) => {
      state.transactionTab = action.payload;
    },
    resetTransactionTab: (state) => {
      state.transactionTab = "Invitations";
    },
  },
});
