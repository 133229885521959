
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import addIcon from "assets/images/add-schedule.svg"
import { Form } from "react-bootstrap";
import Calendar from "react-calendar";
import moment from "moment";
import toastHandler from "components/UI/toastHandler/toastHandler";
import { getStandReservedDates } from "modules/dashboard/_redux/stand/standActions";
import { addBlockOutDay, deleteBlockOutDay } from "modules/dashboard/_redux/reservations/reservationActions";
import Spinner from "components/UI/Spinner.js";
import 'react-calendar/dist/Calendar.css';
import "./style.scss";
import { useHistory } from "react-router";
import { fetchStandDetail } from "modules/landingPage/_redux/landingPageActions";


export default function Schedule({ standId, value, setValue }) {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [showBlockoutCalender, setShowBlockoutCalender] = useState(false)
  const [currentId, setCurrentId] = useState("");
  const [dateError, setDateError] = useState(false);
  const [adding, setAdding] = useState(false)

  const history = useHistory()
  const toastId = history.location.pathname;


  const {
    actionsLoading,
    standDetails,
    reservedDates,
  } = useSelector(
    (state) => ({
      actionsLoading: state.reservation.actionsLoading,
      standDetails: state.landingPage.standDetails,
      reservedDates: state.stand.reservedDates,
    }),
    shallowEqual
  );
  const blockoutDays = [];
  const reservedDateRange = [];
  const recoverDateRange = [];
  const preBlockoutDays = [];

  useEffect(() => {
    dispatch(getStandReservedDates(standId));
  }, [])

  reservedDates && reservedDates.map(date => {
    for (let i = 1; i <= standDetails?.recovery_period_days; i++) {
      const reservationStartDate = moment(date.start_date)
      preBlockoutDays.push(reservationStartDate?.subtract(i, "days")?.format("DD-MM-YYYY"))
    }
  })

  const getDateRange = (startDate, endDate, type) => {
    let currentDate = moment(startDate);
    let dateRangeArray = [];
    while (currentDate <= moment(endDate)) {
      if (type == 'blockout') {
        dateRangeArray.push(currentDate.format("DD-MM-YYYY"))
      } else {
        dateRangeArray.push({
          date: currentDate.format("DD-MM-YYYY"),
          type: "reserved"
        });
      }
      currentDate = currentDate.add(1, "days")
    }
    return dateRangeArray;
  }

  standDetails && standDetails?.blockout_days?.map(item => {
    const blockoutRange = getDateRange(item.start_date, item.end_date, 'blockout');
    blockoutDays.push(...blockoutRange)
  })

  reservedDates && reservedDates?.map((range) => {
    const reserveRange = getDateRange(range.start_date, range.end_date)
    reservedDateRange.push(...reserveRange);
    if (standDetails?.recovery_period_days > 0) {
      for (let i = 1; i <= standDetails?.recovery_period_days; i++) {
        recoverDateRange.push({
          date: moment(range.end_date).add(i, "days").format("DD-MM-YYYY"),
          type: "recovery"
        })
      }
    }
  })

  const handleDispatch = () => {
    setAdding(true)
    if (!moment(moment(startDate).format("MMM DD YYYY"), "MMM DD YYYY").isValid()) {
      setDateError(true);
      return
    }
    if (moment(moment(startDate).format("MMM DD YYYY"), "MMM DD YYYY").isValid()) {
      setDateError(false);
    }
    dispatch(addBlockOutDay({
      start_date: moment(startDate).format("MMM DD YYYY"),
      end_date: moment(endDate).format("MMM DD YYYY"),
    },
      standDetails?.id,
      onSuccess,
      onError))
  }

  const handleDelete = (id) => {
    setCurrentId(id)
    dispatch(deleteBlockOutDay(id, onSuccessDelete, onError))
  }

  const onRangeSelected = (range) => {
    if (!range) return;

    if (range.length == 1) {
      setStartDate(range[0])
      setendDate(range[0])
    } else {
      setStartDate(range[0])
      setendDate(range[1])
    }
  }

  const handleShowCalender = () => {
    setShowBlockoutCalender(!showBlockoutCalender)
    setDateError(false)
    setStartDate("")
    setendDate("")
  }

  const onSuccess = () => {
    toastHandler("Blockout Day added", "success", toastId);
    dispatch(fetchStandDetail(standId));
    setValue(value + 1)
    setShowBlockoutCalender(false)
    setStartDate("")
    setendDate("")
    setAdding(false)
  };

  const onSuccessDelete = () => {
    toastHandler("Blockout Day Deleted", "success", toastId);
    dispatch(fetchStandDetail(standId));
    setValue(value + 1)
    setShowBlockoutCalender(false)
    setStartDate("")
    setendDate("")
    setAdding(false)
  };

  const onError = () => {
    toastHandler("Something Went Wrong, please try again", "error", toastId);
    setStartDate("")
    setendDate("")
    setAdding(false)
  };

  let disableDates = [...blockoutDays, ...reservedDateRange, ...recoverDateRange, ...preBlockoutDays];

  return (
    <>
      <div className="schedule">
        <div className="row">
          <div className="col-md-6">
            <div className="section1">
              <div className="header">
                <span>Schedule</span>
              </div>
              <div className="section1-body">
                <Form className="dates-calender">
                  <div className="">
                    <Calendar
                      allowPartialRange={true}
                      calendarType={"ISO 8601"}
                      selectRange={false}
                      tileClassName={({ date, view }) => {
                        if (reservedDateRange.find(x => x.date === moment(date).format("DD-MM-YYYY") && x.type == "reserved" && !blockoutDays.includes(x.date))) {
                          return 'reserved-date'
                        } if (reservedDateRange.find(x => x.date === moment(date).format("DD-MM-YYYY") && x.type == "reserved-special")) {
                          return 'special-reserved-date'
                        } if (recoverDateRange.find(x => x.date === moment(date).format("DD-MM-YYYY") && x.type == "recovery")) {
                          return 'recovery-date'
                        } if (preBlockoutDays.find(x => x === moment(date).format("DD-MM-YYYY"))) {
                          return 'pre-blocked-date'
                        } if (recoverDateRange.find(x => x.date === moment(date).format("DD-MM-YYYY") && x.type == "special-recovry")) {
                          return 'special-recover-date'
                        } 
                        if (blockoutDays.find(x => x === moment(date).format("DD-MM-YYYY"))) {
                          return 'blocked-date'
                        }
                      }}
                      minDate={new Date()}
                      className="react-calendar w-100"
                    />
                  </div>
                </Form>
                <div className="days-section">
                  <div className="day-section">
                    <span className="reserved-days">Reserved days</span>
                    <span className="pre-blocked-days">Pre Hunt Blocked Days</span>
                    <span className="blocked-days">Blocked Days</span>
                    <span className="recovery-days">Recovery Days</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="section2">
              <div className="header d-flex justify-content-between align-items-center">
                <span>Block Days <span className="optional">(optional)</span></span>
                {
                  showBlockoutCalender ?
                    <button className="btn add-btn d-flex justify-content-between align-items-center p-0" onClick={handleShowCalender}>
                      <i className="bi bi-arrow-left-short text-primary"></i>
                      <span className="text-primary">Back</span>
                    </button> :
                    <button className="btn add-btn d-flex justify-content-between align-items-center p-0" onClick={handleShowCalender}>
                      <img src={addIcon} className="img-fluid add-icon" alt="img" />
                      <span className="text-primary">Add</span>
                    </button>
                }
              </div>
              <div className="section2-body">
                {
                  showBlockoutCalender && (<><Form className="dates-calender">
                    <div className="w-100">
                      <div className="custon-dateRange">
                        <i className="far fa-calendar"></i>
                        <input
                          className="form-control"
                          value={startDate || endDate ? `${moment(startDate).format("MMM DD YYYY")} - ${moment(endDate).format("MMM DD YYYY")}` : null}
                        />
                      </div>
                      <Calendar
                        allowPartialRange={true}
                        calendarType={"ISO 8601"}
                        returnValue={"range"}
                        selectRange={true}
                        onChange={onRangeSelected}
                        tileDisabled={({ date, view }) => {
                          if (disableDates.find(x => x.date === moment(date).format("DD-MM-YYYY")))
                            return true
                        }}
                        minDate={new Date()}
                        tileClassName={({ date, view }) => {
                          if (preBlockoutDays.find(x => x === moment(date).format("DD-MM-YYYY"))) {
                            return 'pre-blocked-date-disabled'
                          } 
                          if (blockoutDays.find(x => x === moment(date).format("DD-MM-YYYY"))) {
                            return 'blocked-date-disabled'
                          }
                        }}
                        className="react-calendar1 w-100"
                      />
                      {dateError && <p className="red">Date is required </p>}
                    </div>
                  </Form>
                    <button className="btn btn-primary w-100 text-white mt-4" onClick={handleDispatch}>
                      {actionsLoading && adding ? (
                        <Spinner />
                      ) : (
                        "ADD"
                      )}
                    </button></>
                  )
                }
                {standDetails?.blockout_days.length > 0 ?
                  <div className={standDetails?.blockout_days.length <= 1 ? "block-days-section-0" : "block-days-section"}>
                    {
                      standDetails && standDetails.blockout_days.map(item => {
                        return (
                          <div className="block-date-detail">
                            <span className="block-date">{item.start_date} - {item.end_date}
                              <button className="btn p-0" style={{ float: "right" }} onClick={() => handleDelete(item.id)}>
                                <span style={{ color: "red" }}> {(actionsLoading && currentId == item.id) ? (
                                  <Spinner />
                                ) : (
                                  "DELETE"
                                )}</span>
                              </button>
                            </span>
                          </div>
                        )
                      })
                    }
                  </div> : !showBlockoutCalender ?
                    <div className="block-days-section-0">
                      <p className="m-auto">No Block Out Day is added yet</p>
                    </div> : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
