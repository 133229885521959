import { createSlice } from "@reduxjs/toolkit";

const initialLandingPageState = {
  error: null,
  actionsLoading: null,
  listLoading: null,
  notificationsLoading: false,
  allStands: [],
  popularStands: [],
  species: null,
  standsList: [],
  fetchingStand: false,
  totalStands: 0,
  standsBlogList: [],
  totalStandBlogs: 0,
  standDetails: null,
  weatherReport: null,
  recentBlogs: null,
  verse: null,
  blogsList: [],
  totalBlogRecords: 0,
  blogDetails: null,
  dashboardCounters: null,
  standTrophies: [],
  standReviews: [],
  faqs: null,
  tutorialsList: [],
  standFollower: [],
  totalFollower: 0,
  totalReviewsRecords: 0,
  totalTrophiesRecords: 0,
  totalTutorialsRecords: 0,
  relatedLands: [],
  notifications: [],
  totalNotificationRecords: 0,
  newNotificationAvailable: false,
  feedbacks: [],
  leasingMarkup: null,
  postList: [],
  selectedPost: null,
  totalPosts: 0,
  inputFieldList: [],
  newRequest: false,
  pointOfInterest: [],
  siteTypes: [],
  terrains: [],
  facebookLink: "",
  isForOthree: false,
  forOThreeMessage: "",
  isLogin: false,
  unAuthorizedMessage: "",
  sliderImages: [],
  authenticationRequired: false,
  authStatus: false,
  userDetails: null,
  reservationLoader: false,
  selectedVoucher: null,
  totalVouchers: 0,
  voucherList: [],
  uploadProgress: 0,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const landingPageSlice = createSlice({
  name: "dashboard",
  initialState: initialLandingPageState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
        state.searched = false;
      } else {
        state.actionsLoading = true;
        state.searched = false;
      }
    },
    landingPageDataFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.allStands = action.payload.allStands.slice(0, 6);
      state.popularStands = action.payload.popularStands.slice(0, 6);
      state.species = action.payload.species;
    },
    requestFetchStand: (state) => {
      state.fetchingStand = true;
      state.error = null
    },
    standsFetched: (state, action) => {
      const { stands, totalRecords, firstTimeFetched } = action.payload;
      state.error = null;
      if (firstTimeFetched) {
        state.standsList = stands;
      } else {
        state.standsList = [...state.standsList, ...stands];
      }
      state.totalStands = totalRecords;
      state.fetchingStand = false;
    },
    createStandPost: (state, action) => {
      state.error = null;
      state.newRequest = !state.newRequest;
      state.actionsLoading = false;
    },
    updateStandPost: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      //state.newRequest = !state.newRequest;
      state.postList = state.postList.map((data) => data.id === action.payload?.id ? action.payload : data);
    },
    standBlogFetched: (state, action) => {
      const { blogs, totalRecords, firstTimeFetched } = action.payload;
      state.error = null;
      if (firstTimeFetched) {
        state.standsBlogList = blogs;
      } else {
        state.standsBlogList = [...state.standsBlogList, ...blogs];
      }
      state.totalStandBlogs = totalRecords;
      state.fetchingStand = false;
    },
    standDetailsFetched: (state, action) => {
      const { standDetails } = action.payload;
      state.error = null;
      state.actionsLoading = false;
      state.standDetails = standDetails;
      state.isBlogEnabled = standDetails?.is_blog_enabled ? true : false;
    },
    clearStandDetailsFectched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.standDetails = null;
    },
    clearStandListing: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.standsList = [];
    },
    weatherReportFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.weatherReport = action.payload.weatherReport;
    },
    recentBlogsFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.recentBlogs = action.payload.blogs;
    },
    blogsListFetched: (state, action) => {
      const { blogs, totalRecords } = action.payload;
      state.error = null;
      state.listLoading = false;
      state.blogsList = blogs;
      state.totalBlogRecords = totalRecords;
    },
    tutorialsListFetched: (state, action) => {
      const { tutorials, totalRecords } = action.payload;
      state.error = null;
      state.listLoading = false;
      state.tutorialsList = tutorials;
      state.totalTutorialsRecords = totalRecords;
    },
    blogDetailFetched: (state, action) => {
      const { blog } = action.payload;
      state.error = null;
      state.listLoading = false;
      state.blogDetails = blog;
    },
    verseFetched: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.verse = action.payload.verse;
    },
    dashboardCountersFetched: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.dashboardCounters = action.payload.dashboardCounters;
    },
    standTrophiesFecthed: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.standTrophies = action.payload.trophies;
      state.totalTrophiesRecords = action.payload.totalData;
    },
    standReviewsFecthed: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.standReviews = action.payload.reviews;
      state.totalReviewsRecords = action.payload.totalReviews;
    },
    standFollowersFetched: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.standFollower = action.payload.follower;
      state.totalFollower = action.payload.totalFollower;
    },
    faqsFetched: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.faqs = action.payload.faqs;
    },
    relatedLandsFetched: (state, action) => {
      const { relatedLands } = action.payload;
      if (relatedLands.length > 3) {
        state.relatedLands = relatedLands.slice(0, 3);
      } else {
        state.relatedLands = relatedLands;
      }
      state.error = null;
      state.listLoading = false;
    },
    startNotificationsCall: (state, action) => {
      state.error = null;
      state.notificationsLoading = true;
    },
    notificationsFetched: (state, action) => {
      const { notifications, totalRecords, isFirstFetch } = action.payload;
      state.notificationsLoading = false;
      state.error = null;
      if (isFirstFetch) {
        state.notifications = notifications;
      } else {
        state.notifications = [...state.notifications, ...notifications];
      }
      state.totalNotificationRecords = totalRecords;
    },
    toggleNewNotificationFlag: (state, action) => {
      const { flag } = action.payload;
      state.newNotificationAvailable = flag;
    },
    feebacksFetched: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.feedbacks = action.payload.feedbacks;
    },
    leasingMarkupFetched: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.leasingMarkup = action.payload.markup;
    },
    leaseApplicationPosted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    notificationCountFetched: (state, action) => {
      state.error = null;
      state.notificationsLoading = false;
      state.notificationsCount = action.payload.count;
    },
    toggleLoading: (state) => {
      state.notificationsLoading = false;
    },
    standPostsFetched: (state, action) => {
      state.error = null;
      state.listLoading = false;
      if (action.payload.firstTime) {
        state.postList = action.payload.data;
      }
      else {
        state.postList = [...state.postList, ...action.payload.data];
      }
      state.totalPosts = action.payload.total_records;
    },
    postDetailsFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.postDetails = action.payload.selectedPost;
    },
    deletedStandPost: (state) => {
      state.error = null;
      state.newRequest = !state.newRequest
    },
    fetchedInputFields: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.inputFieldList = action.payload.data;
    },
    fetchedPointOfInterest: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.pointOfInterest = action.payload.data;
    },
    fetchedSiteTypes: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.siteTypes = action.payload.data;
    },
    fetchedTerrains: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.terrains = action.payload.data;
    },
    facebookFetched: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.facebookLink = action.payload.link;
    },
    setFourOThree: (state, action) => {
      state.isForOthree = action.payload.flag;
      state.forOThreeMessage = action.payload.message;
    },
    sliderPicturesFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.sliderImages = action.payload.images;
    },
    setAuthenticationRequired: (state, action) => {
      state.authenticationRequired = action.payload.flag;
    },
    setAuthStatus: (state, action) => {
      state.authStatus = action.payload.flag;
    },
    setReservationLoader: (state, action) => {
      state.reservationLoader = action.payload.flag;
    },
    userDetailFetched: (state, action) => {
      const { user } = action.payload;
      state.error = null;
      state.actionsLoading = false;
      state.userDetails = user;
    },
    setLogin: (state, action) => {
      state.isLogin = action.payload.flag;
      state.unAuthorizedMessage = action.payload.message;
    },
    standVouchersFetched: (state, action) => {
      state.error = null;
      state.listLoading = false;
      if (action.payload.firstTime) {
        state.voucherList = action.payload.data;
      }
      else {
        state.voucherList = [...state.voucherList, ...action.payload.data];
      }
      state.totalVouchers = action.payload.total_records;
    },
    voucherDetailsFetched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.voucherDetails = action.payload.selectedVoucher;
    },
    deletedStandVoucher: (state) => {
      state.error = null;
      state.newRequest = !state.newRequest
    },
    createStandVoucher: (state, action) => {
      state.error = null;
      state.newRequest = !state.newRequest;
      state.actionsLoading = false;
    },
    updateStandVoucher: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      //state.newRequest = !state.newRequest;
      state.voucherList = state.voucherList.map((data) => data.id === action.payload?.id ? action.payload : data);
    },
    setUploadProgress: (state, action) => {
      state.uploadProgress = action.payload.progress;
    },
    requestBlogPost: (state, action) => {
      state.error = null;
      state.newRequest = !state.newRequest;
      state.actionsLoading = false;
    },
    clearPostDetailsFectched: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.postDetails = null;
    },
  },
});
