import React from "react";
import TopBar from "../../../landingPage/pages/topBar.js/index.js";
import Updated from "../../../../assets/icons/Icon feather-check-circle.svg";
import { Link } from "react-router-dom";

export default function PasswordResetSuccess() {
  return (
    <div className="inner-container">
      <TopBar />
      <div className="form-container">
        <div className="form-box">
          <img src={Updated} className="updated-icon mb-4 mt-2" alt="" />
          <h2 className="text-center mb-1">Password Updated</h2>
          <p className="text-center">
            Your password has been successfully updated. Now you can Login
          </p>
          <Link
            to="/auth/login"
            className="btn btn-primary w-100 text-white mt-4"
          >
            LOGIN
          </Link>
        </div>
      </div>
    </div>
  );
}
