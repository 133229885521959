import React from "react";
import "./style.scss";


export default function UserStory() {
    return (
        <section className="youtube-video-frame">
            <div className="inner-container">
                <div className="row">
                    <div className="col-12">
                    </div>
                    <div className="col-lg-7 mx-auto">
                    </div>
                    <div className="col-lg-6 mx-auto">
                    </div>
                </div>
            </div>

        </section>
    );
}
