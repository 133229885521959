import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { reSendVerificationEmail } from "../../_redux/authActions";
import toastHandler from "components/UI/toastHandler/toastHandler";
import Spinner from "components/UI/Spinner.js";
import { useHistory } from "react-router";

const style = {
  color: "red",
  fontSize: 14,
  textAlign: "center",
  width: "100%",
  display: "flex",
  marginBottom: 0
}

export default function EmailVerfication({ show, onHide }) {
  const history = useHistory();
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const pending = true ? history.location.search == "?=signup" : false
  const token = localStorage.getItem('token')

  const handleTabClose = () => {
    localStorage.removeItem('token');
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleTabClose);

    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, []);

  const { actionsLoading } = useSelector(
    (state) => ({
      actionsLoading: state.auth.actionsLoading,
    }),
    shallowEqual
  );

  const handleEmailSend = () => {
    if (token) {
      dispatch(reSendVerificationEmail(onSuccess, onError))
    } else {
      setError(true);
      setTimeout(() => {
        setError(false);
        history.push("/auth/login")
      }, 1000)
    }
  }
  const onSuccess = () => {
    toastHandler("Email is sent successfully", "success");
    onHide();
  };
  const onError = () => {
    toastHandler("Something Went Wrong, please try again", "error");
    onHide();
  };
  return (
    <div>
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
        centered
        size="md"
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Verification required
            {
              error ? <p style={style}>Token is Expired please login</p> : null
            }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            pending ?
              <p>An Email is sent to your registered email address to verify your account.</p> :
              <p>Your account is not verified. Please verify your account to use iHunt.</p>
          }
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary text-white"
            onClick={async () => {
              if (pending) {
                onHide(true);
              } else {
                handleEmailSend()
              }
            }}
          >
            {actionsLoading ? <Spinner /> : pending ? "OKAY" : "SEND VERIFICATION LINK"}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
