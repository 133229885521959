import React, { useEffect, useMemo, useState } from "react";
import Pagination from "components/UI/pagination/Pagination";
import * as actions from "modules/dashboard/_redux/reservations/reservationActions";
import { HunterNotificationModal } from "./additionalHunter/notifications/hunterNotificationModal";
import Equalizer from "assets/icons/equalizer-line 1.svg";
import { Tab, Tabs, Dropdown, DropdownButton, ButtonGroup } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useDashboardUIContext } from "../DashboardUIContext";
import ReservationItem from "./reservation-item/reservationItem";
import InvitationCodeModal from "components/UI/modals/invitationCode-modal/InvitationCodeModal";
import CustomSpinner from "components/layout/Loader/Spinner";
import NoRecordFound from "utils/noRecordFound";
import axios from "axios";
import "./style.scss";


export default function MyReservations() {
  const history = useHistory();
  let search = useLocation().search;
  const source = axios.CancelToken.source();
  const page = new URLSearchParams(search).get("page") || 1;
  const activeTab = new URLSearchParams(search).get("activeTab") || "current";
  const [key, setKey] = useState(activeTab);
  const [filter, setFilter] = useState("all");
  const [pageCount, setPageCount] = useState(0);
  const [openHunterInvitationNotificationModal,
    setOpenHunterInvitationNotificationModal
  ] = useState(false);
  const [openInvitationCodeModal, setOpenInvitationCodeModal] = useState(false);
  const [value, setValue] = useState(0);

  const dispatch = useDispatch();

  const dashboardPageUIContext = useDashboardUIContext();
  const dashboardPageUIProps = useMemo(() => {
    return {
      queryParams: dashboardPageUIContext.reservationQueryParams,
      setQueryParams: dashboardPageUIContext.setReservationQueryParams,
    };
  }, [dashboardPageUIContext]);

  const {
    reservationsList, totalRecords,
    invitationDetails, invitationDetailsLoading,
    activeReservationLoading,
    reservationHistoryLoading,
    reservationHistoryList,
    user
  } = useSelector(
    (state) => ({
      reservationsList: state.reservation.reservationsList,
      reservationHistoryList: state.reservation.reservationHistoryList,
      activeReservationLoading: state.reservation.activeReservationLoading,
      reservationHistoryLoading: state.reservation.reservationHistoryLoading,
      totalRecords: state.reservation.totalReservations,
      invitationDetails: state.reservation.invitationDetails,
      invitationDetailsLoading: state.reservation.invitationDetailsLoading,
      user: state.auth.user,
    }),
    shallowEqual
  );

  useEffect(() => {
    if(!activeTab) return;
    dashboardPageUIProps.setQueryParams({
      ...dashboardPageUIProps.queryParams,
      per_page: dashboardPageUIProps.queryParams.per_page,
      page: parseInt(page),
      type: activeTab === "current" && "current",
    });
    dispatch(
      actions.getReservations({
        per_page: dashboardPageUIProps.queryParams.per_page,
        page: parseInt(page),
        type: activeTab === "current" && "current",
      }, source)
    );
    setKey(activeTab);
    setFilter("all");
  }, [activeTab])

  useEffect(() => {
    if (!page) return;
    const fetchData = async () => {
      const queryParams = {
        per_page: dashboardPageUIProps.queryParams.per_page,
        page: parseInt(page),
        type: activeTab,
      };

      if (filter !== "all") {
        queryParams.status = filter;
        delete queryParams.type;
      }

      dashboardPageUIProps.setQueryParams(queryParams);
      dispatch(actions.getReservations(queryParams, source));
    };

    fetchData();

    return () => {
      dispatch(actions.clearReservationList());
      if (source) {
        source.cancel();
      }
    };
  }, [filter, page]);

  useEffect(() => {
    if (invitationDetails && (invitationDetails?.user?.id === user?.id)) {
      setOpenHunterInvitationNotificationModal(true);
    }
  }, [invitationDetails, invitationDetailsLoading])

  useEffect(() => {
    setPageCount(totalRecords / dashboardPageUIProps.queryParams.per_page);
  }, [totalRecords]);

  const handleActiveKeyChange = (key) => {
    setKey(key);
    setFilter("all");
    history.push({
      pathname: "/my-profile/my-reservations",
      search: "?" + new URLSearchParams({ activeTab: key }).toString(),
    });
  
    const queryParams = {
      ...dashboardPageUIProps.queryParams,
      type: key === "current" ? 'current' : key,
    };
  
    delete queryParams.status;
  
    dispatch(actions.getReservations(queryParams, source));
  
    if (activeTab !== key) {
      dispatch(actions.clearEntities());
    }
  };

  useEffect(() => {
    return () => {
      dispatch(actions.clearReservationList());
    };
  }, []);

  const onDone = (status) => {
    if (status == "declined") {
      setKey("current");
      history.push("/my-profile/my-reservations")
      dispatch(actions.getReservations({ ...dashboardPageUIProps.queryParams, type: "current" }, source));
    }
  }

  return (
    <>
      <div className="pb-4 d-flex align-items-center justify-content-between position-relative">
        <h6 className="d-content-inner-title font-roboto mb-0">
          My Reservations
        </h6>
        <div className="toggle-listing-map-btn action-btn-2 position-relative w-auto">
          <DropdownButton
            className="action-button"
            as={ButtonGroup}
            key={'up'}
            id={`dropdown-button-drop-up`}
            drop={'up'}
            variant="secondary"
          >
            <Dropdown.Item className="map-filter-btn" onClick={() => setOpenInvitationCodeModal(true)} eventKey="2">
              Enter Invitation code
            </Dropdown.Item>
            <Dropdown.Item className="map-filter-btn" eventKey="3">
              Cancel
            </Dropdown.Item>
          </DropdownButton>
        </div>
      </div>
      <div className="my-land">
        <div className="tabs">
          <Tabs
            defaultActiveKey="current"
            id="uncontrolled-tab-example"
            className="mb-3"
            activeKey={key}
            onSelect={(k) => handleActiveKeyChange(k)}
          >
            <Tab eventKey="current" title="Current">
              <div className="filter-wrapper">
                <div className="filter-btn">
                  <img src={Equalizer} alt="" />
                  <select
                    value={filter}
                    className="select-field"
                    onChange={(e) => {
                      setFilter(e.target.value);
                    }}
                  >
                    <option value={"pending"}>Pending</option>
                    <option value={"approved"}>Approved</option>
                    <option value={"all"}>All</option>
                  </select>
                </div>
              </div>
              <div className="row reservation-boxes">
                {activeReservationLoading ? <CustomSpinner /> :
                  reservationsList && reservationsList.length > 0 ? (
                    <>
                      {reservationsList?.map((reservation, index) => (
                        <ReservationItem
                          reservation={reservation}
                          key={index}
                          activeTab={key}
                          value={value}
                          setValue={setValue}
                        />
                      ))}
                      <div className="inner-container">
                        <Pagination
                          totalRecords={totalRecords}
                          uiProps={dashboardPageUIProps}
                          caller={"myReservations"}
                          activeTab={key}
                          history={history}
                          key={"current"}
                        />
                      </div>
                    </>
                  ) : (
                    <NoRecordFound message="No active reservation found" />
                  )}
              </div>
            </Tab>
            <Tab eventKey="history" title="History">
              <div className="filter-wrapper">
                <div className="filter-btn">
                  <img src={Equalizer} alt="" />
                  <select
                    value={filter}
                    className="select-field"
                    onChange={(e) => {
                      setFilter(e.target.value);
                    }}
                  >
                    <option value={"completed"}>Completed</option>
                    <option value={"canceled"}>Canceled</option>
                    <option value={"rejected"}>Rejected</option>
                    <option value={"all"}>All</option>
                  </select>
                </div>
              </div>
              <div className="row reservation-boxes">
                {
                  reservationHistoryList && reservationHistoryList.length > 0 ? (
                    <>
                      {reservationHistoryList?.map((reservation, index) => (
                        <ReservationItem
                          reservation={reservation}
                          key={index}
                          activeTab={key}
                        />
                      ))}
                      <div className="inner-container">
                        <Pagination
                          totalRecords={totalRecords}
                          uiProps={dashboardPageUIProps}
                          caller={"myReservations"}
                          activeTab={key}
                          history={history}
                          key={"history"}
                        />
                      </div>
                    </>
                  ) : !reservationHistoryLoading && reservationHistoryList?.length !== null ? (
                    <NoRecordFound message="No history found" />
                  ) : (
                    <CustomSpinner />
                  )}
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
      <InvitationCodeModal 
        show={openInvitationCodeModal}
        onHide={() => setOpenInvitationCodeModal(false)}
        value={value}
        setValue={setValue}
      />
      <HunterNotificationModal
        show={openHunterInvitationNotificationModal}
        setShow={setOpenHunterInvitationNotificationModal}
        reload={onDone}
      />
    </>
  );
}
