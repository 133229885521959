import axios from "../../../../services/axiosInstance";

export function getChatThread(_id, userId) {
  return axios.get(`/reservation-chat/${_id}/${userId}`);
}

export function sendMessage(_id, message, userId) {
  return axios.post(`/reservation-chat/${_id}/${userId}`, { message });
}

export function me() {
  return axios.get(`/user-profile`);
}
