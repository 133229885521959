import { handleJuniorHunterModal } from "modules/dashboard/_redux/dashboard/dashboardActions";
import React, { useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { useDashboardUIContext } from "../../DashboardUIContext";
import "./style.scss";


export default function Navlinks() {

  const dispatch = useDispatch()
  const location = useLocation();

  const dashboardUIContext = useDashboardUIContext();

  const { user } = useSelector(
    (state) => ({
      user: state.auth.user
    }),
    shallowEqual
  );

  const handleLogout = () => {
    dashBoardPageUIProps.setShowLougoutConfirmationModal(true);
  };

  const dashBoardPageUIProps = useMemo(() => {
    return {
      setShowLougoutConfirmationModal:
        dashboardUIContext.setShowLougoutConfirmationModal,
      handleCancelReservationModalClose:
        dashboardUIContext.handleCancelReservationModalClose,
      handleCloseModal: dashboardUIContext.handleCancelReservationModalClose,
      reservationId: dashboardUIContext.reservationId,
      cancelReservationToggler: dashboardUIContext.calcelReservationToggler,
    };
  }, [dashboardUIContext]);

  return (
    <div className="navlink-item">
      <ul>
        <li>
          <NavLink 
            exact 
            to="/my-profile/my-stands"
            className={(location?.pathname.includes("/my-profile/stand-bookings") || location?.pathname.includes("/my-profile/user-review")) && "active"}
          >
            My Listing
          </NavLink>
        </li>
        <li>
          <NavLink exact to="/my-profile/followed-stands">
            Followed
          </NavLink>
        </li>
        <li>
          <NavLink exact to="/my-profile/my-reservations">
            My Reservations
          </NavLink>
        </li>
        <li>
          <NavLink exact to="/my-profile/my-profile">
            Profile
          </NavLink>
        </li>
        {
          user?.is_junior ?
            <li>
              <a onClick={() => dispatch(handleJuniorHunterModal(true))}
                style={{
                  color: "#928f8f",
                  textDecoration: "none",
                  display: "block",
                  cursor: "pointer"
                }}
              >
                My Groups
              </a>
            </li> :
            <li>
              <NavLink exact to="/my-profile/my-groups">
                My Groups
              </NavLink>
            </li>
        }
        <li>
          <NavLink exact to="/my-profile/change-password">
            Change Password
          </NavLink>
        </li>
        <li>
          <NavLink exact to="/my-profile/trophies">
            Trophies
          </NavLink>
        </li>
        <li>
          <NavLink exact to="/my-profile/reviews">
            Reviews
          </NavLink>
        </li>
        <li>
          <NavLink exact to="/my-profile/vouchers">
            Vouchers
          </NavLink>
        </li>
        <li>
          <NavLink exact to="/my-profile/payments">
            Payments
          </NavLink>
        </li>
        <li>
          <NavLink exact to="/my-profile/trophies-room">
            Trophy Room
          </NavLink>
        </li>
        <li>
          <NavLink 
            exact 
            to="/my-profile/customer-portal/tickets"
            className={location?.pathname.includes("/my-profile/customer-portal") && "active"}
          >
            Get Help
          </NavLink>
        </li>
        <li>
          <NavLink exact to="/my-profile/feedback">
            Feedback
          </NavLink>
        </li>
        <li>
          <a className="cursor-pointer" onClick={handleLogout}>
            Logout
          </a>
        </li>
      </ul>
    </div >
  );
}
