import React, { useState, useEffect } from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "./AdditionalHunterInvitationModal.scss";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import toastHandler from "components/UI/toastHandler/toastHandler";
import Spinner from "components/UI/Spinner.js";
import { resetLoader, additionalHunterInvitation, getAllAdditionalHuntersInvitations } from "modules/dashboard/_redux/reservations/reservationActions";
import { EMAIL_REGEX, modifyValuesWithDialCode } from "utils/helpers";
import { filterUser, clearUsers } from "modules/dashboard/_redux/stand/standActions";
import PhoneInput from "react-phone-input-2";
import startsWith from 'lodash.startswith';


const AdditionalHunterInvitationModal = ({ 
  show,
  setShow,
  onHide,
  reservationId,
  reload
 }) => {
  const {
    filteredUser,
  } = useSelector(
    (state) => ({
      filteredUser: state.stand.filteredUser,
    }),
    shallowEqual
  );

  const [selectedEmail, setSelectedEmail] = useState("");
  const [showLandownerWrapper, setShowLandownerWrapper] = useState(false);
  const [filter, setFilter] = useState("");
  const [invitationMethod, setInvitationMethod] = useState("");
  const [phone, setPhone] = useState("");
  const [countryDialCode, setCountryDialCode] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isRadioChecked, setIsRadioChecked] = useState(false);

  const emailValidationSchema = Yup.object({
    email: Yup.string()
      .matches(
        EMAIL_REGEX,
        "Please enter a valid Email Address"
      )
      .required("Landowner Email is required"),
  });

  const phoneValidationSchema = Yup.object({
    phone: Yup.string().required("Phone Number is required")
    .test('len', 'Invalid Phone Number', val => isPhoneValid),
    country_dial_code: Yup.string().required(),
  })

  const handleSubmit = (e) => {
    e.preventDefault();

    if (invitationMethod === "email") {
      const emailToValidate = selectedEmail.trim() !== '' ? selectedEmail : filter;
      if (emailToValidate) {
        emailValidationSchema
          .validate({email: emailToValidate}, { abortEarly: false })
          .then((email) => {
            setLoading(true);
            dispatch(additionalHunterInvitation(reservationId, {
              email: emailToValidate
            },
              onSuccess,
              onError))
            setError([]);
          })
        .catch((err) => {
          const errorMessages = err.inner.map((error) => error.message);
          setError(errorMessages.join(", "));
        });
      } else {
        setError("Landowner Email is required")
      }
    } else if (invitationMethod === "sms") {
      if (phone.trim() !== '') {
        phoneValidationSchema
          .validate({phone: phone, country_dial_code: countryDialCode}, { abortEarly: false })
          .then((phone) => {
            const updatedValues = modifyValuesWithDialCode(phone);
            setLoading(true);
            dispatch(additionalHunterInvitation(reservationId, {
              phone: updatedValues?.phone
            },
              onSuccess,
              onError))
            setError([]);
          })
        .catch((err) => {
            const errorMessages = err.inner.map((error) => error.message);
            setError(errorMessages.join(", "));
        });
      } else {
        setError("Phone Number is required");
      }
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (show) {
      return () => dispatch(clearUsers());
    }
  }, [show]);

  useEffect(() => {
    if (show) {
      if (filter.length > 0) {
        const timeout = setTimeout(() => {
          dispatch(
            filterUser({
              filter: filter,
            })
          );
        }, [800]);
        return () => clearTimeout(timeout);
      }
    }
  }, [filter]);

  const handleClose = () => {
    setShow(false);
    setShowLandownerWrapper(false);
    setIsRadioChecked(false);
    setFilter("");
    setSelectedEmail("");
    setPhone("");
    setError("");
    setInvitationMethod("");
    onHide();
    dispatch(resetLoader());
  };

  const handleEmailSelect = (email) => {
    setSelectedEmail(email);
    setShowLandownerWrapper(false);
  };

  const handleInvitationMethodChange = (method) => {
    setInvitationMethod(method);
    setFilter("");
    setSelectedEmail("");
    setPhone("");
    setError("");
    setShowLandownerWrapper(false);
    setIsRadioChecked(true);
  };

  const onError = () => {
    setLoading(false);
  };

  const onSuccess = () => {
    setLoading(false);
    toastHandler("Additional hunter invited successfully", "success");
    handleClose();
    reload()
    dispatch(getAllAdditionalHuntersInvitations({ id: reservationId, type: "" }))
  };

  const ValidatePhonNumber = (phone, country) => {
    let length = country.format?.replace(/[^.]/g, '')?.length;
    if (length > 15) {
      length = length - 1;
    }
    if ((startsWith(phone, country.dialCode) ||
      startsWith(country.dialCode, phone)) &&
      phone?.length == length) {
      return true
    } else return false
  }

  const renderEmailForm = () => {
    return (
      <div className="search-field-main">
        <div className="search-field">
          <FloatingLabel controlId="floatingInputGrid" label="Landowner Email">
            <input
              className="form-control"
              type="email"
              name="email"
              placeholder="Search landowner by email"
              value={selectedEmail ? selectedEmail : filter}
              onChange={(e) => {
                setFilter(e.target.value);
                setSelectedEmail(e.target.value);
                setShowLandownerWrapper(true);
                setError([]);
              }}
            />
          </FloatingLabel>
        </div>
        {error && error?.includes("Email") ? (
          <Form.Control.Feedback type="d-block invalid">
              {error}
            </Form.Control.Feedback>
        ) : null}
        {showLandownerWrapper && filteredUser && filteredUser?.length > 0 && filter.length > 0 && (
          <div className="landowner_wrapper wrapper2">
            {filteredUser?.map((user) => {
              return (
                <ul
                  key={user?.id}
                  onClick={() => {
                    handleEmailSelect(user?.email);
                    setError([]);
                  }}
                  className="cursor-pointer"
                >
                  <li>
                    <div className="user-info">
                      <div className="user-info__desc">
                        <h6>{`${user?.email}`}</h6>
                      </div>
                    </div>
                  </li>
                </ul>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  const renderSmsForm = () => {
    return (
      <>
        <div className="position-relative">
          <PhoneInput
            copyNumbersOnly={false}
            value={phone}
            onChange={(value, country) => {
              setPhone(value);
              setCountryDialCode(country.dialCode);
              setIsPhoneValid(ValidatePhonNumber(value, country));
              setError([]);
            }}
            containerClass="phone-input-container mb-3"
            inputClass="intl-tel-input phone-input phone-input2"
            isValid={(inputNumber, country) => {
              if (country) {
                setIsPhoneValid(ValidatePhonNumber(inputNumber, country))
                return ValidatePhonNumber(inputNumber, country)
              } else {
                setIsPhoneValid(false);
                return false
              }
            }}
          />
        </div>
        {error && error?.includes("Phone") ? (
          <Form.Control.Feedback type="d-block invalid">
            {error}
          </Form.Control.Feedback>
        ) : null}
      </>
    );
  };

  return (
    <>
      <Modal
        className="stand_landowner"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <div className=" confirmation-wizard">
              <h4 className="title">
                Invitation
              </h4>
              <div className="invitation-modal-form">
                <Form onSubmit={handleSubmit}>
                  {["radio"].map((type) => (
                    <div key={`inline-${type}`} className="mb-3">
                      <Form.Check
                        className="d-flex align-items-center w-100 mb-2"
                        inline
                        label="Send Invitation via Email"
                        name="invitationMethod"
                        value="email"
                        type={type}
                        id={`inline-${type}-1`}
                        checked={invitationMethod == "email"}
                        onClick={() => handleInvitationMethodChange("email")}
                      />
                      {invitationMethod === "email" && renderEmailForm()}
                      <Form.Check
                        className="d-flex align-items-center w-100 mb-2"
                        inline
                        label="Send Invitation via SMS"
                        name="invitationMethod"
                        value="sms"
                        type={type}
                        id={`inline-${type}-2`}
                        checked={invitationMethod == "sms"}
                        onClick={() => handleInvitationMethodChange("sms")}
                      />
                      {invitationMethod === "sms" && renderSmsForm()}
                    </div>
                  ))}
                  <button
                    type="submit"
                    className="btn btn-primary text-white w-100 mx-auto d-flex mt-4 h-44 justify-content-center align-items-center"
                    disabled={!isRadioChecked || loading}
                  >
                    {loading ? <Spinner /> : "SEND INVITE"}
                  </button>
                </Form>
              </div>
            </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AdditionalHunterInvitationModal;
