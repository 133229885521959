import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import TopBar from "../../../landingPage/pages/topBar.js/index.js";
import { useAuthUIContext } from "../AuthUIContext.js";
import Spinner from "components/UI/Spinner.js";
import * as Yup from "yup";
import * as actions from "../../_redux/authActions";
import toastHandler from "components/UI/toastHandler/toastHandler.js";
import Countdown from "react-countdown";
import "./styles.scss"

export default function ForgotPassword() {
  const dispatch = useDispatch();

  const [error, setError] = useState("");
  const [resend, setResend] = useState(false);
  const [disable, setDisable] = useState(false);

  const authUIContext = useAuthUIContext();
  const authUIProps = useMemo(() => {
    return {
      initForgotPasword: authUIContext.initForgotPasword,
    };
  }, [authUIContext]);

  const { isLoading } = useSelector(
    (state) => ({
      isLoading: state.auth.actionsLoading,
    }),
    shallowEqual
  );

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Enter a valid email")
      .required("Email is required"),
  });

  const handleSubmit = (values) => {
    setError("");
    dispatch(actions.forgotPassword(values, setError, handleSuccess));
  };

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: authUIProps.initForgotPasword,
    onSubmit: handleSubmit,
  });

  const handleSuccess = () => {
    if (!resend) {
      setResend(true)
    }
    setDisable(true)
    toastHandler("Email is sent to your provided email to reset password", "success");
  };

  const handleComplete = () => {
    setDisable(false);
    setResend(false);
  }

  useEffect(() => {document.title = 'Forgot Password | iHunt'}, []);

  return (
    <div className="inner-container">
      <TopBar />
      <div className="form-container">
        <h2 className="text-center mb-1">Forgot Password</h2>
        <p className="text-center">
          Please enter your email to reset your password
        </p>
        <div className="form-box">
          {error && (
            <Form.Control.Feedback type="d-block invalid">
              {error}
            </Form.Control.Feedback>
          )}
          <Form onSubmit={formik.handleSubmit}>
            <FloatingLabel controlId="floatingInputGrid" label="Email">
              <Form.Control
                type="email"
                name="email"
                placeholder="Email"
                onChange={formik.handleChange}
                value={formik.values.email}
              />
            </FloatingLabel>
            {formik?.errors?.email && (
              <Form.Control.Feedback type="d-block invalid">
                {formik.errors.email}
              </Form.Control.Feedback>
            )}
            <button className={disable ? "btn btn-light w-100 text-white mt-4 disable-btn" : "btn btn-primary w-100 text-white mt-4"} disabled={disable} style={{

            }}>
              {isLoading ? (
                <Spinner />
              ) : (
                resend ? "RESEND LINK" : "SEND LINK"
              )}
            </button>
            {
              resend ?
                <div className="countdown">
                  <span className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="13.673" height="16.188" viewBox="0 0 13.673 16.188">
                      <g id="timer-5478192" transform="translate(-147.744 -40.537)">
                        <path id="Path_1238" data-name="Path 1238" d="M437.174,94.152a1.315,1.315,0,0,0-1.814.408l2.221,1.406a1.314,1.314,0,0,0-.408-1.814Z" transform="translate(-277.895 -51.606)" fill="#515151" />
                        <path id="Path_1239" data-name="Path 1239" d="M330.456,228.017a.271.271,0,0,0-.233-.037.267.267,0,0,0-.142.1l-1.519,2.169h0l-.067-.014-.023,0a.694.694,0,0,0-.093-.007.754.754,0,0,0-.328.074h0c-.022.01-.043.022-.064.034s-.041.027-.061.041-.038.03-.056.046a.757.757,0,0,0,.508,1.317.751.751,0,0,0,.225-.034.76.76,0,0,0,.336-.214c.016-.018.031-.037.046-.056s.028-.04.041-.061.024-.042.034-.064h0a.754.754,0,0,0,.074-.328c0-.01,0-.02,0-.029h0c0-.02,0-.039,0-.058h0a.819.819,0,0,0-.021-.111h0c-.008-.027-.017-.054-.027-.079h0a.779.779,0,0,0-.078-.146h0l1.517-2.167.012-.018a.285.285,0,0,0,.025-.057.27.27,0,0,0-.1-.3Z" transform="translate(-173.798 -181.096)" fill="#515151" />
                        <path id="Path_1240" data-name="Path 1240" d="M155.4,43.106v-.922h.5a.269.269,0,0,0,.269-.269V40.806a.269.269,0,0,0-.269-.269h-2.644a.269.269,0,0,0-.269.269v1.108a.269.269,0,0,0,.269.269h.5v.922a6.838,6.838,0,1,0,1.645,0Zm-.539-.04v-.882h0v.882l-.035,0h.035Zm-2.128,1.777q-.247.091-.482.2Q152.488,44.933,152.735,44.842Zm5.057,9.349q.1-.078.2-.16Q157.9,54.114,157.792,54.191Zm-3.212.529a4.833,4.833,0,1,1,4.833-4.832A4.838,4.838,0,0,1,154.58,54.72Z" fill="#515151" />
                        <path id="Path_1241" data-name="Path 1241" d="M195.311,94.557a1.314,1.314,0,0,0-2.221,1.406Z" transform="translate(-43.616 -51.604)" fill="#515151" />
                      </g>
                    </svg>
                  </span>
                  <Countdown date={Date.now() + 30000}
                    daysInHours={true}
                    zeroPadDays={0}
                    onComplete={handleComplete}
                  />
                </div> : null
            }
          </Form>
        </div>
      </div>
    </div>
  );
}
