import toastHandler from "components/UI/toastHandler/toastHandler";
import { useFormik } from "formik";
import { addCommentToTicket } from "modules/dashboard/_redux/customer-service-portal/customerServicePortalActions";
import React from "react";
import { Form } from "react-bootstrap";
import Spinner from "components/UI/Spinner.js";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

export const AddComment = ({ id, getTicketDetails }) => {
  const dispatch = useDispatch();

  const { actionsLoading } = useSelector(
    (state) => ({
      actionsLoading: state.customerServicePortal.actionsLoading,
    }),
    shallowEqual
  );

  const onSuccess = () => {
    dispatch(getTicketDetails);
    formik.resetForm();
    toastHandler("Comment Added Successfully", "success");
  };

  const onError = (message) => {
    formik.resetForm();
    toastHandler(message, "error");
  };

  const handleSubmit = (values) => {
    const data = new FormData();
    data.append("comment", values.comment);
    dispatch(addCommentToTicket(id, data, onSuccess, onError));
  };

  const validationSchema = Yup.object().shape({
    comment: Yup.string().required("Please enter comment"),
  });

  const formik = useFormik({
    validationSchema,
    initialValues: {
      comment: "",
    },
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="add-comment-section">
        <textarea
          className="comment"
          name="comment"
          onChange={formik.handleChange}
          placeholder="Type your response here..."
          value={formik.values.comment}
        ></textarea>
        {formik?.touched?.comment && formik?.errors?.comment && (
          <Form.Control.Feedback type="d-block invalid" className="mb-2">
            {formik.errors.comment}
          </Form.Control.Feedback>
        )}
        <button type="submit" className="btn btn-primary text-white add-comment-btn">
          {actionsLoading ? (
            <Spinner />
          ) : (
            "Send"
          )}
        </button>
      </div>
    </form>
  );
};
