import config from "config";
import axios from "../../../services/axiosInstance";
import moment from "moment";

export function getLandingPageData(params) {
  return axios.get("dashboard", { params: { ...params, sort_order: "recent" } });
}

export function getStandsList(queryParams, source) {
  delete queryParams["feature"]
  return axios.get("all-stands", {
    params: {
      ...queryParams,
      date: moment().format("MMM DD YY HH:MM"),
      featured: 1
    },
    cancelToken: source.token,
  });
}

export function getStandDetail(id) {
  return axios.get(`stand-detail/${id}`);
}

export function getWeatherUpdate(params) {
  return fetch(
    "https://api.openweathermap.org/data/3.0/onecall?" +
    new URLSearchParams({
      ...params,
    })
  ).then(function (response) {
    return response.json();
  });
}

export function getBlogs(queryParams) {
  return axios.get("blogs", { params: queryParams });
}

export function getTutorials(queryParams) {
  return axios.get("tutorials", { params: queryParams });
}

export function getVerse(bibleId) {
  return axios.get(
    `https://api.scripture.api.bible/v1/bibles/${bibleId}/search?query=${"MAT"}`,
    {
      headers: {
        "api-key":
          config.VERSE_API_KEY,
      },
    }
  );
}
export function getBlogDetail(id) {
  return axios.get(`blog/${id}`);
}

export function getDashboardCounters() {
  return axios.get("dashboard-counters");
}

export function getStandTrophies(id, queryParams) {
  return axios.get(`stand-trophies/${id}`, { params: queryParams });
}

export function getStandReviews(id, queryParams) {
  return axios.get(`stand-review/${id}`, { params: queryParams });
}
export function getStandFollowers(id, queryParams) {
  return axios.get(`stand-followers/${id}`, { params: queryParams });
}
export function getFAQs() {
  return axios.get("get-faqs");
}

export function getAllStandBlogs(params, source) {
  return axios.get('all-stand-post', { params, cancelToken: source.token, });
}

export function getNotifications(queryParams) {
  return axios.get(`user-notifications`, { params: queryParams });
}

export function getNotificationsCount() {
  return axios.get(`/unread-notifications-count`);
}

export function markNotificationsRead(id, data) {
  return axios.post(`/notification/${id}`, data, {
    headers: {
      "Accept": "application/json",
    },
  });
}

export function markAllNotificationsRead() {
  return axios.post(`/notifications/mark-all-read`);
}

export function postContactUsQuery(data) {
  return axios.post("contact-us", data);
}

export function getFeedbacks() {
  return axios.get(`public-feedback`);
}

export function getLeasingMarkup() {
  return axios.get(`lease-document`);
}

export function postLeaseApplication(data) {
  return axios.post(`lease-document`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function createStandPost(data, onUploadProgress) {
  return axios.post('stand-post', data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: onUploadProgress
  });
}

export function updateStandPost(id, data, onUploadProgress) {
  return axios.post(`stand-post/${id}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: onUploadProgress
  });
}

export function getStandPosts(id, queryParams) {
  return axios.get(`stand-post-detail/${id}`, { params: queryParams });
}

export function getStandPostById(id) {
  return axios.get(`stand-post/${id}`);
}

export function deleteStandPost(id) {
  return axios.delete(`stand-post/${id}`);
}

export function getPointOfInterest() {
  return axios.get('get-point-of-interest');
}

export function getSiteTypes() {
  return axios.get('get-site-types');
}

export function getTerrains() {
  return axios.get('terrains');
}

export function getFacebookLink() {
  return axios.get(`get-fb-link`);
}

export function getSliderPicture() {
  return axios.get(`slider-images`);
}

export function getUser(id) {
  return axios.get(`user/${id}`);
}

export function getStandVouchers(id) {
  return axios.get(`stand-vouchers/${id}`);
}

export function createStandVoucher(id, data) {
  return axios.post(`stand-voucher/${id}`, data);
}

export function updateStandVoucher(id, data) {
  return axios.put(`stand-voucher/${id}`, data);
}
export function getStandVoucherById(id) {
  return axios.get(`stand-voucher/${id}`);
}
export function deleteStandVoucher(id) {
  return axios.delete(`stand-voucher/${id}`);
}
export function blogPostRequest(id, data) {
  return axios.post(`handle-stand-post-request/${id}`, data);
}