import React, { useState } from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import "./style.scss";
import EyeOffIcon from "../../../../assets/icons/feather-icon - eye-off.svg";
import EyeOnIcon from "../../../../assets/icons/feather-eye.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../auth/_redux/authActions";
import toastHandler from "components/UI/toastHandler/toastHandler";
import Spinner from "components/UI/Spinner.js";


export default function ChangePassword() {
  const dispatch = useDispatch();

  const { isLoading } = useSelector(
    (state) => ({
      isLoading: state.auth.actionsLoading,
    }),
    shallowEqual
  );

  const [currentPassword, setCurrentPassword] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const [confirmationPassword, setConfirmationPassword] = useState(false);
  const [error, setError] = useState("");

  const formInitialValues = {
    current_password: "",
    new_password: "",
    confirm_password: "",
  };

  const formValidationSchema = Yup.object().shape({
    current_password: Yup.string().required("Current Password is required"),
    new_password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be 6 characters long."),
    confirm_password: Yup.string()
      .required("Confirmation Password is required")
      .when("new_password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("new_password")],
          "Password and Confirm Password didn't match"
        ),
      }),
  });

  const handleSubmit = (values) => {
    dispatch(actions.changePassword(values, onError, onSuccess));
  };

  const onSuccess = (message) => {
    toastHandler(message, "success");
    formik.resetForm();
    setError("");
  };

  const onError = (message) => {
    setError(message);
  };

  const formik = useFormik({
    validationSchema: formValidationSchema,
    initialValues: formInitialValues,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <div className="pb-3">
        <h6 className="d-content-inner-title font-roboto mb-0">
          Change Password
        </h6>
      </div>
      <div className="d-profile-middle-content">
        <div>
          <div className="p-user-details font-roboto">
            <div className="create-password-content">
              <h3 className="create-password-title">Create New Password</h3>
            </div>
          </div>
          {error && (
            <Form.Control.Feedback type="d-block invalid">
              {error}
            </Form.Control.Feedback>
          )}
          <Form onSubmit={formik.handleSubmit}>
            <FloatingLabel
              className="mb-3"
              controlId="floatingInputGrid"
              label="Old Password"
            >
              <Form.Control
                type={currentPassword ? "text" : "password"}
                name="current_password"
                placeholder="Password"
                onChange={formik.handleChange}
                value={formik.values.current_password}
              />
              <div
                className="input-icon eye-icon"
                onClick={() => {
                  setCurrentPassword(!currentPassword);
                }}
              >
                <img src={currentPassword ? EyeOnIcon : EyeOffIcon} alt="" />
              </div>
            </FloatingLabel>
            {formik?.touched?.current_password &&
              formik?.errors?.current_password && (
                <Form.Control.Feedback type="d-block invalid">
                  {formik.errors.current_password}
                </Form.Control.Feedback>
              )}
            <FloatingLabel
              controlId="floatingInputGrid"
              label="New Password"
              className="mb-3"
            >
              <Form.Control
                type={newPassword ? "text" : "password"}
                name="new_password"
                placeholder="New Password"
                defaultValue=""
                onChange={formik.handleChange}
                value={formik.values.new_password}
              />
              <div
                className="input-icon eye-icon"
                onClick={() => {
                  setNewPassword(!newPassword);
                }}
              >
                <img src={newPassword ? EyeOnIcon : EyeOffIcon} alt="" />
              </div>
            </FloatingLabel>
            {formik?.touched?.new_password && formik?.errors?.new_password && (
              <Form.Control.Feedback type="d-block invalid">
                {formik.errors.new_password}
              </Form.Control.Feedback>
            )}
            <FloatingLabel
              controlId="floatingInputGrid"
              label="Confirm Password"
            >
              <Form.Control
                type={confirmationPassword ? "text" : "password"}
                name="confirm_password"
                placeholder="Confirm Password"
                defaultValue=""
                onChange={formik.handleChange}
                value={formik.values.confirm_password}
              />
              <div
                className="input-icon eye-icon"
                onClick={() => {
                  setConfirmationPassword(!confirmationPassword);
                }}
              >
                <img
                  src={confirmationPassword ? EyeOnIcon : EyeOffIcon}
                  alt=""
                />
              </div>
            </FloatingLabel>
            {formik?.touched?.confirm_password &&
              formik?.errors?.confirm_password && (
                <Form.Control.Feedback type="d-block invalid">
                  {formik.errors.confirm_password}
                </Form.Control.Feedback>
              )}

            <div className="d-flex">
              <button
                className="btn btn-primary font-roboto w-100 text-white mt-4"
                type="submit"
              >
                {isLoading ? (
                  <Spinner/>
                ) : (
                  "UPDATE PASSWORD"
                )}
              </button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}
