import React from "react";
import { GoogleMap, Polygon, Polyline, Marker, useJsApiLoader } from "@react-google-maps/api";
import "./style.scss";


export const Polyshape = (props) => {

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "YOUR_API_KEY"
    })

    const options = {
        fillColor: "#EA9B45",
        fillOpacity: 0.5,
        strokeColor: "#EA9B45",
        strokeOpacity: 1,
        strokeWeight: 2,
        clickable: false,
        draggable: false,
        editable: false,
        geodesic: false,
        zIndex: 1
    }

    const center = {
        lat: props.polygonePath ? props?.polygonePath[parseInt(props?.polygonePath?.length / 2)]?.lat :
            props?.polylinePath[parseInt(props?.polylinePath?.length / 2)]?.lat,
        lng: props.polygonePath ? props?.polygonePath[parseInt(props?.polygonePath?.length / 2)]?.lng :
            props?.polylinePath[parseInt(props?.polylinePath?.length / 2)]?.lng
    }

    const startMarkerPosition = {
        lat: props.polygonePath ? props?.polygonePath[0].lat : props?.polylinePath[0]?.lat,
        lng: props.polygonePath ? props?.polygonePath[0].lng : props?.polylinePath[0]?.lng
    }

    const endMarkerPosition = {
        lat: props.polygonePath ? props?.polygonePath[props?.polygonePath?.length - 1]?.lat :
            props?.polylinePath[props?.polylinePath.length - 1]?.lat,
        lng: props.polygonePath ? props?.polygonePath[props?.polygonePath?.length - 1]?.lng :
            props?.polylinePath[props?.polylinePath.length - 1]?.lng
    }

    const poltLineOptions = {
        strokeColor: '#EA9B45',
        strokeOpacity: 0.8,
        strokeWeight: 5,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
        clickable: false,
        draggable: false,
        editable: false,
        visible: true,
        radius: 30000,
        zIndex: 1
    };

    return (
        <>
            {
                isLoaded && (
                    <div className="shape">
                        {
                            props.type == "polygone" ?
                                <GoogleMap
                                    mapContainerClassName="shape-map"
                                    mapTypeId='satellite'
                                    center={center}
                                    zoom={15}
                                    version="weekly"
                                    onLoad={map => {
                                        const bounds = new window.google.maps.LatLngBounds();
                                        for (let i = 0; i < props?.polygonePath?.length; i++) {
                                            const marker = props?.polygonePath[i];
                                            const newPoint = new window.google.maps.LatLng(marker.lat, marker.lng);
                                            bounds.extend(newPoint);
                                        }
                                        map.fitBounds(bounds);
                                    }}
                                >
                                    <>
                                        <Marker position={props?.positions?.parking} />
                                        <Marker position={props?.positions?.stand} />
                                        <Polygon
                                            path={props.polygonePath}
                                            options={options}
                                        />
                                    </>
                                </GoogleMap> :
                                <GoogleMap
                                    mapContainerClassName="shape-map"
                                    mapTypeId='satellite'
                                    center={center}
                                    zoom={18}
                                    version="weekly"
                                    onLoad={map => {
                                        const bounds = new window.google.maps.LatLngBounds();
                                        for (let i = 0; i < props?.polylinePath?.length; i++) {
                                            const marker = props?.polylinePath[i];
                                            const newPoint = new window.google.maps.LatLng(marker.lat, marker.lng);
                                            bounds.extend(newPoint);
                                        }
                                        map.fitBounds(bounds);
                                    }}
                                >
                                    <>
                                        <Marker position={startMarkerPosition} />
                                        <Marker position={endMarkerPosition} />

                                        <Polyline
                                            path={props.polylinePath}
                                            options={poltLineOptions}
                                        />
                                    </>
                                </GoogleMap>
                        }
                    </div>
                )
            }
        </>

    );
}

