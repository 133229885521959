import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import "./style.scss";
import * as actions from "../../_redux/landingPageActions";

export default function Achievement() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.fetchDashboardCounters());
  }, []);

  const { dashboardCounters } = useSelector(
    (state) => ({
      dashboardCounters: state.landingPage.dashboardCounters,
    }),
    shallowEqual
  );

  return (
    <div className="acheivement">
      <div className="inner-container">
        <div className="row">
          <div className="col-md-6">
            <div className="acheivement-items">
              <div className="d-grid w-100">
                <div>
                  <h2>{dashboardCounters?.reservations}+</h2>
                  <p>Hunts Booked</p>
                </div>
                <div>
                  <h2>{dashboardCounters?.species}+</h2>
                  <p>Animals</p>
                </div>
                <div>
                  <h2>{dashboardCounters?.standCounter}+</h2>
                  <p>Hunting Locations</p>
                </div>
                <div>
                  <h2>{dashboardCounters?.landlords}+</h2>
                  <p>Landlords</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 achievement-detail">
            <h2>Our Achievements</h2>
            <p>
              As a group of hunting enthusiasts, we are proud that you can join
              our club and erase the missing link that has limited hunting for
              so many years. We are bridging the gap between private land owners
              and hunters by helping you lease the land you want for just a day
              or for the full season. With each new member that joins our club,
              it brings us one step closer to realizing our dream and we thank
              each of you for what you have done to help bring this dream alive!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
