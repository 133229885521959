import { createSlice } from "@reduxjs/toolkit";

const initialTrophyState = {
  error: null,
  actionsLoading: null,
  listLoading: null,
  trophiesList: [],
  totalTrophies: 0
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const trophySlice = createSlice({
  name: "trophies",
  initialState: initialTrophyState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    clearEntities: (state, action) => {
      state.trophiesList = null;
    },
    // trophies list fetched
    trophiesFetched: (state, action) => {
      state.error = null;
      state.listLoading = false;
      state.trophiesList = action.payload.entities;
      state.totalTrophies = action.payload.totalRecords;
    },


  },
});
