import React, { useEffect } from "react";
import "./style.scss";

export default function Privacy() {
  useEffect(() => {document.title = 'Privacy Policy | iHunt'}, []);
  
  return (
    <div className="mt-5 mb-5">
      <div className="inner-container">
        <h2 className="text-center">Privacy Policy</h2>
        <div className="faq-container mt-5 p-4">
          <h4>Privacy Policy</h4>
          <p>Last Updated: March 12-2022</p>
          <p>
          Our Goal at iHunt is to bridge the divide between Land Owners, Hunters and Business Owners to connect all with the hunting experience they desire, right at their fingertips, at a moment’s notice all the while respecting the rights and privileges of all.  This document is to provide you with a clear understanding of how we accomplish this goal.
          </p>
          <p>This Privacy Policy describes how iHunt llc. processes personal information that we collect through the iHunt Platform.</p>
         <h5 className="mt-4 ">1. PERSONAL INFORMATION WE COLLECT</h5>

          <h5 className="mt-3 "> 1.1 Information needed to use the iHunt Platform.</h5>
         <p>We collect personal information about you when you use the iHunt Platform. Without it, we may not be able to provide you with all services requested. This information includes:</p>
          <ul className="mt-2 mb-2">
            <li className="text-black m-3"><b>Contact Information, Account, Profile Information. </b> Such as your first name, last name, phone number, postal address, email address, date of birth, and profile photo, some of which will depend on the features you use.</li>
            <li className="text-black m-3"><b>Identity Verification and Payment Information. </b> Such as images of hunting license, bank account or payment account information.</li>
           
          </ul>
          <h5 className="mt-5 ">1.2 Information Automatically Collected by Using the iHunt Platform and our Payment Services.</h5>
       <p>When you use the iHunt Platform and Payment Services, we automatically collect personal information. This information may include:</p>
       
       <ul className="mt-2 mb-2">
            <li className="text-black m-3"><b>Geo-location Information. </b> 
            Such as precise or approximate location determined from your IP address or mobile device’s GPS depending on your device settings. We may also collect this information when you’re not using the app if you enable this through your settings or device permissions.</li>
            <li className=" text-black m-3"><b>Usage Information. </b>Such as the pages or content you view, searches for Listings, bookings you have made, and other actions on the iHunt Platform.</li>
            <li className="text-black m-3"><b>Log Data and Device Information. </b>Such as details about how you’ve used the iHunt Platform (including if you clicked on links to third party applications), IP address, access dates and times, hardware and software information, device information, device event information, unique identifiers, crash data, cookie data, and the pages you’ve viewed or engaged with before or after using the iHunt Platform. We may collect this information even if you haven’t created an iHunt account or logged in.</li>
            <li className="text-black m-3"><b>Payment Transaction Information. </b>Such as payment instrument used, date and time, payment amount, payment instrument expiration date and billing postcode, your address and other related transaction details.</li>
           
          </ul>
          <h5 className="mt-5 ">2.1  Personal Information We Collect from Third Parties.</h5>
          <ul className="mt-2 mb-2">
            <li className="text-black m-3">
         <b>Co-Hunters. </b> If you are invited to the iHunt Platform such as a co-Hunter on a trip, the person who invited you can submit personal information about you such as your email address or other contact information.
   </li>
   </ul>
   <h4 className="mt-5">3. HOW WE USE INFORMATION WE COLLECT</h4>
  
      <p className=" text-black   mt-2 "><b>3.1 Provide, Improve, and Develop the iHunt Platform. </b>We use personal information to:</p>
      <ul className="mt-2 mb-2">
            <li className="text-black m-3"> enable you to access the iHunt Platform and make and receive payments,</li>
            <li className="text-black m-3"> enable you to communicate with other Members on iHunt,</li>
            <li className="text-black m-3"> perform analytics, debug and conduct research,</li>
            <li className="text-black m-3"> provide customer service,</li>
            <li className="text-black m-3"> send you messages, updates, security alerts, and account notifications,</li>
            <li className="text-black m-3"> personalize and customize your experience based on your interactions with the iHunt Platform, your search and booking history, your profile information and preferences, and other content you submit, and</li>
            
            </ul>
            
            <p  className="text-black mt-2"><b>3.2   Create and Maintain a Trusted and Safer Environment. </b>We use personal information to:</p>
       <ul>
         
       <li className="text-black m-3"> detect and prevent fraud, spam, abuse, security and safety incidents, and other harmful activity,</li>
       <li className="text-black m-3">conduct security investigations and risk assessments,</li>
       <li className="text-black m-3">verify or authenticate information provided by you,</li>
       <li className="text-black m-3">comply with our legal obligations, protect the property and well-being of our Land Owners, Hunters And Business Owners</li>
       <li className="text-black m-3">resolve disputes with any Members,</li>
       <li className="text-black m-3">enforce our agreements with third parties,</li>
       <li className="text-black m-3"> comply with law, respond to legal requests, prevent harm and protect our rights </li>
       <li className="text-black m-3">enforce our rights in connection with the activities above, we may conduct profiling based on your interactions with the iHunt Platform, your profile information and other content you submit to iHunt, and information obtained from third parties. In limited cases, automated processes could restrict or suspend access to the iHunt Platform if such processes detect activity that we think poses a safety or other risk to iHunt, our Land Owners, or any third parties.</li>
       </ul>

       <p  className="text-black mt-2"><b>3.3 Provide, Personalize, Measure, and Improve our Advertising and Marketing.  </b>We may use personal information to:</p>
       <ul>
         
       <li className="text-black m-3"> send you promotional messages, marketing, advertising, and other information based on your preferences and social media advertising through social media platforms,</li>
       <li className="text-black m-3">personalize, measure, and improve our advertising,</li>
       <li className="text-black m-3">administer referral programs, rewards, surveys, sweepstakes, contests, or other promotional activities or events sponsored or managed by or its third-party partners,</li>
       <li className="text-black m-3">analyze characteristics and preferences to send you promotional messages, marketing, advertising and other information that we think might be of interest to you, and</li>
      
          </ul>

          <p  className="text-black"><b>  3.4 Provide Payment services. </b>Personal information is used to enable or authorize third parties to use Payment Services:</p>
          <ul>
         
         <li className="text-black m-3">
    Detect and prevent money laundering, fraud, abuse, security incidents.
    </li>
    <li className="text-black m-3">
     Conduct security investigations and risk assessments.
     </li>
     <li className="text-black m-3">Comply with legal obligations (such as anti-money laundering regulations).</li>
     <li className="text-black m-3">Enforce our payment terms and other payment policies.</li>
     <li className="text-black m-3">With your consent, send you promotional messages, marketing, advertising, and other information that may be of interest to you based on your preferences.
    </li>  <li className="text-black m-3"> Provide and improve the Payment Services.
    </li>
    </ul>
    <h4 className="mt-5 ">4. SHARING & DISCLOSURE</h4>
    <h5 className="mt-5 "> 4.1 Sharing With Your Consent or at Your Direction.</h5>
    <p>
    Where you provide consent, we share your information as described at the time of consent, such as when authorizing a third-party application or website to access your iHunt account or participating in promotional activities by iHunt partners or third parties.
    </p>
    <h5 className="mt-5 ">4.2 Sharing Between Members.</h5>
    <p>To help facilitate bookings or other interactions between Members, we may need to share certain information such as:</p>
      
    <ul>
         
         <li className="text-black m-3"> When a booking request is made or dispute is submitted, certain information may be shared between land owner and Hunters, including profile, name, names of any additional Hunters, cancellation history, review information, age of Hunters (unless prohibited by applicable law), dispute outcome (when applicable) and other information you choose to share and submit. When a booking is confirmed, additional information is shared to assist with coordinating the hunting trip, like profile photo and phone number. When A hunter books a hunt, land owner contact information is shared with hunter.</li>
         <li className="text-black m-3"> When you as a land owner allow anyone to manage your hunting properties, you authorize that person to access and update your information, including but not limited to certain information like your full name, phone number, Accommodation address, calendar, Listing information, Listing photos, and email address.</li>
         <li className="text-black m-3">  
    If you as a Hunter invite any other hunters along, you are allowing us to share information with all in your party with said land owner and iHunt associates.</li>
          
            </ul>
            <h5 className="mt-5 ">4.3 Complying with Law, Responding to Legal Requests, Preventing Harm and Protecting our Rights.</h5>
       <p>We may disclose your information to courts, law enforcement, governmental or public authorities, tax authorities, or authorized third parties, if and to the extent we are required or permitted to do so by law or where disclosure is reasonably necessary: (i) to comply with our legal obligations, (ii) to comply with a valid legal request or to respond to claims asserted against iHunt, (iii) to respond to a valid legal request relating to a criminal investigation to address alleged or suspected illegal activity, or to respond to or address any other activity that may expose us, you, or any other of our users to legal or regulatory liability, (iv) to enforce and administer our agreements with Landowners, or (v) to protect the rights, property or personal safety of iHunt and its associates </p>
       <p>For jurisdictions where iHunt facilitates the collection and remittance of Taxes where legally permissible according to applicable law, we may disclose Land Owner and Hunters information about transactions, bookings, Accommodations and Taxes to the applicable tax authority, such as land owner and hunters names, Listing addresses, transaction dates and amounts, tax identification number(s), the amount of taxes received (or due) by land owners or business owners from hunters, and contact information.</p>
       <h4  className="mt-5 ">5. OTHER IMPORTANT INFORMATION</h4>
       <h5  className="mt-5 ">5.1 Analyzing your Communications.</h5>
       <p>We may review, scan, or analyze your communications on the iHunt Platform, including fraud prevention, risk assessment, regulatory compliance, investigation, product development, research, analytics, enforcing our Policies and customer support purposes. For example, as part of our fraud prevention efforts, we may need to manually review communications, such as for fraud investigations and customer support. We will not review, scan, or analyze your messaging communications to send third-party marketing messages to you and we will not sell reviews or analyses of these communications.</p>
       <h4 className="mt-5 ">6. YOUR RIGHTS</h4>
       <h5 className="mt-5 ">6.1 Managing Your Information.</h5>
       <p>You can access and update some of your personal information through your Profile settings.  You are responsible for keeping your personal information up to date.</p>
       <h5 className="mt-5 ">6.2 Data Erasure.</h5>
       <p> In the event that you delete your account or any data, we may retain information in inactive accounts information for up to three years from time of deletion. </p>
      <h4 className="mt-5 ">7. SECURITY</h4>
      <p>While no organization can guarantee perfect security, we are continuously implementing and updating administrative, technical, and physical security measures to help protect your information against unauthorized access, loss, destruction, or alteration.</p>
      <h4 className="mt-5 ">8. CHANGES TO THIS PRIVACY POLICY</h4>
      <p>We reserve the right to modify this Privacy Policy at any time in accordance with applicable law. If we do so, we will post the revised Privacy Policy and update the “Last Updated” date at the top. In case of material changes, we will also provide you with notice of the modification by email. If you disagree with the revised Privacy Policy, you can cancel your Account. If you do not cancel your Account before the date the revised Privacy Policy becomes effective, your continued access to or use of the iHunt Platform will be subject to the revised Privacy Policy.</p>
       
       <h4  className="mt-5 "> 9. CONTACT INFORMATION FOR iHunt LLC.</h4>
       <p>For questions or complaints about this Privacy Policy or iHunt’s handling of personal information please write us at 1110 Clyde Marengo Rd. Clyde, Ny. 14433  or email us at <a className="link cursor-pointer" onClick={()=>window.location = 'mailto:contact@ihunt.com'}> contact@ihunt.com</a></p>
        </div>
      </div>
    </div>
  );
}
