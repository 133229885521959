import React from "react";
import Section1Img from "./../../../../assets/images/hunt-for-hunter.jpg";
import PlaceHolderImage from "./../../../../assets/images/hunt-for-hunter-1.jpg";
import "./style.scss";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


export default function huntForHunter() {
    return (
        <section className="hunt-for-hunter-bg">
            <div className="inner-container">
                <div className="row">
                    <div className="col-12">
                        <h3 className="col-12 d-flex justify-content-center">iHunt For The Hunter</h3>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="left pe-lg-5 me-lg-5">
                            <div className="left-inner pe-lg-5">
                            <h5>Welcome to iHunt.</h5>
                                <p>Join our hunting community for access to the future of hunting. Simply book your next hunt right on your phone. Here’s how it works.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="right">
                            <div className="right-inner rounded-5">
                                <LazyLoadImage
                                    src={Section1Img}
                                    alt=""
                                    className="lazy-load-image"
                                    placeholderSrc={PlaceHolderImage}
                                    visibleByDefault={Section1Img}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
