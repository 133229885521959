import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Spinner from "components/UI/Spinner.js";
import "./style.scss";

export default function Success() {
  const history = useHistory()
  const location = useLocation();
  const [load, setLoad] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setTitle(queryParams.get("process_completion_title") || "");
    setDescription(queryParams.get("process_completion_description") || "");
  }, [location.search]);

  const handleRedirect = () => {
    setLoad(true);
    setTimeout(() => {
      setLoad(false);
      history.push("/my-profile/my-reservations")
    }, 2000);
  }

  return (
    <div className="reservation-success-wrapper">
      <h4>{title}</h4>
      <p>
        {description}{" "}
      </p>
      <button className="btn btn-primary text-white pd-btn" onClick={handleRedirect}>
        {load ? <Spinner /> : "MY RESERVATIONS"}
      </button>
    </div>
  );
}
