import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import miniLogo from "assets/images/miniLogo.png"
import mainLogo from "assets/images/mainLogo.png"
import topBanner from "assets/images/top-banner.png"
import bottomBanner from "assets/images/bottom-banner.png"
import { fetchStandDetail } from "modules/landingPage/_redux/landingPageActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Polyshape } from "./polyshape";
import "./style.scss";

export const HuntPackage = () => {

    const [lat, setLat] = useState(0);
    const [lng, setLng] = useState(0)
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        // api call to fetch stand detail
        const id = parseInt(history?.location?.search?.replace("?standId=", ""))
        dispatch(fetchStandDetail(id));
        navigator.geolocation?.getCurrentPosition(function (position) {
            setLat(position.coords.latitude);
            setLng(position.coords.longitude);
        });
    }, []);

    const {
        standDetails,
    } = useSelector(
        (state) => ({
            standDetails: state.landingPage.standDetails,
        }),
        shallowEqual
    );

    const parkingRoute = JSON.parse(standDetails && standDetails?.geo_location_a_p)?.map(item => {
        return {
            lat: item.lat,
            lng: item.lng
        }
    })

    const standRoute = JSON.parse(standDetails && standDetails?.geo_location_p_s)?.map(item => {
        return {
            lat: item.lat,
            lng: item.lng
        }
    })

    const boundaryRoute = JSON.parse(standDetails && standDetails?.boundary_route)?.map(item => {
        return {
            lat: item.lat,
            lng: item.lng
        }
    })

    const positions = {
        stand: {
            lat: standDetails?.stand_latitude,
            lng: standDetails?.stand_longitude
        },
        parking: {
            lat: standDetails?.parking_latitude,
            lng: standDetails?.parking_longitude
        }
    }

    return (
        <div
            style={{
                margin: 0,
                backgroundColor: "#f1f1f1",
                color: "#ffffff",
                fontweight: "400px",
                lineHeight: "normal",
                fontSize: "16px"
            }}
        >
            <div style={{
                maxWidth: "800px",
                margin: "auto",
                backgroundColor: "#000000"
            }}>
                <div class="header"
                    style={{
                        backgroundImage: `url(${topBanner})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "right",
                        paddingTop: "46px",
                        paddingBottom: "70px",
                        paddingLeft: "40px",
                        paddingRight: "15px"
                    }}>
                    <div class="logo" style={{
                        width: "304px",
                        textAlign: "center"
                    }}>
                        <img src={miniLogo} class="mini-logo" alt="" />
                        <img src={mainLogo} class="big-logo" alt=""
                            style={{
                                maxWidth: "280px",
                                position: "relative",
                                top: "-45px"
                            }} />
                    </div>
                </div>

                <table style={{
                    paddingTop: "0px",
                    paddingBottom: "10px",
                    paddingLeft: "45px",
                    paddingRight: "45px"
                }}>
                    <tbody>
                        <tr>
                            <td data-colspan-max style={{
                                display: "block",
                                maxWidth: "100px !important",
                                clear: "both",
                                width: "100% !important"
                            }}>
                                <div style={{
                                    marginBottom: "20px"
                                }}>
                                    <span style={{
                                        color: "#EA9B45",
                                        fontSize: "28px",
                                        fontWeight: "500px"
                                    }}>{standDetails?.title}</span>
                                    <p style={{
                                        fontSize: "14px",
                                        color: "#B1B1B1",
                                        marginTop: "8px",
                                        marginBottom: "25px"
                                    }}>
                                        <span>
                                            <svg style={{
                                                verticalAlign: "middle",
                                                marginRight: "5px"
                                            }} xmlns="http://www.w3.org/2000/svg"
                                                width="15.121" height="20.876" viewBox="0 0 15.121 20.876">
                                                <link xmlns="" type="text/css" rel="stylesheet" id="dark-mode-custom-link" />
                                                <link xmlns="" type="text/css" rel="stylesheet" id="dark-mode-general-link" />
                                                <style xmlns="" lang="en" type="text/css" id="dark-mode-custom-style" />
                                                <style xmlns="" lang="en" type="text/css" id="dark-mode-native-style" />
                                                <style xmlns="" lang="en" type="text/css" id="dark-mode-native-sheet" />
                                                <g id="maps-and-flags" transform="translate(0 2)">
                                                    <g id="Group_2732" data-name="Group 2732">
                                                        <path id="Path_581" data-name="Path 581"
                                                            d="M78.133,0a7.569,7.569,0,0,0-7.56,7.56c0,5.174,6.766,12.769,7.054,13.09a.681.681,0,0,0,1.013,0c.288-.321,7.054-7.916,7.054-13.09A7.569,7.569,0,0,0,78.133,0Zm0,11.364a3.8,3.8,0,1,1,3.8-3.8A3.808,3.808,0,0,1,78.133,11.364Z"
                                                            transform="translate(-70.573 -2)" fill="#928f8f" />
                                                    </g>
                                                </g>
                                            </svg>
                                        </span>
                                        {standDetails?.address}
                                    </p>
                                </div>
                            </td>
                            <td data-colspan-max
                                style={{
                                    display: "block",
                                    maxWidth: "100% !important",
                                    clear: "both",
                                    width: "100% !important"
                                }}>
                                {
                                    standDetails?.notes_to_hunter && <div style={{
                                        marginBottom: "15px",
                                        paddingBottom: "15px",
                                        borderBottom: "1px solid #2D2D2D"
                                    }}>
                                        <span style={{
                                            color: "#EA9B45",
                                            fontSize: "28px",
                                            fontweight: "500px"
                                        }}>Landowner Notes</span>
                                        <p style={{
                                            fontSize: "14px",
                                            color: "#ffffff",
                                            marginTop: "8px",
                                            marginBottom: "6px",
                                            maxHeight: "82px",
                                            textsizeAdjust: "auto",
                                            webkitLineClamp: "3px",
                                            WebkitBoxOrient: "vertical",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis"
                                        }}>{standDetails?.notes_to_hunter}</p>
                                    </div>
                                }
                            </td>
                            <td data-colspan-max
                                style={{
                                    display: "block",
                                    maxWidth: "100% !important",
                                    clear: "both",
                                    width: "100% !important"
                                }}>
                                <div style={{ marginBottom: "15px" }}>
                                    <span style={{
                                        color: "#EA9B45",
                                        fontSize: "28px",
                                        fontWeight: "500px"
                                    }}>Stand Property Overview</span>
                                    <p style={{
                                        fontSize: "14px",
                                        color: "#ffffff",
                                        marginTop: "8px",
                                        marginBottom: "15px"
                                    }}>Here is an overview of
                                        the property you will be hunting, please use this to familiarize yourself with this location and have a
                                        happy and safe hunting experience tomorrow!Please feel free to print these off if you would rather use
                                        hard copies instead of in app information.</p>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table style={{
                    paddingTop: "0px",
                    paddingBottom: "50px",
                    paddingLeft: "45px",
                    paddingRight: "45px"
                }}>
                    <tbody>
                        <tr class="stack-on-767">
                            <td style={{
                                width: "28%",
                                verticalAlign: "sub"
                            }}>
                                <div>
                                    <span style={{
                                        color: "#EA9B45",
                                        fontSize: "19px",
                                        fontWeight: "500px"
                                    }}>Speices</span>
                                    <ul style={{
                                        display: "flex",
                                        listStyle: "none",
                                        marginBottom: "30px",
                                        marginTop: "12px",
                                        paddingLeft: "0px"
                                    }}>
                                        {
                                            standDetails?.specie?.map(item => {
                                                return <li style={{
                                                    textAlign: "center",
                                                    marginRight: "10px"
                                                }}>
                                                    <div className="specie-circle">
                                                        <img src={item?.image} alt="" width={25} />
                                                    </div>
                                                    <span style={{
                                                        color: "#B1B1B1",
                                                        fontSize: "14px",
                                                        display: "block",
                                                        marginTop: 8
                                                    }}>{item?.specie_name}</span>
                                                </li>
                                            })
                                        }
                                    </ul>
                                </div>
                            </td>
                            <td style={{
                                width: "36%",
                                verticalAlign: "sub",
                                paddingLeft: "25px",
                                paddingRight: "25px"
                            }}>
                                <div style={{
                                    marginBottom: "15px",
                                }}>
                                    <span style={{
                                        color: "#EA9B45",
                                        fontSize: "19px",
                                        fontWeight: "500px"
                                    }}>Information & Guidelines</span>
                                    <p style={{
                                        fontSize: "14px",
                                        color: "#ffffff",
                                        marginTop: "8px",
                                        marginBottom: "8px"
                                    }}>
                                        {standDetails?.rules}
                                    </p>
                                    <p style={{
                                        fontSize: "14px",
                                        color: "#ffffff",
                                        marginTop: "0px",
                                        marginBottom: "8px"
                                    }}>
                                        You can cancel this hunt up to {standDetails?.cancellation_days} Day before hunt, a ${standDetails?.cancellation_fee} processing fee will be applied if this hunt is cancelled
                                    </p>
                                </div>
                            </td>
                            <td style={{
                                width: "32%",
                                verticalAlign: "sub"
                            }}>
                                <div>
                                    <span style={{
                                        color: "#EA9B45",
                                        fontSize: "19px",
                                        fontWeight: "500px"
                                    }}>Listing Detail: </span>
                                    <ul style={{
                                        listStyle: "none",
                                        marginBottom: "30px",
                                        paddingLeft: "0px",
                                        marginTop: 8
                                    }}>
                                        <li style={{
                                            fontSize: "14px",
                                            marginBottom: "6px"
                                        }}>Acreage: <span className="fw-700">{standDetails?.acres || "N/A"}</span>
                                        </li>
                                        <li style={{
                                            fontSize: "14px",
                                            marginBottom: "6px"
                                        }}>Hunting Site: <span className="fw-700">{standDetails && standDetails?.capacity?.capacity == 1 ?
                                            "Single Listing" : `${standDetails?.capacity?.capacity} Stands` || "N/A"}</span> </li>
                                        <li style={{
                                            fontSize: "14px",
                                            marginBottom: "6px"
                                        }}>Maximum Hunters on Property: <span className="fw-700">{standDetails?.maximum_hunters_on_property || "N/A"}</span> </li>
                                        <li style={{
                                            fontSize: "14px",
                                            marginBottom: "6px"
                                        }}>Cellular Coverage: <span className="fw-700">{standDetails?.cellular_coverage ? "Yes" : "No"}</span> </li>
                                        <li style={{
                                            fontSize: "14px",
                                            marginBottom: "6px"
                                        }}>Access Via Wheel Chair: <span className="fw-700">{standDetails?.handicap_accessible ? "Yes" : "No"}</span> </li>
                                        <li className="dfp-mec">Distance From Parking: <span
                                            className="fw-700">{standDetails?.parking_distance || "N/A"}</span> </li>
                                        <li className="dfp-mec">Maximum Elevation Change: <span
                                            className="fw-700">{standDetails?.boundary_avg_elevation || "N/A"}</span> </li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="page-break"></div>
                <div class="full-height">
                    <table style={{
                        paddingTop: "0px",
                        paddingBottom: "50px",
                        paddingLeft: "45px",
                        paddingRight: "45px",
                        width: "100%"
                    }}>
                        <tbody>
                            <tr class="stack-on-767">
                                <td style={{
                                    width: "31.5%",
                                    verticalAlign: "sub"
                                }}>
                                    <div>
                                        <span
                                            style={{
                                                color: "#EA9B45",
                                                fontSize: "19px",
                                                fontWeight: "500px",
                                                display: "block",
                                                marginBottom: "20px"
                                            }}>Parking
                                            Route</span>
                                        <div class="map" style={{
                                            border: "5px solid #707070",
                                            solid: "#707070",
                                            borderRadius: 10,
                                            overflow: "hidden"
                                        }}>
                                            <div class="inner-map" id="map-polyline1">
                                                {parkingRoute && <Polyshape polylinePath={parkingRoute} type="polyline" />}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                {standRoute && <td style={{
                                    verticalAlign: "sub",
                                    paddingLeft: "20px",
                                    paddingRight: "20px"
                                }}>
                                    <div style={{
                                        marginBottom: "15px"
                                    }}>
                                        <span
                                            style={{
                                                color: "#EA9B45",
                                                fontSize: "19px",
                                                fontWeight: "500px",
                                                display: "block",
                                                marginBottom: "20px"
                                            }}>Stand
                                            Route</span>
                                        <div class="map" style={{
                                            border: "5px solid #707070",
                                            borderRadius: "10px",
                                            overflow: "hidden",
                                            width: !boundaryRoute ? "220px" : null
                                        }}>

                                            <div class="inner-map" id="map-polyline2">
                                                {standRoute && <Polyshape polylinePath={standRoute} type="polyline" />}
                                            </div>
                                        </div>
                                    </div>
                                </td>}
                                {boundaryRoute &&
                                    <td style={{
                                        width: "31.5%",
                                        verticalAlign: "sub"
                                    }}>
                                        <div>
                                            <span
                                                style={{
                                                    color: "#EA9B45",
                                                    fontSize: "19px",
                                                    fontWeight: "500px",
                                                    display: "block",
                                                    marginBottom: "20px"
                                                }}>Hunting
                                                Boundary</span>
                                            <div class="map" style={{
                                                border: "5px solid #707070",
                                                borderRadius: "10px",
                                                overflow: "hidden"
                                            }}>

                                                <div class="inner-map" id="map-polygons">
                                                    <Polyshape polygonePath={boundaryRoute} type="polygone" positions={positions} />
                                                </div>
                                            </div>
                                        </div>
                                    </td>}
                            </tr>
                        </tbody>
                    </table>
                    <div class="footer" style={{
                        backgroundImage: `url(${bottomBanner})`,
                        backgroundRepeat: "no-repeat",
                        fontWeight: "200px",
                        fontSize: "16px",
                        textAlign: "center",
                        paddingTop: "0px",
                        backgroundPosition: "center",
                        paddingBottom: "60px",
                        paddingLeft: "15px",
                        paddingRight: "15px"
                    }}>
                        <div class="logo">
                            <img src={mainLogo} alt="" style={{
                                width: "187px",
                                margin: "auto",
                                marginBottom: "25px"
                            }} />
                        </div>
                        © COPYRIGHT 2023. iHunt LLC.RIGHTS RESERVED
                    </div>
                </div>
            </div>
        </div >

    )
}