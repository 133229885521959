import React, { useEffect } from "react";
import BannerHome from "./banner2";
import "./banner.scss"
import Section1Img from "./../../assets/images/simply-hunting-experience.png";
import PlaceHolderImage1 from "./../../assets/images/simply-hunting-experience-1.jpg";
import LoginScreen from "./../../assets/images/conveniencemobile.png";
import PlaceHolderImage2 from "./../../assets/images/conveniencemobile-1.jpg";
import Panda from "./../../assets/images/panda.png";
import PlaceHolderImage3 from "./../../assets/images/panda-1.jpg";
import Birds from "./../../assets/images/birds.png";
import PlaceHolderImage4 from "./../../assets/images/birds-1.jpg";
import BannerOld from "./../../modules/landingPage/pages/banner";
import Achievement from "./../../modules/landingPage/pages/achievements";
import Downloads from "./../../modules/landingPage/pages/downloads";
import Feedback from "./../../modules/landingPage/pages/feedback";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useHistory, useLocation } from "react-router-dom";


function NewHome() {
    useEffect(() => {document.title = 'Home | iHunt'}, []);
    
    const history = useHistory();
    const location = useLocation();
    useEffect(() => {
        let urlParams = new URLSearchParams(location.search);
        const params = Object.fromEntries(urlParams.entries());
        if (params.code) history.push(`/my-profile/payments/?code=${params.code}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]);

    return (
        <div>
            <BannerHome />
            <div className="hunter-page-main">
                <section className="hunt-for-hunter-bg home-bg1">
                    <div className="inner-container">
                        <div className="row">
                            <div className="col-12">
                                <h3 className="col-12 d-flex justify-content-center">Simplifying the Hunting Experience</h3>
                            </div>
                            <div className="col-xl-8 col-md-6">
                                <div className="left pe-xl-5 me-xl-5">
                                    <div className="left-inner pe-xl-5">
                                        <p>
                                            Gone are the days of door-to-door requests and negotiations. With iHunt, booking private hunting land is as easy as a few taps on your smartphone. Our intuitive and user-friendly app streamlines the process, allowing you to browse through a diverse range of property listings and secure your hunting ground effortlessly. Say goodbye to the frustrations of seeking permission and hello to a hassle-free hunting experience.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6">
                                <div className="right ms-0 hunting-exp-right">
                                    <div className="right-inner rounded-5">
                                        <LazyLoadImage
                                            src={Section1Img}
                                            alt=""
                                            className="lazy-load-image"
                                            placeholderSrc={PlaceHolderImage1}
                                            visibleByDefault={Section1Img}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="home-bg2 py-0">
                    <div className="overlay-account-detail-guide">
                        <div className="inner-container">
                            <div className="row justify-content-between align-items-center">
                                <div className="col-lg-4 col-md-6 order-lg-1 order-md-2 order-2">
                                    <div className="right ms-0">
                                        <div className="right-inner">
                                            <LazyLoadImage
                                                src={LoginScreen}
                                                alt=""
                                                className="lazy-load-image"
                                                placeholderSrc={PlaceHolderImage2}
                                                visibleByDefault={LoginScreen}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-6 order-lg-2 order-md-1 order-1">
                                    <div className="left me-0">
                                        <div className="left-inner">
                                            <h3>Transparency and Convenience</h3>
                                            <p >
                                                We value transparency and convenience. With iHunt, you have access to transparent pricing for all listed properties, eliminating the need for negotiations. You can compare rates, check availability, and make secure payments directly through the app. Our aim is to provide you with a seamless experience from start to finish, saving you time and energy.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="inner-container">
                        <div className="row justify-content-between">

                            <div className="col-xl-8 col-lg-8 col-md-6 order-lg-1 order-md-1 order-sm-1 order-1">
                                <div className="left">
                                    <div className="left-inner conservation">
                                        <h3>Join a Conservation-Minded Community</h3>
                                        <p>
                                            Connect with a vibrant community of like-minded hunters on iHunt. Share your experiences, exchange tips, and contribute to the collective knowledge base. By fostering a community focused on ethical hunting practices, we can create a positive impact on wildlife conservation. Leave reviews for properties you've hunted, helping others make informed decisions.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-5 order-lg-2 order-md-2 order-sm-2 order-2">
                                <div className="right ms-xl-3 ms-lg-0">
                                    <div className="right-inner">
                                        <LazyLoadImage
                                            src={Panda}
                                            alt=""
                                            className="lazy-load-image"
                                            placeholderSrc={PlaceHolderImage3}
                                            visibleByDefault={Panda}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="bg-gray">
                    <div className="inner-container">
                        <div className="row justify-content-between">
                            <div className="col-xxl-4 col-xl-5 col-md-5 order-lg-1 order-md-1 order-sm-2 order-2">
                                <div className="right ms-0 justify-content-start me-auto">
                                    <div className="right-inner">
                                        <LazyLoadImage
                                            src={Birds}
                                            alt=""
                                            className="lazy-load-image"
                                            placeholderSrc={PlaceHolderImage4}
                                            visibleByDefault={Birds}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-7 col-xl-6 col-md-6 order-lg-2 order-md-2 order-sm-1 order-1">
                                <div className="left">
                                    <div className="left-inner">
                                        <h3>Discover the Future</h3>
                                        <p>
                                            Download our app today and embark on unforgettable hunting adventures. Together, let's make a difference. Discover, Connect, Hunt - the iHunt Way.
                                        </p><p>
                                            Happy Hunting!
                                            <br /> The iHunt Team
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="old-banner">
                    <BannerOld />
                </div>
                <Achievement />
                <Downloads />
                <Feedback />
            </div>
        </div>
    );
}

export default NewHome;
