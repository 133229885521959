import React, { useEffect } from "react";
import "./style.scss";

export default function About() {
  useEffect(() => {document.title = 'About | iHunt'}, []);
  return (
    <div>
      <div className="about-page">
        <section className="youtube-video-frame about-v2">
          <div className="about-bg2">
            <div className="inner-container">
                <div className="row">
                    <div className="col-12">
                        <h3 className="d-flex justify-content-center pb-2 text-white">iHunt, the Future of Hunting</h3>
                    </div>
                    <div className="col mx-auto">
                        <div className="frame mx-auto">
                        <iframe width="100%" src="https://www.youtube.com/embed/AKwdOSgHGxc?si=1IeoiIehJ5TdBTIs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
