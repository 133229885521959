import React from "react";
import { Modal } from "react-bootstrap";
import trashIcon from "../../../../assets/images/trash-stand.svg";
import "./style.scss";


export function DeletedStand({ show, onHide }) {
  return (
    <Modal
      show={show}
      onHide={() => onHide(false)}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="deleted-stand-div"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="deleted-stand-body">
          <img src={trashIcon} alt="trash" />
          <p className="title">Listing Deleted</p>
          <span className="body">
            We are sorry to inform you that this listing has been deleted by the
            owner
          </span>
        </div>
      </Modal.Body>
    </Modal>
  );
}
