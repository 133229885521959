import React, { useState } from "react";
import aboutimage from "./../../../../assets/images/about-iHunt.png"
import { EditStandModal } from "modules/dashboard/pages/ownerDashboard/mystands/standItem/editModal/editModal";
import "./style.scss";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import PlaceHolderImage from "./../../../../assets/images/about-iHunt-1.jpg"


export default function AboutiHunt() {
    const [openModal, setOpenModal] = useState(false);

    const handleModalOpen = () => {
        setOpenModal(true)
    }

    const handleModalClose = () => {
        setOpenModal(false)
    }

    return (
        <>
            <section className="about-iHunt-section">
                <div className="inner-container">
                    <div className="row">
                        <div className="col-lg-6 order-lg-1 order-2">
                            <div className="left">
                                <div className="left-inner">
                                    <h3>What is iHunt?</h3>
                                    <p>
                                        Learn how iHunt can reduce landowner headaches and increase revenue on your property by giving you the tools you need to manage hunting on your properties.
                                    </p>
                                    <p>
                                        Today more than ever people are willing to pay to access private hunting land, and this can be a huge <br />revenue for landowners. The problem is the time and energy it takes for landowners to manage hunters.
                                    </p>
                                    <p>
                                        We are here to change everything by streamlining and simplifying all the paperwork and scheduling with our state of the art property management app. Read on, or download our app to see the iHunt Difference.
                                    </p>
                                    <button className="btn btn-primary text-white text-uppercase" onClick={handleModalOpen}>DOWNLOAD APP</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 order-lg-2 order-1 mb-lg-0 mb-4">
                            <div className="right">
                                <div className="img">
                                    <LazyLoadImage
                                        src={aboutimage}
                                        alt=""
                                        className="lazy-load-image"
                                        placeholderSrc={PlaceHolderImage}
                                        visibleByDefault={aboutimage}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <EditStandModal
                show={openModal}
                onHide={handleModalClose}
            />
        </>
    );
}
