import React, { useEffect, useMemo, useState } from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import Spinner from "components/UI/Spinner.js";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAuthUIContext } from "../AuthUIContext";
import EyeOffIcon from "assets/icons/feather-icon - eye-off.svg";
import EyeOnIcon from "assets/icons/feather-eye.svg";
import { useHistory, useLocation } from "react-router";
import * as actions from "../../_redux/authActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import toastHandler from "components/UI/toastHandler/toastHandler.js";
import { checkLinkExpiry } from "../../_redux/authActions";
import oops from "assets/images/oops.png"
import "./style.scss";


export default function ResetPassword() {
  const dispatch = useDispatch();
  const history = useHistory();
  const toastId = history.location.pathname;
  const queryParams = useLocation().search;

  const { isLoading, isExpired, expiryMessage } = useSelector(
    (state) => ({
      isLoading: state.auth.actionsLoading,
      isExpired: state.auth.isExpired,
      expiryMessage: state.auth.expiryMessage
    }),
    shallowEqual
  );

  const getEmail = () => {
    if (history?.location?.search && history?.location?.search?.includes("email=")) {
      return history.location.search.split("email=")[1]
    } else return ""
  }

  const { token, email } = useMemo(() => {
    return {
      token: new URLSearchParams(queryParams).get("token"),
      email: getEmail(),
    };
  }, [queryParams]);

  useEffect(() => {
    if (history.location.search) {
      dispatch(checkLinkExpiry({
        token: token,
        email: getEmail()
      }))
    }
  }, [])

  useEffect(() => {
    if (token) {
      formik.setFieldValue("token", token);
    }
    if (email) {
      formik.setFieldValue("email", email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, email]);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const authUIContext = useAuthUIContext();
  const authUIProps = useMemo(() => {
    return {
      initResetPassword: authUIContext.initResetPasword,
    };
  }, [authUIContext]);


  const validationSchema = Yup.object().shape({
    token: Yup.string().required("token is required"),
    email: Yup.string()
      .email("Enter a valid email")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
    password_confirmation: Yup.string()
      .required("Confirmation Password is required")
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password and Confirm Password didn't match"
        ),
      }),
  });

  const handleSubmit = (values) => {
    dispatch(actions.resetPassword(values, handleError, handleSuccess));
  };

  const handleSuccess = () => {
    toastHandler("Successfully reset password, continue login", "success");
    history.push("/auth/reset-password/success");
  };

  const handleError = (message) => {
    toastHandler(message, "error", toastId);
  };

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: authUIProps.initResetPassword,
    onSubmit: handleSubmit,
  });

  useEffect(() => { document.title = 'Reset Password | iHunt' }, []);

  return (
    <div className="inner-container">
      {(history.location.search && isExpired) ?
        <div className="cintainer-div">
          <img src={oops} alt="" />
          <p className="title-message">{expiryMessage}</p>
        </div> :
        <div className="form-container">
          <h2 className="text-center mb-1 mt-5">Reset Password</h2>
          <p className="text-center">
            Create a new strong password that you don't use for other websites
          </p>
          <div className="form-box">
            <Form onSubmit={formik.handleSubmit}>
              <FloatingLabel controlId="floatingInputGrid" label="Password">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                />
                <div
                  className="input-icon"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                >
                  <img src={showPassword ? EyeOnIcon : EyeOffIcon} alt="" />
                </div>
              </FloatingLabel>
              {formik?.errors?.password && formik?.touched?.password && (
                <Form.Control.Feedback type="d-block invalid">
                  {formik.errors.password}
                </Form.Control.Feedback>
              )}
              <FloatingLabel
                controlId="floatingInputGrid"
                label="Confirm Password"
              >
                <Form.Control
                  type={showConfirmPassword ? "text" : "password"}
                  name="password_confirmation"
                  placeholder="Confirm Password"
                  onChange={formik.handleChange}
                  value={formik.values.password_confirmation}
                />
                <div
                  className="input-icon"
                  onClick={() => {
                    setShowConfirmPassword(!showConfirmPassword);
                  }}
                >
                  <img
                    src={showConfirmPassword ? EyeOnIcon : EyeOffIcon}
                    style={{ flexShrink: 0 }}
                    alt=""
                  />
                </div>
                {formik?.errors?.password_confirmation &&
                  formik?.touched?.password_confirmation && (
                    <Form.Control.Feedback type="d-block invalid">
                      {formik.errors.password_confirmation}
                    </Form.Control.Feedback>
                  )}
              </FloatingLabel>
              <button className="btn btn-primary w-100 text-white mt-4">
                {isLoading ? (
                  <Spinner />
                ) : (
                  "UPDATE PASSWORD"
                )}
              </button>
            </Form>
          </div>
        </div>}
    </div>
  );
}
