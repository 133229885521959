import React from "react";
import "./style.scss";
import PinMap from "../../../../assets/icons/pin-location.svg";
import CustomSpinner from "components/layout/Loader/Spinner";

export default function Locations({ land }) {
  const getDirections = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      const from = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
      const desitnation = {
        lat: land?.property_address_latitude,
        lng: land?.property_address_longitude,
      };
      const link = document.createElement("a");
      link.href = `https://www.google.com/maps/dir/?api=1&origin=${from.lat},${from.lng}&destination=${desitnation.lat},${desitnation.lng}`;
      link.target = "_blank";
      link.click();
      link.remove();
    });
  };

  return (
    <>
      {land ? (
        <div className="locations-tab me-md-3">
          <h4 className="mt-5 mb-3">All Locations</h4>
          <div className="all-location-holder">
            <div className="all-locations">
              <div className="icons">
                <div>
                  <img src={PinMap} alt="" />
                </div>
              </div>
              <div className="location-detail">
                <div>
                  <p className="mb-1">{land?.address}</p>
                  <a
                    className="text-primary text-decoration-none cursor-pointer"
                    onClick={getDirections}
                  >
                    <i className="bi bi-arrow-90deg-right me-3"></i>Get directions
                  </a>
                </div>
              </div>
            </div>
            <div className="all-locations">
              <div className="icons">
                <i className="bi bi-info-circle me-2"></i>
              </div>
              <div className="location-detail">
                <p className="mb-1">Enable parking and listing location</p>
                <p className="caption">
                  To Enable parking and listing location please go to your mobile
                  app.
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <CustomSpinner />
      )}
    </>
  );
}
