import React, { useEffect } from "react";
import * as firebase from "../../firebase";
import { onMessage } from "firebase/messaging";
import { useDispatch, useSelector } from "react-redux";
import { chatSlice } from "modules/dashboard/_redux/chat/chatSlice";
import * as landingPageActions from "modules/landingPage/_redux/landingPageActions";
const { actions } = chatSlice;

export default function ChatListener({ children }) {
  const chatData = useSelector((state) => state.chat);
  const dispatch = useDispatch();
  onMessage(firebase.messaging, (payload) => {
    let data = {
      ...payload?.data,
      data: payload?.data?.data ? JSON?.parse(payload?.data?.data) : null
    };
    if (data?.type == 'chat' && data?.data?.stand_reservation_id === chatData.activeThread.id) {
      dispatch(actions.pushNewMessage(data?.data));
    } else {
      // Todo
      //Show Notification
    }
    dispatch(landingPageActions.toggleNewNotificationFlag(true));
  });

  return <>{children}</>;
}
